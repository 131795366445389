import styled from '@emotion/styled'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Loading, Typography } from '../../ui'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { paths } from '../paths'
import {
  getArticleResources,
  getVideoResources,
  selectArticlesList,
  selectArticlesLoading,
  selectVideosList,
  selectVideosLoading,
} from './resourcesSlice'
import { ArticleResource, VideoResource } from './types'

type ResourceType = 'article' | 'video' | undefined

export const ResourceLinkedPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { resourceId } = useParams<{ resourceId: string; type: string }>()
  const videoResources = useSelector(selectVideosList)
  const articleResources = useSelector(selectArticlesList)
  const [type, setType] = useState<ResourceType>(undefined)
  const [video, setVideo] = useState<VideoResource>()
  const [article, setArticle] = useState<ArticleResource>()
  const videosLoading = useSelector(selectVideosLoading)
  const articlesLoading = useSelector(selectArticlesLoading)
  const [resourceIndex, setResourceIndex] = useState(0)

  useEffect(() => {
    dispatch(getVideoResources(resourceId))
    dispatch(getArticleResources(resourceId))
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (videoResources.length > 0) {
      setType('video')
      setResourceIndex(
        videoResources.findIndex((res: VideoResource) => res._id === resourceId)
      )
      const foundResource = videoResources.find(
        (res: VideoResource) => res._id === resourceId
      )
      if (foundResource) {
        setVideo(foundResource)
      }
    } else if (articleResources.length > 0) {
      setType('article')
      setResourceIndex(
        articleResources.findIndex(
          (res: ArticleResource) => res._id === resourceId
        )
      )
      const foundResource = articleResources.find(
        (res) => res._id === resourceId
      )
      if (foundResource) {
        setArticle(foundResource)
      }
    }
  }, [resourceId, videoResources, articleResources])

  const handleBack = () => {
    history.push(paths.app.dashboard())
  }

  if (videosLoading || articlesLoading) {
    return (
      <PageWrapper showBack={false}>
        <Box className={classes.loading}>
          <Loading />
        </Box>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper showBack={false} paper={false}>
      <Box className={classes.resourceContainer}>
        <Header>
          <IconButton
            onClick={handleBack}
            disableRipple
            color="primary"
            className={classes.backIcon}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" align="center">
            {video?.title}
          </Typography>
        </Header>
        <Content>
          {type === 'video' ? (
            <div className={classes.articleContainer}>
              {video?.thumbnailURL && !video?.mediaURL && (
                <ThumbnailURL src={video?.thumbnailURL} alt="thumbnail" />
              )}
              {video?.mediaURL && (
                <Video
                  src={video?.mediaURL}
                  controls
                  autoPlay
                  className={classes.img}
                />
              )}
              <Box className={classes.htmlContainer}>
                {video?.description && (
                  <Box className={classes.articleContent}>
                    <ArticleInner
                      dangerouslySetInnerHTML={{ __html: video.description }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ) : (
            <Box className={classes.articleContainer}>
              {article?.imageURL && (
                <Box className={classes.imageContainer}>
                  <img
                    src={article?.imageURL}
                    alt="thumbnail"
                    className={classes.img}
                  />
                </Box>
              )}
              <Box
                className={
                  article?.imageURL
                    ? classes.htmlContainer
                    : classes.htmlContainerNoImage
                }
              >
                <Typography
                  className={classes.title}
                  variant="h6"
                  align="center"
                >
                  {article?.title}
                </Typography>
                {article?.html && (
                  <Box className={classes.articleContent}>
                    <ArticleInner
                      dangerouslySetInnerHTML={{ __html: article.html }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Content>
      </Box>
    </PageWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: '125px',
  },
  resourceContainer: {
    borderRadius: '16px',
    backgroundColor: '#FFF',
    padding: '32px 48px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
      margin: '-24px',
    },
  },
  htmlContainerNoImage: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(90vh)',
      overflow: 'scroll',
    },
  },
  htmlContainer: {
    width: '80%',
    overflow: 'auto',
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      // height: 'calc(55vh)',
      // overflow: 'auto',
      marginTop: 0,
      width: '90%',
    },
  },
  articleContent: {
    padding: '0 15px',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    margin: '48px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  img: {
    // maxWidth: 500,
    margin: '48px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0px',
      maxWidth: 350,
    },
  },
  title: {
    color: '#505358',
    fontSize: '36px',
    lineHeight: '47px',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      lineHeight: '31px',
    },
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backIcon: {
    'color': '#003C72',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    [theme.breakpoints.down(576)]: {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',

      alignItems: 'center',
    },
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '0px 24px',
    width: '100%',
    // margin: '0px auto',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: '90%',
      minHeight: '46px',
    },
  },
}))

const Video = styled.video`
  min-height: 200px;
  width: 100%;
`
const ArticleInner = styled.div`
  line-height: 1.43;
  @media (max-width: 479px) {
    -webkit-line-clamp: 7;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
`
const DiseaseInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const ResourceTitle = styled.div`
  margin-bottom: 24px;
  font-size: 32px;
  color: #666666;
`
const ThumbnailURL = styled.img`
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Content = styled.div`
  flex: 1;
  width: 100%;
  box-sizing: border-box;
`
const Header = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  align-items: center;
  color: rgb(0, 60, 114);
`
