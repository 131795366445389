import * as Yup from 'yup'
import { I18n } from '../../features/translation/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (i18n: I18n) {
  return Yup.object().shape({
    insuranceId: Yup.string().required(i18n.required),
    groupNumber: Yup.string().required(i18n.required),
    planName: Yup.string().required(i18n.required),
    coverageDate: Yup.date(),
  })
}
