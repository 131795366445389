import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  downloadLabReportPDF,
  selectOrderPDF,
} from '../../../pages/test-details/model'
import { ModuleHeader } from '../../../ui/templates/modules/moduleHeader'
import { selectLanguageContent } from '../../translation'

export const PDFViewer = (props: { onClose: () => void; id: string }) => {
  const { onClose, id } = props
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const pdf = useSelector(selectOrderPDF)
  const classes = useStyles()

  useEffect(() => {
    dispatch(downloadLabReportPDF(id))
  }, [id])

  const downloadLabReport = () => {}

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={onClose}
      color="#505358"
      rightActionIcon={<CloudDownloadIcon className={classes.rightIcon} />}
      rightContentAction={downloadLabReport}
    >
      <div style={{ height: 'calc(100vh - 65px)' }}>
        {pdf && (
          <object
            data={pdf}
            className={classes.embed}
            type="application/octet-stream"
            width="100%"
            height="700px"
          ></object>
        )}
      </div>
    </ModuleHeader>
  )
}

const useStyles = makeStyles((theme) => ({
  embed: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      height: 'unset',
    },
  },
  rightIcon: {
    marginRight: 48,
    [theme.breakpoints.down(600)]: {
      marginRight: '5vw',
    },
  },
  backIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10,
    [theme.breakpoints.down(600)]: {
      marginRight: '8px',
    },
  },
}))
