import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { USAStateList } from '../../constants'
import { SubHeaderGetTested } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Button, Select } from '../../ui'
import {
  Content,
  Controls,
  FormWrapper,
  useStyles,
  Wrapper,
} from '../../ui/templates/app-layout'
import { selectPolicyHolderInfo, setPolicyholderInfo } from '../insurance/model'
import { paths } from '../paths'
import { FormValues } from './types'
import validationSchema from './validationSchema'

const InputMask = require('react-input-mask')

export const PolicyHolderInfoPage = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const policyHolderInfo = useSelector(selectPolicyHolderInfo)
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory<any>()
  const { type, id } = useParams<{
    type: string
    id: string
  }>()

  const [formValues, setFormValues] = useState({
    firstName: policyHolderInfo?.firstName || '',
    lastName: policyHolderInfo?.lastName || '',
    mobileNumber: policyHolderInfo?.phone || '',
    dateOfBirth: policyHolderInfo?.birthDate
      ? moment(policyHolderInfo?.birthDate, 'YYYY-MM-DD').toDate()
      : new Date(),
    relationship: policyHolderInfo?.relationship || '',
    line1: policyHolderInfo?.address1 || '',
    line2: policyHolderInfo?.address2 || '',
    city: policyHolderInfo?.city || '',
    state: policyHolderInfo?.state || USAStateList[0].value,
    zip: policyHolderInfo?.zipCode || '',
  })
  const dispatch = useDispatch()

  const handleBack = () => {
    history.goBack()
  }

  const onSubmit = async (values: FormValues) => {
    const policyholderInfo = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.mobileNumber,
      birthDate: moment(values.dateOfBirth).format('YYYY-MM-DDT00:00:00:00'),
      relationship: values.relationship,
      address1: values.line1,
      address2: values.line2,
      city: values.city,
      state: values.state,
      zipCode: values.zip,
    }
    await dispatch(setPolicyholderInfo(policyholderInfo))
    history.push(paths.confirmInsurance(type, id, 'edit'))
  }

  const formik = useFormik({
    initialValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      mobileNumber: formValues.mobileNumber,
      dateOfBirth: formValues.dateOfBirth,
      relationship: formValues.relationship,
      line1: formValues.line1,
      line2: formValues.line2,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
    },
    validationSchema: validationSchema(i18n),
    enableReinitialize: true,
    onSubmit,
  })

  return (
    <Wrapper>
      <SubHeaderGetTested
        title={i18n.policy_holder_screen_title}
        onHandleBack={handleBack}
      />
      <Wrapper>
        <Content onSubmit={formik.handleSubmit} noValidate>
          <FormWrapper>
            <FirstName formik={formik} i18n={i18n}/>
            <LastName formik={formik} i18n={i18n} />
            <PhoneNumber formik={formik} i18n={i18n} />
            <DateOfBirth formik={formik} i18n={i18n} />
            <Relationship formik={formik} i18n={i18n} />
            <StreetAddress formik={formik} i18n={i18n} />
            <Apt formik={formik} i18n={i18n} />
            <City formik={formik} i18n={i18n} />
            <State formik={formik} i18n={i18n} />
            <Zip formik={formik} i18n={i18n} />
          </FormWrapper>
        </Content>
        <Controls style={{ marginBottom: 16 }}>
          <Button
            className={`${classes.button} ${childClasses.btn}`}
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {i18n.continue_button}
          </Button>
        </Controls>
      </Wrapper>
    </Wrapper>
  )
}

const FirstName = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.first_name}
      name="firstName"
      value={formik.values.firstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.firstName && formik.touched.firstName)}
      helperText={formik.touched.firstName ? formik.errors.firstName : ''}
      required
    />
  )
}

const LastName = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.last_name}
      name="lastName"
      value={formik.values.lastName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.lastName && formik.touched.lastName)}
      helperText={formik.touched.lastName ? formik.errors.lastName : ''}
      required
    />
  )
}

const PhoneNumber = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <InputMask
      mask="+1 (999) 999-9999"
      maskChar=""
      // alwaysShowMask={true}
      value={formik.values.mobileNumber}
      onChange={formik.handleChange}
      label={i18n.phone_number}
      name="mobileNumber"
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.mobileNumber && formik.touched.mobileNumber)}
      helperText={formik.touched.mobileNumber ? formik.errors.mobileNumber : ''}
      required
    >
      {(inputProps: any) => <TextField {...inputProps} />}
    </InputMask>
  )
}

const DateOfBirth = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  const handleChangeDate = (e: any) => {
    formik.setFieldValue('dateOfBirth', e)
  }
  return (
    <KeyboardDatePicker
      label={i18n.date_of_birth}
      name="dateOfBirth"
      format="yyyy/MM/dd"
      onChange={handleChangeDate}
      value={formik.values.dateOfBirth}
      error={Boolean(formik.errors.dateOfBirth && formik.touched.dateOfBirth)}
      helperText={formik.touched.dateOfBirth ? formik.errors.dateOfBirth : ''}
      required
      color="primary"
    />
  )
}

const Relationship = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.relationship}
      name="relationship"
      value={formik.values.relationship}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.relationship && formik.touched.relationship)}
      helperText={formik.touched.relationship ? formik.errors.relationship : ''}
    />
  )
}

const StreetAddress = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.street_address}
      id="line1"
      name="line1"
      value={formik.values.line1}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.line1 && formik.touched.line1)}
      helperText={formik.touched.line1 ? formik.errors.line1 : ''}
      disabled={formik.values.isSameAsShipping}
    />
  )
}
const Apt = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.apt_and_ste_short}
      id="line2"
      name="line2"
      value={formik.values.line2}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.line2 && formik.touched.line2)}
      helperText={formik.touched.line2 ? formik.errors.line2 : ''}
      disabled={formik.values.isSameAsShipping}
    />
  )
}
const City = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.city}
      id="city"
      name="city"
      value={formik.values.city}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.city && formik.touched.city)}
      helperText={formik.touched.city ? formik.errors.city : ''}
      disabled={formik.values.isSameAsShipping}
    />
  )
}
const State = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <Select
      options={USAStateList}
      label={i18n.state}
      value={formik.values.state}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      disabled={formik.values.isSameAsShipping}
      name="state"
    />
  )
}

const Zip = (props: { formik: any, i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.zip_code}
      id="zip"
      name="zip"
      type="number"
      value={formik.values.zip}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.zip && formik.touched.zip)}
      helperText={formik.touched.zip ? formik.errors.zip : ''}
      disabled={formik.values.isSameAsShipping}
    />
  )
}

const childStyles = makeStyles({
  btn: {
    margin: '8px',
    maxHeight: 48,
  },
})
