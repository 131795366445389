import { makeStyles } from '@material-ui/core/styles'
import { hexToRGBA } from '../../libs/helpers'

const SPACE_BETWEEN = 'space-between'

export const shopifyStoreStyles = makeStyles((theme) => ({
  pageTitle: {
    'color': '#282D37',
    'marginBottom': 32,
    'fontSize': 36,
    'fontWeight': 600,
    '&.noMargin': {
      marginBottom: 0,
    },
    [theme.breakpoints.down(600)]: {
      'paddingLeft': '5vw',
      '&.noPadding': {
        paddingLeft: 0,
      },
      '&.noMargin': {
        marginBottom: 0,
      },
    },
  },
  testListWrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(600)]: {
      gap: 16,
    },
  },
  listContent: {
    marginTop: 65,
    [theme.breakpoints.down(600)]: {
      width: '100vw',
      margin: '24px auto',
    },
  },
  cartContent: {
    marginTop: 65,
    width: '100%',
    margin: '0px auto',
    maxWidth: '1100px',
    [theme.breakpoints.down(960)]: {
      width: '100%',
      margin: '24px auto 0px',
    },
  },
  checkoutMethodContent: {
    marginTop: 65,
    width: '100%',
    margin: '0px auto',
    maxWidth: '500px',
    cursor: 'pointer',
    [theme.breakpoints.down(960)]: {
      width: '90%',
      margin: '24px auto 0px',
    },
  },
  spinner: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  productItemWrapper: {
    width: 'calc(33.333333% - 32px)',
    cursor: 'pointer',
    marginBottom: 48,
    padding: '16px',
    borderRadius: 8,
    [theme.breakpoints.down(600)]: {
      width: 'calc(50% - 8px)',
      padding: '0px',
    },
  },
  paymentStorageNotice: {
    fontSize: 12,
    color: '#757575',
    fontWeight: 500,
  },
  productItemImageWrapper: {
    height: 0,
    padding: '50% 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productItemDetailImg: {
    height: '12vw',
    maxHeight: '250px',
    [theme.breakpoints.down(600)]: {
      height: '20vw',
    },
  },
  productItemDetailWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 12,
    gap: 2,
    [theme.breakpoints.down(600)]: {
      margin: '24px 16px 0px',
    },
  },
  productItemName: {
    lineHeight: '150%',
    color: '#282D37',
    fontWeight: 600,
    width: '100%',
    fontSize: '22px',

    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
    },
  },
  productItemDesc: {
    lineHeight: '150%',
    color: '#757575',
    fontWeight: 500,
    fontSize: '19px',
    [theme.breakpoints.down(960)]: {
      fontSize: '12px',
    },
  },
  productItemPrice: {
    fontSize: '20px',
    lineHeight: '150%',
    fontWeight: 600,
    width: '100%',
    color: '#282D37',
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
      marginTop: 8,
    },
  },
  imageWrapper: {
    height: 0,
    padding: '50% 0px',
    width: '100%',
    maxWidth: 500,
    maxHeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5',
    [theme.breakpoints.down(960)]: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '90vw',
      height: '40vw',
      margin: '48px auto 0px',
      padding: 0,
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      margin: 0,
      width: '100vw',
      height: '100vw',
    },
  },
  imageLimit: {
    width: '100%',
    maxWidth: 500,
    [theme.breakpoints.down(1400)]: {
      maxWidth: '35vw',
    },
    [theme.breakpoints.down(960)]: {
      margin: '0px auto',
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
  },
  dialogWrapper: {
    padding: '30px 10px',
    height: 250,
    width: 350,
    [theme.breakpoints.down(960)]: {
      height: 'unset',
      width: 'unset',
      padding: '30px 0px',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  img: {
    height: '15vw',
    maxHeight: '250px',
    [theme.breakpoints.down(960)]: {
      width: '50vw',
      maxHeight: 'unset',
      height: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      objectFit: 'cover',
      width: '80%',
    },
  },
  imgThumbnailContainer: {
    display: 'flex',
    gap: 16,
    margin: '24px 0px',
    [theme.breakpoints.down(600)]: {
      margin: 0,
      position: 'absolute',
      bottom: 16,
    },
  },
  selectedImageOverlay: {
    width: 100,
    height: 100,
    zIndex: 1001,
    background: hexToRGBA(theme.palette.primary.main, 0.9),
  },
  imageThumbnailWrapper: {
    'height': 100,
    'width': 100,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'backgroundColor': '#F5F5F5',
    '&.selected': {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
    },
    [theme.breakpoints.down(600)]: {
      'borderRadius': '100%',
      'width': '10px',
      'height': '10px',
      'backgroundColor': hexToRGBA(theme.palette.primary.main, 0.2),
      '&.selected': {
        backgroundColor: hexToRGBA(theme.palette.primary.main, 1),
      },
    },
  },
  imgThumbnail: {
    '&.selected': {
      opacity: 0.7,
    },
    'height': '50px',
  },
  htmlContainer: {
    width: '100%',
    overflow: 'auto',
  },
  content: {
    maxWidth: 500,
    display: 'flex',
    gap: 32,
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    [theme.breakpoints.down(960)]: {
      maxWidth: 'unset',
      width: '90%',
      margin: '0px 64px',
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: 'unset',
      width: '90%',
      margin: '0px auto',
    },
  },
  minorHeader: {
    color: '#757575',
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  includedLabel: {
    color: '#505358',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  testName: {
    fontWeight: 600,
    color: '#282D37',
    fontSize: 32,
    textAlign: 'left',
    lineHeight: '110%',
    margin: '4px 0px',
    [theme.breakpoints.down(960)]: {
      fontSize: 24,
      fontWeight: 500,
      margin: '24px 0px 0px',
    },
  },
  altName: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'left',
  },
  descriptionTitle: {
    fontWeight: 600,
    color: '#505358',
    fontSize: 12,
    textAlign: 'left',
    lineHeight: '170%',
    letterSpacing: '1.25px',
  },
  description: {
    fontWeight: 500,
    color: '#757575',
    fontSize: 16,
    textAlign: 'left',
    lineHeight: '170%',
  },
  cart: {
    [theme.breakpoints.down(600)]: {
      margin: 0,
    },
  },
  quantityWrapper: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  quantityTextWrapper: {
    borderRadius: 8,
    backgroundColor: '#F4F4F4',
  },
  quantityText: {
    'padding': '0px 9px',
    'fontSize': 20,
    'fontWeight': 500,
    'color': '#505358',
    '&.small': {
      fontSize: 16,
    },
  },
  assetBox: {
    width: '100%',
    minWidth: 320,
    [theme.breakpoints.down(320)]: {
      minWidth: 'unset',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 80,
    width: '100%',
    padding: '78px 0px',
    [theme.breakpoints.down(960)]: {
      gap: 0,
      margin: '0px auto',
      flexDirection: 'column',
      justifyContent: SPACE_BETWEEN,
      padding: 0,
    },
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
  },
  confirmButton: {
    padding: '0px 48px',
    maxWidth: 'fit-content',
    height: '46px',

    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: '100%',
      minHeight: '46px',
    },
  },
  dialogButton: {
    padding: '0px 48px',
    height: '46px',
    width: '100%',
    minHeight: '46px',
  },
  cartButton: {
    'width': '100%',
    'height': '46px',
    'margin': '8px 0px',
    '&.noHover': {
      '&:hover': {
        backgroundColor: '#FFF',
        borderColor: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.down(600)]: {
      'marginTop': 10,
      'marginBottom': 10,
      'maxWidth': 'calc(50% - 8px)',
      'minHeight': '46px',
      '&.fullWidth': {
        maxWidth: 'unset',
      },
    },
  },
  testPrice: {
    'fontSize': '36px',
    'lineHeight': '150%',
    'fontWeight': 600,
    'width': '100%',
    'color': '#282D37',
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down(960)]: {
      fontSize: '24px',
    },
  },
  titleWrapper: {
    marginBottom: 32,
    [theme.breakpoints.down(600)]: {
      marginBottom: 16,
    },
  },
  cartWrapper: {
    width: '100%',
    display: 'flex',
    // justifyContent: SPACE_BETWEEN,
    gap: 80,
    [theme.breakpoints.down(960)]: {
      gap: 50,
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      gap: 8,
    },
  },
  cartSummary: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '85%',
      margin: '0px auto',
    },
  },
  summaryTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#282D37',
    marginBottom: 32,
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  orderSummaryTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#282D37',
  },
  summaryLineText: {
    'color': '#505358',
    'fontSize': 16,
    'fontWeight': 500,
    'lineHeight': '150%',
    '&.link': {
      color: '#757575',
    },
    '&.total': {
      fontWeight: 700,
      color: '#282D37',
    },
    [theme.breakpoints.down(600)]: {
      '&.mobileMargin': {
        margin: '0px 5%',
      },
    },
  },
  summaryLineValue: {
    'fontSize': 16,
    'fontWeight': 500,
    'color': '#282D37',
    '&.total': {
      fontWeight: 700,
    },
  },
  summaryLineItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: SPACE_BETWEEN,
    margin: '4px 0px',
  },
  inCart: {
    width: '65%',
    maxWidth: 640,
    [theme.breakpoints.down(960)]: {
      width: '50%',
    },
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      margin: '0px auto',
    },
  },
  applePayIcon: {
    height: 23,
  },
  buttonWrapper: {
    marginTop: 48,
    [theme.breakpoints.down(600)]: {
      marginTop: 0,
      display: 'flex',
      justifyContent: SPACE_BETWEEN,
      flex: 0,
      width: '100%',
    },
  },
  cartSummaryAnchor: {
    'width': '35%',
    'maxWidth': 358,
    '&.checkout': {
      width: '40%',
    },
    [theme.breakpoints.down(960)]: {
      width: '50%',
    },
    [theme.breakpoints.down(600)]: {
      'padding': '24px 0px',
      'backgroundColor': '#FFF',
      'width': '100vw',
      'maxWidth': 'unset',
      '&.checkout': {
        width: '100vw',
      },
    },
  },
  cartItemWrapper: {
    width: '100%',
    cursor: 'pointer',
    marginBottom: 24,
    display: 'flex',
  },
  cartItemContentWrapper: {
    width: '100%',
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  priceAndQuantity: {
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
  },
  cartImageWrapper: {
    height: '145px',
    width: '145px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(960)]: {
      height: '123px',
      width: '123px',
    },
  },
  testImg: {
    width: '104px',
    [theme.breakpoints.down(960)]: {
      width: '96px',
    },
  },
  testDetails: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    'marginTop': 12,
    'gap': 2,
    '&.noMargin': {
      marginTop: 0,
    },
  },
  cartTestDesc: {
    fontSize: '16px',
    lineHeight: '150%',
    color: '#757575',
    fontWeight: 500,
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
    },
  },
  cartTextBold: {
    'fontSize': '20px',
    'lineHeight': '150%',
    'fontWeight': 600,
    'color': '#282D37',
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
    },
  },
  dataCardWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '-1px',
    justifyContent: SPACE_BETWEEN,
    borderBottom: '1px solid #E0E0E0',
    cursor: 'pointer',
    borderTop: '1px solid #E0E0E0',
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
  },
  iconWrapper: {
    paddingTop: 2,
  },
  dataContentWrapper: {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'gap': 16,
    'padding': '24px 0px',
    '&.orderDetails': {
      borderBottom: '1px solid #E0E0E0',
      borderRadius: 8,
    },
    [theme.breakpoints.down(600)]: {
      gap: 8,
      padding: '16px 0px',
    },
  },
  checkoutMethodTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#282D37',
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  termsLink: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  form: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 0px',
    gap: 24,
    [theme.breakpoints.down(960)]: {
      gap: 16,
    },
  },
  selectElm: {
    width: '100%',
  },
  twoFields: {
    display: 'flex',
    gap: 24,
    justifyContent: SPACE_BETWEEN,
    width: '100%',
    [theme.breakpoints.down(960)]: {
      gap: 16,
    },
  },
  methodSelectionWrapper: {
    'border': '1px solid #CECECE',
    'borderRadius': 8,
    'width': '100%',
    '&.dashed': {
      borderStyle: 'none',
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");border-radius: 12px`,
    },
  },
  methodSelectionContent: {
    padding: 32,
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 64px)',
    justifyContent: SPACE_BETWEEN,
    [theme.breakpoints.down(960)]: {
      padding: '24px 16px',
      width: 'calc(100% - 32px)',
    },
  },
  testWrapper: {
    width: '100%',
    display: 'flex',
  },
  checkoutImageWrapper: {
    height: '64px',
    width: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkoutImage: {
    width: '50px',
  },
  orderDetailItemDesc: {
    'fontSize': '16px',
    'lineHeight': '150%',
    'color': '#505358',
    'fontWeight': 500,
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down(600)]: {
      '-webkit-line-clamp': 1,
    },
  },
  orderDetailQuantity: {
    fontSize: '14px',
    lineHeight: '150%',
    color: '#757575',
    fontWeight: 500,
  },
}))
