import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SubHeaderGetTested } from '../../features/application'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Controls, useStyles, Wrapper } from '../../ui/templates/app-layout'
import { paths } from '../paths'
import { InsuranceProviderCompanyData } from '../register/model/type'
import {
  getInsuranceProviderCompanies,
  selectInsurance,
  selectInsuranceProvidersCompanies,
  setSelectedProvider,
} from './model'

export const AddInsuranceProvider = () => {
  const childClasses = childStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const i18n = useSelector(selectLanguageContent)
  const fullProviderList = useSelector(selectInsuranceProvidersCompanies)
  const insuranceData = useSelector(selectInsurance)
  const [loading, setLoading] = useState(true)
  const [insuranceProviderList, setInsuranceProviderList] = useState<
    InsuranceProviderCompanyData[]
  >([])
  const [searchValue, setSearchValue] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await dispatch(getInsuranceProviderCompanies())
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (fullProviderList?.length) {
      setInsuranceProviderList(fullProviderList)
    }
  }, [fullProviderList])

  const goBack = () => {
    if (!insuranceData) {
      history.push(paths.app.insurance(type, id))
    } else {
      history.push(paths.confirmInsurance(type, id, 'details'))
    }
  }

  const goToInsuranceProvider = (selectedProvider: any) => {
    dispatch(setSelectedProvider(selectedProvider))
    history.push(paths.confirmInsurance(type, id, 'edit'))
  }

  const handleChangeSearch = (value: any) => {
    setSearchValue(value)
    if (value) {
      const filteredList = fullProviderList.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      )
      setInsuranceProviderList(filteredList)
    } else setInsuranceProviderList(fullProviderList)
  }

  return (
    <Wrapper className={childClasses.wrapper}>
      {loading ? <ContentLoaderBox /> : null}
      <SubHeaderGetTested
        title={i18n.checkout_add_insuranse_provider}
        onHandleBack={goBack}
        onChangeSearch={handleChangeSearch}
        withSearchBar={true}
        searchValue={searchValue}
      />
      <Wrapper>
        <DataNotFound
          searchValue={searchValue}
          listLength={insuranceProviderList.length}
          i18n={i18n}
        />
        <ProviderList
          insuranceProviderList={insuranceProviderList}
          goToInsuranceProvider={goToInsuranceProvider}
          i18n={i18n}
        />
      </Wrapper>
    </Wrapper>
  )
}

const ProviderList = (props: any) => {
  const classes = useStyles()
  const childClasses = childStyles()
  const { insuranceProviderList, goToInsuranceProvider, i18n } = props

  if (!insuranceProviderList?.length) return null

  return (
    <Box className={classes.optionsWrapper}>
      <Box className={`${classes.infoText} ${childClasses.infoText}`}>
        {i18n.select_insurance_provider_message}
      </Box>
      <Box className={childClasses.providerList}>
        {insuranceProviderList.slice(0, 50).map((item: any, key: number) => (
          <Box key={key} className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>{item.name}</Typography>
            </Box>
            <Box
              className={classes.testPrice}
              onClick={() => goToInsuranceProvider(item)}
            >
              <Typography className={childClasses.goToProvider}>
                {i18n.insurance_provider_go_button}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const DataNotFound = (props: {
  searchValue: string
  listLength: number
  i18n: I18n
}) => {
  const { searchValue, listLength, i18n } = props
  const childClasses = childStyles()
  return searchValue && !listLength ? (
    <Box className={childClasses.dataNotFoundWrapper}>
      <Box>
        <Box className={childClasses.cantFindText}>
          {i18n["can't_find_insurnace_provider_message"]}
        </Box>
        <Box className={childClasses.helpCareTeam}>
          {i18n.care_team_here_to_help}
        </Box>
      </Box>
      <Controls>
        <Box className={childClasses.contactCareTeam}>
          {i18n.contact_care_team_button}
        </Box>
      </Controls>
    </Box>
  ) : null
}

const childStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 540,
    margin: '0 auto',
  },
  providerList: {
    overflow: 'hidden',
    overflowY: 'scroll',
    height: 'calc(100vh - 208px)',
  },
  cantFindText: {
    margin: '75px 0',
    color: '#54565B',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.334,
  },
  helpCareTeam: {
    fontSize: 22,
    margin: '75px 0',
    color: '#54565B',
    lineHeight: 1.334,
    textAlign: 'center',
  },
  infoText: {
    margin: '25px 0',
    fontSize: 16,
    color: '#54565B',
  },
  contactCareTeam: {
    margin: '25px 0',
    fontSize: 20,
    color: '#003C72',
    fontWeight: 900,
    textAlign: 'center',
  },
  goToProvider: {
    color: '#003C72',
  },
  optionWrapper: {
    padding: '25px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E7EA',
    margin: '0 auto',
    maxWidth: 400,
  },
  dataNotFoundWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
  },
}))
