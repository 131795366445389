import { Divider, styled, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ApplePay from '../../assets/icons/apple-pay.png'
import { selectLanguageContent } from '../../features/translation'
import { useMUITheme } from '../../libs/useTheme'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { selectNetworkSettings } from '../../pages/network/model'
import { paths } from '../../pages/paths'
import { Button, Loading } from '../../ui'
import { StoreStepType } from './model/types'
import { AdjustCartLineQuantityParameters } from './SAFE-ecommerce-service/EcommerceSDK'
import { CartItem } from './shopify-ui-items/cart-item'
import { LineItem } from './shopify-ui-items/order-summary-line-item'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const ShopifyCart = (props: {sdk: any}) => {
  const { sdk } = props
  const classes = shopifyStoreStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const networkSettings = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)
  const dispatch = useDispatch()
  const [isLoading, setIsloading] = useState(false)

  const isMobile = useMUITheme().isMobile

  const goBack = () => {
    history.push(paths.selectTestOrder(type))
  }

  const [cartState, setCartState] = useState(
    sdk.stateManager.currentCartViewState()
  )
  let isObserverSetup = false

  useEffect(() => {
    setIsloading(true)
    setupObserver()
    setTimeout(() => {
      loadCart()
    }, 250)
  }, [])

  function setupObserver() {
    if (isObserverSetup) {
      return
    }
    sdk.stateManager.observeCartViewState(
      (cartViewState: any) => {
        setCartState(cartViewState)
      },
      (error: any) => {
        console.log('Error observing products view state: ', error)
      }
    )
    isObserverSetup = true
  }
  function loadCart() {
    if (isLoading) {
      return
    }
    setIsloading(true)
    sdk.cartManager
      .loadCart()
      .then((response: any) => {
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setIsloading(false)
      })
  }

  function adjustQuantity(parameters: any) {
    setIsloading(true)
    sdk.cartManager
      .adjustQuantity(parameters)
      .then((response: any) => {
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setIsloading(false)
      })
  }

  const handleChangeQuantity = (type: 'decrease' | 'increase', id: string) => {
    if (type === 'increase') {
      adjustQuantity(AdjustCartLineQuantityParameters.Companion.increment(id))
    } else {
      adjustQuantity(AdjustCartLineQuantityParameters.Companion.decrement(id))
    }
  }

  const getSubtotalText = () => {
    if (!cartState.lines) return ''
    let text = i18n['ecommerce.order.charges.subtotal']
    if (!isMobile) return text

    return `${text} (${cartState.lines.length}${
      cartState.lines > 1
        ? i18n['ecommerce.item_text.plural']
        : i18n['ecommerce.item_text.singular']
    })`
  }
  const goNext = () => {
    history.push(paths.shopifyStore(StoreStepType.CHECKOUT))
  }

  if (isLoading) return <ShopLoading />

  return (
    <ContentWrapper>
      <div className={classes.cartContent}>
        <div className={classes.titleWrapper}>
          <Typography variant="h2" className={classes.pageTitle}>
            {i18n['ecommerce.cart_view.navigation_title']}
          </Typography>
        </div>
        <div className={classes.cartWrapper}>
          <div className={classes.inCart}>
            {cartState.lines.map((product: any) => (
              <CartItem
                i18n={i18n}
                product={product}
                color="#F5F5F5"
                handleAdjustQuantity={handleChangeQuantity}
              />
            ))}
          </div>
          <div className={classes.cartSummaryAnchor}>
            {isMobile && (
              <Divider
                style={{ marginBottom: 16, backgroundColor: '#E0E0E0' }}
              />
            )}
            <div className={classes.cartSummary}>
              {!isMobile && (
                <Typography variant="h2" className={classes.summaryTitle}>
                  {i18n['ecommerce.order_summary_header']}
                </Typography>
              )}
              <LineItem title={getSubtotalText()} value={cartState.subtotal} />
              <LineItem
                title={i18n['ecommerce.order.charges.applied_benefits']}
                value={cartState.appliedBenefitsFee}
              />
              {!isMobile && (
                <Divider
                  style={{ marginBottom: 16, backgroundColor: '#E0E0E0' }}
                />
              )}
              <LineItem
                title={i18n['ecommerce.estimated_subtotal']}
                value={cartState.estimatedSubtotal}
                // unit={'$'}
                isTotal
              />
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={goNext}
                  className={classes.cartButton}
                  disabled={!cartState.canProceedToCheckout}
                >
                  {i18n['ecommerce.checkout']}
                </Button>
                {cartState.displaysNativePaymentButton && (
                  <Button
                    onClick={goNext}
                    className={`${classes.cartButton} noHover`}
                    variant="outlined"
                  >
                    <img
                      src={ApplePay}
                      alt={'Use Apple Pay'}
                      className={classes.applePayIcon}
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

const ContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '0px auto 64px',
  [theme.breakpoints.down(600)]: {
    margin: '0px auto',
  },
}))
