import CameraAltIcon from '@material-ui/icons/CameraAlt'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import { ReactComponent as Pdf } from '../../../assets/icons/pdf.svg'

export const InfoCell = () => <InfoOutlinedIcon style={{ marginRight: 10 }} />
export const CalendarCell = () => <Calendar style={{ marginRight: 10 }} />
export const PDFCell = () => <Pdf style={{ marginRight: 10 }} />
export const CameraCell = () => (
  <CameraAltIcon style={{ marginRight: 10, fontSize: 18 }} />
)
export const ChevronRight = () => <ChevronRightIcon color="primary" />
