import { styled, Typography } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ShopifyCart } from '../../features/shopify/cart'
import { ShopifyCheckout } from '../../features/shopify/checkout'
import { selectCartItemCount } from '../../features/shopify/model/shopifyStoreSlice'
import { StoreStepType } from '../../features/shopify/model/types'
import { ProductDetail } from '../../features/shopify/product-detail'
import { ShopifyListPage } from '../../features/shopify/product-list'
import { EcommerceCollectionType } from '../../features/shopify/SAFE-ecommerce-service/EcommerceSDK'
import { ecommerceService } from '../../features/shopify/SAFE-ecommerce-service/services'
import { ShopLoading } from '../../features/shopify/shopify-ui-items/storeLoading'
import { selectLanguageContent } from '../../features/translation'
import { XLResponsiveContentWrapper } from '../../libs/shared-components'
import { useMUITheme } from '../../libs/useTheme'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { OrderProcessingPage } from './order-processing'

export const ShopifyModule = () => {
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const isMobile = useMUITheme().isMobile
  const sdk = ecommerceService.getSDKService()
  const networkSettings = useSelector(selectNetworkSettings)
  const [storeIntitalized, setStoreIntitalized] = useState(false)
  const cartItems = useSelector(selectCartItemCount)
  const { type } = useParams<{ type: StoreStepType }>()

  const showCartIcon =
    type === StoreStepType.PRODUCTS || type === StoreStepType.PRODUCT_DETAIL

  useEffect(() => {
    if (networkSettings || !storeIntitalized) {
      try {
        initializeEcommerce()
      } catch {
        console.log('FAILED')
      }
    }
  }, [networkSettings])

  function initializeEcommerce() {
    let collectionType = EcommerceCollectionType.testKits()
    sdk.customerManager
      .prepareShopSessionForFlow(collectionType)
      .then(() => {
        sdk.cartManager.loadCart()
        setStoreIntitalized(true)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const handleBack = () => {
    if (type === StoreStepType.PRODUCTS) {
      history.push(paths.app.dashboard())
    } else if (type === StoreStepType.CHECKOUT) {
      history.push(paths.shopifyStore(StoreStepType.CART))
    } else {
      history.push(paths.shopifyStore(StoreStepType.PRODUCTS))
    }
  }

  const openCart = () => {
    history.push(paths.shopifyStore(StoreStepType.CART))
  }

  const steps = [
    {
      type: StoreStepType.PRODUCTS,
      content: <ShopifyListPage sdk={sdk} />,
    },
    {
      type: StoreStepType.PRODUCT_DETAIL,
      content: <ProductDetail sdk={sdk} />,
    },
    {
      type: StoreStepType.CART,
      content: <ShopifyCart sdk={sdk} />,
    },
    {
      type: StoreStepType.CHECKOUT,
      content: <ShopifyCheckout sdk={sdk} />,
    },
  ]
  const renderStepContent = () => {
    const step = _.find(steps, { type })
    const index = _.findIndex(steps, { type })
    // if (currentStep !== index) {
    //   history.push(paths.registerTest(steps[currentStep].type))
    // }
    return <>{step?.content || steps[0].content}</>
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      rightContentTitle={
        storeIntitalized && showCartIcon
          ? i18n['ecommerce.cart_view.navigation_title']
          : undefined
      }
      rightActionIcon={
        storeIntitalized && showCartIcon ? (
          <Cart itemCount={cartItems} />
        ) : undefined
      }
      rightContentAction={
        storeIntitalized && showCartIcon ? openCart : undefined
      }
      border={!isMobile}
      color="#505358"
    >
      <ContentWrapper>
        <XLResponsiveContentWrapper>
          {storeIntitalized ? <>{renderStepContent()}</> : <ShopLoading />}
        </XLResponsiveContentWrapper>
      </ContentWrapper>
    </ModuleHeader>
  )
}

const Cart = (props: { itemCount: number }) => {
  const { itemCount } = props

  return (
    <CartIconWrapper>
      <ShoppingCartOutlinedIcon />
      {itemCount > 0 && (
        <CartCountWrapper>
          <CartCount>{itemCount}</CartCount>
        </CartCountWrapper>
      )}
    </CartIconWrapper>
  )
}

const ContentWrapper = styled('div')(({ theme }) => ({
  width: 'calc(100% - 9vw)',
  margin: '0px auto 64px',
  [theme.breakpoints.down(600)]: {
    width: '100vw',
  },
}))

const CartIconWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: 40,
  width: 35,
  [theme.breakpoints.down(600)]: {
    alignItems: 'flex-end',
  },
}))

const CartCountWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  height: 20,
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // padding: '3px',
  [theme.breakpoints.down(600)]: {
    top: 7,
  },
}))

const CartCount = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontSize: 12,
  fontWeight: 600,
  marginLeft: 1,
  marginTop: 1,
}))

const LoadingWrapper = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
