import { makeStyles, Slider, Typography, withStyles } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  updateAnswer,
} from '../questionnaireV2Slice'
import styles from './test.module.css'

export const SliderQuestion = () => {
  const container = useRef<any>(null)
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const existingAnswer = useSelector(selectExistingAnswer)
  const [valueoffset, setValueOffset] = useState(0)
  const [elemWidth, setElemWidth] = useState(0)
  const [sliderVal, setSliderVal] = useState(question.min || 0)
  const [valueLabel, setValueLabel] = useState('')

  useEffect(() => {
    if (existingAnswer) {
      setSliderVal(Number(existingAnswer.value))
      dispatch(updateAnswer(Number(existingAnswer.value)))
    } else {
      setSliderVal(question.min || 0)
    }
  }, [question, existingAnswer])

  useEffect(() => {
    window.addEventListener('resize', calculatePosition)
    calculatePosition()
  }, [container, sliderVal, valueLabel])

  const handleChange = (
    e: React.ChangeEvent<Record<string, unknown>>,
    value: number | number[]
  ) => {
    let result: number
    if (typeof value === 'object') {
      result = value[0]
    } else result = value
    dispatch(updateAnswer(result))
    setSliderVal(result)
  }

  const sliderDecrement = () => {
    let newValue = sliderVal || question.min || 0
    newValue--
    if (newValue >= (question.min || 0)) {
      dispatch(updateAnswer(newValue))
      setSliderVal(newValue)
    } else {
      setSliderVal(question.min || 0)
    }
  }

  const sliderIncrement = () => {
    let newValue = sliderVal || question.min || 0
    newValue++
    if (newValue <= (question.max || 1)) {
      dispatch(updateAnswer(newValue))
      setSliderVal(newValue)
    }
  }

  const calculatePosition = () => {
    if (container?.current) {
      setElemWidth(container.current.offsetWidth)
    }
    const currentValue = sliderVal || question.min || 0
    const positionPercent =
      (currentValue - (question.min || 0)) /
      ((question.max || 1) - (question.min || 0))
    const position = positionPercent * (elemWidth - 78)
    setValueOffset(position)
  }

  const getThumb = (props: any) => {
    return (
      <span
        {...props}
        style={{ pointerEvents: 'none', marginLeft: valueoffset }}
      >
        <Typography variant="body1">
          {sliderVal} {valueLabel}
        </Typography>
      </span>
    )
  }

  return (
    <div
      className={`${styles.questionWrapper} text-center mx-auto d-flex flex-grow`}
    >
      <div className="row flex-grow">
        <div className="col-12 fb-auto mb-auto">
          <div className={styles.rangeSliderWrapper}>
            <span
              className={`${styles.rsArrow} ${styles.rsArrowLeft}`}
              onClick={sliderDecrement}
            >
              <ChevronLeftIcon color="secondary" />
            </span>
            <div className={styles.rangeSliderInner} ref={container}>
              <IOSSlider
                min={question.min || undefined}
                max={question.max || undefined}
                value={sliderVal}
                step={question.step || 1}
                ThumbComponent={getThumb}
                onChange={handleChange}
              />
            </div>
            <span className={`${styles.rsArrow}`} onClick={sliderIncrement}>
              <ChevronRightIcon color="secondary" />
            </span>
          </div>
          {/* {question.unit === 'temperature' && (
            <Box style={{ width: '100%' }}>
              <ScalePill showC={showC} setShowC={setShowC} />
            </Box>
          )} */}
        </div>
      </div>
    </div>
  )
}

const ScalePill = (props: {
  showC: boolean
  setShowC: (prop1: boolean) => void
}) => {
  const classes = useStyles()
  const { showC, setShowC } = props

  const handleClick = () => {
    setShowC(!showC)
  }
  return (
    <div className={classes.pillContainer} onClick={() => handleClick()}>
      <Typography
        align="center"
        className={!showC ? `${classes.degree} selected` : classes.degree}
      >
        °F
      </Typography>
      <Typography
        align="center"
        className={showC ? `${classes.degree} selected` : classes.degree}
      >
        °C
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pillContainer: {
    margin: '0px auto',
    width: '100px',
    heigh: '32px',
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '16px',
    padding: '1px',
    cursor: 'pointer',
  },
  degree: {
    'width': '50px',
    'color': '#FFF',
    '&.selected': {
      width: '50px',
      borderRadius: '16px',
      color: theme.palette.secondary.main,
      backgroundColor: '#FFF',
    },
  },
}))

const iOSBoxShadow = '0px 2px 9px 0px #00000040'

const thumbWidth = 78

const IOSSlider = withStyles(() => ({
  root: {
    color: 'transparent',
    height: 2,
    padding: '30px 0',
    width: `calc(100% - ${thumbWidth}px - 10px)`,
  },
  thumb: {
    'height': 38,
    'width': thumbWidth,
    'backgroundColor': '#fff',
    'boxShadow': iOSBoxShadow,
    'marginTop': -14,
    'borderRadius': 30,
    'marginLeft': 5,
    '&.Mui-focusVisible': {
      boxShadow: iOSBoxShadow,
    },
    '&:focus, &:hover, &$active': {
      'boxShadow': iOSBoxShadow,
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    'left': 'calc(-50% + 12px)',
    'top': -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    marginTop: 4,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
    width: `calc(100% + ${thumbWidth}px + 10px)`,
  },
}))(Slider)
