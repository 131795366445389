import styled from '@emotion/styled'
import {
  Box,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import createDOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Loading } from '../../ui'
import { paths } from '../paths'
import { getFAQsList, selectFAQsList, selectFAQsLoading } from './faqSlice'
import { Faq } from './types'
import { HTMLTextComponent } from '../../libs/shared-components'

const DOMPurify = createDOMPurify(window)

export const FaqList = () => {
  const { faqId } = useParams<{ faqId: string }>()
  const classes = useStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const isLoading = useSelector(selectFAQsLoading)
  const faqsList = useSelector(selectFAQsList)
  const [searchValue, setSearchValue] = useState('')
  const [searchedFAQs, setSearchedFAQs] = useState([...faqsList])
  const [faqDetails, setFaqsDetails] = useState<Faq>()

  useEffect(() => {
    dispatch(getFAQsList())
  }, [])

  const getFaqDetail = () => {
    return faqsList?.find((f) => titleToURL(f.question) === faqId)
  }

  useEffect(() => {
    const selectedTerm = getFaqDetail()
    if (selectedTerm) {
      setFaqsDetails(selectedTerm)
    }
  }, [faqId, faqsList])

  useEffect(() => {
    if (searchValue) {
      let filteredData = faqsList.filter(
        (faq) =>
          faq.answer?.toLowerCase().includes(searchValue.toLowerCase()) ||
          faq.question?.toLowerCase().includes(searchValue.toLowerCase())
      )
      if (filteredData) {
        setSearchedFAQs(filteredData)
      } else return
    } else setSearchedFAQs([...faqsList])
  }, [faqsList, searchValue])

  const handleChange = (e: any) => {
    const value = e.target.value
    setSearchValue(value)
  }

  const titleToURL = (title: string) => {
    const removeSpecials = title.replace(/[|&;$%@"<>()+?,]/g, '')
    return removeSpecials.replaceAll(' ', '-').toLocaleLowerCase()
  }

  return (
    <div className={classes.contentWrapper}>
      {!faqId && (
        <Box className={classes.searchFieldWrapper}>
          <TextField
            id="outlined-basic"
            className={classes.searchField}
            variant="outlined"
            placeholder={i18n.search_placeholder}
            value={searchValue}
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#666666', fontSize: 20 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {searchValue.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchValue('')}>
                        <CloseIcon style={{ color: '#666666', fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </Box>
      )}
      <Box className={classes.faqList}>
        {isLoading ? (
          <Box className={classes.loading}>
            <Loading />
          </Box>
        ) : (
          <>
            {searchedFAQs.length ? (
              faqId ? (
                <>
                  <Box className={classes.detailContainer}>
                    <Box className={classes.infoText}>
                      {faqDetails?.question}
                    </Box>
                    <Box className={classes.answer}>
                      {faqDetails?.answer ? (
                        <HTMLTextComponent HTMLString={faqDetails?.answer} />
                      ) : (
                        <Typography>
                          {i18n.no_data_loaded_error_message}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </>
              ) : (
                searchedFAQs.map((faq) => (
                  <TermsListItem
                    key={faq.sequenceNo}
                    button
                    tabIndex={-1}
                    title={faq.question}
                  >
                    <TermsLink to={paths.faqsDetails(titleToURL(faq.question))}>
                      <Typography variant="body2" className={classes.title}>
                        {faq.question}
                      </Typography>
                      <ChevronRightIcon />
                    </TermsLink>
                  </TermsListItem>
                ))
              )
            ) : (
              <Box className={classes.loading}>
                <NoFAQs i18n={i18n} className={classes.noContent}/>
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  )
}

const NoFAQs = ({ i18n, className }: { i18n: I18n; className: string }) => (
  <Typography color="primary" align="center" className={className}>
    {i18n.no_result_found}
  </Typography>
)

const TermsListItem = styled(ListItem)`
  width: 100%;
  padding: 0 10px 0 15px !important;
`
const TermsLink = styled(Link)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 0.9rem 0;
  border-bottom: 1px solid #efefef;
  text-decoration: none;
  outline: none;
  color: #7b7c7d;
  @media (min-width: 600px) {
    padding: 0.9rem 10px 0.9rem 10px !important;
  }
`

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    width: '100%',
    maxWidth: '600px',
    margin: '0px auto',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailContainer: {
    maxHeight: 'calc(92vh)',
    overflow: 'auto',
    width: '100%',
  },
  answer: {
    [theme.breakpoints.up(767)]: {
      margin: '0 auto',
    },
  },
  detailContent: {
    color: '#545454',
    fontSize: '18px',
    lineHeight: '27px',
  },
  noContent: {
    width: '100%',
    margin: 'auto',
    fontFamily: 'Avenir Next Rounded Medium',
    fontSize: 24,
  },
  searchField: {
    'textTransform': 'none',
    'fontFamily': 'Avenir Next Rounded Regular',
    'marginTop': 10,
    'color': theme.palette.primary.main,
    'width': '100%',
    'background': '#FFF',
    'borderRadius': '8px',
    'outline': 'none',
    '& input': {
      padding: '7px 0',
    },
    [theme.breakpoints.up(1023)]: {
      marginLeft: 15,
    },
  },
  searchFieldContainer: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingBottom: 10,
  },
  searchFieldWrapper: {
    width: '45%',
    [theme.breakpoints.down(500)]: {
      margin: '0 auto',
      width: '90%',
    },
  },
  loading: {
    marginTop: 150,
  },
  faqList: {
    [theme.breakpoints.down(500)]: {
      maxWidth: theme.spacing(89),
      maxHeight: 'calc(92vh)',
      overflow: 'auto',
    },
  },
  faqItem: {
    'marginBottom': theme.spacing(1.5),
    'color': '#526A8C',
    'borderRadius': 8,
    'backgroundColor': '#F3F3F3',
    'boxShadow': 'none',
    [theme.breakpoints.up(500)]: {
      margin: '10px auto',
      width: '100%',
    },

    '&:first-child': {
      borderRadius: 8,
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-h6': {
      fontSize: 18,
      [theme.breakpoints.down(767)]: {
        fontSize: 15,
      },
    },
    '& p': {
      fontSize: 16,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      fontSize: '2rem',
    },
    '& .MuiAccordionSummary-root': {
      [theme.breakpoints.down(767)]: {
        backgroundColor: '#F8F9FA',
        borderRadius: 8,
      },
    },
    '& .MuiAccordionDetails-root': {
      [theme.breakpoints.down(767)]: {
        backgroundColor: '#F8F9FA',
        borderRadius: '0 0 8px 8px',
      },
    },
  },
  infoText: {
    margin: '24px 0',
    fontWeight: 600,
    fontSize: 30,
    // color: '#7B7C7D',
    // alignSelf: 'center',
    textAlign: 'left',
  },
}))
