import * as Yup from 'yup'
import moment from 'moment'
import { I18n } from '../../features/translation/types'
import { validateReadyTime, validateLatestTime } from '../../libs/helpers'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (i18n: I18n, timeRange: any) {
  const formatTime = (time: string) => moment(time, 'HH:mm').format('hh:mm a')
  const compareDates = (readyTime: string) => {
    const startDate = moment(readyTime, 'HH:mm')
    const endDate = moment(timeRange.to, 'HH:mm')
    return moment(startDate).isSameOrBefore(moment(endDate)) &&
      moment(startDate).isSameOrAfter(moment(timeRange.from, 'HH:mm'))
      ? startDate
      : timeRange.from
  }
  return Yup.lazy((values) =>
    Yup.object().shape({
      pickupDate: Yup.number().required(i18n.required),
      readyTime: Yup.string()
        .test(
          'readyIsBetween',
          `Select time from ${formatTime(timeRange.from)} to ${formatTime(
            timeRange.to
          )}`,
          (value) =>
            value
              ? validateReadyTime(value, timeRange.from, timeRange.to)
              : false
        )
        .required(i18n.required),
      latestTimeAvailable: Yup.string()
        .test(
          'latestIsBetween',
          `Select time from ${formatTime(
            compareDates(values.readyTime)
          )} to ${formatTime(timeRange.to)}`,
          (value) =>
            value
              ? validateLatestTime(
                  value,
                  timeRange.from,
                  timeRange.to,
                  values.readyTime
                )
              : false
        )
        .required(i18n.required),
      comment: Yup.string().min(5, i18n.zip_code_min),
    })
  )
}
