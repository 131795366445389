import { makeStyles } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment-timezone'
import React, { useState } from 'react'
// mui

const useDateStyles = makeStyles({
  dateLabel: {
    fontSize: 18,
  },
  dateInput: {
    'borderBottom': 'solid 1px #D9E1EC',
    'marginLeft': 20,

    '& .MuiIconButton-root': {
      outline: 'none',
    },
  },
})

const DateInput = (props: any) => {
  const { field, value, handleChange, disabled, setShowDateError } = props
  const [selectedDate, setSelectedDate] = useState()
  const [error, setError] = useState('')
  const classes = useDateStyles()
  const handleDateChange = (dateValue: any) => {
    // const isValidDate = moment(dateValue, 'MM/DD/YYYY').isValid()
    // const isLowerMax = moment(dateValue).isBefore(moment(new Date()))
    // const isBiggerMin = moment(dateValue).isAfter(moment(field && field.minDate ? field.minDate : new Date(2020, 10, 1)))

    handleChange(moment(dateValue).format('MMM DD, yyyy'))
    setError('')
    setSelectedDate(dateValue)

    // else if (isValidDate) {
    //   setShowDateError(isLowerMax ? "Date cannot be earlier than 11/1/2020" : "Date cannot be later than today's date")
    // }
    // else if (!isValidDate) {
    //   setShowDateError("Invalid Date")
    // }
  }

  return (
    <KeyboardDatePicker
      InputLabelProps={{
        shrink: true,
        className: classes.dateLabel,
      }}
      InputProps={{
        className: classes.dateInput,
        disableUnderline: true,
      }}
      variant="static"
      onChange={handleDateChange}
      format="MM/dd/yyyy"
      placeholder={'mm/dd/yyyy'}
      value={selectedDate}
      // keyboardIcon={<InsertInvitationIcon style={{fontSize: 25, position: 'relative'}}/>}
      minDate={new Date()}
      maxDate={'2022-11-1'}
      autoOk
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  )
}
export default DateInput
