import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Dialog, Link as MUILink, Typography } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import RoomIcon from '@material-ui/icons/Room'
import { useSelector } from 'react-redux'
import { Button } from '../../ui'
import { Controls, useStyles } from '../../ui/templates/app-layout'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import { selectLanguageContent } from '../../features/translation'
import { ReactComponent as AlertIcon } from '../../assets/img/alert.svg'
import { SupportDialog } from '../contact-support/supportDialog'
import { paths } from '../paths'
import { ADDRESS_TYPES, testTypes } from '../../constants'

export const InvalidAddressDialog = (props: {
  isOpen: boolean
  isLoading?: boolean
  onRetry: () => void
  onClose: () => void
  info?: {
    date?: string
    time?: string
    selectedLocation?: any
  }
}) => {
  const {
    isOpen,
    isLoading,
    onRetry,
    onClose,
    info,
  } = props

  const i18n = useSelector(selectLanguageContent)
  const [supportDialogOpen, setSupportDialogOpen]  = useState(false)
  const { orderId } = useParams<{
    type: string
    orderId: string
  }>()
  const history = useHistory()

  const classes = useStyles()
  const childClasses = childStyles()

  const retryCheckAddress = () => {
    onClose();
    onRetry();
  }

  const findDropOffLocation = () => {
    history.push(paths.dropOff(testTypes.DROPOFF, orderId))
  }

  return (
    <Dialog open={isOpen}>
      <Box className={childClasses.schedulePageHolder}>
        {isLoading ? <ContentLoaderBox /> : null}
        <Box className={classes.scheduleWrapper}>
          <AlertIcon className={childClasses.alertIcon} />
          <Box className={childClasses.title}>{i18n.address_invalid}</Box>
          <Box
            className={childClasses.boxSubtitle}>
            <Typography align={'center'}>
              {i18n.doesnt_pickup_by_current_address_message}
            </Typography>
          </Box>
          <Controls>
            <MUILink
              role="button"
              color='primary'
              className={childClasses.pageLink}
              onClick={() => setSupportDialogOpen(true)}
            >
              {i18n.contact_support}
            </MUILink>
            <Button
              onClick={retryCheckAddress}
              className={classes.button}
            >
              {i18n.scan_cassete_try_again}
            </Button>
            <Button
              onClick={findDropOffLocation}
              className={classes.button}
            >
              {i18n.find_drop_off_location_button}
            </Button>
          </Controls>
          <SupportDialog
            open={supportDialogOpen}
            setSupportDialogOpen={setSupportDialogOpen} />
        </Box>
      </Box>
    </Dialog>
  )
}

const font = 'Avenir Next Rounded Regular'

const childStyles = makeStyles((theme) => ({
  schedulePageHolder: {
    backgroundColor: '#F7F7F7',
    width: '100%',
    fontFamily: font,
  },
  title: {
    width: 327,
    fontSize: 28,
    fontWeight: 'bold',
    color: '#083c71',
    margin: '30px 0',
    [theme.breakpoints.down(400)]: {
      width: 'auto',
    },
  },
  boxSubtitle: {
    marginLeft: '10px',
    letterSpacing: 0.5,
    fontSize: 14,
    color: '#7B7C7D',
    alignSelf: 'center',
  },
  alertIcon: {
    marginTop: 40,
    width: 150,
    height: 150,
    fill: '#083d72',
  },
  pageLink: {
    marginTop: 20,
    marginLeft: 4,
    lineHeight: 2,
    cursor: 'pointer',
  },
}))
