import { Box, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import React, { ReactChild } from 'react'
import { Typography } from '../../atoms'
import { Button, Variant } from '../../atoms/button/button'
import { IconButton } from '../../atoms/icon-button/icon-button'

type ColorValueHex = `#${string}`

export interface ModuleActionButton {
  label: string
  action: () => void
  variant: Variant
  disabled: boolean
}

type MobileContentPosition = 'unset' | 'absolute' | 'fixed' | 'relative'

type Position = 'start' | 'center' | 'end'

interface ModuleIntroWrapperProps {
  contentImage?: string
  backgroundColor?: ColorValueHex
  title?: string | ReactChild
  body?: string | ReactChild
  actions?: ModuleActionButton[]
  onBack?: () => void
  onClose?: () => void
  children?: ReactChild
  contentPosition?: Position
  childPosition?: Position
  mobileContentPosition?: MobileContentPosition
}

export function ModuleIntroWrapper(props: ModuleIntroWrapperProps) {
  const {
    title,
    body,
    actions,
    contentImage,
    backgroundColor,
    onBack,
    onClose,
    children,
    contentPosition = 'center',
    childPosition = 'start',
    mobileContentPosition = 'absolute',
  } = props

  const hasImage = Boolean(contentImage)
  const classes = useStyles({ mobileContentPosition, hasImage })
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(960))

  const MobileView = () => (
    <div
      className={classes.mobileImage}
      style={{ backgroundImage: `url(${contentImage})` }}
    >
      <Content />
    </div>
  )

  const DesktopView = () => (
    <>
      <div
        className={classes.desktopImage}
        style={{ backgroundImage: `url(${contentImage})` }}
      />
      <Content />
    </>
  )

  const Content = () => (
    <div className={classes.childWrapper}>
      {onBack && (
        <IconButton
          icon={<ArrowBackIcon />}
          handleClick={onBack}
          childClass={classes.backIcon}
        />
      )}
      <div className={classes.contentWrapper}>
        <div
          className={classes.content}
          style={{
            justifyContent:
              contentPosition === 'center'
                ? contentPosition
                : `flex-${contentPosition}`,
          }}
        >
          {children && childPosition === 'start' && <>{children}</>}
          {title && (
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
          )}
          {body && <Typography className={classes.body}>{body}</Typography>}
          {children && childPosition === 'center' && <>{children}</>}
          <Box className={classes.buttonContainer}>
            {actions?.map((b) => {
              return (
                <Button
                  className={`${classes.button} ${b.variant}`}
                  variant={isMobile ? 'outlined' : b.variant}
                  onClick={b.action}
                  disabled={b.disabled}
                >
                  {b.label}
                </Button>
              )
            })}
          </Box>
          {children && childPosition === 'end' && <>{children}</>}
        </div>
      </div>
      {onClose && (
        <IconButton
          icon={<CloseIcon />}
          handleClick={onClose}
          childClass={classes.closeIcon}
        />
      )}
    </div>
  )

  return (
    <div className={classes.wrapper}>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#FFF',
    display: 'flex',
  },
  mobileImage: {
    minHeight: '100vh',
    width: '100vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
  },
  desktopImage: {
    minHeight: '100vh',
    width: '35vw',
    minWidth: '350px',
    maxWidth: '960px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 0%',
    backgroundSize: 'cover',
  },
  childWrapper: {
    display: 'flex',
    padding: '0px 64px',
    minWidth: '500px',
    [theme.breakpoints.down(1200)]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.down(960)]: {
      padding: '0px',
      minWidth: 'unset',
    },
  },
  image: {
    margin: '0 auto',
    justifySelf: 'center',
  },
  iconFilled: {
    'borderRadius': '100%',
    'fontSize': '28px',
    'padding': 6,
    'backgroundColor': 'rgba(0, 0, 0, 0.34)',
    'color': 'white',
    'cursor': 'pointer',
    '&.left': {
      marginLeft: '-48px',
      transform: 'rotate(-180deg)',
      [theme.breakpoints.down(960)]: {
        marginLeft: '-12px',
      },
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
      padding: 6,
    },
  },
  backIcon: {
    position: 'absolute',
    left: 32,
    top: 32,
  },
  closeIcon: {
    position: 'absolute',
    right: 32,
    top: 32,
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    margin: '64px auto',
    [theme.breakpoints.down(960)]: {
      width: '90%',
      maxWidth: '500px',
      margin: '0px auto',
    },
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down(960)]: {
      position: ({
        mobileContentPosition,
      }: {
        mobileContentPosition: MobileContentPosition
        hasImage: boolean
      }) => mobileContentPosition,
      bottom: 0,
      width: '100vw',
    },
  },
  title: {
    fontWeight: 600,
    color: '#505358',
    lineHeight: '55px',
    fontSize: 36,

    textAlign: 'inherit',
    [theme.breakpoints.down(960)]: {
      color: ({ hasImage }: { hasImage: boolean }) =>
        hasImage ? '#FFF' : '#505358',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  body: {
    fontWeight: 500,
    color: '#505358',
    lineHeight: '22px',
    fontSize: 16,
    marginBottom: '32px',
    maxWidth: 500,
    [theme.breakpoints.down(960)]: {
      marginBottom: '16px',
      color: ({ hasImage }: { hasImage: boolean }) =>
        hasImage ? '#FFF' : '#505358',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    [theme.breakpoints.down(960)]: {
      marginBottom: 24,
    },
  },
  button: {
    margin: '8px 0px',
    padding: '6px 48px',
    [theme.breakpoints.down(960)]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },
}))
