import { Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import React from 'react'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { I18n } from '../../translation/types'

export const Quantity = (props: {
  i18n: I18n
  quantity: number
  productId: string
  size: 'lg' | 'sm'
  showTitle: boolean
  canIncrement: boolean
  canDecriment: boolean
  handleChangeQuantity: (type: 'decrease' | 'increase', id: string) => void
}) => {
  const {
    i18n,
    quantity,
    handleChangeQuantity,
    size,
    showTitle,
    productId,
    canIncrement,
    canDecriment,
  } = props

  const classes = shopifyStoreStyles()

  return (
    <div>
      {showTitle && (
        <Typography className={classes.descriptionTitle}>
          {i18n['ecommerce.quantity_capitalized_text']}
        </Typography>
      )}
      <div className={classes.quantityWrapper}>
        {canDecriment && (
          <RemoveIcon
            style={{
              color: '#505358',
              cursor: 'pointer',
              fontSize: size === 'lg' ? 24 : 20,
            }}
            onClick={() => handleChangeQuantity('decrease', productId)}
          />
        )}
        <div className={classes.quantityTextWrapper}>
          <Typography
            className={
              size === 'sm'
                ? `${classes.quantityText} small`
                : classes.quantityText
            }
          >
            {quantity}
          </Typography>
        </div>
        {canIncrement && (
          <AddIcon
            style={{
              color: '#505358',
              cursor: 'pointer',
              fontSize: size === 'lg' ? 24 : 20,
            }}
            onClick={() => handleChangeQuantity('increase', productId)}
          />
        )}
      </div>
    </div>
  )
}
