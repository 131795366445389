import { Box } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { addDeviceInterest } from '../../features/pusher-notifications/beamsMessaging'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectNetworkSettings } from '../network/model'
import { TelehealthOptions } from '../network/type'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import { telehealthStyles } from './components/styles'
import { createSession } from './model'
import { ConfirmLocation } from './steps/confirm-location'

export const TelehealthIntakePage = () => {
  const classes = telehealthStyles()
  const dispatch = useDispatch()
  const networkSettings = useSelector(selectNetworkSettings)
  const profileData = useSelector(selectProfileData)
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()
  const { type } = useParams<{
    type: 'confirm-location'
  }>()

  const handleExit = () => {
    history.push(paths.app.dashboard())
  }
  const onConfirmLocation = () => {
    const patientTestId = lsClient.getUserLSByKey('patientTestId')
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')

    const onSuccess = () => {
      if (
        networkSettings?.telehealthConfiguration ===
        TelehealthOptions.SAFE_TELEHEALTH
      ) {
        addDeviceInterest(profileData._id)
        history.push(paths.app.telehealth('waiting-room'))
      } else {
        history.push(paths.app.dashboard())
      }
    }
    const data = {
      orderableTestId: null,
      patientTestId,
      allowSMS: true,
      healthServiceId,
    }
    dispatch(createSession(data, onSuccess))
  }

  const steps = [
    //todo: Add insurance and payment steps
    {
      label: 'Confirm Location',
      type: 'confirm-location',
      content: <ConfirmLocation onConfirm={onConfirmLocation} i18n={i18n} />,
    },
  ]

  const renderStepContent = () => {
    const step = _.find(steps, { type })
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.content}>
          {step?.content || steps[0].content}
        </Box>
      </Box>
    )
  }

  return (
    <ModuleHeader
      rightContentTitle={i18n.exit_button}
      rightContentAction={handleExit}
    >
      <Box className={classes.wrapper}>{renderStepContent()}</Box>
    </ModuleHeader>
  )
}
