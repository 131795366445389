import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import CropFreeIcon from '@material-ui/icons/CropFree'
import EmailIcon from '@material-ui/icons/Email'
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded'
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import PeopleIcon from '@material-ui/icons/People'
import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/LogoutIcon.svg'
import { ReactComponent as TaskAlt } from '../../../assets/icons/task-alt.svg'
import { ReactComponent as Vaccinations } from '../../../assets/icons/vaccinations-icon.svg'
import { selectNetworkSettings } from '../../../pages/network/model'
import { NetworkSettings } from '../../../pages/network/type'
import { paths } from '../../../pages/paths'
import { selectHasDependents } from '../../../pages/profile/model'
import { Drawer } from '../../../ui'
import { setDialog } from '../../dialog/model'
import { DialogType } from '../../dialog/types'
import { selectLanguageContent } from '../../translation'
import { DrawerHeader } from '../../user'
import { selectUserPermission } from '../../user/model/userTenantSlice'
import { DrawerListItem } from '../atoms'
import { drawerClosed, selectIsDrawerOpen } from '../model'

export const ApplicationDrawer = ({
  hideWhenClosed,
  isDesktop,
  isSmallDesktop,
}: {
  hideWhenClosed?: boolean
  isDesktop: boolean
  isSmallDesktop: boolean
}) => {
  const isOpen = useSelector(selectIsDrawerOpen)
  const i18n = useSelector(selectLanguageContent)
  const networkSettings = useSelector(selectNetworkSettings)
  const dispatch = useDispatch()
  const hasDependents = useSelector(selectHasDependents)
  const footerItems =
    (networkSettings?.footerConfiguration &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      Object.values(networkSettings.footerConfiguration)) ||
    []
  const handleDrawerClose = () => {
    dispatch(drawerClosed())
  }

  const openLogoutDialog = () => {
    dispatch(setDialog({ type: DialogType.LOGOUT }))
  }

  // const HealthWorkerItem = () => {
  //   const userPermission = useSelector(selectUserPermission)
  //   return (
  //     <DrawerListItem
  //       path={paths.app.scanOrderCode()}
  //       icon={<CropFreeIcon />}
  //       label={i18n.health_worker}
  //       isVisible={userPermission === 'CHECKER'}
  //     />
  //   )
  // }

  const DependentsItem = () => (
    <DrawerListItem
      path={paths.app.dependents()}
      icon={<PeopleIcon />}
      label={i18n.dependents}
      isVisible={
        hasDependents &&
        Boolean(networkSettings) &&
        !footerItems.includes('DEPENDENTS')
      }
    />
  )

  // const CarePlanItem = () => (
  //   <DrawerListItem
  //     // path={paths.app.getTested()}
  //     icon={<FolderSharedIcon />}
  //     label={i18n.treatment_plans}
  //     isVisible={
  //       Boolean(networkSettings) && !footerItems.includes('CARE_PLANS')
  //     }
  //   />
  // )

  // const OrderHistoryItem = () => (
  //   <DrawerListItem
  //     // path={paths.app.getTested()}
  //     icon={<ShoppingCartRoundedIcon />}
  //     label={'Order History'}
  //     isVisible={
  //       Boolean(networkSettings) && !footerItems.includes('ORDER_HISTORY')
  //     }
  //   />
  // )

  // const VaccinationsItem = () => (
  //   <DrawerListItem
  //     // path={paths.app.getTested()}
  //     icon={<Vaccinations style={{ marginLeft: 4 }} />}
  //     label={'Vaccinations'}
  //     isVisible={
  //       networkSettings?.verifyVaccinationSettings.enableVaccinationRecord
  //     }
  //   />
  // )

  const GetTestedItem = () => (
    <DrawerListItem
      path={paths.app.getTested()}
      icon={<AssignmentTurnedInIcon />}
      label={i18n.get_tested}
    />
  )

  // const ImportHealthRecordsItem = () => {
  //   return (
  //     <DrawerListItem
  //       icon={<ShoppingCartRoundedIcon />}
  //       label={i18n.import_health_records}
  //     />
  //   )
  // }

  const TestResultsItem = () => (
    <DrawerListItem
      icon={<HistoryRoundedIcon />}
      path={paths.testResults()}
      label={i18n.test_history_screen_title}
      isVisible={
        Boolean(networkSettings) && !footerItems.includes('TEST_HISTORY')
      }
    />
  )

  const ResourcesItem = () => (
    <DrawerListItem
      icon={<LayersOutlinedIcon />}
      path={paths.resources()}
      label={i18n.resources}
      isVisible={Boolean(networkSettings) && !footerItems.includes('RESOURCES')}
    />
  )

  const FaqItem = () => (
    <DrawerListItem
      path={paths.faqs()}
      icon={<HelpRoundedIcon />}
      label={i18n.help_center}
      isVisible={
        Boolean(networkSettings) && !footerItems.includes('HELP_CENTER')
      }
    />
  )

  const ContactSupportItem = () => (
    <DrawerListItem
      path={paths.app.contactSupport()}
      icon={<EmailIcon />}
      label={i18n.contact_support}
      isVisible={Boolean(networkSettings) && !footerItems.includes('CONTACT')}
    />
  )

  const LegalItem = () => (
    <DrawerListItem
      path={paths.termsAndConds()}
      icon={<PolicyRoundedIcon />}
      label={i18n.legal_notice}
    />
  )

  // const HealthRecordItem = () => (
  //   <DrawerListItem
  //     icon={<FavoriteRoundedIcon />}
  //     label={i18n.my_health_record}
  //   />
  // )

  const SettingsItem = () => (
    <DrawerListItem
      path={paths.settingsTab('account', 'personal-info')}
      icon={<SettingsIcon />}
      label={i18n.settings}
      isVisible={Boolean(networkSettings) && !footerItems.includes('SETTINGS')}
    />
  )

  const LogoutItem = () => (
    <DrawerListItem
      // path={paths.login()}
      onClick={openLogoutDialog}
      icon={<LogoutIcon />}
      label={i18n.log_out}
    />
  )

  return (
    <Drawer
      anchor={isDesktop ? 'left' : 'right'}
      isOpen={isDesktop ? true : isOpen}
      isDesktop={isDesktop}
      onDrawerClose={handleDrawerClose}
      drawerHeader={<DrawerHeader isOpen={isDesktop ? true : isOpen} />}
      drawerItems={[
        DependentsItem,
        // HealthWorkerItem,
        // MyFamilyItem,
        // VaccinationsItem,
        // CarePlanItem,
        // OrderHistoryItem,
        GetTestedItem,
        TestResultsItem,
        // ImportHealthRecordsItem,
        ResourcesItem,
        FaqItem,
        ContactSupportItem,
        LegalItem,
        // HealthRecordItem,
        SettingsItem,
        LogoutItem,
      ].map((item, id) => ({ DrawerItem: item, id }))}
      hideWhenClosed={hideWhenClosed}
    />
  )
}
