import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import Carousel from 'react-elastic-carousel'
import CallIcon from '@material-ui/icons/Call'
import LanguageIcon from '@material-ui/icons/Language'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { stripHyphen } from '../../../libs/utils'
import { testTypes } from '../../../constants'
import { Button } from '../../../ui'
import { isFacilityOpenNow, isFedExOpenNow } from '../../../libs/helpers'
import { useStyles } from '../../../ui/templates/app-layout'
import { I18n } from '../../../features/translation/types'
import moment from 'moment/moment'

export const FacilityOptionDetails = (props: {
  facility: any
  setShowDetails: any
  i18n: I18n
  config: any
}) => {
  const classes = useStyles()
  const childClasses = childStyles()
  const { facility, setShowDetails, i18n, config } = props
  const { type } = useParams<{ type: string; id: string }>()

  const facilityIsWorking = type === testTypes.DROPOFF ? isFedExOpenNow(facility?.normalHours): isFacilityOpenNow(facility?.workingHour)

  return (
    <Grid style={{ marginTop: 10 }}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CloseIcon
          onClick={() => {
            setShowDetails(false)
          }}
          className={childClasses.closeIcon}
        />
      </Box>
      <Box className={childClasses.locationTitle}>{facility.name}</Box>
      <Box className={childClasses.locationInfo}>
        <Box
          className={`${childClasses.locationText} ${childClasses.locationAddress}`}
        >
          {`${facility.address1}, ${facility.city}, ${
            facility.state
          } ${facility.zip.slice(0, 5)}`}
        </Box>
        <Box>
          <Box
            className={`${childClasses.locationText} ${facilityIsWorking ? childClasses.open : childClasses.closed}`}>
            {facilityIsWorking ? `${i18n.open_label}` : `${i18n.closed_label}`}
          </Box>
          <Box className={childClasses.locationText}>
            {`${Math.round(facility.latitudeLongitudeDistance) / 10}mi`}
          </Box>
        </Box>
      </Box>
      <Box className={childClasses.btnWrapper}>
        <Button
          disabled={type === testTypes.DROPOFF && !facilityIsWorking}
          onClick={config[type].onClickSelect}
          className={`${classes.button} ${childClasses.confirmButton}`}
        >
          {config[type].selectButtonText}
        </Button>
      </Box>
      {facility.imageUrl ? (
        <Box className={childClasses.sliderWrapper}>
          <Carousel itemsToShow={1} isRTL={false}>
            <img src={facility.imageUrl} alt='' />
          </Carousel>
        </Box>
      ) : null}
      {facility.phone ? (
        <Box className={childClasses.moreInfoBox}>
          <a
            href={`tel:${stripHyphen(facility?.phone)}`}
            className={childClasses.locationText}
          >
            {facility.phone}
          </a>
          <Box>
            <CallIcon className={childClasses.iconColor} />
          </Box>
        </Box>
      ) : null}
      {facility.website ? (
        <Box className={childClasses.moreInfoBox}>
          <Box className={childClasses.locationText}>{facility.website}</Box>
          <Box>
            <LanguageIcon className={childClasses.iconColor} />
          </Box>
        </Box>
      ) : null}
      <Box className={childClasses.moreInfoBox}>
        <Box className={childClasses.addressInfo}>
          {`${facility.address1}, ${facility.city}, ${
            facility.state
          } ${facility.zip.slice(0, 5)} ${
            facility.country === 'US' ? `${i18n.united_states}` : null
          }`}
        </Box>
        <Box>
          <LocationOnIcon className={childClasses.iconColor} />
        </Box>
      </Box>
      {/*<Box className={classes.moreInfoBox}>*/}
      {/*  <Box className={classes.hoursText}>Hours</Box>*/}
      {/*</Box>*/}
      {facility?.workingHour && (
        <Box className={childClasses.moreInfoBox}>
          <Box>
            {facility.workingHour.map((schedule: any) => (
              <Box className={childClasses.locationText}>{schedule.weekDays}</Box>
            ))}
          </Box>
          {facility.workingHour ? (
            <Box className={childClasses.timeStatus}>
              {facility.workingHour.map((schedule: any) => (
                <Box className={childClasses.locationText}>
                  {schedule.from && schedule.to
                    ? `${schedule.from} - ${schedule.to}`
                    : `${i18n.closed_label}`}
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      )}

      {type === testTypes.DROPOFF && facility?.normalHours && (
        <RenderFedExWorkingHours officeData={facility} i18n={i18n} />
      )}


    </Grid>
  )
}

const RenderFedExWorkingHours = (props: { officeData: any, i18n: any }) => {
  const { officeData, i18n } = props
  const childClasses = childStyles()

  const getWorkingHours = (workingHours: any)=>{
    let workingHoursString = '-';

    if(workingHours?.length){
      // eslint-disable-next-line sonarjs/no-unused-collection
      let timeRanges: any = [];
      workingHours.forEach((item: any) =>{
        const beginTime = moment(item.begins).format('LT');
        const endTime = moment(item.ends).format('LT');
        timeRanges.push(`${beginTime} - ${endTime}`)
      });
      workingHoursString = timeRanges.join(',');
    }

    return workingHoursString
  }

  return <Box className={childClasses.moreInfoBox} style={{flexDirection: 'column', alignItems: 'normal'}}>
    {officeData.normalHours.map((item: any) => (
      // eslint-disable-next-line sonarjs/no-duplicate-string
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box className={childClasses.locationText}>{item.dayOfWeek}</Box>
        <Box className={childClasses.timeStatus}>{getWorkingHours(item.hours)}</Box>
      </Box>
    ))}

    {officeData.workingHour ? (
      <Box className={childClasses.timeStatus}>
        {officeData.workingHour.map((schedule: any) => (
          <Box className={childClasses.locationText}>
            {schedule.from && schedule.to
              ? `${schedule.from} - ${schedule.to}`
              : `${i18n.closed_label}`}
          </Box>
        ))}
      </Box>
    ) : null}
  </Box>
}

const borderInfo = '1px solid #E5E5E6'

const childStyles = makeStyles({
  closeIcon: {
    color: '#003C71',
    marginRight: 20,
    marginLeft: 20,
    fontWeight: 600,
    cursor: 'pointer',
  },
  locationText: {
    color: '#505358',
    letterSpacing: 0.25,
    fontSize: 16,
  },
  locationInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
  locationTitle: {
    color: '#54565B',
    fontWeight: 900,
    fontSize: 16,
    marginBottom: 5,
    marginLeft: 20,
  },
  locationAddress: {
    maxWidth: 167,
  },
  btnWrapper: {
    textAlign: 'center',
    width: '100%',
  },
  confirmButton: {
    height: 48,
    fontWeight: 600,
    marginTop: 40,
    alignSelf: 'center',
    width: 300,
    marginBottom: 24,
  },
  moreInfoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',
    borderBottom: `${borderInfo}`,
    marginLeft: 20,
    paddingRight: 20,
    marginBottom: 10,
  },
  iconColor: {
    color: '#003C71',
  },
  sliderWrapper: {
    'padding': '20px 0',
    'borderTop': `${borderInfo}`,
    'borderBottom': `${borderInfo}`,
    '& .rec-slider-container': {
      margin: 0,
    },
    '& .rec.rec-arrow': {
      display: 'none',
    },
    '& img': {
      width: '100%',
    },
    '& .rec-dot': {
      boxShadow: 'none !important',
      backgroundColor: '#CBCCCD',
    },
    '& .rec-dot_active': {
      backgroundColor: '#003C71',
    },
  },
  addressInfo: {
    maxWidth: 170,
  },
  timeStatus: {
    textAlign: 'right',
  },
  open: {
    color: '#5cb960',
  },
  closed: {
    color: '#f91819',
  },
})
