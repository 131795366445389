import { Dialog, Theme, useMediaQuery } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { StepType } from '../../../constants'
import { selectLanguageContent } from '../../../features/translation'
import { Button, FilledTextField, Typography } from '../../../ui'
import { onboardingStyles } from '../../_styles/registrationStyles'
import { getNetworkSettings, selectNetworkSettings } from '../../network/model'
import { NetworkSettings } from '../../network/type'
import { paths } from '../../paths'
import { scrollTop } from '../helpers'
import {
  getCurrentStep,
  getSignupType,
  resetRegisterForm,
  selectEligibilityErrorDialog,
  setActive,
  setEligibiltyDialogOpen,
  setSignupType,
  submitAccessCode,
} from '../model/registerSlice'
import { OnboardingType } from '../model/type'
import { EligibilityError } from './eligibilityErrorDialog'

export const AccessCode = () => {
  const classes = onboardingStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const [code, setCode] = useState('')
  const currentStep = useSelector(getCurrentStep)
  const currentNetwork = useSelector(selectNetworkSettings)
  const tenantId = localStorage.getItem('tenantId')
  const signupType = useSelector(getSignupType)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))
  const eligibilityErrorDialog = useSelector(selectEligibilityErrorDialog)

  useEffect(() => {
    if (tenantId && !currentNetwork) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [currentNetwork, tenantId])

  useEffect(() => {
    document.addEventListener('focusout', scrollTop)
    return () => {
      document.removeEventListener('focusout', scrollTop)
    }
  }, [])

  useEffect(() => {
    if (currentStep && currentStep !== 1) {
      history.push(paths.registerTab(StepType.SIGN_UP))
    }
  }, [currentStep])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const nextStep = (res: NetworkSettings) => {
    if (signupType === OnboardingType.UNSET) {
      dispatch(setSignupType(OnboardingType.SELF))
    }
    if (res.enableAccountVerificationScreen) {
      dispatch(setActive(0))
      history.push(paths.registerTab(StepType.ELIGIBILITY))
    } else {
      dispatch(setActive(1))
      history.push(paths.registerTab(StepType.SIGN_UP))
    }
  }

  const onSubmit = () => {
    dispatch(submitAccessCode(code, nextStep))
  }

  const cancelEligibility = () => {
    dispatch(resetRegisterForm())
    localStorage.removeItem('tenantId')
    history.push(paths.login())
  }

  return (
    <div className={classes.stepContent}>
      <div
        className={classes.backIconWrapper}
        onClick={() => history.push(paths.login())}
      >
        <ArrowBackIcon className={classes.pageLink} />
        {!isMobile && (
          <Typography className={classes.pageLink}>
            {i18n.back_button}
          </Typography>
        )}
      </div>
      <form className={classes.content}>
        <Typography className={classes.title} color="primary" variant="h1">
          {i18n.enter_access_code}
        </Typography>
        <Typography className={classes.description} align="left">
          {i18n.enter_access_code_instruction}
        </Typography>
        <FilledTextField
          label={i18n.access_code}
          name={'Access Code'}
          type={'text'}
          value={code}
          variant="filled"
          onChange={handleChange}
          required
        />
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            className={classes.actionBtn}
            onClick={onSubmit}
            disabled={!code}
          >
            {i18n.submit_button}
          </Button>
        </div>
      </form>
      <Dialog open={eligibilityErrorDialog} fullScreen>
        <div className={classes.dialogContainer}>
          <EligibilityError
            onClose={() => dispatch(setEligibiltyDialogOpen(false))}
            onCancel={cancelEligibility}
          />
        </div>
      </Dialog>
    </div>
  )
}
