import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { lsClient } from '../../ls-client'

interface EncounterService {
  isLoading: boolean
  _id: string
  healthserviceId: string
  data: any
}

const initialStore: EncounterService = {
  isLoading: false,
  _id: '',
  healthserviceId: '',
  data: undefined,
}

export enum EncounterStatus {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
}

export enum EncounterType {
  SYNC_CONSULT_VIDEO = 'SYNC_CONSULT_VIDEO',
  SYNC_CONSULT_PHONE = 'SYNC_CONSULT_PHONE',
  SYNC_CONSULT_CHAT = 'SYNC_CONSULT_CHAT',
  ASYNC_CONSULT = 'ASYNC_CONSULT',
  // DIAGNOSTIC_TEST = 'DIAGNOSTIC_TEST',
  // VIRTUAL_CONSULT = 'VIRTUAL_CONSULT',
  // DIAGNOSTIC_ASSESMENT = 'DIAGNOSTIC_ASSESMENT',
  // TELEHEALTH_CONSULT = 'TELEHEALTH_CONSULT',
  // VIRTUAL_CONSOLE_REVIEW = 'VIRTUAL_CONSOLE_REVIEW',
}

export const encounterServiceSlice = createSlice({
  name: 'encounterService',
  initialState: initialStore,
  reducers: {
    // setHealthServiceList(store, { payload }: { payload: HealthService[] }) {
    //   store.healthServiceList = payload
    // },
    setEncounterLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setEncounterData(store, { payload }: { payload: any }) {
      store.data = payload
    },
    resetHSStore: () => initialStore,
  },
})

const { resetHSStore, setEncounterLoading, setEncounterData } =
  encounterServiceSlice.actions

export const createEncounterRecord = (params: any) => async (dispatch: any) => {
  dispatch(setEncounterLoading(true))
  const { data, error } = await api.createEncounterRecord(params)

  if (error) {
    dispatch(setEncounterLoading(false))
  }

  if (data) {
    dispatch(setEncounterLoading(false))
  }
}

export const getEncounterRecord =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(setEncounterLoading(true))
    const { data, error } = await api.getEncounterRecord(id)

    if (error) {
      dispatch(setEncounterLoading(false))
    }

    if (data) {
      dispatch(setEncounterLoading(false))
      dispatch(setEncounterData(data))
    }
  }

export const updateEncounterRecord =
  (params: any, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    dispatch(setEncounterLoading(true))
    const { data, error } = await api.updateEncounterRecord(params)

    if (error) {
      dispatch(setEncounterLoading(false))
    }

    if (data) {
      dispatch(setEncounterLoading(false))
      if (onSuccess) onSuccess()
    }
  }

export const selectEncounter = ({ encounterService }: RootStore) =>
  encounterService.data

export const selectEncounterLoading = ({ encounterService }: RootStore) =>
  encounterService.isLoading

export const selectTreatmentPlan = ({ encounterService }: RootStore) =>
  encounterService.data?.treatmentPlan

export const selectEncounterDisposition = ({ encounterService }: RootStore) =>
  encounterService.data?.disposition

export const encounterServiceReducer = encounterServiceSlice.reducer

export const encounterServiceReducerName = encounterServiceSlice.name

interface RootStore {
  [encounterServiceReducerName]: typeof initialStore
}
