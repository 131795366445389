import { Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ScanImageLG from '../../assets/img/ScanImageLG.png'
import ScanImageSM from '../../assets/img/ScanImageSM.png'
import { ImageBackgroundHeader } from '../../features/application/templates/image-bg-header'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { Button, Loading, Typography } from '../../ui'
import { Controls } from '../../ui/templates/app-layout'
import { paths } from '../paths'
// import { selectOutcomeOrderableTestDetails } from '../questionnaireV2/questionnaireV2Slice'
import { getLabTestDetails, selectRegisterDetails, selectTestLoading } from './model'

export const ScanIntroductionPage = () => {
  const history = useHistory()
  const theme = useTheme()
  const loadingTest = useSelector(selectTestLoading)
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const registerDetails = useSelector(selectRegisterDetails)
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [scanImage, setScanImage] = useState(
    matches ? ScanImageLG : ScanImageSM
  )
  const classes = useStyles({ scanImage })
  const { id } = useParams<{ id: string }>()

  const goNext = () => {
    history.push(paths.app.scanQrCode())
  }

  useEffect(() => {
    // Advance member to Collection Flow if resuming HS and kit scan id exists
    const dmCode = lsClient.getUserLSByKey('code')
    const step = lsClient.getUserLSByKey('step')
    if (dmCode && !step) {
      history.push(paths.app.productAssetDetails())
    } else if (dmCode && step) {
      history.push(paths.app.instructions())
    } else if (id && !registerDetails) {
      dispatch(getLabTestDetails(id))
    }
  }, [])

  useEffect(() => {
    if (registerDetails?.imageURL && !matches) {
      setScanImage(registerDetails.imageURL)
    } else if (matches) {
      setScanImage(ScanImageLG)
    }
  }, [matches, registerDetails])

  if (loadingTest) return <Loading />

  return (
    <Box className={classes.pageWrapper}>
      <ImageBackgroundHeader className={classes.transparentAppBar} />
      <Box className={classes.contentWrapper}>
        <div className={classes.assetWrapper}>
          <Box className={classes.assetBox}>
            <Typography className={classes.title}>
              {registerDetails?.title || i18n.register_test_kit_screen_title}
            </Typography>
            {registerDetails?.description ? (
              <Typography className={classes.body}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(registerDetails.description),
                  }}
                />
              </Typography>
            ) : (
              <Typography className={classes.body}>
                {i18n.scan_code_instruction_text}
              </Typography>
            )}
          </Box>
          <Controls>
            <Button
              onClick={goNext}
              className={classes.confirmButton}
              variant="outlined"
            >
              {registerDetails?.buttonTitle || i18n.scan_code_button}
            </Button>
          </Controls>
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  transparentAppBar: {
    boxShadow: 'none',
    background: 'rgba(0,0,0,0.5)',
    [theme.breakpoints.down(600)]: {
      background: 'none',
    },
  },
  pageWrapper: {
    width: '100%',
    minHeight: '100vh',
    backgroundImage: ({ scanImage }: { scanImage: string }) =>
      `url(${scanImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'fixed',
    backgroundPosition: '50%',
    [theme.breakpoints.down(600)]: {
      backgroundPosition: '50% 0%',
    },
  },
  assetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    width: '100%',
    margin: '0px auto',
    [theme.breakpoints.down(600)]: {
      paddingTop: '150px',
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.77) 100%)',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    minHeight: 'calc(100vh - 64px)',
    height: 'fit-content',
    backgroundColor: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      bottom: '0px',
      background: 'none',
      width: '100%',
      padding: '0px',
      margin: '0px auto',
      textAlign: 'left',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
  },
  confirmButton: {
    marginTop: 48,
    maxWidth: '220px',
    [theme.breakpoints.down(600)]: {
      maxWidth: 'unset',
      marginBottom: '40px',
    },
    [theme.breakpoints.down(389)]: {
      marginTop: 16,
    },
  },
  title: {
    width: '342px',
    fontWeight: 500,
    color: '#FFF',
    lineHeight: '55px',
    fontSize: 40,
    textAlign: 'inherit',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  body: {
    width: '342px',
    fontWeight: 600,
    marginTop: 10,
    color: '#FFF',
    lineHeight: '22px',
    fontSize: 16,
    textAlign: 'inherit',
    marginBottom: '8px',
    [theme.breakpoints.down(600)]: {
      marginBottom: '16px',
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  assetBox: {
    [theme.breakpoints.down(600)]: {
      padding: '0px 24px',
    },
  },
  pageLink: {
    textDecoration: 'underline',
    color: '#FFF',
    cursor: 'pointer',
    textAlign: 'inherit',
  },
}))
