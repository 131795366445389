import { Box, Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import api from '../../../api'
import { I18n } from '../../../features/translation/types'
import { clearTestKitFlow, handleError } from '../../../libs/helpers'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { lsClient } from '../../../ls-client'
import { Button, Loading } from '../../../ui'
import { ModuleHeader } from '../../../ui/templates/modules/moduleHeader'
import { paths } from '../../paths'
import {
  selectFullOrderableTest,
  selectProductAssetDetail,
  selectProductAssetDetailLoading,
  setActive,
  setCollectionsForContinue,
  setLoading,
} from '../model'
import { RegisterTestProps, RegisterTestStepType } from '../types'

export const ProductDetails = (props: RegisterTestProps) => {
  const { i18n } = props
  const dispatch = useDispatch()
  const productAssetDetails = useSelector(selectProductAssetDetail)
  const orderableTest = useSelector(selectFullOrderableTest)
  const isLoading = useSelector(selectProductAssetDetailLoading)
  const classes = useStyles()
  const history = useHistory()
  const testId = lsClient.getUserLSByKey('testId')
  const testType = lsClient.getUserLSByKey('testType')

  const [backDialogOpen, setBackDialogOpen] = useState(false)

  useEffect(() => {
    //Reloads the test data in case of refresh on this page
    if (!productAssetDetails && testId) {
      ;(async function () {
        dispatch(setLoading(true))
        let instructionsReload

        if (testType === 'orderableTest') {
          instructionsReload = await api.getLabTestForContinue(testId, 'en')
        } else {
          await api.getLabTestPanel(testId, 'en').then((response) => {
            instructionsReload = response.result
          })
        }
        if (instructionsReload && !instructionsReload.error) {
          dispatch(setCollectionsForContinue(instructionsReload))
        } else if (instructionsReload?.error) {
          // try to get translated error by key from localization file or render received error string
          dispatch(
            handleError(
              null,
              i18n[instructionsReload.error] || instructionsReload.error
            )
          )
        }
        dispatch(setLoading(false))
      })()
    }
  }, [productAssetDetails])

  const onExit = () => {
    history.push(paths.app.dashboard())
  }

  const goNext = () => {
    history.push(paths.instructions())
    if (!lsClient.getUserLSByKey('step')) {
      lsClient.setUserLS('step', '0')
    }
  }

  const cancelTest = () => {
    clearTestKitFlow()
    setBackDialogOpen(false)
    dispatch(setActive(0))
    history.push(paths.registerTest(RegisterTestStepType.INTRO))
  }

  if (isLoading) return <Loading />

  const onBack = () => {
    setBackDialogOpen(true)
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={onBack}
      rightContentTitle={i18n.exit_button}
      rightContentAction={onExit}
      color="#505358"
    >
      <Box className={classes.contentWrapper}>
        <Box className={classes.imageWrapper}>
          <div className={classes.backgroundWrapper}>
            <img
              className={classes.img}
              src={productAssetDetails?.imageURL}
              alt={
                orderableTest?.customTestName
                  ? orderableTest?.customTestName
                  : 'Orderable Test'
              }
            />
          </div>
        </Box>
        <Box className={classes.content}>
          {(orderableTest?.customTestName || orderableTest?.shortName) && (
            <>
              <Typography className={classes.minorHeader}>
                {i18n.test_kit_type}
              </Typography>
              <Typography className={classes.testName}>
                {orderableTest.customTestName || orderableTest?.shortName}
              </Typography>
              <Typography className={classes.altName}>
                {orderableTest.orderableName}
              </Typography>
            </>
          )}
          <Typography className={classes.includedLabel}>
            {i18n.whats_included_label}
          </Typography>
          <Box className={classes.assetBox}>
            <Box className={classes.htmlContainer}>
              {productAssetDetails?.body && (
                <HTMLTextComponent HTMLString={productAssetDetails.body} />
              )}
            </Box>
          </Box>
          <Box className={classes.lgButtonBox}>
            <Button onClick={goNext} className={classes.confirmButton}>
              {i18n.continue_button}
            </Button>
          </Box>
        </Box>
        <Dialog open={backDialogOpen}>
          <GoBackWarning
            cancelTest={cancelTest}
            setBackDialogOpen={setBackDialogOpen}
            classes={classes}
            i18n={i18n}
          />
        </Dialog>
      </Box>
    </ModuleHeader>
  )
}

const GoBackWarning = (props: {
  cancelTest: () => void
  setBackDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  classes: ClassNameMap
  i18n: I18n
}) => {
  const { cancelTest, setBackDialogOpen, classes, i18n } = props

  return (
    <Box className={classes.dialogWrapper}>
      <Box className={classes.dialogContent}>
        <Box>
          <Typography variant="h2" style={{ marginBottom: 10 }}>
            {i18n.are_you_sure}
          </Typography>
        </Box>
        <Box className={classes.headerWrapper}>
          <Typography
            variant="body1"
            align="center"
            style={{ marginBottom: 30, fontWeight: 500 }}
          >
            {i18n.going_back_will_permanently}
          </Typography>
        </Box>
        <div className={classes.lgButtonBox}>
          <Button
            onClick={() => setBackDialogOpen(false)}
            className={`${classes.confirmButton} dialog`}
            style={{ margin: '20px 0px' }}
          >
            {i18n.continue_button}
          </Button>
          <Button
            onClick={cancelTest}
            className={`${classes.confirmButton} dialog`}
            style={{ margin: '20px 0px' }}
          >
            {i18n.abandon_test_flow_title}
          </Button>
        </div>
      </Box>
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#E0E0E0',
    width: '40vw',
    maxWidth: 416,
    [theme.breakpoints.down(960)]: {
      marginTop: '48px',
      width: '100%',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
      width: '100vw',
    },
  },
  backgroundWrapper: {
    height: 0,
    padding: '50% 0px',
    display: 'flex',
    alignItems: 'center',
  },
  dialogWrapper: {
    padding: '30px 10px',
    height: 250,
    width: 350,
    [theme.breakpoints.down(960)]: {
      height: 'unset',
      width: 'unset',
      padding: '30px 0px',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  img: {
    width: '40vw',
    maxWidth: 416,
    [theme.breakpoints.down(600)]: {
      objectFit: 'cover',
      width: '100%',
    },
  },
  htmlContainer: {
    width: '90%',
    margin: '0 25px',
    overflow: 'auto',
  },
  content: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    borderRadius: 32,
    backgroundColor: '#FFF',
    [theme.breakpoints.down(960)]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  minorHeader: {
    color: '#757575',
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  includedLabel: {
    color: '#505358',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  testName: {
    fontWeight: 600,
    color: '#282D37',
    fontSize: 32,
    textAlign: 'left',
    lineHeight: '110%',
    margin: '4px 0px',
    [theme.breakpoints.down(960)]: {
      fontSize: 24,
      fontWeight: 500,
    },
  },
  altName: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'left',
    marginBottom: 14,
  },
  assetBox: {
    overflowX: 'hidden',
    width: '100%',
    minWidth: 320,
    marginTop: 15,
    alignContent: 'left',
    [theme.breakpoints.down(960)]: {
      border: '1px solid #E5E5E6',
      borderRadius: 16,
    },
    [theme.breakpoints.down(320)]: {
      minWidth: 'unset',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 80,
    width: '100%',
    maxWidth: 1400,
    margin: '78px auto 0px',
    [theme.breakpoints.down(960)]: {
      width: '90%',
      margin: '0px auto',
      flexDirection: 'column',
      justifyContent: SPACE_BETWEEN,
      alignItems: 'center',
      gap: 0,
    },
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    marginTop: '32px',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  confirmButton: {
    'minWidth': 'unset',
    'padding': '0px 24px',
    'width': '100%',
    'height': '46px',
    '&.dialog': {
      maxWidth: 'fit-content',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: '100%',
      minHeight: '46px',
    },
  },
}))
