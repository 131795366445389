import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { lsClient } from '../../../ls-client'
import { Loading } from '../../../ui'
import {
  ModuleActionButton,
  ModuleIntroWrapper,
} from '../../../ui/templates/modules/moduleIntroWrapper'
import { paths } from '../../paths'
import {
  getLabTestDetails,
  getTestPanelDetails,
  selectRegisterDetails,
  selectTestLoading,
  setActive,
} from '../model'
import { RegisterTestProps } from '../types'

export const ScanIntroduction = (props: RegisterTestProps) => {
  const { i18n, handleNext, handleBack } = props
  const history = useHistory()
  const loadingTest = useSelector(selectTestLoading)
  const dispatch = useDispatch()
  const registerDetails = useSelector(selectRegisterDetails)
  const testId = lsClient.getUserLSByKey('testId')
  const testType = lsClient.getUserLSByKey('testType')

  useEffect(() => {
    // Advance member to Collection Flow if resuming HS and kit scan id exists
    const dmCode = lsClient.getUserLSByKey('code')
    const step = lsClient.getUserLSByKey('step')
    if (dmCode && !step) {
      dispatch(setActive(3))
    } else if (dmCode && step) {
      history.push(paths.app.instructions())
    } else if (testId && !registerDetails && testType === 'orderableTest') {
      dispatch(getLabTestDetails(testId))
    } else if (testId && !registerDetails && testType === 'orderablePanel') {
      dispatch(getTestPanelDetails(testId))
    }
  }, [])

  const back = () => {
    lsClient.removeUserKeyLS('testId')
    lsClient.removeUserKeyLS('testType')
    handleBack()
  }

  const actions = [
    {
      label: registerDetails?.buttonTitle,
      action: handleNext,
      variant: 'contained',
    },
    {
      label: i18n.go_back,
      action: back,
      variant: 'outlined',
    },
  ] as ModuleActionButton[]

  if (loadingTest) return <Loading size={100} />

  return (
    <ModuleIntroWrapper
      title={registerDetails?.title || i18n.register_test_kit_screen_title}
      body={
        registerDetails?.description ? (
          <HTMLTextComponent HTMLString={registerDetails.description} />
        ) : (
          ''
        )
      }
      contentImage={registerDetails?.imageURL || ''}
      actions={actions}
    />
  )
}
