import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilledTextField } from '../../../ui'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  setCanContiune,
  updateAnswer,
} from '../questionnaireV2Slice'

enum Field {
  feet = 'feet',
  inches = 'inches',
}

export const HeightQuestion = () => {
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const existingAnswer = useSelector(selectExistingAnswer)
  const [answer, setAnswer] = useState({ feet: 0, inches: 0 })
  const [feetError, setFeetError] = useState('')
  const [inchesError, setInchesError] = useState('')

  useEffect(() => {
    if (existingAnswer) {
      const values = existingAnswer.value.split(', ')
      const previousAnswer = {
        feet: Number(values[0]) || 0,
        inches: Number(values[1]) || 0,
      }
      dispatch(updateAnswer(previousAnswer))
      setAnswer(previousAnswer)
    }
  }, [question, existingAnswer])

  const handleFocus = () => {}

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: Field
  ) => {
    const value = e.target.value
    if (Number.isNaN(Number(value))) return
    if (question.maxFeet && field === Field.feet) {
      if (Number(value) > question.maxFeet) {
        dispatch(setCanContiune(false))
        setFeetError(`Height (Feet) should not exceed ${question.maxFeet}.`)
        return
      }
      if (Number(value) <= question.maxFeet) {
        setFeetError('')
      }
    }
    if (question.maxInches && field === Field.inches) {
      if (Number(value) > question.maxInches) {
        setCanContiune(false)
        setInchesError(
          `Height (Inches) should not exceed ${question.maxInches}.`
        )
        return
      }
      if (Number(value) <= question.maxInches) {
        setInchesError('')
      }
    }
    const updatedAnswer = { ...answer, [field]: Number(value) }
    dispatch(updateAnswer(updatedAnswer))
    setAnswer(updatedAnswer)
  }

  const handleIncrease = (field: Field) => {
    setFeetError('')
    setInchesError('')
    if (
      (field === Field.feet &&
        question.maxFeet &&
        answer.feet < question.maxFeet) ||
      (field === Field.inches &&
        question.maxInches &&
        answer.inches < question.maxInches)
    ) {
      const updatedAnswer = {
        ...answer,
        [field]: answer[field] + (question.step || 1),
      }
      dispatch(updateAnswer(updatedAnswer))
      setAnswer(updatedAnswer)
    } else if (
      field === Field.feet &&
      question.maxFeet &&
      answer.feet >= question.maxFeet
    ) {
      setFeetError(`Height (Feet) should not exceed ${question.maxFeet}.`)
    } else if (
      field === Field.inches &&
      question.maxInches &&
      answer.inches >= question.maxInches
    ) {
      setInchesError(`Height (Inches) should not exceed ${question.maxInches}.`)
    }
  }
  const handleDecrease = (field: Field) => {
    if (
      (field === Field.feet && answer.feet > 0) ||
      (field === Field.inches && answer.inches > 0)
    ) {
      setFeetError('')
      setInchesError('')
      const updatedAnswer = {
        ...answer,
        [field]: answer[field] - (question.step || 1),
      }
      dispatch(updateAnswer(updatedAnswer))
      setAnswer(updatedAnswer)
    }
  }

  return (
    <>
      <FilledTextField
        label={'Height (feet)'}
        value={answer.feet}
        onChange={(e) => handleChange(e, Field.feet)}
        max={question.maxFeet ? question.maxFeet : undefined}
        min={0}
        // onFocus={(e: any) => e.target.select()}
        error={Boolean(feetError)}
        helperText={feetError}
        isNumberField={true}
        showNumericIncrement={true}
        increase={() => handleIncrease(Field.feet)}
        decrease={() => handleDecrease(Field.feet)}
      />
      <FilledTextField
        label={'Height (inches)'}
        value={answer.inches}
        onChange={(e) => handleChange(e, Field.inches)}
        max={question.maxInches ? question.maxInches : undefined}
        min={0}
        // onFocus={(e: any) => e.target.select()}
        error={Boolean(inchesError)}
        helperText={inchesError}
        isNumberField={true}
        showNumericIncrement={true}
        increase={() => handleIncrease(Field.inches)}
        decrease={() => handleDecrease(Field.inches)}
      />
    </>
  )
}
