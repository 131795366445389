import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import React from 'react'
import { I18n } from '../../features/translation/types'
import { Content, ContentInner, useStyles, Wrapper } from './app-layout'

export const ProcessingLoader = ({ i18n }: { i18n: I18n }) => {
  const classes = useStyles()

  return (
    <Wrapper>
      <Box className={classes.contentStyles}>
        <Content style={{ justifyContent: 'center', margin: 0 }}>
          <ContentInner>
            <CircularProgress
              style={{ color: 'white', width: 100, height: 100 }}
            />
            <Box className={classes.verifyingProcessText}>
              {i18n.processing}
            </Box>
          </ContentInner>
        </Content>
      </Box>
    </Wrapper>
  )
}
