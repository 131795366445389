import React, { useEffect, useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Select } from '../../ui'
import { allowedRelationships } from '../../constants'
import { submitGuardianInfo, getGuardianInfo, selectGuardianInfo, selectGuardianIsUpdate, updateGuardianInfo } from './model'
import { FormValues } from './types'
import validationSchema from './validationSchema'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { SubHeaderGetTested } from '../../features/application'
import { useStyles, Wrapper, Content, FormWrapper, Controls } from '../../ui/templates/app-layout'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import {translateOptions} from "../../libs/helpers";

export const GuardianInfoPage = () => {
  const childClasses = childStyles()
  const theme = useTheme();
  const classes = useStyles()
  const history = useHistory()
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const historyState = history.location.state as {prevSection?: string}
  const i18n = useSelector(selectLanguageContent)
  const { _id: userId, email } = useSelector(selectProfileData)
  const guardianInfo = useSelector(selectGuardianInfo)
  const isUpdate = useSelector(selectGuardianIsUpdate)
  const [formData, setFormData] = useState<FormValues>({
    relatedPersonFirstName: '',
    relatedPersonLastName: '',
    relationship: '',
    userId: '',
    relatedPersonEmail: '',
  })

  useEffect(() => {
    if (guardianInfo) {
        setFormData({
            relatedPersonFirstName: guardianInfo.relatedPersonFirstName || '',
            relatedPersonLastName: guardianInfo.relatedPersonLastName || '',
            relationship: guardianInfo.relationship || '',
            userId: '',
            relatedPersonEmail: '',
          })
    }
  }, [guardianInfo])

  useEffect(() => {
    if (!email) history.push(paths.app.dashboard())
    else dispatch(getGuardianInfo(userId))
  }, [])

  const dispatch = useDispatch();

  const onSubmit = async (values: FormValues) => {
      if (isUpdate) {
          const data = {...guardianInfo, ...values}
        await dispatch(updateGuardianInfo(data))
      } else {
          await dispatch(submitGuardianInfo(values))
      }
  }

  const formik = useFormik({
    initialValues: {
        relatedPersonFirstName: formData.relatedPersonFirstName,
        relatedPersonLastName: formData.relatedPersonLastName,
        relationship: formData.relationship,
        userId: userId,
        relatedPersonEmail: email,
    },
    enableReinitialize: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Wrapper>
      <SubHeaderGetTested title={i18n.legal_guardian_required} onHandleBack={handleBack} fullWidth={matches && historyState?.prevSection!=='checkout'}/>
        <Content onSubmit={formik.handleSubmit} noValidate className={childClasses.content}>
          <FormWrapper >
          <Box className={childClasses.infoText}>
              {i18n.legal_guardian_info}
          </Box>
            <Box className={childClasses.formWrapper}>
              <NameWrapper>
                <FirstName formik={formik} i18n={i18n} />
                <LastName formik={formik} i18n={i18n} />
              </NameWrapper>
              <Relationship formik={formik} i18n={i18n} />
            </Box>
          </FormWrapper>
          <Controls>
            <Button
              className={classes.button}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {i18n.save_button}
            </Button>
          </Controls>
        </Content>
      </Wrapper>
  )
}

const FirstName = (props: { formik: any, i18n: any }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.first_name}
      name='relatedPersonFirstName'
      value={formik.values.relatedPersonFirstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.relatedPersonFirstName && formik.touched.relatedPersonFirstName)}
      helperText={formik.touched.relatedPersonFirstName ? formik.errors.relatedPersonFirstName : ''}
      required
    />
  )
}

const LastName = (props: { formik: any, i18n: any }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.last_name}
      name='relatedPersonLastName'
      value={formik.values.relatedPersonLastName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.relatedPersonLastName && formik.touched.relatedPersonLastName)}
      helperText={formik.touched.relatedPersonLastName ? formik.errors.relatedPersonLastName : ''}
      required
    />
  )
}

const Relationship = (props: { formik: any, i18n: any }) => {
  const { formik, i18n } = props

  return (
    <Select
      label={i18n['relationship']}
      name='relationship'
      value={formik.values.relationship}
      options={translateOptions(allowedRelationships, i18n)}
      onChange={formik.handleChange}
      error={formik.errors.relationship && formik.touched.relationship}
      onBlur={formik.handleBlur}
      required
    />
  )
}


const childStyles = makeStyles((theme) => ({
  infoText: {
    margin: '24px 0',
    fontSize: 26,
    color: '#7B7C7D',
    alignSelf: 'center',
    textAlign: 'center',
  },
  content: {
    padding: '0px 0px !important',
  },
  field: {
    width: '100%',
    marginBottom: '25px',
    letterSpacing: '.1em',
    fontFamily: 'Avenir Next Rounded Medium',
    fontWeight: 600,
    color: 'red'
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding:"0px 15px",
  },
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#6d6d6d',
    fontWeight: 600,
    backgroundColor: 'rgb(237, 237, 237)',
    width: '100%',
    height: '45px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTop: '1px solid rgba(0, 0, 0, 0.42)',
  },
}))

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`
