import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilledDatePicker } from '../../../ui'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  updateAnswer,
} from '../questionnaireV2Slice'

type DurationConstructor = 'days' | 'weeks' | 'months' | 'years'

export const DateQuestion = () => {
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const existingAnswer = useSelector(selectExistingAnswer)
  const [minDate, setMinDate] = useState<Date | undefined>(undefined)
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined)
  const [dateError, setDateError] = useState('')
  const [date, setDate] = useState(moment.utc().toDate())

  useEffect(() => {
    if (existingAnswer) {
      setDate(moment.utc(existingAnswer.value).toDate())
      dispatch(updateAnswer(existingAnswer.value))
    }
  }, [question, existingAnswer])

  useEffect(() => {
    if (typeof question.min === 'number') {
      setMinDate(getDateRange(question.min))
    }
    if (typeof question.max === 'number') {
      setMaxDate(getDateRange(question.max))
    }
  }, [question])

  const getDateRange = (value: number | null | undefined) => {
    if (value === null || value === undefined) return undefined
    return moment
      .utc()
      .add(value, question.precision?.toLowerCase() as DurationConstructor)
      .local()
      .endOf('day')
      .toDate()
  }

  const handleDateChange = (e: MaterialUiPickersDate) => {
    setDateError('')
    if (moment.utc(e).isValid()) {
      const date = moment.utc(e).local().endOf('day')
      if (question.min && minDate && date.toDate() < minDate) {
        setDateError('Entry is less than the minimum allowed Date')
        return
      }
      if (question.max && maxDate && date.toDate() > maxDate) {
        setDateError('Entry is greater than the maximum allowed Date')
        return
      }
      setDate(date.toDate())
      dispatch(updateAnswer(date.format('YYYY-MM-DD')))
    }
  }

  return (
    <FilledDatePicker
      value={date}
      format="MM/dd/yyyy"
      fullWidth
      inputVariant="filled"
      error={Boolean(dateError)}
      helperText={dateError}
      onChange={(e) => handleDateChange(e)}
      maxDate={maxDate}
      minDate={minDate}
    />
  )
}
