import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useHistory, useParams } from 'react-router-dom'
import {
  Typography,
  Box,
  Checkbox,
  Divider,
  TextField, Link,
} from '@material-ui/core'
import moment from 'moment'
import { testTypes, ADDRESS_TYPES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { getTestKitDetails, selectTestKitDetails } from '../order-test/model'
import { Button } from '../../ui'
import { paths } from '../paths'
import { selectNetworkSettings } from '../network/model'
import { SubHeaderGetTested } from '../../features/application'
import { getProfileData, selectProfileData } from '../profile/model'
import {
  selectCreditCards,
} from '../payment-methods/model'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import {
  placeOrder,
  getLegalNotes,
  applyCoupon,
  selectCoupon,
  resetCoupon,
  selectSelectedFacility,
  checkoutMounted,
  selectSelectedAppointmentDate,
  getInsuranceResponsibility,
  selectInsuranceIsSelected,
  selectInsuranceResponsibility,
  selectAppointmentId, selectSelectedCreditCard,
} from './model'
import { selectInsurance } from '../insurance/model'
import { selectLanguageContent } from '../../features/translation'
import { useStyles, Wrapper, Content } from '../../ui/templates/app-layout'
import { I18n } from '../../features/translation/types'
import { TestKitDetails } from '../../types/tests'
import {CreditCard} from "../payment-methods/types";

export const CheckoutPage = () => {
  const childClasses = childStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const i18n = useSelector(selectLanguageContent)
  const appointmentDate = useSelector(selectSelectedAppointmentDate)
  const insuranceData = useSelector(selectInsurance)
  const isInsuranceSelected = useSelector(selectInsuranceIsSelected)
  const appointmentId = useSelector(selectAppointmentId)
  const testData = useSelector(selectTestKitDetails)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfileData())
    dispatch(getLegalNotes())
    dispatch(checkoutMounted())
  }, [])

  useEffect(() => {
    if (isInsuranceSelected) {
      dispatch(getInsuranceResponsibility(id))
    }
  }, [isInsuranceSelected])

  useEffect(() => {
    if (!testData) {
      dispatch(getTestKitDetails(id))
    }
  }, [testData])

  const goBack = () => {
    history.goBack()
  }

  const handlePlaceOrder = async () => {
    if (type === testTypes.APPOINTMENT) {
      history.push(paths.informedConsent(type, id))
    } else {
      setLoading(true)
      if (await dispatch(placeOrder(type))) history.push(paths.orderPlaced(type))
      setLoading(false)

      // history.push(paths.orderPlaced())
    }
  }

  return (
    <Wrapper>
      {loading ? <ContentLoaderBox /> : null}
      <SubHeaderGetTested title={i18n.checkout_screen_title} onHandleBack={goBack} />
      <Content className={childClasses.innerWrapper}>
        <AccountDetailsBox i18n={i18n} />
        <OrderDetailsBox testData={testData} i18n={i18n} />
        <AppointmentBox appointmentDate={appointmentDate} i18n={i18n} />
        <InsuranceBox testData={testData} insuranceData={insuranceData} isInsuranceSelected={isInsuranceSelected} i18n={i18n} />
        <AddressInfoBox addressType={ADDRESS_TYPES.SHIPPING_ADDRESS} i18n={i18n} />
        <PaymentBox testData={testData} i18n={i18n} />
        <SummaryBox testData={testData} i18n={i18n} />
        <ShippingAgreement onHandlePlaceOrder={handlePlaceOrder} i18n={i18n} />
      </Content>
    </Wrapper>
  )
}

const AccountDetailsBox = ({ i18n }: { i18n: I18n }) => {
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const memberData = useSelector(selectProfileData)
  const { type, id } = useParams<{ type: string; id: string }>()

  return (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>Account Details</Typography>
      <Content className={childClasses.margin0}
               onClick={() => history.push(paths.editAccount(type, id), {prevSection: 'checkout'})}
      >
        <Box className={classes.optionsWrapper}>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {`${memberData?.firstName} ${memberData?.lastName}`}
              </Typography>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {i18n.checkout_member_account}
              </Typography>
            </Box>
            <Box className={classes.testPrice}>
              <ChevronRightIcon color='primary' />
            </Box>
          </Box>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {memberData?.contact?.mobileNumber ? `+1 ${memberData?.contact?.mobileNumber}` : '-'}
              </Typography>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {i18n.checkout_member_phone}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Content>
    </>
  )
}

const OrderDetailsBox = (props: { testData: TestKitDetails, i18n: I18n }) => {
  const { testData, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()

  return (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>{i18n.order_details_title}</Typography>
      <Content className={childClasses.margin0}>
        <Box className={classes.optionsWrapper}>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {testData?.customTestName ||
                testData?.labTest?.shortName}
              </Typography>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {testData?.shortDescription}
              </Typography>
            </Box>
            <Box className={classes.testPrice}>
              <Typography color='primary' >
                {`$${testData?.price}`}
              </Typography>
              <ChevronRightIcon color='primary' />
            </Box>
          </Box>
        </Box>
      </Content>
    </>
  )
}

const AppointmentBox = (props: {
  appointmentDate: { date: string; time: string }
  i18n: I18n
}) => {
  const { appointmentDate, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  const selectedFacility = useSelector(selectSelectedFacility)
  const { type } = useParams<{ type: string; id: string }>()

  return type === 'appointment' ? (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>{i18n.appointment_title}</Typography>
      <Content className={childClasses.margin0}>
        <Box className={classes.optionsWrapper}>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {selectedFacility?.name}
              </Typography>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {`${selectedFacility?.address1}`}
              </Typography>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {`${selectedFacility?.city}, ${selectedFacility?.state} ${selectedFacility?.zip}`}
              </Typography>
            </Box>
          </Box>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {moment(appointmentDate?.date, 'D MMM YYYY').format(
                  'MMM DD, YYYY',
                )}
              </Typography>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {moment(appointmentDate?.time, 'H:m').format('h:mm A')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Content>
    </>
  ) : null
}

const InsuranceBox = (props: { testData: TestKitDetails, isInsuranceSelected: boolean, insuranceData: any, i18n: I18n  }) => {
  const classes = useStyles()
  const childClasses = childStyles()

  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const { testData, isInsuranceSelected, insuranceData, i18n } = props
  const networkSettings = useSelector(selectNetworkSettings)

  const goToAddInsurance = () => {
    history.push(paths.confirmInsurance(type, id, 'details'))
  }

  if (
    !networkSettings?.labOrderCoverPatientResponsibility &&
    networkSettings?.labOrderCollectInsuranceInformation &&
    testData?.price
  ) {
    return (
      <>
        <Typography className={`${classes.testName} ${childClasses.sectionName}`}>{i18n.insurance_title}</Typography>
        <Content className={childClasses.margin0}>
          <Box className={classes.optionsWrapper}>
            <Box className={childClasses.optionWrapper} onClick={goToAddInsurance}>
              <Box className={classes.testDescription}>
                <Typography className={classes.testName}>
                  {isInsuranceSelected && insuranceData?.insuranceId ? insuranceData?.insuranceId : '-'}
                </Typography>
                {/*<Typography className={childClasses.testName}>*/}
                {/*  United Healthcare*/}
                {/*</Typography>*/}
                {/*<Typography variant='subtitle2' className={childClasses.testLab}>*/}
                {/*  Insurance Provider*/}
                {/*</Typography>*/}
              </Box>
              <Box className={classes.testPrice}>
                <ChevronRightIcon color='primary' />
              </Box>
            </Box>
          </Box>
        </Content>
      </>
    )
  }
  return null
}

const AddressInfoBox = (props: { addressType: string, i18n: I18n  }) => {
  const { addressType, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const memberData = useSelector(selectProfileData)
  const address =
    addressType === ADDRESS_TYPES.SHIPPING_ADDRESS
      ? memberData?.shippingAddress
      : memberData?.billingAddress

  const goToAddressInfo = () => {
    if (addressType === ADDRESS_TYPES.SHIPPING_ADDRESS) {
      history.push(paths.shippingAddress(type, id), { addressType })
    } else {
      history.push(paths.billingAddress(type, id), { addressType })
    }
  }

  if (type === testTypes.APPOINTMENT && addressType === ADDRESS_TYPES.SHIPPING_ADDRESS) return null

  return (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>
        {addressType === ADDRESS_TYPES.SHIPPING_ADDRESS ? `${i18n.shipping_address_title}` : `${i18n.checkout_billing_address}`}
      </Typography>
      <Content className={childClasses.margin0}>
        <Box className={classes.optionsWrapper}>
          <Box className={childClasses.optionWrapper} onClick={goToAddressInfo}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {address?.line1 || '-'}
              </Typography>
              <Typography className={classes.testName}>
                {address?.city && address?.state && address?.zip
                  ? `${address?.city}, ${address?.state} ${address?.zip}`
                  : ''}
              </Typography>
            </Box>
            <Box className={classes.testPrice}>
              <ChevronRightIcon color='primary' />
            </Box>
          </Box>
        </Box>
      </Content>
    </>
  )
}

const PaymentBox = (props: { testData: TestKitDetails, i18n: I18n  }) => {
  const { testData, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const creditCards: CreditCard[] = useSelector(selectCreditCards)
  const selectedCreditCard: CreditCard = useSelector(selectSelectedCreditCard)

  const goToPaymentMethods = () => {
    if (creditCards?.length) {
      history.push(paths.paymentMethods(type, id))
    } else {
      history.push(paths.addPaymentCard(type, id))
    }
  }

  const renderPaymentMethod = () => {
    if (selectedCreditCard) {
      return (
        <>
          {selectedCreditCard?.brand || i18n.credit_card}
          {selectedCreditCard?.last4
            ? `...${selectedCreditCard?.last4}`
            : '...'}
        </>
      )
    }

    return '-'
  }

  if (!testData?.price) return null

  return (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>{i18n.checkout_payment_method}</Typography>
      <Content className={childClasses.margin0}>
        <Box onClick={goToPaymentMethods} className={classes.optionsWrapper}>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography className={classes.testName}>
                {renderPaymentMethod()}
              </Typography>
            </Box>
            <Box className={classes.testPrice}>
              <ChevronRightIcon color='primary' />
            </Box>
          </Box>
        </Box>
      </Content>
    </>
  )
}

const SummaryBox = (props: { testData: TestKitDetails, i18n: I18n  }) => {
  const { testData, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  const coupon = useSelector(selectCoupon)

  return (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>{i18n.order_summary_title}</Typography>
      <Content className={childClasses.margin0}>
        <Box className={classes.optionsWrapper}>
          <CouponCodeBox coupon={coupon} i18n={i18n}/>
          <ChargesBox coupon={coupon} testData={testData} i18n={i18n}/>
        </Box>
      </Content>
    </>
  )
}

const CouponCodeBox = (props: {
  coupon: {
    discount: number
    discountType: number
    id: string
    isValid: boolean
    message: string
  },
  i18n: I18n
}) => {
  const { coupon, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  const [couponCode, setCouponCode] = useState('')
  const [invalidCoupon, setInvalidCoupon] = useState(false)
  const dispatch = useDispatch()

  const handleChangeField = (e: any) => {
    setCouponCode(e.target.value)
    setInvalidCoupon(false)
  }

  const handleResetField = async () => {
    setCouponCode('')
    setInvalidCoupon(false)
    if (!invalidCoupon) {
      dispatch(resetCoupon())
    }
  }

  const handleApplyCoupon = async () => {
    if (couponCode && !coupon?.isValid)
      setInvalidCoupon(!(await dispatch(applyCoupon(couponCode))))
  }

  return (
    <Box className={childClasses.optionWrapper}>
      <Box className={classes.testDescription}>
        <Typography className={classes.testName}>{i18n.coupon_code_screen_title}</Typography>
        <TextField
          className={
            coupon?.isValid
              ? childClasses.couponCodeFieldSuccess
              : childClasses.couponCodeField
          }
          placeholder={`${i18n.checkout_placeholder_add_coupon_code}`}
          fullWidth={true}
          value={couponCode}
          error={Boolean(couponCode) && invalidCoupon}
          helperText={
            invalidCoupon
              ? `${i18n.checkout_invalid_coupon}`
              : (coupon?.isValid
              ? `${i18n.applied_successfully}`
              : '')
          }
          disabled={coupon?.isValid}
          onChange={handleChangeField}
          InputProps={{
            endAdornment: (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {couponCode ? (
                  <CloseIcon
                    style={{ height: 18, cursor: 'pointer', color: '#003c72' }}
                    onClick={handleResetField}
                  />
                ) : null}
                <Typography
                  className={classes.testPrice}
                  color='primary'
                  style={{
                    userSelect: 'none',
                    fontSize: 12,
                    cursor: 'pointer',
                  }}
                  onClick={handleApplyCoupon}
                >
                  {i18n.apply}
                </Typography>
              </Box>
            ),
          }}
        />
      </Box>
    </Box>
  )
}

const ChargesBox = (props: {
  coupon: {
    discount: number
    discountType: number
    id: string
    isValid: boolean
    message: string
  },
  i18n: I18n
  testData: TestKitDetails
}) => {
  const { coupon, testData, i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()

  const isInsuranceSelected = useSelector(selectInsuranceIsSelected)
  const insuranceResponsibility = useSelector(selectInsuranceResponsibility)

  const insuranceRespPrice = isInsuranceSelected && insuranceResponsibility?.payerResponsibilty ? insuranceResponsibility?.payerResponsibilty : 0

  const testPrice = testData?.price || 0
  const taxesAndFees = 0
  const discount = coupon?.discount || 0
  const total =
    (testPrice + taxesAndFees - discount) > 0
      ? testPrice + taxesAndFees - discount - insuranceRespPrice
      : 0

  return (
    <Box className={childClasses.optionWrapper}>
      <Box className={classes.testDescription}>
        <Typography className={classes.testName}>{i18n.charges_label}</Typography>
        <PriceOptionBox
          title={testData?.customTestName ||
          testData?.labTest?.shortName || ''}
          price={testPrice}
          i18n={i18n}
        />
        <PriceOptionBox title={`${i18n.checkout_taxes_fees}`} price={taxesAndFees} i18n={i18n} />
        {insuranceRespPrice ? <PriceOptionBox title={i18n.insurance_responsibility} price={-insuranceRespPrice} i18n={i18n} /> : null}
        {coupon ? <PriceOptionBox title={`${i18n.checkout_coupon_coverage}`} price={-discount} i18n={i18n} /> : null}
        <Divider className={childClasses.divider} />
        <PriceOptionBox title={`${i18n.total_label}`} price={total} i18n={i18n}/>
      </Box>
    </Box>
  )
}

const PriceOptionBox = (props: {
  title: string
  price: number
  i18n: I18n
}) => {
  const childClasses = childStyles()
  const { title, price, i18n } = props
  const USD = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

  return (
    <Box className={childClasses.pricebox}>
      <Typography variant='subtitle2' className={childClasses.testLab}>
        {title}
      </Typography>
      <Typography variant='subtitle2' className={childClasses.testLab}>
        {USD.format(price)}
      </Typography>
    </Box>
  )
}

const ShippingAgreement = (props: { onHandlePlaceOrder: any, i18n: I18n  }) => {
  const { onHandlePlaceOrder, i18n } = props
  const history = useHistory()
  const classes = useStyles()
  const childClasses = childStyles()
  const [agreeToTerms, setAgreeToTerms] = useState(false)

  const handleCheck = () => {
    setAgreeToTerms(!agreeToTerms)
  }

  const goNext = async () => {
    onHandlePlaceOrder()
  }

  const redirectToTermsConditions = async () => {
    history.push(paths.termsAndConds(), {prevPath: history.location.pathname})
  }

  return (
    <>
      <Typography className={`${classes.testName} ${childClasses.sectionName}`}>
        Shipping Agreement
      </Typography>
      <Content className={childClasses.margin0}>
        <Box className={classes.optionsWrapper}>
          <Box className={childClasses.optionWrapper}>
            <Box className={classes.testDescription}>
              <Typography variant='subtitle2' className={childClasses.testLab}>
                {shippingAgreement}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={childClasses.consent}>
          <Checkbox
            color='primary'
            checked={agreeToTerms}
            onChange={handleCheck}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography variant='subtitle2' className={childClasses.testLab}>
            {i18n.agree_terms_label}{' '}
            <Link onClick={redirectToTermsConditions}>
              {i18n.terms_ampersand_conditions_link}
            </Link>
          </Typography>
        </Box>
        <Button
          onClick={goNext}
          className={`${classes.button} ${childClasses.confirmButton}`}
          disabled={!agreeToTerms}
        >
          {i18n.confirm_order_label}
        </Button>
      </Content>
    </>
  )
}

const childStyles = makeStyles((theme) => ({
  innerWrapper: {
    backgroundColor: '#EDEDED',
    alignItems: 'flex-start!important',
    margin: '0!important',
    boxSizing: 'border-box',
    padding: '10px!important',
  },
  consent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginLeft: '30px',
  },
  pricebox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  divider: {
    width: '100%',
    borderBottom: '1px solid #54565B',
    backgroundColor: '#ddd',
    marginBottom: '10px',
  },
  sectionName: {
    margin: 10,
  },
  testLab: {
    color: '#7B7C7D',
  },
  margin0: {
    padding: '0px!important',
    margin: '0!important',
  },
  optionWrapper: {
    'padding': '10px 15px',
    'backgroundColor': '#FFF',

    'borderRadius': 10,
    'display': 'flex',
    'justifyContent': 'space-between',
    'border': '1px solid #E6E7EA',
    'margin': '0 auto 20px',

    '&:first-child': {
      cursor: 'pointer',
    },
  },
  confirmButton: {
    maxWidth: 400,
    height: 48,
    marginTop: 40,
    marginBottom: 48,
  },
  couponCodeField: {
    '&.MuiFormControl-root': {
      '& .MuiInput-underline': {
        '&.Mui-error': {
          '&::after': {
            borderBottomColor: theme.palette.warning.main,
          },
        },
      },
      '& .MuiFormHelperText-root': {
        'cursor': 'text',
        '&.Mui-error': {
          color: theme.palette.warning.main,
        },
      },
    },
  },
  couponCodeFieldSuccess: {
    '&.MuiFormControl-root': {
      '& .MuiInput-underline': {
        'color': theme.palette.success.main,
        '&::before': {
          borderBottomColor: theme.palette.success.main,
        },
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.success.main,
      },
    },
  },
}))

const shippingAgreement =
  'Please read the following terms before placing this order. By using the SAFE App, you agree to be bound by the terms of Service, the Privacy Policy, and Notice of Privacy Practices. If you choose to import any of your diagnostic test records, or if you choose to get tested through the SAFE App, or both, you further agree to be bound by the additional terms that will be presented when you choose those services, including the informed consent.'
