import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { hexToRGBA } from '../../../libs/helpers'
import { Typography } from '../../../ui'
import { ArticleResource, ResourceType, VideoResource } from '../types'

export const ListVideoResource = (props: {
  r: VideoResource
  onResourceClick: (type: ResourceType, id: string) => void
  grid: boolean
}) => {
  const { r, grid, onResourceClick } = props
  const classes = useStyles()

  const Resource = () => (
    <Box
      className={classes.listResourceFrame}
      onClick={() => onResourceClick('video', r._id)}
    >
      <div
        style={{
          backgroundImage: `url(${r.largeImageURL || r.thumbnailURL})`,
        }}
        className={classes.listResourceImage}
      >
        <PlayArrowIcon className={classes.iconFilled} />
      </div>
      <Box className={classes.listResourceBody}>
        <Typography className={classes.listResourceTitle}>{r.title}</Typography>
      </Box>
    </Box>
  )

  return grid ? (
    <Grid item md={4} sm={6} xs={12} key={r._id}>
      <Resource />
    </Grid>
  ) : (
    <Box className={classes.noGridWrapper}>
      <Resource />
    </Box>
  )
}

export const ListArticleResource = (props: {
  r: ArticleResource
  onResourceClick: (type: ResourceType, id: string) => void
  grid: boolean
}) => {
  const { r, grid, onResourceClick } = props
  const classes = useStyles()

  const Resource = () => (
    <Box
      className={classes.listResourceFrame}
      onClick={() => onResourceClick('article', r._id)}
    >
      <div
        style={{ backgroundImage: `url(${r.imageURL})` }}
        className={classes.listResourceImage}
      />
      <Box className={classes.listResourceBody}>
        <Typography className={classes.listResourceTitle}>{r.title}</Typography>
      </Box>
    </Box>
  )
  return grid ? (
    <Grid item md={4} sm={6} xs={12} key={r._id}>
      <Resource />
    </Grid>
  ) : (
    <Box className={classes.noGridWrapper}>
      <Resource />
    </Box>
  )
}

const WEBKIT_BOX = '-webkit-box'
const shadow = '0px 8px 23px 0px rgba(0, 0, 0, 0.08);'

const useStyles = makeStyles((theme) => ({
  noGridWrapper: {
    width: 392,
    minWidth: 392,
    [theme.breakpoints.down(600)]: {
      width: 250,
      minWidth: 250,
    },
  },
  listResourceFrame: {
    'height': '275px',
    'backgroundColor': '#FFF',
    'borderRadius': '16px',
    'padding': '16px',
    'transition': 'all .1s ease-in-out',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
      transition: 'all .1s ease-in-out',
    },
    [theme.breakpoints.down(600)]: {
      height: '180px',
      borderRadius: '8px',
      marginLeft: '-2px',
    },
  },
  listResourceImage: {
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '185px',
    [theme.breakpoints.down('xs')]: {
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      height: '135px',
    },
  },
  listResourceBody: {
    padding: '16px',
  },
  listResourceTitle: {
    'fontSize': '16px',
    'fontWeight': 500,
    'lineHeight': '21px',
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  listResourceDesc: {
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'fontSize': '12px',
    'lineHeight': '18px',
    'marginTop': '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '15px',
    },
  },
  iconFilled: {
    borderRadius: '100%',
    fontSize: '32px',
    padding: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    cursor: 'pointer',
  },
}))
