import { Box, Link as MUILink, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import alert from '../../assets/img/test_kit_updated.png'
import { SubBackHeader } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Button } from '../../ui'
import { Content, Controls,Wrapper } from '../../ui/templates/app-layout'
import { SupportDialog } from '../contact-support/supportDialog'
import { paths } from '../paths'
import { selectError } from '../scan-qr/model'

export const InvalidQRPage = () => {
  const childClasses = childStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)
  const error = useSelector(selectError) as keyof I18n

  const goBack = () => {
    history.push(paths.app.scanQrCode())
  }

  const errorText = i18n.scan_code_instruction_text.split('\\n')

  return (
    <Wrapper>
      <SubBackHeader title={''} backPath={'scan-qr'} />
      <Content style={{ width: '90%' }}>
        <img src={alert} alt="test qr codes" className={childClasses.img} />
        <Box className={childClasses.title}>{i18n.testkit_taken_title}</Box>
        <Box className={childClasses.assetBox}>
          {errorText.map((text) => (
            <Typography align="center" className={childClasses.errorText}>
              {text}
            </Typography>
          ))}
        </Box>
      </Content>
      <Controls>
        <MUILink
          role="button"
          color="primary"
          className={childClasses.pageLink}
          onClick={() => setSupportDialogOpen(true)}
        >
          {i18n.contact_support}
        </MUILink>
        <Button
          onClick={() => history.push(paths.app.scanQrCode())}
          className={childClasses.confirmButton}
        >
          {i18n.rescan_button}
        </Button>
        <Button
          onClick={() => history.push(paths.app.dashboard())}
          className={childClasses.confirmButton}
          variant="outlined"
        >
          {i18n.home_button}
        </Button>
      </Controls>
      <SupportDialog
        open={supportDialogOpen}
        setSupportDialogOpen={setSupportDialogOpen}
      />
    </Wrapper>
  )
}

const childStyles = makeStyles({
  errorText: {
    padding: '4px 0px',
  },
  confirmButton: {
    marginTop: 15,
    marginBottom: 10,
  },
  title: {
    fontSize: 24,
    fontFamily: 'Avenir Next Rounded Medium',
    color: '#505358',
    marginTop: '24px',
  },
  assetBox: {
    color: '#7B7C7D',
    fontSize: 16,
    fontFamily: 'Avenir Next Rounded Medium',
    margin: 20,
    textAlign: 'center',
  },
  img: {
    maxWidth: '100%',
  },
  pageLink: {
    marginLeft: 4,
    cursor: 'pointer',
  },
})
