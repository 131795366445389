import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import appointmentImage from '../../assets/img/appointment.png'
import { paths } from '../paths'

export const SchedulePage = () => {
  const classes = useStyles()
  const history = useHistory()

  const goBack = () => {
    history.push(paths.testDetails())
  }

  const goNext = () => {
    history.push(paths.scheduleAppointment())
  }

  return (
    <Box className={classes.schedulePageHolder}>
      <Box className={classes.scheduleWrapper}>
        <Box className={classes.headerWrapper}>
          <Box onClick={goBack} className={classes.backIconWrapper}>
            <CallReceivedIcon className={classes.backIcon} />
          </Box>
        </Box>
        <Box className={classes.imageWrapper}>
          <img
            src={appointmentImage}
            className={classes.appointmentImage}
            alt="appointment"
          />
        </Box>
        <Box className={classes.title}>
          Would you like to make an appointment or walk in at your convenience?
        </Box>
        <Box className={classes.appointmentOptionsWrapper} onClick={goNext}>
          <Box className={classes.optionLeftText}>
            <Box className={classes.boxTitle}>Make Appointment</Box>
            <Box className={classes.boxSubtitle}>Reserve your timeslot</Box>
          </Box>
          <ChevronRightIcon className={classes.nextIcon} />
        </Box>
        <Box className={classes.appointmentOptionsWrapper}>
          <Box className={classes.optionLeftText}>
            <Box className={classes.boxTitle}>Walk-in</Box>
            <Box className={classes.boxSubtitle}>Come whenever you please</Box>
          </Box>
          <ChevronRightIcon className={classes.nextIcon} />
        </Box>
        <Box className={classes.whyLink}>
          <Box className={classes.whyText}>Why does it matter?</Box>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  schedulePageHolder: {
    backgroundColor: '#F7F7F7',
    width: '100%',
  },
  scheduleWrapper: {
    margin: '0 auto',
    display: 'flex',
    padding: '0 15px',
    maxWidth: 540,
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  appointmentImage: {
    display: 'block',
    width: '100%',
  },
  imageWrapper: {},
  title: {
    width: 327,
    margin: '0 auto',
    fontSize: 24,
    color: '#505358',
    marginBottom: 48,
    marginTop: 10,
    [theme.breakpoints.down(400)]: {
      width: 'auto',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
    width: '100%',
    height: 27,
  },
  backIconWrapper: {
    transform: 'rotate(45deg)',
    position: 'absolute',
    left: 15,
    cursor: 'pointer',
  },
  backIcon: {
    color: '#003C71',
    cursor: 'pointer',
  },
  appointmentOptionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: '14px 14px 14px 20px',
    backgroundColor: '#fff',
    width: '100%',
    cursor: 'pointer',
    marginBottom: 20,
    boxSizing: 'border-box',
  },
  boxTitle: {
    color: '#54565B',
    fontSize: 16,
    fontWeight: 900,
  },
  boxSubtitle: {
    letterSpacing: 0.5,
    fontSize: 14,
    color: '#7B7C7D',
  },
  optionLeftText: {
    textAlign: 'left',
  },
  nextIcon: {
    fontSize: 30,
    color: '#003C71',
  },
  whyLink: {
    width: '100%',
    textAlign: 'center',
    marginTop: 13,
    marginBottom: 32,
  },
  whyText: {
    fontWeight: 900,
    fontSize: 14,
    color: '#54565B',
    cursor: 'pointer',
  },
}))
