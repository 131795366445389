import styled from '@emotion/styled'
import { Box, Dialog, Link as MUILink, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PriorityHighRoundedIcon from '@material-ui/icons//PriorityHighRounded'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as AlertRed } from '../../../assets/icons/alert-red.svg'
import { ReactComponent as AlertYellow } from '../../../assets/icons/alert-yellow.svg'
import { ReactComponent as CheckGreen } from '../../../assets/icons/check-green.svg'
import { ReactComponent as PinCircle } from '../../../assets/icons/pin-circle.svg'
import healthCheckLogo from '../../../assets/logo-row.png'
import { PDFViewer } from '../../../features/details-helper/pdf-viewer/pdf-viewer'
import { TestImageViewer } from '../../../features/details-helper/test-image-viewer/test-image-viewer'
import { I18n } from '../../../features/translation/types'
import { formatDateAndTime } from '../../../libs/helpers'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { capitalizeString } from '../../../libs/utils'
import { Button } from '../../../ui'
import { TestDetailFields } from './testDetailsMapper'

export const Divider = styled.div`
  margin: 8px 0;
  width: 100%;
  border-bottom: solid 1px #ccccce;
`

export const TextField = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.testName}>
        {i18n[field.translationName]}
      </Typography>
      <Typography variant="subtitle2" className={classes.info}>
        {field.valueIsKey ? i18n[test[field.id]] : test[field.id]}
      </Typography>
      {field.divider && <Divider />}
    </>
  )
}

export const DateField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.testName}>
        {i18n[field.translationName]}
      </Typography>
      <Typography variant="subtitle2" className={classes.info}>
        {formatDateAndTime(test[field.id])}
      </Typography>
      {field.divider && <Divider />}
    </>
  )
}

export const AddressField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  if (
    !test[field.id].homeAddressAddressOne ||
    !test[field.id].homeAddressAddressCity
  )
    return null

  let url = `http://maps.google.com/?q=${test[field.id].homeAddressAddressOne}`
  if (test[field.id].homeAddressAddressTwo) {
    url += `%20${test[field.id].homeAddressAddressTwo}`
  }
  url += `%20${test[field.id].homeAddressAddressCity}`
  if (test[field.id].homeAddressState) {
    url += `%2C${test[field.id].homeAddressState}`
  }
  if (test[field.id].homeAddressZip) {
    url += `%20${test[field.id].homeAddressZip}`
  }

  const openMapLocation = () => {
    window.open(url, '_blank')
  }

  return (
    <>
      <Typography className={classes.testName}>
        {i18n[field.translationName]}
      </Typography>
      <MUILink
        role="button"
        color="primary"
        className={classes.addressContainer}
        onClick={() => openMapLocation()}
      >
        <Box className={classes.addressContainer}>
          <Typography variant="subtitle2" className={classes.info}>
            {test[field.id].homeAddressAddressOne} <br />
            {test[field.id].homeAddressAddressTwo} <br />
            {test[field.id].homeAddressAddressCity},{' '}
            {test[field.id].homeAddressState} {test[field.id].homeAddressZip}
          </Typography>
          <PinCircle />
        </Box>
      </MUILink>
      {field.divider && <Divider />}
    </>
  )
}

export const LinkField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <MUILink
        role="button"
        color="primary"
        component={Link}
        to={
          field.linkIsDetailView
            ? `${field.linkPath}/${test._id}`
            : field.linkPath
        }
        className={classes.rescheduleLink}
      >
        <Box className={classes.rescheduleContainer}>
          {field.startIcon?.()}
          <Typography className={classes.testName}>
            {i18n[field.translationName]}
          </Typography>
        </Box>
        {field.endIcon?.()}
      </MUILink>
      {field.divider && <Divider />}
    </div>
  )
}

export const PDFField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()
  if (!test.downloadLabReport) return null

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        className={classes.rescheduleLink}
        onClick={() => setOpenDialog(true)}
      >
        <Box className={classes.rescheduleContainer}>
          {field.startIcon?.()}
          <Typography className={classes.testName}>
            {i18n[field.translationName]}
          </Typography>
        </Box>
        {field.endIcon?.()}
        {field.divider && <Divider />}
      </div>
      <Dialog open={openDialog} fullScreen>
        <PDFViewer onClose={handleClose} id={test._id} />
      </Dialog>
    </div>
  )
}

export const TestImageField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
  isMobile: boolean
}) => {
  const { field, i18n, test, isMobile } = props
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <div className={classes.multiPathResultWrapper}>
      <div
        className={classes.contentWrapper}
        onClick={() => setOpenDialog(true)}
      >
        <div className={classes.outcomeWrapper}>
          <Typography className={classes.multiPathResult}>
            {i18n[field.translationName]}
          </Typography>
          {!isMobile && (
            <div
              style={{
                height: 32,
                borderRight: 'solid 1px #E0E0E0',
              }}
            />
          )}
          <Typography className={classes.multiPathTitle}>
            {i18n.image_result}
          </Typography>
        </div>

        {field.divider && <Divider />}
        <ChevronRightSharpIcon
          style={{ marginRight: '-6px', color: '#757575' }}
        />
      </div>
      <Dialog open={openDialog} maxWidth={'xl'}>
        <TestImageViewer onClose={handleClose} id={test._id} />
      </Dialog>
    </div>
  )
}

export const ButtonField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  if (!test[field.enable]) return <></>

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        component={Link}
        to={
          field.linkIsDetailView
            ? `${field.linkPath}/${test._id}`
            : field.linkPath
        }
        className={classes.rescheduleLink}
      >
        {field.plainTextName || i18n[field.translationName]}
      </Button>
    </>
  )
}

export const CredentialHeaderField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field } = props
  const classes = useStyles()

  return (
    <Box className={classes.credentialBox}>
      <Typography className={classes.credential}>
        TEST <br /> CREDENTIAL
      </Typography>
      <img
        src={healthCheckLogo}
        alt="healthcheck_logo"
        className={classes.credentialImage}
      />
      {field.divider && <Divider />}
    </Box>
  )
}

export const Result = (props: { result: string }) => {
  const classes = useStyles()
  const { result } = props
  switch (result) {
    case 'POSITIVE': {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#FF0000' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#FF0000' }}
          >
            {capitalizeString(result)}
          </Typography>
          <AlertRed />
        </Box>
      )
    }
    case 'NEGATIVE': {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#009104' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#009104' }}
          >
            {capitalizeString(result)}
          </Typography>
          <CheckGreen />
        </Box>
      )
    }
    case 'INVALID':
    case 'UNKNOWN':
    case 'ERROR':
    case 'INCONCLUSIVE': {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#FA9C13' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#FA9C13' }}
          >
            {capitalizeString(result)}
          </Typography>
          <AlertYellow />
        </Box>
      )
    }
    default: {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#FA9C13' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#FA9C13' }}
          >
            No result found
          </Typography>
          <AlertYellow />
        </Box>
      )
    }
  }
}

export const ResultIcon = (props: { result: string }) => {
  const classes = useStyles()
  const { result } = props
  switch (result) {
    case 'POSITIVE': {
      return (
        <AddRoundedIcon
          className={classes.iconFilled}
          style={{ backgroundColor: '#F20000' }}
        />
      )
    }
    case 'NEGATIVE': {
      return (
        <RemoveRoundedIcon
          className={classes.iconFilled}
          style={{ backgroundColor: '#00C04D' }}
        />
      )
    }
    case 'INVALID':
    case 'UNKNOWN':
    case 'ERROR':
    case 'INCONCLUSIVE': {
      return (
        <PriorityHighRoundedIcon
          className={classes.iconFilled}
          style={{ backgroundColor: '#E59701' }}
        />
      )
    }
    default: {
      return null
    }
  }
}

export const TitleAndDescField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <div className={classes.titleAndDescWrapper}>
      <Typography className={classes.multiPathResult}>
        {test[field.id].title}
      </Typography>
      <Typography className={classes.multiPathTitle}>
        <HTMLTextComponent
          HTMLString={test[field.id].description}
          className={classes.summaryInfo}
        />
      </Typography>
      {field.divider && <Divider />}
    </div>
  )
}

export const ResultField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const testResult = test?.patientTestResult || test?.metadata?.outcome

  return (
    <>
      <Typography className={classes.testName}>
        {capitalizeString(i18n[field.translationName])}
      </Typography>
      {<Result result={testResult} />}
      {field.divider && <Divider />}
    </>
  )
}

export const SummaryField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  if (!test[field.id]) return <></>

  const getResult = () => {
    if (test.patientTestResult?.toUpperCase() === 'NEGATIVE') {
      return (
        <Typography className={classes.resultText} style={{ color: '#00C04D' }}>
          {test.resultDisplay}
        </Typography>
      )
    }
    if (test.patientTestResult?.toUpperCase() === 'POSITIVE') {
      return (
        <Typography className={classes.resultText} style={{ color: '#F20000' }}>
          {test.resultDisplay}
        </Typography>
      )
    }
  }
  return (
    <Box className={classes.summaryBox}>
      {(field.plainTextName || i18n[field.translationName]) && (
        <Typography className={classes.credential}>
          {capitalizeString(
            field.plainTextName || i18n[field.translationName]
          ) || ''}{' '}
        </Typography>
      )}
      {getResult()}
      {test[field.id] && (
        <HTMLTextComponent
          HTMLString={test[field.id]}
          className={classes.summaryInfo}
        />
      )}
      {field.divider && <Divider />}
    </Box>
  )
}

export const TestField = (props: { test: any }) => {
  const { test } = props
  const classes = useStyles()
  return (
    <>
      <Box className={classes.vendorBox}>
        <Box>
          <Typography variant="subtitle2" className={classes.summaryHeader}>
            {test?.testName}
          </Typography>
          <Typography variant="subtitle2" className={classes.vendor}>
            {test?.labVendor}
          </Typography>
        </Box>
        <Box>
          <img src={test.testImage} style={{ height: '33px' }} />
        </Box>
      </Box>
    </>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((theme) => ({
  summaryBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  summaryHeader: {
    color: '#505358',
    fontWeight: 600,
    fontSize: 14,
  },
  summaryInfo: {
    color: '#757575',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '150%',
  },
  vendorBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vendor: {
    color: '#757575',
  },
  credentialBox: {
    minHeight: '74px',
    width: '100%',
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
  },
  credential: {
    fontWeight: 600,
    fontSize: 16,
    color: '#54565B',
  },
  resultText: {
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  titleAndDescWrapper: {
    [theme.breakpoints.up(600)]: {
      padding: '32px',
    },
  },
  alert: {
    height: 14,
    width: 14,
  },
  resultBox: {
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
    borderRadius: '12px',
    border: 'solid 1px',
    padding: '2px 10px',
    width: '95%',
  },
  testName: {
    color: '#54565B',
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
  },
  rescheduleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  rescheduleLink: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px auto',
    padding: '0px 16px',
    [theme.breakpoints.up(600)]: {
      border: '1px solid #E6E7EA',
      padding: '32px',
      borderRadius: 12,
      margin: '8px auto',
    },
  },
  addressContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: SPACE_BETWEEN,
  },
  info: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    color: '#7B7C7D',
  },
  credentialImage: {
    maxHeight: '48px',
    [theme.breakpoints.down(500)]: {
      maxHeight: '32px',
    },
  },
  iconFilled: {
    borderRadius: '100%',
    fontSize: '42px',
    padding: 4,
    fontWeight: 600,
    color: 'white',
  },
  multiPathTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#757575',
  },
  multiPathResultWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 16,
    [theme.breakpoints.down(600)]: {
      padding: '8px 0px',
    },
  },
  multiPathResult: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#505358',
    [theme.breakpoints.up(600)]: {
      minWidth: '177px',
    },
  },
  outcomeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
    },
  },
  contentWrapper: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      padding: '0px',
    },
  },
}))
