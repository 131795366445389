import { Box, Link as MUILink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { VerificationCode } from '../../../features/code-verification/code-verification'
import { selectLanguageContent } from '../../../features/translation'
import {
  resetError,
  selectChallengeType,
  selectLoginMFASettings,
  selectLoginSessionId,
  selectUserError,
  submitMFAChallenge,
} from '../../../features/user/model/userTenantSlice'
import { ChallengeType } from '../../../features/user/types/mfa'
import { Checkbox, ContentLayout, Typography } from '../../../ui'
import { Wrapper } from '../../../ui/templates/app-layout'
import { paths } from '../../paths'

export const MFAChallengePage = () => {
  const username = localStorage.getItem('email') || ''
  const cookieName = `${btoa(username)}_safe_device`
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const mfaSettings = useSelector(selectLoginMFASettings)
  const i18n = useSelector(selectLanguageContent)
  const error = useSelector(selectUserError)
  const sessionId = useSelector(selectLoginSessionId)
  const challengeType = useSelector(selectChallengeType)
  const [submitReady, setSubmitReady] = useState(false)
  const [rememberDevice, setRememberDevice] = useState(false)
  const [mfaCode, setMfaCode] = useState('')
  const submitting = false
  const [cookies, setCookie] = useCookies([cookieName])
  let timeout: NodeJS.Timeout

  useEffect(() => {
    if (!sessionId || !challengeType) {
      returnToLogin()
    }
  }, [])

  const returnToLogin = () => {
    dispatch(resetError())
    history.push(paths.login())
  }

  useEffect(() => {
    if (error.includes('expired')) {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        returnToLogin()
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    if (submitReady) {
      submitVerifyCode()
    }
  }, [mfaCode, submitReady])

  const setRememberDeviceCookieCallback = (authResponse: any) => {
    if (!authResponse || !mfaSettings) return
    const expires = new Date(Date.now() + 86400 * 1000 * mfaSettings.memberDays)
    const data = btoa(JSON.stringify(authResponse))
    setCookie(cookieName, data, { path: '/', expires })
    history.push(paths.app.dashboard())
  }

  const submitVerifyCode = () => {
    const data = {
      mfaCode,
      sessionId,
      username,
    }
    dispatch(
      submitMFAChallenge(
        data,
        challengeType,
        i18n,
        rememberDevice ? setRememberDeviceCookieCallback : undefined
      )
    )
  }

  return (
    <Wrapper>
      <Box className={classes.pageWrapper}>
        <form className={classes.form}>
          <ContentLayout className={classes.form}>
            <Typography className={classes.title} align="center">
              {i18n.enter_code_header}
            </Typography>
            <Typography className={classes.body} align="center">
              {challengeType === ChallengeType.SMS_MFA
                ? i18n.enter_code_instruction
                : i18n.enter_totp_instruction}
            </Typography>
            <VerificationCode
              setVerificationCode={setMfaCode}
              setSubmitReady={setSubmitReady}
              verifyError={error}
              clearError={() => dispatch(resetError())}
              submitting={submitting}
              type="Integer"
              simpleErrorView={true}
            />
            {mfaSettings?.enableDeviceRemembering &&
              mfaSettings.memberDays > 0 && (
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    label={i18n.remember_this_device}
                    checked={rememberDevice}
                    onChange={() => setRememberDevice(!rememberDevice)}
                    name="rememberEmail"
                  />
                </div>
              )}
            <div className={classes.loginWrapper}>
              <Typography style={{ paddingTop: 2 }}>
                {i18n.did_not_recieve_code}
              </Typography>
              <MUILink
                role="button"
                color="primary"
                className={classes.pageLink}
                onClick={() => history.push(paths.login())}
              >
                {i18n.retry_login}
              </MUILink>
            </div>
          </ContentLayout>
        </form>
      </Box>
    </Wrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFilledInput-root': {
      background: '#FFF',
    },
    '& .MuiFilledInput-underline:before': { borderBottom: 'none' },
    '& .MuiFilledInput-underline:after': { borderBottom: 'none' },
  },
  pageWrapper: {
    width: '90vw',
    maxWidth: '500px',
  },
  title: {
    fontWeight: 500,
    fontSize: '36px',
    marginBottom: '16px',
  },
  checkboxWrapper: {
    marginLeft: '18px',
  },
  body: {
    color: '#505358',
    fontSize: '14px',
    fontFamily: 'Avenir Next, Avenir Next Rounded medium',
    fontWeight: 400,
    lineHeight: '22px',
    margin: ' 0px 40px',
    [theme.breakpoints.down(600)]: {
      margin: ' 0px 20px',
    },
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
    paddingBottom: '32px',
  },
  fieldContainer: {
    padding: '1px',
    width: '100%',
    border: '1px solid #79747E',
    borderRadius: '4px',
  },
  form: {
    'marginTop': 'auto',
    'marginBottom': 'auto',
    'width': '100%',
    'boxSizing': 'border-box',
    'padding': '0px',
    'display': 'flex',
    'flexDirection': 'column',
    'flexGrow': 1,
    '@media (max-width: 500px)': {},
  },
  checkbox: {
    color: '#003C71',
    padding: 0,
  },
  actionBtn: {
    '&:hover': {
      borderColor: '#003C72',
      color: '#003C72',
    },
    'fontSize': '16px',
    'textTransform': 'none',
    'letterSpacing': '2.25px',
    'maxWidth': 'unset',
    'width': '100%',
    'justifySelf': 'center',
    'maxHeight': 48,
    'paddingTop': 10,
    'paddingBottom': 10,
    'marginTop': 10,
    'marginBottom': 0,
    '@media (max-width: 300px)': {
      minWidth: 'unset',
    },
  },
  pageLink: {
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
  },
  loginWrapper: {
    marginTop: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
}))
