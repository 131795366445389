import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useDispatch, useSelector } from 'react-redux'
import { paths } from '../paths'
import { Button } from '../../ui'
import { setInsuranceIsSelected } from '../checkout/model'
import {
  useStyles,
  Controls,
  Wrapper,
  ContentInner,
  Content,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'

export const InsuranceProviderAdded = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const { id, type } = useParams<{ id: string; type: string }>()

  const dispatch = useDispatch()

  const handleOk = () => {
    dispatch(setInsuranceIsSelected())
    history.push(paths.checkout(type, id))
  }

  return (
    <Wrapper>
      <Box className={classes.contentStyles}>
        <Content style={{ justifyContent: 'center', margin: 0 }}>
          <ContentInner>
            <CheckCircleOutlineIcon style={{ fontSize: 124, color: 'white' }} />
            <Box className={classes.verifyingProcessText}>
              {i18n.insurance_provider_added_message}
            </Box>
          </ContentInner>
        </Content>
        <Controls style={{ marginBottom: 16 }}>
          <Button className={childClasses.btn} onClick={handleOk}>
            {i18n.ok}
          </Button>
        </Controls>
      </Box>
    </Wrapper>
  )
}

const primaryColor = '#003C72'
const childStyles = makeStyles({
  btn: {
    'margin': '8px',
    'maxHeight': 48,
    'width': '80%',
    'backgroundColor': 'white',
    'color': primaryColor,
    '&:hover': {
      borderColor: primaryColor,
      color: 'white',
      backgroundColor: primaryColor,
    },
  },
})
