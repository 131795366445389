import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilledTextField } from '../../../ui'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  setCanContiune,
  updateAnswer,
} from '../questionnaireV2Slice'

export const NumericQuestion = () => {
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const existingAnswer = useSelector(selectExistingAnswer)
  const [answer, setAnswer] = useState(0)
  const [error, seterror] = useState('')
  const [valueLabel, setValueLabel] = useState(question.label || '')

  useEffect(() => {
    if (
      question.label &&
      answer.toString() === '1' &&
      question.label.endsWith('s')
    ) {
      const singular = question.label.slice(
        0,
        Math.max(0, question.label.length - 1)
      )
      setValueLabel(singular || '')
    } else setValueLabel(question.label || '')
  }, [answer, question])

  useEffect(() => {
    if (existingAnswer) {
      dispatch(updateAnswer(existingAnswer.value || 0))
      setAnswer(Number(existingAnswer.value || 0))
    }
  }, [question, existingAnswer])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (Number.isNaN(Number(value))) return
    if (question.max) {
      if (Number(value) > question.max) {
        dispatch(setCanContiune(false))
        seterror(`Answer should not exceed ${question.max}.`)
        return
      }
      if (Number(value) <= question.max) {
        seterror('')
      }
    }
    if (question.min || question.min === 0) {
      if (!value) {
        dispatch(setCanContiune(false))
        dispatch(updateAnswer(value))
        setAnswer(Number(value))
      }
      if (Number(value) < question.min) {
        dispatch(setCanContiune(false))
        seterror(`Answer cannot be less than ${question.min}.`)
        return
      }
      if (Number(value) <= question.min) {
        seterror('')
      }
    }
    dispatch(updateAnswer(value))
    setAnswer(Number(value))
  }

  const handleIncrease = () => {
    seterror('')
    if (question.max && answer < question.max) {
      dispatch(updateAnswer(answer + 1))
      setAnswer(answer + 1)
    } else if (question.max && answer >= question.max) {
      seterror(`Answer should not exceed ${question.max}.`)
    }
  }

  const handleDecrease = () => {
    seterror('')
    if ((question.min || question.min === 0) && answer > question.min) {
      dispatch(updateAnswer(answer - 1))
      setAnswer(answer - 1)
    } else if (question.min || (question.min === 0 && answer <= question.min)) {
      seterror(`Answer cannot be less than ${question.min}.`)
    }
  }

  return (
    <>
      <FilledTextField
        value={answer}
        valueLabel={valueLabel}
        onChange={(e) => handleChange(e)}
        max={question.maxFeet ? question.maxFeet : undefined}
        min={0}
        error={Boolean(error)}
        helperText={error}
        isNumberField={true}
        showNumericIncrement={true}
        increase={handleIncrease}
        decrease={handleDecrease}
      />
    </>
  )
}
