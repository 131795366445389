import { Box, IconButton, Link as MUILink } from '@material-ui/core'
import { FormikProps, FormikValues, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../features/cookies/cookie-helper'
import { hideNotification } from '../../features/notifications/model'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import {
  authorizedDeviceLogin,
  loginUser,
  resetError,
  selectUserLoading,
} from '../../features/user/model/userTenantSlice'
import validationSchema from '../../features/user/model/validationSchema'
import { useAuth0 } from '../../libs/useAuth0'
import {
  Button,
  FilledTextField,
  PasswordVisibility,
  Typography,
} from '../../ui'
import { onboardingStyles } from '../_styles/registrationStyles'
import { NetworkSettings } from '../network/type'
import { paths } from '../paths'
import {
  getDefaultSignupTenant,
  resetRegisterForm,
  setActive,
  setSignupType,
} from '../register/model/registerSlice'
import { OnboardingType } from '../register/model/type'
import { FormValues } from './type'

export const LoginPage = () => {
  const classes = onboardingStyles()
  const history = useHistory()
  const loading = useSelector(selectUserLoading)
  const i18n = useSelector(selectLanguageContent)
  const savedEmail = localStorage.getItem('email') || ''
  const rememberEmail = localStorage.getItem('rememberEmail') || ''
  const dispatch = useDispatch()

  useAuth0({ closedForAnonymous: false, closedForLoggedIn: true })

  useEffect(() => {
    if (rememberEmail && savedEmail) formik.setFieldValue('email', savedEmail)
    return () => {
      dispatch(hideNotification())
    }
  }, [])

  const onSubmit = async ({ email, password, rememberEmail }: FormikValues) => {
    dispatch(resetError())
    const userHasCookie = userHasDeviceCookie(email)
    if (userHasCookie) {
      const deviceParams = getSavedDeviceValues(email)
      const loginParams = {
        ...deviceParams,
        password,
        username: email,
      }
      dispatch(authorizedDeviceLogin(loginParams, deviceParams.type))
    } else {
      if (!(await dispatch(loginUser({ email, password })))) return

      const prevPath = localStorage.getItem('path')
      if (rememberEmail) {
        localStorage.setItem('rememberEmail', rememberEmail)
      } else {
        localStorage.removeItem('rememberEmail')
      }
      if (prevPath) {
        localStorage.removeItem('path')
        history.push(prevPath)
      } else history.push(paths.app.dashboard())
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
      rememberEmail: Boolean(localStorage.getItem('rememberEmail')),
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleSignUp = () => {
    const callback = (res: NetworkSettings) => {
      if (res.enableAccessCodes) {
        dispatch(setSignupType(OnboardingType.SELF))
        history.push(paths.accessCode())
      } else {
        dispatch(setActive(1))
        dispatch(setSignupType(OnboardingType.SELF))
        history.push(paths.registerTab('sign-up'))
      }
    }
    dispatch(resetRegisterForm())
    dispatch(getDefaultSignupTenant(callback))
  }

  return (
    <div className={classes.stepContent}>
      <form className={classes.content} onSubmit={formik.handleSubmit}>
        <Typography className={classes.title} color="primary" variant="h1">
          {i18n.sign_in}
        </Typography>
        <Email formik={formik} i18n={i18n} />
        <Password formik={formik} i18n={i18n} />
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.actionBtn}
            disabled={!formik.isValid || loading}
            isLoading={formik.isSubmitting}
            type="submit"
          >
            {i18n.sign_in_button}
          </Button>
        </div>
        <Box marginTop="5px">
          <Box className={classes.actionText}>
            <MUILink
              role="button"
              className={classes.pageLink}
              onClick={handleSignUp}
              aria-label={i18n.sign_up_button}
            >
              {i18n.sign_up}
            </MUILink>
          </Box>
          <Box className={classes.actionText}>
            <MUILink
              role="button"
              className={classes.pageLink}
              onClick={() => history.push(paths.forgotPassword())}
              aria-label="forgot_password"
            >
              {i18n.forgot_password_screen_title}
            </MUILink>
          </Box>
        </Box>
      </form>
    </div>
  )
}

const Email = ({
  formik,
  i18n,
}: {
  formik: FormikProps<FormValues>
  i18n: I18n
}) => {
  return (
    <FilledTextField
      label={i18n.email}
      value={formik.values.email}
      autoComplete="off"
      type="text"
      name="email"
      variant="filled"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={(formik.touched.email && formik.errors.email) || ''}
      required
    />
  )
}

const Password = ({
  formik,
  i18n,
}: {
  formik: FormikProps<FormValues>
  i18n: I18n
}) => {
  const classes = onboardingStyles()
  const [isShowPassword, setIsShowPassword] = useState(false)

  return (
    <FilledTextField
      label={i18n.login_password}
      required
      autoComplete="password"
      name="password"
      variant="filled"
      type={isShowPassword ? 'text' : 'password'}
      value={formik.values.password}
      error={formik.touched.password && Boolean(formik.errors.password)}
      helperText={(formik.touched.password && formik.errors.password) || ''}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      endAdornment={
        <IconButton
          className={classes.iconBtn}
          aria-label="toggle password visibility"
          onClick={() => setIsShowPassword((prev) => !prev)}
        >
          <PasswordVisibility showPassword={isShowPassword} />
        </IconButton>
      }
    />
  )
}
