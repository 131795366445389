import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearTestKitFlow } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { SHADOW } from '../../pages/_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../pages/network/model'
import { TelehealthOptions } from '../../pages/network/type'
import { paths } from '../../pages/paths'
import { resetQuestionnaireV2Store } from '../../pages/questionnaireV2/questionnaireV2Slice'
import {
  getLabTestDetails,
  getTestPanelDetails,
  selectTestLoading,
} from '../../pages/register-a-test-module/model'
import {
  cancelTelehealthSession,
  resetTelehealthStore,
  selectTelehealthStatus,
} from '../../pages/telehealth/model'
import {
  getEncounterRecord,
  selectEncounter,
  selectEncounterDisposition,
} from '../encounter/model'
import { useFlowControl } from '../flowcontrol/use-flow-control'
import {
  completeHealthService,
  selectFVFConsult,
} from '../health-service-lite/model'
import {
  ConsultStatus,
  EncounterDisposition,
  HealthService,
  HealthServiceDisposition,
  HealthServiceType,
} from '../health-service-lite/types'
import { clearDeviceInterests } from '../pusher-notifications/beamsMessaging'
import { selectLanguageContent } from '../translation'
import { CancelDialog } from './dialogs/end-service'
import { statusCards } from './helpers/statusCards'
import { HSDialogs } from './types'
import { ProgressView, SimpleView } from './views'

export const TreatmentPlanSnackbar = (props: {
  healthServiceList: HealthService[]
}) => {
  const { healthServiceList } = props
  const loadingTest = useSelector(selectTestLoading)
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const sessionStatus = useSelector(selectTelehealthStatus)
  const firstViewFinancialConsult = useSelector(selectFVFConsult)
  const disposition = useSelector(selectEncounterDisposition)
  const encounter = useSelector(selectEncounter)
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles()
  const [hsType, setHsType] = useState(HealthServiceType.Unset)
  const [hsTypeId, setHsTypeId] = useState('')
  const [view, setView] = useState<ConsultStatus | undefined>(undefined)
  const [dialogOpen, setDialogOpen] = useState<HSDialogs>('')
  const { state, actions } = useFlowControl()

  useEffect(() => {
    return () => {
      if (
        view === ConsultStatus.cancelled ||
        view === ConsultStatus.safe_cancelled ||
        view === ConsultStatus.CANCELLED ||
        view === ConsultStatus.healthservice_expired ||
        view === ConsultStatus.safe_treatment_ready
      ) {
        endHealthService()
      }
    }
  }, [view])

  useEffect(() => {
    if (healthServiceList[0] && healthServiceList[0].status === 'Cancelled') {
      if (
        healthServiceList[0].disposition ===
        HealthServiceDisposition.Expired24Hours
      ) {
        setView(ConsultStatus.healthservice_expired)
      } else {
        setView(ConsultStatus.CANCELLED)
      }
    }
    if (healthServiceList[0] && healthServiceList[0].status !== 'Completed') {
      if (healthServiceList[0].lastUpdatedStep) {
        const typeKey = healthServiceList[0].lastUpdatedStep.discriminator
        const typeId = healthServiceList[0].lastUpdatedStep.id
        setHsType(HealthServiceType[typeKey])
        setHsTypeId(typeId)
      }
      dispatch(getEncounterRecord(healthServiceList[0].id))
    }
  }, [healthServiceList, networkSettings])

  useEffect(() => {
    if (
      healthServiceList[0] &&
      healthServiceList[0].status !== 'Completed' &&
      disposition
    ) {
      setView(getStatusForTenantAndDisposition())
      if (
        disposition === EncounterDisposition.COMPLETED ||
        disposition === EncounterDisposition.TREATMENT_READY
      ) {
        clearDeviceInterests()
      }
    }
  }, [disposition, healthServiceList, encounter])

  const onCancel = () => setDialogOpen('cancel')
  const gotoTreatmentPlan = () =>
    history.push(paths.treatmentPlan(healthServiceList[0].id))
  const reviewCarePlan = () =>
    history.push(paths.treatmentPlan(firstViewFinancialConsult?._id))

  const cancelSession = () => {
    dispatch(cancelTelehealthSession(hsTypeId, healthServiceList[0].id, false))
  }

  const getStatusForTenantAndDisposition = () => {
    const SAFE =
      networkSettings?.telehealthConfiguration ===
      TelehealthOptions.SAFE_TELEHEALTH
    const SteadyMD =
      networkSettings?.telehealthConfiguration === TelehealthOptions.STEADY_MD

    if (encounter.status === 'treatment_ready')
      return ConsultStatus.safe_treatment_ready

    switch (disposition) {
      case EncounterDisposition.UNASSIGNED: {
        if (SAFE) return ConsultStatus.safe_created
        if (SteadyMD) return ConsultStatus.NOT_ASSIGNED
        return undefined
      }
      case EncounterDisposition.IN_REVIEW: {
        if (SAFE) return ConsultStatus.safe_dequeued
        if (SteadyMD) return ConsultStatus.steadyMD_in_review
        return undefined
      }
      case EncounterDisposition.TREATMENT_READY: {
        return ConsultStatus.safe_treatment_ready
      }
      default:
        return disposition
    }
  }

  const endHealthService = () => {
    const hasSessionThatCanCancel =
      sessionStatus === 'Waiting' ||
      disposition === EncounterDisposition.UNASSIGNED

    const params = {
      status: 'Completed',
    }
    lsClient.removeUserKeyLS('ratedProvider')
    lsClient.removeUserKeyLS('VCquestionnaireId')
    lsClient.removeUserKeyLS('healthServiceId')
    lsClient.removeUserKeyLS('patientTestId')
    lsClient.removeUserKeyLS('telehealthSessionId')
    lsClient.removeUserKeyLS('telehealth')

    clearTestKitFlow()
    dispatch(resetTelehealthStore())
    dispatch(resetQuestionnaireV2Store())
    dispatch(
      completeHealthService(
        healthServiceList[0].id,
        params,
        hasSessionThatCanCancel ? cancelSession : undefined
      )
    )
  }

  const returnToWaitingRoom = () => {
    history.push(paths.app.telehealth('waiting-room'))
  }
  const continueService = () => {
    const flowEx = lsClient.getUserLSByKey('flowExecution')

    if (flowEx) {
      actions.resumeFlow()
    } else {
      switch (hsType) {
        case HealthServiceType.VirtualConsult: {
          lsClient.setUserLS('VCquestionnaireId', hsTypeId)
          history.push(paths.symptomCheckerV2())
          break
        }
        case HealthServiceType.ConnectedTestV1:
        case HealthServiceType.ConnectedTest: {
          const testType = lsClient.getUserLSByKey('testType')
          lsClient.setUserLS('testId', hsTypeId)
          if (healthServiceList[0].connectedTests.length === 1) {
            const success = () => {
              history.push(paths.registerTest())
            }
            if (testType === 'orderableTest') {
              dispatch(getLabTestDetails(hsTypeId, success))
            } else {
              dispatch(getTestPanelDetails(hsTypeId, success))
            }
          } else if (healthServiceList[0].connectedTests.length > 1) {
            lsClient.setUserLS('patientTestId', hsTypeId)
            history.push(paths.testResultDetails(hsTypeId))
          }
          break
        }
        case HealthServiceType.TelehealthConsult:
        case HealthServiceType.TelehealthConsultV1: {
          lsClient.setUserLS('telehealthSessionId', hsTypeId)
          returnToWaitingRoom()
          break
        }
        case HealthServiceType.Prescription: {
          lsClient.setUserLS('PEquestionnaireId', hsTypeId)
          history.push(paths.symptomCheckerV2())
          break
        }
        default:
          break
      }
    }
  }
  const openMap = () => {
    let url = `http://maps.google.com/?q=Walgreens%205504%20Balboa%20Ave%20San%20Diego%2CCA`
    // let url = `http://maps.google.com/?q=${
    //   test[field.id].homeAddressAddressOne
    // }`
    // if (test[field.id].homeAddressAddressTwo) {
    //   url += `%20${test[field.id].homeAddressAddressTwo}`
    // }
    // url += `%20${test[field.id].homeAddressAddressCity}`
    // if (test[field.id].homeAddressState) {
    //   url += `%2C${test[field.id].homeAddressState}`
    // }
    // if (test[field.id].homeAddressZip) {
    //   url += `%20${test[field.id].homeAddressZip}`
    // }

    window.open(url, '_blank')
  }

  const buttonActions = {
    continueService,
    returnToWaitingRoom,
    endHealthService,
    cancelSession,
    gotoTreatmentPlan,
    reviewCarePlan,
    onCancel,
    openMap,
  }

  const renderStatusCard = () => {
    const step = _.find(
      statusCards(i18n, classes, buttonActions, loadingTest),
      {
        view,
      }
    )

    if (!step) return

    if (step.type === 'PROGRESS') {
      return (
        <ProgressView
          networkSettings={networkSettings}
          endHealthService={endHealthService}
          step={step}
          healthService={healthServiceList[0]}
          deliveryStep={
            firstViewFinancialConsult?.rxOrderResponse?.data?.delivery
              ?.delivery_status
          }
        />
      )
    }
    return (
      <>
        <SimpleView
          networkSettings={networkSettings}
          step={step}
          healthService={healthServiceList[0]}
          endHealthService={onCancel} // Remove this endHealthService once dev complete
        />
      </>
    )
  }

  return (
    <>
      {view && (
        <StatusCardWrapper>
          <Box
            className={classes.btnbase}
            style={{
              borderRadius: radius,
            }}
          >
            <Box style={{ padding: '8px 0px' }}>{renderStatusCard()}</Box>
          </Box>
          <CancelDialog
            open={dialogOpen === 'cancel'}
            endTest={endHealthService}
            setDialogOpen={setDialogOpen}
          />
        </StatusCardWrapper>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  btnbase: {
    'padding': '20px 32px',
    'marginBottom': '24px',
    'width': '100%',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'color': theme.palette.primary.main,
    'boxSizing': 'border-box',
    'backgroundColor': '#fff',
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
      padding: '4px 15px',
    },
  },
}))

const StatusCardWrapper = styled.div`
  margin: 8px 0px;
  width: 100%;
  @media (max-width: 600px) {
    margin-bottom: 24px;
    }
  }
`
