import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box, Grid,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../ui'
import { SubHeaderGetTested } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { Wrapper, Content, Controls, FormWrapper, ContentInner } from '../../ui/templates/app-layout'
import { ReactComponent as PackageIcon } from '../../assets/img/package.svg'
import { ADDRESS_TYPES, testTypes } from '../../constants'
import { paths } from '../paths'
import { confirmDropOff } from '../shipping/shippingTestSlice'

export const ShippingOptionsPage = () => {
  const childClasses = childStyles()
  const i18n = useSelector(selectLanguageContent)
  const { orderId } = useParams<{ orderId: string; }>()
  const history = useHistory<any>()

  const dispatch = useDispatch()

  const goBack = () => {
    history.goBack()
  }

  const goToSelectDropOff = () => {
    history.push(paths.dropOff(testTypes.DROPOFF, orderId))
  }

  const goToConfirmPickUpAdderess = () => {
    history.push(paths.confirmAddress(testTypes.PICKUP, orderId), { addressType: ADDRESS_TYPES.PICKUP_ADDRESS })
  }

  return (
    <Wrapper>
      <SubHeaderGetTested title={i18n.shipping_options_screen_title} onHandleBack={goBack} />
      <Content>
        <Grid className={childClasses.contentWrapper}>
          <PackageIcon className={childClasses.packageIcon} />
          <Box className={childClasses.processText}>{i18n.return_options_label}</Box>
          <Box className={childClasses.placedText}>{i18n.pickup_or_drop_off_message}</Box>
        </Grid>

        <Controls>
          <Button className={childClasses.btn} onClick={goToSelectDropOff}>
            {i18n.find_drop_off_location_button}
          </Button>
          <Button className={childClasses.btn} onClick={goToConfirmPickUpAdderess}>
            {i18n.schedule_pickup_button}
          </Button>
        </Controls>
      </Content>
    </Wrapper>
  )
}

const font = 'Avenir Next Rounded Regular'

const childStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    fontFamily: font,
    color: '#54565B',
  },
  innerWrapper: {
    // backgroundColor: '#EDEDED',
    alignItems: 'flex-start!important',
    margin: '0!important',
    boxSizing: 'border-box',
    padding: '10px!important',
  },
  processText: {
    fontSize: 28,
    fontFamily: font,
    fontWeight: 'bold',
    lineHeight: '80px',
  },
  placedText: {
    fontFamily: font,
    textAlign: 'center',
    lineHeight: '25px',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '150px',
  },
  btn: {
    margin: 10,
  },
  packageIcon: {
    width: 200,
    height: 200,
    fill: '#083d72',
  },
}))
