import React, { useEffect, useRef, useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SvgIcon from '@material-ui/core/SvgIcon'
import {
  resetVerifError,
  verifyEmailCode,
  selectVerificationError,
  selectVerificationSubmitting,
} from '../../../register/model/registerSlice'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg'
import { Typography } from '../../../../ui'
import { paths } from '../../../paths'
import { StepType } from '../../../../constants'

export const VerificationCode = () => {
  const classes = useStyles()
  const verifSubmitting = useSelector(selectVerificationSubmitting)
  const verifError = useSelector(selectVerificationError)
  const [values, setValues] = useState<any>([])

  const history = useHistory()
  const dispatch = useDispatch()

  const inputContainerRef = useRef<HTMLDivElement>(null)
  const refs = useRef([...new Array(6)])

  useEffect(() => {
    checkValues()
  }, [values])

  const checkValues = async () => {
    const codeIsFull =
      values.filter((v: number) => Number.isInteger(v)).length === 6
    if (!codeIsFull || verifSubmitting || verifError) return

    const code = values.join('')

    if (await dispatch(verifyEmailCode(code))) {
      history.push(paths.registerTab(StepType.ACC_INFO))
    } else {
      refs.current = [...refs.current].map((i) => (i.value = ''))
      setValues([])
      refs.current[0].focus()
    }
  }

  const handleInputValue = (_v: number, index: number) => {
    const value = !Number.isNaN(_v) ? Number(_v.toString().slice(0, 1)) : ''

    setValues((prev: any[]) => {
      const updatedValues = [...prev]
      updatedValues[index] = value
      return updatedValues
    })
    refs.current[index].value = value

    if (
      Number.isInteger(value) &&
      !Number.isNaN(value) &&
      index + 1 <= refs.current.length - 1
    ) {
      refs.current[index + 1].focus()
      refs.current[index + 1].setSelectionRange(0, 0)
      dispatch(resetVerifError())
    }
  }

  const handleChange = (e: any, index: any) => {
    if (e.nativeEvent.inputType === 'insertFromPaste') return

    const _v = Number.parseInt(e.target.value, 10)
    handleInputValue(_v, index)
  }

  const onPaste = (e: any) => {
    const code: string[] = e.clipboardData
      .getData('Text')
      .replaceAll(/[^+\d]/g, '')
      .slice(0, 6)
      .split('')

    code.forEach((value, index) => handleInputValue(Number(value), index))
  }

  const onKeyDown = async (e: any, index: any) => {
    const currentValue = values[index]
    if (e.keyCode !== 8 && e.keycode !== 46) return

    dispatch(resetVerifError())

    if (currentValue || index - 1 < 0) return

    refs.current[index - 1].focus()
    values.splice(index, 1)
  }
  const renderErrorMessage = () => {
    if (!verifError) return null

    return (
      <Box
        className={classes.errorContainer}
        maxWidth={inputContainerRef.current?.clientWidth || '100%'}
      >
        <SvgIcon
          component={WarningIcon}
          viewBox="0 0 200 200"
          style={{ fill: '#CE365C', fontSize: 25 }}
        />
        <Typography className={classes.errorText}>
          {verifError || 'That code was not valid. Please try again.'}
        </Typography>
      </Box>
    )
  }

  return (
    <Box className={classes.contentWrapper}>
      <form>
        <Box className={classes.input}>
          <div className={classes.inputContainer} ref={inputContainerRef}>
            {refs.current.map((item, index) => {
              const inputClass = `input-${index + 1}` as keyof typeof classes
              return (
                <Box
                  key={inputClass}
                  display="flex"
                  alignItems="center"
                  className={classes[inputClass]}
                >
                  {!(index % 3) && index !== 0 ? (
                    <div className={classes.divider} />
                  ) : null}
                  <TextField
                    inputRef={(ref) => {
                      refs.current[index] = ref
                    }}
                    value={refs.current[index]?.value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    autoFocus={index === 0}
                    onChange={(e) => handleChange(e, index)}
                    onPaste={onPaste}
                    onKeyDown={(e) => onKeyDown(e, index)}
                    variant="outlined"
                  />
                </Box>
              )
            })}
          </div>
          {renderErrorMessage()}
        </Box>
      </form>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  'contentWrapper': {
    maxWidth: '500px',
    width: '100%',
    padding: '0 15px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign: 'center',
  },
  'profileBtnsWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  'continueBtn': {
    'backgroundColor': 'rgb(16, 6, 159)',
    'textTransform': 'capitalize',
    'color': '#fff',
    'height': 48,
    'width': 190,
    'maxWidth': '100%',
    'fontSize': 18,
    '&:hover': {
      backgroundColor: '#2A1ED9',
    },
  },
  'backBtn': {
    'backgroundColor': '#fff',
    'height': 48,
    'width': 112,
    'maxWidth': '100%',
    'fontWeight': 500,
    'fontSize': 18,
    'textTransform': 'capitalize',
    'color': '#767993',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  'input': {
    'marginTop': 40,
    'display': 'flex',
    'flexDirection': 'column',
    '& input': {
      textAlign: 'center',
      fontSize: 36,
      fontWeight: 600,
      height: 57,
      width: 46,
      [theme.breakpoints.down('sm')]: {
        height: 16,
        width: 23,
      },
    },
  },
  'backdropWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'errorContainer': {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    padding: 18,
    boxSizing: 'border-box',
    backgroundColor: '#FAEBEE',
    marginTop: 16,
    borderRadius: 4,
    border: '1px solid #E8A3B3',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
  },
  'errorText': {
    textAlign: 'center',
    color: '#505358',
    fontSize: 14,
  },
  'divider': {
    height: 1,
    margin: '0 5px',
    width: 8,
    backgroundColor: '#C4C4C4',
  },
  'input-1': {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  'input-2': {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  'input-3': {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  'input-4': {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  'input-5': {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  'input-6': {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  'inputContainer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    alignSelf: 'center',
  },
}))
