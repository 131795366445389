/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { selectCurrentStep, setActive } from './model'
import {
  GetTested,
  ProductDetails,
  ScanIntroduction,
  ScanQRCode,
} from './steps'
import { RegisterTestProps, RegisterTestStepType } from './types'

export const RegisterTest = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const networkSettings = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)
  const currentStep = useSelector(selectCurrentStep)
  const tenantId = localStorage.getItem('tenantId') || ''
  const { type } = useParams<{ type: RegisterTestStepType }>()

  useEffect(() => {
    if (tenantId && !networkSettings) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [networkSettings, tenantId])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const hasTestId = lsClient.getUserLSByKey('testId')
    const hasScannedCode = lsClient.getUserLSByKey('kitId')
    const isInTestFlow = lsClient.getUserLSByKey('step')

    if (isInTestFlow) {
      history.push(paths.instructions())
    }
    if (hasTestId) {
      if (hasScannedCode) {
        dispatch(setActive(3))
      } else dispatch(setActive(2))
    }
  }, [])

  function handleNext() {
    dispatch(setActive(currentStep + 1))
  }

  function handleBack() {
    dispatch(setActive(currentStep - 1))
  }

  const stepProps = {
    handleNext,
    handleBack,
    i18n,
    networkSettings,
  } as RegisterTestProps

  const steps = [
    {
      type: RegisterTestStepType.INTRO,
      content: <GetTested {...stepProps} />,
    },
    {
      type: RegisterTestStepType.TEST_INTRODUCTION,
      content: <ScanIntroduction {...stepProps} />,
    },
    {
      type: RegisterTestStepType.SCAN_CODE,
      content: <ScanQRCode {...stepProps} />,
    },
    {
      type: RegisterTestStepType.PRODUCT_ASSET_DETAILS,
      content: <ProductDetails {...stepProps} />,
    },
  ]
  const renderStepContent = () => {
    const step = _.find(steps, { type })
    const index = _.findIndex(steps, { type })
    if (currentStep !== index) {
      history.push(paths.registerTest(steps[currentStep].type))
    }
    return <Box>{step?.content || steps[0].content}</Box>
  }

  return <Box style={{ width: '100%' }}>{renderStepContent()}</Box>
}
