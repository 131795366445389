import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import alertLight from '../../../assets/img/alertLight.svg'
import { selectLanguageContent } from '../../../features/translation'
import { I18n } from '../../../features/translation/types'
import { clearTestKitFlow } from '../../../libs/helpers'
import { Button } from '../../../ui'
import {
  resetOutcomeStore,
  selectDiagnosticOutcomeResponseMessage,
} from '../../outcomes/model'
import { paths } from '../../paths'
import { resetTestStore } from '../../scan-qr/model'
import { resetUploadStore } from '../model'

export const UploadFailedPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const message = useSelector(
    selectDiagnosticOutcomeResponseMessage
  ) as keyof I18n

  const tryAgain = () => {
    dispatch(resetOutcomeStore())
    dispatch(resetUploadStore())
    history.push(paths.app.instructions())
  }

  const endTest = () => {
    clearTestKitFlow()
    dispatch(resetOutcomeStore())
    dispatch(resetTestStore())
    dispatch(resetUploadStore())
    history.push(paths.app.dashboard())
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.placedText}>
          <Box className={classes.loadingContent}>
            <Box>
              <img
                src={alertLight}
                alt="alert"
                style={{ width: '200px', color: '#FA9C14' }}
              />
            </Box>
          </Box>
          <Box className={classes.processText}>
            {message
              ? i18n[message]
              : i18n.scan_cassete_result_availability_details}
          </Box>
        </Box>
        <Box>
          {message?.includes('err') ? (
            <>
              <Button onClick={tryAgain} className={classes.loadingButton}>
                {i18n.scan_cassete_try_again}
              </Button>
              <Button onClick={endTest} className={classes.loadingButton}>
                {i18n.cancel_button}
              </Button>
            </>
          ) : (
            <Button onClick={endTest} className={classes.loadingButton}>
              {i18n.continue_button}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: '500px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '80px',
  },
  processText: {
    fontSize: 26,
    color: '#FFF',
    margin: '40px',
  },
  loadingButton: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 48,
    fontWeight: 600,
    marginBottom: 24,
    marginLeft: 15,
    marginRight: 15,
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
}))
