import { Box } from '@material-ui/core'
import React from 'react'
import { NetworkSettings } from '../../../pages/network/type'
import { MessengerIconButton } from '../atoms'
import { AvatarAndFullName } from './AvatarAndFullName'
import { FooterItems } from './footerItems'

// To Do: Add LanguageListMenu once Spanish language file is made available

export const ApplicationHeaderRightContent = ({
  light,
  networkSettings,
  isDesktop,
  isSmallDesktop,
}: {
  light?: boolean
  networkSettings: NetworkSettings | null
  isDesktop: boolean
  isSmallDesktop: boolean
}) => {
  return (
    <>
      {isDesktop ? (
        <Box style={{ width: 327 }}>
          <FooterItems isDesktop={isDesktop} light={light}  />
        </Box>
      ) : (
        <>
          {isSmallDesktop && (
            <Box style={{ width: 327 }}>
              <FooterItems isDesktop={isSmallDesktop} light={light} />
            </Box>
          )}
          <AvatarAndFullName light={light} forHeader={true} />
        </>
      )}
      {/* {networkSettings?.showMessenger ? (
        <MessengerIconButton light={light} />
      ) : (
      )} */}
    </>
  )
}
