import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AgoraRTC from 'agora-rtc-sdk-ng'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BeginTelehealthLG from '../../assets/img/BeginTelehealthLG.png'
import BeginTelehealthSM from '../../assets/img/BeginTelehealthSM.png'
import { ImageBackgroundHeader } from '../../features/application/templates/image-bg-header'
import { selectLanguageContent } from '../../features/translation'
import { handleError } from '../../libs/helpers'
import { Button } from '../../ui'
import { Controls } from '../../ui/templates/app-layout'
import { paths } from '../paths'

export const TelehealthIntroductionPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)

  const requestPermissions = () => {
    AgoraRTC.getDevices()
      .then((devices) => {
        telehealthIntake()
      })
      .catch((e) => {
        dispatch(
          handleError(
            'error',
            'You need to enable camera permissions to continue.'
          )
        )
      })
  }

  const telehealthIntake = () => {
    history.push(paths.telehealthIntake('confirm-location'))
  }

  return (
    <Box className={classes.pageWrapper}>
      <ImageBackgroundHeader className={classes.transparentAppBar} />
      <Box className={classes.contentWrapper}>
        <div className={classes.assetWrapper}>
          <Box className={classes.infoBox}>
            <Typography className={classes.title}>
              {i18n.telehealth_consult_intro_title}
            </Typography>
            <Typography className={classes.body}>
              {i18n.telehealth_consult_intro_message}
            </Typography>
          </Box>
          <Controls>
            <Button
              onClick={requestPermissions}
              className={classes.confirmButton}
              variant="outlined"
            >
              {i18n.begin_telehealth_consult_button}
            </Button>
          </Controls>
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  transparentAppBar: {
    boxShadow: 'none',
    background: 'rgba(0,0,0,0.5)',
    [theme.breakpoints.up(1200)]: {
      paddingLeft: 241,
    },
    [theme.breakpoints.down(600)]: {
      background: 'none',
    },
  },
  pageWrapper: {
    width: '100%',
    minHeight: '100vh',
    backgroundImage: `url(${BeginTelehealthSM})`,
    backgroundPosition: '0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'fixed',
    [theme.breakpoints.up(1200)]: {
      paddingLeft: 120,
    },
    [theme.breakpoints.down(600)]: {
      backgroundPosition: '90%',
    },
    [theme.breakpoints.down(520)]: {
      backgroundImage: `url(${BeginTelehealthSM})`,
      backgroundPositionY: '0%',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    minHeight: 'calc(100vh - 64px)',
    height: 'fit-content',
    backgroundColor: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      bottom: '0px',
      background: 'none',
      width: '100%',
      padding: '0px',
      margin: '0px auto',
      textAlign: 'left',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
  },
  assetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    width: '100%',
    margin: '0px auto',
    [theme.breakpoints.down(600)]: {
      paddingTop: '150px',
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.77) 100%)',
    },
  },
  confirmButton: {
    marginTop: 48,
    maxWidth: '220px',
    [theme.breakpoints.down(600)]: {
      maxWidth: 'unset',
      marginBottom: '40px',
    },
    [theme.breakpoints.down(389)]: {
      marginTop: 16,
    },
  },
  title: {
    width: '600px',
    fontWeight: 500,
    color: '#FFF',
    lineHeight: '55px',
    fontSize: 40,
    textAlign: 'inherit',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  body: {
    width: '600px',
    fontWeight: 600,
    marginTop: 10,
    color: '#FFF',
    lineHeight: '22px',
    fontSize: 16,
    textAlign: 'inherit',
    marginBottom: '16px',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  infoBox: {
    [theme.breakpoints.down(600)]: {
      padding: '0px 24px',
    },
  },
  pageLink: {
    textDecoration: 'underline',
    color: '#FFF',
    cursor: 'pointer',
    textAlign: 'inherit',
  },
}))
