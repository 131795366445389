import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  selectIsInsuranceVerified,
  selectSelectedProvider,
} from '../insurance/model'
import { paths } from '../paths'
import {
  Wrapper,
  Content,
  useStyles,
  ContentInner,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'

export const InsuranceVerifyingProcess = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const i18n = useSelector(selectLanguageContent)
  const selectedProvider = useSelector(selectSelectedProvider)
  const isInsuranceVerified = useSelector(selectIsInsuranceVerified)

  useEffect(() => {
    if (!selectedProvider) {
      history.push(paths.addInsuranceProvider(type, id))
    }
  }, [selectedProvider])

  useEffect(() => {
    if (isInsuranceVerified === true) {
      history.push(paths.insuranceProviderAdded(type, id))
    } else if (isInsuranceVerified === false) {
      history.push(paths.insuranceProblem(type, id))
    }
  }, [isInsuranceVerified])

  return (
    <Wrapper>
      <Box className={classes.contentStyles}>
        <Content style={{ justifyContent: 'center', margin: 0 }}>
          <ContentInner>
            <CircularProgress
              style={{ color: 'white', width: 100, height: 100 }}
            />
            <Box className={classes.verifyingProcessText}>
              {i18n.insurance_verifying_eligibility}
            </Box>
          </ContentInner>
        </Content>
      </Box>
    </Wrapper>
  )
}
