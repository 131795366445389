import { Box, styled, Theme, useMediaQuery } from '@material-ui/core'
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp'
import { useHistory } from 'react-router-dom'
import GetTestedImage from '../../assets/img/HealthService.svg'
import { lsClient } from '../../ls-client'
import { paths } from '../../pages/paths'
import { Typography } from '../../ui'
import { ModuleIntroWrapper } from '../../ui/templates/modules/moduleIntroWrapper'
import { useFlowControl } from './use-flow-control'

export interface FlowsListProps {}

export const FlowsList = (props: FlowsListProps) => {
  const { state, actions } = useFlowControl()
  const history = useHistory()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(960))

  const startFlow = (flow: any) => {
    lsClient.setUserLS('flowId', flow.id)
    lsClient.setUserLS('flowTitle', flow.name)
    actions.startFlow(flow.id)
  }

  const handleClose = () => {
    lsClient.removeUserKeyLS('flowId')
    lsClient.removeUserKeyLS('flowTitle')
    lsClient.removeUserKeyLS('flowExecution')

    history.push(paths.app.dashboard())
  }

  return (
    <ModuleIntroWrapper
      contentImage={isMobile ? undefined : GetTestedImage}
      childPosition="center"
      contentPosition="start"
      title={'Health Services'}
      body={
        'Select a health service to see if you qualify for on demand treatment:'
      }
      onClose={handleClose}
    >
      <ListWrapper>
        {state.flowsList.map((flow: any) => {
          return (
            <FlowContainer onClick={() => startFlow(flow)} key={flow.id}>
              <ContentWrapper>
                <div>
                  <Title>{flow.name}</Title>
                  <Body>{flow.description}</Body>
                </div>
                <ChevronRightSharpIcon />
              </ContentWrapper>
            </FlowContainer>
          )
        })}
      </ListWrapper>
    </ModuleIntroWrapper>
  )
}

const FlowContainer = styled(Box)(({ theme }) => ({
  'borderRadius': '10px',
  'backgroundColor': '#fff',
  'width': 'calc(100% - 2px)',
  'margin': '20px 0px',
  'border': '1px solid #aaa',
  'cursor': 'pointer',
  '&:hover': {
    backgroundColor: '#ddd',
  },
  [theme.breakpoints.down(960)]: {
    margin: '10px 0px',
  },
}))

const ListWrapper = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  [theme.breakpoints.down(960)]: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '32px',
  maxWidth: '660px',
  [theme.breakpoints.down(960)]: {
    padding: '16px',
  },
}))

const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  color: '#505358',
})

const Body = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  color: '#898989',
})
