import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { hexToRGBA } from '../../../libs/helpers'
import { Checkbox, RoundedCheckbox } from '../../../ui'

interface QuestionnaireCheckboxProps {
  type: 'checkbox' | 'radio'
  checked?: boolean
  onChange?: (e: React.ChangeEvent) => void
  label?: string
}
export const QuestionnaireCheckbox: React.FC<QuestionnaireCheckboxProps> = ({
  onChange,
  checked = false,
  label,
  type,
}) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      control={
        type === 'checkbox' ? (
          <Checkbox color="secondary" checked={checked} onChange={onChange} />
        ) : (
          <RoundedCheckbox
            color="secondary"
            checked={checked}
            onChange={onChange}
          />
        )
      }
      label={label}
      labelPlacement={'end'}
      className={
        checked
          ? `${classes.questionnaireCheckbox} checked`
          : classes.questionnaireCheckbox
      }
    />
  )
}

const useStyles = makeStyles((theme) => ({
  questionnaireCheckbox: {
    'display': 'flex',
    'justifyContent': 'flex-start',
    'alignItems': 'flex-start',
    'padding': '12px 12px 12px 24px',
    'borderRadius': '8px',
    'margin': '0px auto',
    'width': '90%',
    'backgroundColor': '#fff',
    'transition': 'all .03s ease-in-out',
    '&:hover': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.05),
    },
    '& span:nth-child(1)': {
      padding: 0,
    },
    '& span:nth-child(2)': {
      fontWeight: 500,
    },
    '&.checked': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.1),
    },
  },
}))
