import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Loading, TextField, Typography } from '../../ui'
import { BackHeader } from '../../ui/templates/back-header'
import { SHADOW } from '../dashboard/constants'
import { paths } from '../paths'
import {
  ListArticleResource,
  ListVideoResource,
} from './components/resourceCards'
import {
  getArticleResources,
  getVideoResources,
  selectArticlesList,
  selectArticlesLoading,
  selectResourcesLoaded,
  selectResourcesSearch,
  selectVideosList,
  selectVideosLoading,
  setSearch,
} from './resourcesSlice'
import { ResourceType } from './types'

export const ResourcesPage = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const searchValueState = useSelector(selectResourcesSearch)
  const [searchValue, setSearchValue] = useState(searchValueState)
  const videoResources = useSelector(selectVideosList)
  const articleResources = useSelector(selectArticlesList)
  const videosLoading = useSelector(selectVideosLoading)
  const articlesLoading = useSelector(selectArticlesLoading)
  const resourcesLoaded = useSelector(selectResourcesLoaded)
  const [timeout, setStateTimeout] = useState<any>(undefined)
  const i18n = useSelector(selectLanguageContent)
  const matches = useMediaQuery(theme.breakpoints.down('xs'))

  const NoResources = () => (
    <Typography color="primary" variant="h2" className={classes.noContent}>
      {i18n.no_resources_message}
    </Typography>
  )

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (!resourcesLoaded) {
      dispatch(getVideoResources(searchValue))
      dispatch(getArticleResources(searchValue))
    }
  }, [])

  const handleChange = (e: any) => {
    const value = e.target.value
    setSearchValue(value)
    dispatch(setSearch(value))
    if (value.length >= 3 || value.length === 0) {
      if (timeout) {
        clearTimeout(timeout)
      }
      setStateTimeout(
        setTimeout(() => {
          dispatch(getVideoResources(value))
          dispatch(getArticleResources(value))
        }, 1000)
      )
    }
  }

  const onClick = (type: ResourceType, id: string) => {
    history.push(paths.resourceDetails(type, id))
  }

  const clearSearchValue = () => {
    setSearchValue('')
    dispatch(setSearch(''))
    dispatch(getVideoResources(''))
    dispatch(getArticleResources(''))
  }

  const getText = (html: string) => {
    const temp = document.createElement('div')
    temp.innerHTML = html

    return temp.textContent
  }

  const featuredResources = () => {
    const featured = videoResources.filter((r) => r.featured)

    if (featured.length === 0) return null

    return (
      <Box>
        {featured.map((r) => (
          <Box
            className={classes.featuredResource}
            key={r._id}
            onClick={() => onClick('video', r._id)}
          >
            <img src={r.thumbnailURL} className={classes.featuredImage} />
            <Box className={classes.featuredResourceBody}>
              <Typography className={classes.featuredResourceTitle}>
                {r.title}
              </Typography>
              {r.description && (
                <Typography className={classes.featuredResourceDesc}>
                  {getText(r.description)}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
        <Divider className={classes.divider} />
      </Box>
    )
  }

  return (
    <div>
      <BackHeader
        title={matches ? i18n.resources_screen_title : ''}
        backPath={paths.app.dashboard()}
      />
      <div className={classes.contentWrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.titleBar}>
            {!matches && (
              <Typography className={classes.pageTitle}>
                {i18n.resources_screen_title}
              </Typography>
            )}
            <Box className={classes.resourceSearchContainer}>
              <SearchField
                handleChange={(e) => handleChange(e)}
                classes={classes}
                searchValue={searchValue}
                i18n={i18n}
                clearSearchValue={clearSearchValue}
              />
            </Box>
          </div>

          <div className={classes.content}>
            {videosLoading || articlesLoading ? (
              <Box className={classes.loading}>
                <Loading />
              </Box>
            ) : (
              <>
                {videoResources.length === 0 &&
                  articleResources.length === 0 && <NoResources />}
                {featuredResources()}
                <Box className={classes.resourceListContainer}>
                  <Grid container spacing={2}>
                    {videoResources.length > 0 &&
                      videoResources
                        .filter((r) => !r.featured)
                        .map((res) => (
                          <ListVideoResource
                            r={res}
                            onResourceClick={onClick}
                            grid={true}
                          />
                        ))}
                    {articleResources.length > 0 &&
                      articleResources.map((res) => (
                        <ListArticleResource
                          r={res}
                          onResourceClick={onClick}
                          grid={true}
                        />
                      ))}
                  </Grid>
                  <Box className={classes.resourceList}></Box>
                </Box>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const SearchField = (props: {
  handleChange: (e: React.ChangeEvent) => void
  classes: ClassNameMap
  searchValue: string
  i18n: I18n
  clearSearchValue: () => void
}) => {
  const { handleChange, classes, searchValue, i18n, clearSearchValue } = props
  return (
    <Box className={classes.searchFieldWrapper}>
      <TextField
        id="outlined-basic"
        className={classes.searchField}
        placeholder={i18n.search_placeholder}
        value={searchValue}
        name="searchbar"
        onChange={(e) => handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#666666', fontSize: 20 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {searchValue.length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => clearSearchValue()}>
                    <CloseIcon style={{ color: '#666666', fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </Box>
  )
}
const NO_BORDER_BOTTOM = '0px solid #FFF'
const WEBKIT_BOX = '-webkit-box'

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    marginTop: 80,
    maxWidth: '1400px',
    width: '100vw',
  },
  contentContainer: {
    margin: '108px 114px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '48px',
    },
    [theme.breakpoints.down(600)]: {
      margin: '24px 12px',
    },
  },
  content: {
    paddingTop: 48,
  },
  titleBar: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'absolute',
  },
  pageTitle: {
    color: '#282D37',
    fontSize: '36px',
    fontWeight: 600,
  },
  loading: {
    width: 'calc(100vw - 228px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '250px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 96px)',
    },
    [theme.breakpoints.down(600)]: {
      width: 'calc(100vw - 24px)',
    },
  },
  featuredResource: {
    borderRadius: '16px',
    backgroundColor: '#FFF',
    display: 'flex',
    margin: '16px 0px',
    maxHeight: '340px',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.primary.main}`,
      maxHeight: '372px',
    },
  },
  featuredResourceBody: {
    padding: '32px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  featuredResourceTitle: {
    'fontSize': '36px',
    'lineHeight': '46px',
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  featuredImage: {
    borderRadius: '16px',
    objectFit: 'cover',
    width: '50%',
    [theme.breakpoints.down(600)]: {
      maxHeight: '220px',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      borderBottomLeftRadius: '0px',
      width: '100%',
    },
  },
  featuredResourceDesc: {
    'marginTop': '16px',
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down('xs')]: {
      'fontSize': '14px',
      'lineHeight': '21px',
      '-webkit-line-clamp': 3,
    },
  },
  resourceListContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  },
  resourceSearchContainer: {
    display: 'flex',
    width: '292px',

    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      width: 'calc(100% - 30px)',
    },
  },
  resourceList: {
    display: 'flex',
  },
  img: {
    objectFit: 'contain',
    height: '70px',
    width: '100%',
  },
  noContent: {
    fontWeight: 500,
    margin: '25px auto',
  },
  searchFieldWrapper: {
    display: 'flex',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '100px',
    border: '1px solid #757575',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  searchField: {
    'textTransform': 'none',
    'width': '100%',
    'background': '#FFF',
    '& input': {
      padding: '7px 0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: NO_BORDER_BOTTOM,
    },
    '& .MuiInput-underline:before': {
      borderBottom: NO_BORDER_BOTTOM,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: NO_BORDER_BOTTOM,
    },
  },
  divider: {
    margin: '24px 0px 0px',
    width: '100%',
    borderBottom: 'solid 1px #CCCCCE',
    [theme.breakpoints.down(600)]: {
      margin: '16px 0',
    },
  },
}))
