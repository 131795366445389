import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../../ui'
import { paths } from '../paths'

export const ConfirmationPage = () => {
  const classes = useStyles()
  const history = useHistory()

  const goNext = () => {
    history.push(paths.app.dashboard())
  }

  return (
    <Box className={classes.confirmContainer}>
      <Box className={classes.confirmationWrapper}>
        <Box className={classes.confirmContent}>
          <Box className={classes.checkIconWrapper}>
            <DoneIcon className={classes.checkIcon} />
          </Box>
          <Box className={classes.confirmText}>
            Image uploaded and being processed! Thank you for participating in
            our test. Please proceed by taking the Symptom Checker on the next
            screen, and our followup survey to complete your study
            participation.
          </Box>
        </Box>
        <Button onClick={goNext} className={classes.confirmButton}>
          OK
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  confirmContainer: {
    backgroundColor: '#003C71',
    width: '100%',
  },
  confirmationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  confirmContent: {
    textAlign: 'center',
    margin: 'auto',
  },
  checkIconWrapper: {
    width: 118,
    height: 118,
    borderRadius: '50%',
    border: '2px solid #fff',
    margin: '0 auto 48px',
  },
  checkIcon: {
    display: 'block',
    fontSize: 118,
    color: '#fff',
  },
  confirmText: {
    color: '#fff',
    fontSize: 26,
    maxWidth: 450,
    padding: '0 30px',
  },
  confirmButton: {
    'width': '100%',
    'maxWidth': 300,
    'maxHeight': 48,
    'borderRadius': 100,
    'fontWeight': 600,
    'backgroundColor': '#FFF',
    'color': '#003C71',
    'marginBottom': 48,
    'border': '1px solid #003C71',
    'marginLeft': 15,
    'marginRight': 15,

    '&:hover': {
      backgroundColor: '#003C71',
      color: '#FFF',
      border: '1px solid #fff',
    },
  },
}))
