import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import SafeHealth from '../../assets/SafeHealth.png'
import {
  selectEncounterLoading,
  updateEncounterRecord,
} from '../../features/encounter/model'
import { getNextStep } from '../../features/health-service-lite/model'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { Loading } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { ResultQuestionnaire } from './questionnaireOutcome'
import { QuestionnaireExecution } from './questionnaireV2Execution'
import {
  previousQuestionV1,
  resumeQuestionnaireV2,
  selectIsFirstQuestion,
  selectQuestionnaireComplete,
  selectQuestionnaireProgress,
  startQuestionnareV2,
} from './questionnaireV2Slice'

const PE_QUESTIONNAIRE = process.env.REACT_APP_ELIGIBILITY_QUESTIONNAIRE

export function QuestionnaireV2Page() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isComplete = useSelector(selectQuestionnaireComplete)
  const history = useHistory()
  const encounterLoading = useSelector(selectEncounterLoading)
  const questionnaireProgress = useSelector(selectQuestionnaireProgress)
  const { id } = useParams<{ id: string }>()
  const i18n = useSelector(selectLanguageContent)
  const [progress, setProgress] = useState(0)
  const isFirstQuestion = useSelector(selectIsFirstQuestion)
  const [displayOutcomeReady, setDisplayOutcomeReady] = useState(false)
  const startedQuestionnaireId = lsClient.getUserLSByKey('VCquestionnaireId')
  const lastUpdatedId = lsClient.getUserLSByKey('encounterLastUpdatedId')
  const networkSettings = useSelector(selectNetworkSettings)
  const tenantId = localStorage.getItem('tenantId')

  useEffect(() => {
    if (!networkSettings && tenantId) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [])

  const handleBack = () => {
    if (isFirstQuestion) {
      history.push(paths.symptomCheckerIntro())
    } else if (startedQuestionnaireId) {
      dispatch(previousQuestionV1(startedQuestionnaireId))
    }
  }
  useEffect(() => {
    if (questionnaireProgress) {
      setProgress(
        (questionnaireProgress.currentQuestion /
          (questionnaireProgress.totalQuestions + 1)) *
          100
      )
    }
  }, [questionnaireProgress])

  useEffect(() => {
    if (isComplete) {
      const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
      if (healthServiceId) {
        if (!lastUpdatedId || lastUpdatedId !== startedQuestionnaireId) {
          const encounterParams = {
            _id: healthServiceId,
            questionnaireResponses: [
              {
                questionnaireExecutionId: startedQuestionnaireId,
              },
            ],
          }
          dispatch(
            updateEncounterRecord(encounterParams, () =>
              lsClient.setUserLS(
                'encounterLastUpdatedId',
                startedQuestionnaireId
              )
            )
          )
        }
        dispatch(
          getNextStep(healthServiceId, () => setDisplayOutcomeReady(true))
        )
      } else setDisplayOutcomeReady(true)
    }
  }, [isComplete])

  useEffect(() => {
    const isForEligibility = lsClient.getUserLSByKey('patientTestId')
    lsClient.removeUserKeyLS('isHealthService')
    const startedQuestionnaireId = lsClient.getUserLSByKey('VCquestionnaireId')
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    if (startedQuestionnaireId) {
      dispatch(resumeQuestionnaireV2(startedQuestionnaireId))
    } else if (id) {
      dispatch(startQuestionnareV2(healthServiceId, id))
    } else if (isForEligibility) {
      const eligibilityQuestionnaire = PE_QUESTIONNAIRE
      dispatch(startQuestionnareV2(healthServiceId, eligibilityQuestionnaire))
    } else if (healthServiceId) {
      dispatch(startQuestionnareV2(healthServiceId))
    } else {
      dispatch(startQuestionnareV2())
    }
  }, [])

  const handleExit = () => {
    history.push(paths.app.dashboard())
  }

  if (encounterLoading || (isComplete && !displayOutcomeReady))
    return <Loading size={100} />

  return (
    <Box className={classes.fullWidth}>
      {displayOutcomeReady ? (
        <ResultQuestionnaire />
      ) : (
        <ModuleHeader
          leftContentTitle={i18n.back_button}
          leftContentAction={handleBack}
          rightContentTitle={i18n.exit_button}
          rightContentAction={handleExit}
          centerContentImage={SafeHealth}
          progress={progress}
          progressColor="secondary"
          color="#505358"
        >
          <div className={classes.contentWrapper}>
            <Box
              style={{
                height: 'calc(100vh - 64px)',
                overflow: 'auto',
              }}
            >
              <QuestionnaireExecution />
            </Box>
          </div>
        </ModuleHeader>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100%',
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      width: 'calc(100vw)',
    },
  },
  contentWrapper: {
    position: 'relative',
    margin: '0px auto',
  },
}))
