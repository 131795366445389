import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lsClient } from '../../../ls-client'
import {
  getNetworkSettings,
  selectNetworkSettings,
} from '../../../pages/network/model'
import { selectActiveTenant } from '../../../pages/tenant-selection/tenantSlice'
import { Header } from '../../../ui'
import { Logo } from '../atoms'
import { drawerOpened } from '../model'
import { ApplicationHeaderRightContent } from '../molecules'

export const ApplicationHeader = (props: {
  isDesktop: boolean
  isSmallDesktop: boolean
}) => {
  const dispatch = useDispatch()
  const { isDesktop, isSmallDesktop } = props
  const networkSettings = useSelector(selectNetworkSettings)
  const activeTenant = useSelector(selectActiveTenant)
  const handleDrawerOpen = () => {
    dispatch(drawerOpened())
  }

  useEffect(() => {
    if (lsClient.getUserLSByKey('tenantId') && !networkSettings) {
      dispatch(getNetworkSettings())
    }
  }, [activeTenant])

  return (
    <Header
      onMenuClick={handleDrawerOpen}
      middleContent={<Logo />}
      rightContent={
        <ApplicationHeaderRightContent
          networkSettings={networkSettings}
          isDesktop={isDesktop}
          isSmallDesktop={isSmallDesktop}
        />
      }
    />
  )
}
