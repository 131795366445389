interface LabVendor {
  name: string
  vendorKey: string
  address: {
    address1: string
    address2: string
    city: string
    state: string
    zip: string
    country: string | null
  }
  contactDetail: {
    firstName: string
    lastName: string
    phoneNumber: string
    faxNumber: string
    email: string
  }
  isRequisitionSupported: boolean
  isResultDocumentSupported: boolean
  isSpecimenLabelSupported: boolean
  isActive: boolean
  _id: string
  modifier: string
  creator: string
  created: string
  modified: string
}

interface Appointment {
  appointmentEnd: string
  appointmentStart: string
  cancelDateTime: string
  confirmationId: string
  created: string
  creator: string
  locationCode: string
  locationId: string
  locationName: string
  modified: string
  modifier: string
  nationalHealthId: string
  status: string
  timeZone: string
  _id: string
}
interface Facility {
  homeAddressAddressCity: string
  homeAddressAddressOne: string
  homeAddressAddressTwo: string
  homeAddressCountry: string
  homeAddressState: string
  homeAddressZip: string
  homeAddressZipExtn: string | null
  title: string
  workingHours: string
}
interface PickupAddress {
  address1: string | null
  address2: string | null
  city: string | null
  state: string | null
  zip: string | null
}
interface PickupDetails {
  address: null | PickupAddress
}
interface OrderableTest {
  orderableName: string
  customName: string
  shortDescription: string
  longDescription: string
  vendorOrderId: string
  price: number
}
interface TestFormat {
  format: string
  isActive: boolean
  isRapidTest: boolean
  isTestKit: boolean
  isFacility: boolean
  formatId: string
  _id: string
  modifier: string
  creator: string
  created: string
  modified: string
}
interface ShippingCarrier {
  name: string
}
interface TestResultItemDetails {
  labVendor: null | LabVendor
  appointment: null | Appointment
  facility: null | Facility
  pickupDetails: null | PickupDetails
  orderableTest: null | OrderableTest
  testFormat: null | TestFormat
  orderablePanel: null
  panelTests: null
  shippingCarrier: null | ShippingCarrier
}
export interface TestResultItem {
  statusDisplayText: Status
  details: TestResultItemDetails
  vendorOrderId: null | string
  userUUID: null
  _class: null
  userId: null
  nationalHealthId: string
  tenantId: null | string
  source: string
  orderId: string
  status: Status | undefined
  statusMessage: null
  encounterId: string
  result: keyof typeof AllowedResults | undefined
  questionnaireId: string
  metadata: TestMetadata | null
  lastUpdated: string
  requisitionBase64: null
  internalTestId: null | string
  labTestOrderableId: null
  notes: null
  medicationNotes: null
  referralNotes: null
  diagnosticNotes: null
  medications: null
  referrals: null
  diagnostics: null
  shipmentDetailsId: string
  kitInfo: null
  appointmentId: null
  orderableTestIds: null
  orderablePanelId: null
  paymentMode: string
  insuranceInfoId: null
  paymentTransactionId: null
  orderingProviderId: null
  patientSignature: null
  questionnaireExecutionId: null
  requisitionBarcodeImage: null
  labelData: null
  specimenLabelPath: null
  manuallyAdded: false
  collectionDate: string | null
  isAntibodyTest: false
  _id: string
  modifier: null
  creator: null
  created: string
  modified: string
}
export type TestResultItemExtended = TestResultItem & {
  canDisplayButtons: {
    cancelPickUp: boolean
    reschedule: boolean
    registerKit: boolean
    trackPackage: boolean
  }
  details: TestResultItemDetails & {
    insurance: null
    orderingProvider: null
    shipping: {
      safeOrderId: string
      labVendorOrderId: null
      shipmentVendorOrderID: null
      outboundTrackingId: null
      outboundTrackingCarier: string
      outboundTrackingCarierService: string
      returnTrackingID: null
      returnTrackingCarier: null
      shipmentStatus: string
      kitOriginationAdress: null
      kitLabDestinationAddress: null
      testkitdetailsid: null
      shippingAddress: null
      shippingReturnOption: string
      shippingReturnDropoff: null
      shippingReturnScheduledPickup: null
      _id: string
      modifier: null
      creator: null
      created: string
      modified: string
    }
  }
}

export interface TestMetadata {
  outcome: AllowedResults
  selfAssessmentOutcome: AllowedResults
}
export interface TestResultsV2State {
  isLoading: boolean
  list: TestResultItem[]
  activeItem: TestResultItemExtended | null
  error: string
  page: number
  isDone: boolean
}
export interface TestResultsContract {
  success: boolean
  data: TestResultItem[]
  message: string
  code: number
}

export interface TestResultsItemContract {
  success: boolean
  data: TestResultItemExtended | null
  message: string
  code: number
}

export type Status =
  | 'COMPLETED'
  | 'CANCELLED'
  | 'APPOINTMENT_SCHEDULED'
  | 'SHIPPED'
  | 'PENDING_RESULTS'
  | 'SCAN_COLLECTED'
  | 'TESTKIT_DISPATCHED'
  | 'TESTKIT_PICKUP_SCHEDULED'
  | 'TESTKIT_PICKUP_CANCELLED'
  | 'TESTKIT_DROPOFF_CONFIRMED'
  | 'TESTKIT_ORDER_PLACED'
  | 'TESTKIT_PAIRED'
  | 'INITIALIZED'

export enum StatusValues {
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
  SHIPPED = 'SHIPPED',
  PENDING_RESULTS = 'PENDING_RESULTS',
  SCAN_COLLECTED = 'SCAN_COLLECTED',
  TESTKIT_DISPATCHED = 'TESTKIT_DISPATCHED',
  TESTKIT_PICKUP_SCHEDULED = 'TESTKIT_PICKUP_SCHEDULED',
  TESTKIT_PICKUP_CANCELLED = 'TESTKIT_PICKUP_CANCELLED',
  TESTKIT_DROPOFF_CONFIRMED = 'TESTKIT_DROPOFF_CONFIRMED',
  TESTKIT_ORDER_PLACED = 'TESTKIT_ORDER_PLACED',
  TESTKIT_PAIRED = 'TESTKIT_PAIRED',
  INITIALIZED = 'INITIALIZED',
}

export enum AllowedResults {
  UNKNOWN = 'UNKNOWN',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  INVALID = 'INVALID',
}
