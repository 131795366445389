import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { lsClient } from '../../../ls-client'
import { Typography } from '../../../ui'
import { ModuleHeader } from '../../../ui/templates/modules/moduleHeader'
import { paths } from '../../paths'
import {
  getScanCode,
  resetScanPage,
  selectResetScanPage,
  setActive,
  setCassetteNumber,
  setError,
} from '../model'
import { RegisterTestProps, RegisterTestStepType } from '../types'

const data_matrix = 'GS1DataMatrix2Dbarcode'
const QRCode = 'QRCode'

export const ScanQRCode = (props: RegisterTestProps) => {
  const { i18n, handleNext, handleBack } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [data, setData] = useState('')
  const resetScan = useSelector(selectResetScanPage)
  const healthServiceId = lsClient.getUserLSByKey('healthServiceId')

  const resetScanFlow = () => {
    lsClient.removeUserKeyLS('scan_timer_start')
    lsClient.removeUserKeyLS('wait_timer_start')
    lsClient.removeUserKeyLS('scan_capture_time')
    lsClient.removeUserKeyLS('step')
    // lsClient.removeUserKeyLS('testId')
    // lsClient.removeUserKeyLS('kitId')
  }

  useEffect(() => {
    if (resetScan) {
      setData('')
      dispatch(resetScanPage(false))
    }
  }, [resetScan])

  const onExit = () => {
    history.push(paths.app.dashboard())
  }

  const onBack = () => {
    lsClient.removeUserKeyLS('testId')
    dispatch(setActive(0))
    history.push(paths.registerTest(RegisterTestStepType.INTRO))
  }

  const handleResult = (result: any) => {
    dispatch(setError(''))
    const success = () => {
      resetScanFlow()
      handleNext()
    }
    if (result.format === 5) {
      lsClient.setUserLS('code', result.text)
      dispatch(setCassetteNumber(result.text))
      dispatch(
        getScanCode(
          'en',
          result.text,
          data_matrix,
          i18n,
          healthServiceId,
          success
        )
      )
    }
    setData(result.text)
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={onBack}
      rightContentTitle={i18n.exit_button}
      rightContentAction={onExit}
      color="#505358"
    >
      <div className={classes.content}>
        <Box className={classes.cameraContent}>
          <Typography variant="h2" className={classes.title}>
            {i18n.scan_code_title}
          </Typography>
          <Typography variant="h2" className={classes.body}>
            {i18n.camera_scaning_hint}
          </Typography>
          <div className={classes.divider} />
          <Box className={classes.blackBox}>
            <div className={classes.cameraCenter}>
              {!data && (
                <BarcodeScannerComponent
                  height={500}
                  width={660}
                  onUpdate={(error, result: any) => {
                    if (result) handleResult(result)
                  }}
                />
              )}
            </div>
          </Box>
        </Box>
      </div>
    </ModuleHeader>
  )
}

const MASK_RADIAL = '-webkit-radial-gradient(circle, white 100%, black 100%)'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxWidth: '500px',
    margin: '64px auto',
    position: 'relative',
    [theme.breakpoints.down(1200)]: {
      margin: '32px auto',
    },
    [theme.breakpoints.down(600)]: {
      margin: '16px auto',
    },
  },
  cameraCenter: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    [theme.breakpoints.down(1000)]: {
      top: 30,
      right: 20,
      maxWidth: '120vw',
      overflow: 'hidden',
    },
    [theme.breakpoints.down(600)]: {
      top: 0,
    },
    [theme.breakpoints.down(281)]: {
      right: 0,
    },
  },
  blackBox: {
    '-webkit-mask-image': MASK_RADIAL,
    'width': '500px',
    'height': '500px',
    'backgroundColor': '#FFF',
    [theme.breakpoints.down(600)]: {
      margin: '0px auto',
      width: '90vw',
      height: '60vh',
    },
  },
  title: {
    fontWeight: 600,
    color: '#282D37',
    fontSize: 36,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      marginLeft: '24px',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  divider: {
    width: '100%',
    height: 1,
    marginBottom: '8px',
    borderBottom: '1px solid #E0E0E0',
  },
  body: {
    width: '342px',
    fontWeight: 500,
    color: '#757575',
    lineHeight: '22px',
    fontSize: 16,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      marginBottom: '16px',
      width: '90%',
      marginLeft: '24px',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  cameraContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: '100%',
    width: '100%',
  },
  button: {
    margin: 15,
  },
}))
