import React, { ChangeEvent, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Button, Select } from '../../ui'
import { SubHeaderGetTested } from '../../features/application'
import { FormValues } from './types'
import validationSchema from './validationSchema'
import { selectLanguageContent } from '../../features/translation'
import { Wrapper, FormWrapper, Content } from '../../ui/templates/app-layout'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { TimePicker } from '@material-ui/pickers'
import moment from 'moment'
import { paths } from '../paths'
import { ProcessingLoader } from '../../ui/templates/processingLoader'
import {
  schedulePickUp,
  selectAvailablePickUps,
  selectDropOffPickupLocation,
  setDropOffPickupLocation,
} from '../shipping/shippingTestSlice'
import { ConfirmationDialog } from '../schedule-appointment/confirmationDialog'
import { ADDRESS_TYPES, testTypes } from '../../constants'
import { AvailablePickUp, Contact, SchedulePickUpRequest, ShortAddress } from '../shipping/types'
import { getAvailableDaysForPickUp } from '../../libs/helpers'

export const SchedulePickupPage = () => {
  const classes = childStyles()
  const i18n = useSelector(selectLanguageContent)
  const selectedLocation = useSelector(selectDropOffPickupLocation)
  const availablePickups = useSelector(selectAvailablePickUps)
  const history = useHistory<any>()
  const { type, orderId } = useParams<{
    type: string
    orderId: string
  }>()
  const [timeRange, setTimeRange] = useState<{from: string, to: string}>({ from: '', to: ''})
  const [formValues, setFormValues] = useState<FormValues>({
    pickupDate: '',
    readyTime: '',
    latestTimeAvailable: '',
    comment: '',
  })

  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [processing, setProcessing] = useState(false)

  const dispatch = useDispatch()

  useEffect(()=>{
    if(!selectedLocation) history.push(paths.confirmAddress(testTypes.PICKUP, orderId), { addressType: ADDRESS_TYPES.PICKUP_ADDRESS })
  }, [selectedLocation])

  const onSubmit = async (values: FormValues) => {
    const updatedLocationInfo = {
      pickupDate: values.pickupDate,
      readyTime: values.readyTime,
      latestTimeAvailable: values.latestTimeAvailable,
      comment: values.comment,
      ...selectedLocation,
    }
    await dispatch(setDropOffPickupLocation(updatedLocationInfo))
    setConfirmationOpen(true)
    // history.push(paths.checkout(type, id))
  }

  const formik = useFormik({
    initialValues: {
      pickupDate: formValues.pickupDate,
      readyTime: formValues.readyTime,
      latestTimeAvailable: formValues.latestTimeAvailable,
      comment: formValues.comment,
    },
    validationSchema: validationSchema(i18n, timeRange),
    enableReinitialize: true,
    onSubmit,
  })

  useEffect(()=>{
    const accessTime = availablePickups ? availablePickups[Number(formik.values.pickupDate)]?.accessTime : 'PT0H0M'

    const [hours, minutes] = accessTime.slice(2).slice(0, -1).split('H')

    const pickupDate = moment(availablePickups ? availablePickups[Number(formik.values.pickupDate)]?.pickupDate : '')
    const timeTo = moment(pickupDate).add(hours, 'hours').add(minutes, 'minutes')
    const cutoffTime = moment(availablePickups ? availablePickups[Number(formik.values.pickupDate)]?.cutOffTime : '')

    const a = moment(moment(timeTo.format()).format('HH:mm'), 'HH:mm')
    const b = moment(moment(cutoffTime).format('HH:mm'), 'HH:mm')

    const to = a.isSameOrBefore(b, 'minute') ? timeTo : cutoffTime

    setTimeRange({
      from: pickupDate.format('HH:mm'),
      to: to.format('HH:mm'),
    })
  }, [formik.values.pickupDate])

  const goBack = () => {
    history.goBack()
  }

  const handleConfirmDialog = () => {
    // dispatch(setDropOffPickupLocation(address))
    const payload:SchedulePickUpRequest = {
      address: {
        city: selectedLocation.city,
        postalCode: String(selectedLocation.zip),
        stateOrProvinceCode: selectedLocation.state,
        countryCode: selectedLocation.country,
        addressLine: selectedLocation.address1,
      },
      logisticsPartner: 'FedEx',
      orderId: '1',
      pickupReadyTime: moment(availablePickups ? availablePickups[Number(selectedLocation?.pickupDate)].pickupDate : '').set('hours', moment(formik.values.readyTime).get('hours')).set('minutes', moment(formik.values.readyTime).get('minutes')).format('YYYY-MM-DDTHH:mm:ss'),
      closeTime: moment(formik.values.latestTimeAvailable).format('YYYY-MM-DDTHH:mm:ss'),
      remarks: formik.values.comment,
      contact: {}
    }

    dispatch(schedulePickUp(payload))
    // console.log(moment(availablePickups ? availablePickups[Number(selectedLocation?.pickupDate)].pickupDate : ''))
    // setProcessing(true)
    // setTimeout(() => history.push(paths.dropOffVerified(type, orderId)), 3000)
  }

  if (processing) return <ProcessingLoader i18n={i18n} />

  return (
    <>
      <Wrapper>
        <SubHeaderGetTested
          title={i18n.schedule_pickup_screen_title}
          onHandleBack={goBack}
        />
        <Wrapper>
          <Content onSubmit={formik.handleSubmit} noValidate>
            <FormWrapper>
              <PickupDate formik={formik} i18n={i18n} availablePickups={availablePickups ? availablePickups : []} />
              <ReadyTime formik={formik} i18n={i18n} />
              <LatestTimeAvailable formik={formik} i18n={i18n} />
              <Comment formik={formik} i18n={i18n} />
            </FormWrapper>
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.btn}
                type='submit'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {i18n.continue_button}
              </Button>
            </Box>
          </Content>
        </Wrapper>
      </Wrapper>
      <ConfirmationDialog
        title={i18n.pickup_confirmation_alert_title}
        isOpen={confirmationOpen}
        info={{
          selectedLocation: selectedLocation,
          date: moment(availablePickups ? availablePickups[Number(formik.values.pickupDate)].pickupDate : '').format('LL'),
          time: `${moment(selectedLocation?.readyTime).format('hh:mm')} - ${moment(selectedLocation?.latestTimeAvailable).format('LT')}`,
        }}
        onConfirm={handleConfirmDialog}
        onCancel={() => setConfirmationOpen(false)}
      />
    </>
  )
}

const PickupDate = (props: { formik: any, i18n: any, availablePickups: AvailablePickUp[] }) => {
  const { formik, i18n, availablePickups } = props
  const [options, setOptions] = useState([] as any)

  useEffect(() => {
    const opt = getAvailableDaysForPickUp(availablePickups || [])
    setOptions(opt)
  }, [availablePickups])

  const handleChange = (e: ChangeEvent<any>) => {
    formik.setFieldValue('pickupDate', String(e.target.value))
  }
  return (
    <Select
      value={formik.values.pickupDate}
      label={i18n.pickup_date_field_placeholder}
      onChange={handleChange}
      name='pickupDate'
      options={options}
      onBlur={formik.handleBlur}
      error={formik.errors.pickupDate && formik.touched.pickupDate}
      required
    />
  )
}

const ReadyTime = (props: { formik: any, i18n: any }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  const handleChangeTime = (e: any) => {
    const readyTime =
    formik.setFieldValue('readyTime', e)
  }

  return (
    <TimePicker
      className={childClasses.field}
      autoOk
      label={i18n.ready_time_field_placeholder}
      name='readyTime'
      onChange={handleChangeTime}
      value={formik.values.readyTime}
      error={Boolean(formik.errors.readyTime && formik.touched.readyTime)}
      helperText={formik.errors.readyTime}
      onBlur={formik.handleBlur}
      required
      disabled={!formik.values.pickupDate}
      color='primary'
    />
  )
}

const LatestTimeAvailable = (props: { formik: any, i18n: any }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()
  const handleChangeTime = (e: MaterialUiPickersDate ) => {
    formik.setFieldValue('latestTimeAvailable', e)
  }

  return (
    <TimePicker
      className={childClasses.field}
      autoOk
      label={i18n.latest_available_time_field_placeholder}
      name='latestTimeAvailable'
      onChange={handleChangeTime}
      value={formik.values.latestTimeAvailable}
      error={Boolean(formik.errors.latestTimeAvailable && formik.touched.latestTimeAvailable)}
      helperText={formik.errors.latestTimeAvailable}
      onBlur={formik.handleBlur}
      required
      disabled={!formik.values.pickupDate}
      color='primary'
    />
  )
}

const Comment = (props: { formik: any, i18n: any }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()
  const handleChangeComment = (e: any) => {
    formik.setFieldValue('comment', e.target.value)
  }

  return (
    <TextField
      className={childClasses.field}
      label={i18n.instructions_details_message}
      name='comment'
      value={formik.values.comment}
      onChange={handleChangeComment}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.comment && formik.touched.comment)}
      helperText={formik.touched.comment ? formik.errors.comment : ''}
    />
  )
}

const childStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 0px !important',
  },
  field: {
    width: '100%',
    marginBottom: '25px',
    letterSpacing: '.1em',
    fontFamily: 'Avenir Next Rounded Medium',
    fontWeight: 600,
    color: 'red',
  },
  btn: {
    'margin': '8px',
    'maxHeight': 48,
    'width': '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 15px',
  },
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Avenir Next Rounded Medium',
    color: '#7B7C7D',
    fontWeight: 600,
    backgroundColor: 'rgb(237, 237, 237)',
    width: '100%',
    height: '35px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTop: '1px solid rgba(0, 0, 0, 0.42)',
  },
}))
