import { Theme, useMediaQuery } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lsClient } from '../../../ls-client'
import {
  getNetworkSettings,
  selectNetworkSettings,
} from '../../../pages/network/model'
import { selectActiveTenant } from '../../../pages/tenant-selection/tenantSlice'
import { Header } from '../../../ui'
import { LightLogo } from '../atoms'
import { drawerOpened } from '../model'
import { ApplicationHeaderRightContent } from '../molecules'
import { ApplicationDrawer } from './application-drawer'

export const ImageBackgroundHeader = ({
  className,
}: {
  className?: string
}) => {
  const dispatch = useDispatch()
  const networkSettings = useSelector(selectNetworkSettings)
  const activeTenant = useSelector(selectActiveTenant)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(1200))
  const isSmallDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(960)
  )

  const handleDrawerOpen = () => {
    dispatch(drawerOpened())
  }

  useEffect(() => {
    if (lsClient.getUserLSByKey('tenantId') && !networkSettings) {
      dispatch(getNetworkSettings())
    }
  }, [activeTenant])

  return (
    <>
      <Header
        onMenuClick={handleDrawerOpen}
        middleContent={<LightLogo />}
        rightContent={
          <ApplicationHeaderRightContent
            light
            networkSettings={networkSettings}
            isDesktop={isDesktop}
            isSmallDesktop={isSmallDesktop}
          />
        }
        className={className ? className : undefined}
      />
      <ApplicationDrawer
        hideWhenClosed
        isDesktop={isDesktop}
        isSmallDesktop={isSmallDesktop}
      />
    </>
  )
}
