import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { lsClient } from '../../../ls-client'
import { Button } from '../../../ui'
import { paths } from '../../paths'
import { resetUploadStore } from '../model'

export const UploadSuccessfulPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)

  const goNext = () => {
    const testId = lsClient.getUserLSByKey('patientTestId')
    dispatch(resetUploadStore())
    history.push(paths.testResultDetails(testId))
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.placedText}>
          <Box className={classes.loadingContent}>
            <Box>
              <CheckCircleOutlineIcon className={classes.loadingIcon} />
            </Box>
          </Box>
          <Box className={classes.processText}>
            {i18n.scan_cassete_processed}
          </Box>
        </Box>
        <Button onClick={goNext} className={classes.loadingButton}>
          {i18n.continue_button}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: '500px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '150px',
  },
  processText: {
    fontSize: 26,
    color: '#FFF',
    margin: 10,
  },
  loadingIcon: {
    display: 'block',
    fontSize: 250,
    color: '#fff',
  },
  loadingText: {
    color: '#fff',
    fontSize: 26,
    padding: '0 30px',
  },
  loadingButton: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 48,
    fontWeight: 600,
    marginBottom: 48,
    marginLeft: 15,
    marginRight: 15,
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
}))
