import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addLinkWithinString } from '../../../features/details-helper/fields'
import { selectLanguageContent } from '../../../features/translation'
import {
  addMFAPreference,
  clearHold,
  selectLoginMFASettings,
  selectToken,
} from '../../../features/user/model/userTenantSlice'
import { MFAOptions } from '../../../features/user/types/mfa'
import { Button, RoundedCheckbox, Typography } from '../../../ui'
import { selectNetworkSettings } from '../../network/model'
import { paths } from '../../paths'
import { TermsAndConditionsPage } from '../../terms-and-conds/signupPage'

export const MFASetup = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()
  // const currentNetwork = useSelector(selectNetworkSettings)
  const dispatch = useDispatch()
  const [termsOpen, setTermsOpen] = useState(false)
  const tokens = useSelector(selectToken)
  const [selected, setSelected] = useState(MFAOptions.undefined)
  const mfaSettings = useSelector(selectLoginMFASettings)

  if (!tokens.accessToken || !tokens.idToken) history.push(paths.login())

  const handleNext = () => {
    const next = () => {
      localStorage.setItem('id_token', tokens.idToken as string)
      localStorage.setItem('access_token', tokens.accessToken as string)
      dispatch(clearHold())
      history.push(paths.app.dashboard())
    }

    if (selected === MFAOptions.SMS) {
      dispatch(addMFAPreference('0', next, undefined, tokens))
    } else if (selected === MFAOptions.TOTP) {
      history.push(paths.setupMFAType(selected))
    }
  }

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.mfaWrapper}>
        <Typography className={classes.title} variant="h4" color="primary">
          {i18n.mfa_initial_setup_title}
        </Typography>
        <Typography align="center" className={classes.body}>
          {i18n.mfa_initial_setup_instruction}
        </Typography>
        {mfaSettings?.enableGoogleAuth && (
          <div
            className={classes.mfAOption}
            onClick={() => setSelected(MFAOptions.TOTP)}
          >
            <RoundedCheckbox
              checked={selected === MFAOptions.TOTP}
              color="primary"
              onChange={() => setSelected(MFAOptions.TOTP)}
            />
            <Typography style={{ fontWeight: 500 }}>
              {i18n.use_authenticator_application}
            </Typography>
          </div>
        )}
        {mfaSettings?.enableGoogleAuth && (
          <>
            <div
              className={classes.mfAOption}
              onClick={() => setSelected(MFAOptions.SMS)}
            >
              <RoundedCheckbox
                checked={selected === MFAOptions.SMS}
                color="primary"
                onChange={() => setSelected(MFAOptions.SMS)}
              />
              <Typography style={{ fontWeight: 500 }}>
                {i18n.send_a_text}
              </Typography>
            </div>
            {selected === MFAOptions.SMS && (
              <>
                {addLinkWithinString(
                  i18n.phone_number_data_rates_warning,
                  i18n.terms_ampersand_conditions_link,
                  () => setTermsOpen(true),
                  '',
                  classes.termsLink,
                  2
                )}
              </>
            )}
          </>
        )}
        <Button
          onClick={handleNext}
          className={classes.confirmButton}
          disabled={selected === MFAOptions.undefined}
        >
          {i18n.continue_button}
        </Button>
      </div>
      <Dialog open={termsOpen} fullScreen>
        <div className={classes.dialogContainer}>
          <TermsAndConditionsPage onClose={() => setTermsOpen(false)} />
        </div>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((_theme) => ({
  pageWrapper: {
    'justifyContent': 'center',
    'marginTop': 12,
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    '@media (max-width: 767px)': {
      backgroundColor: '#fff',
    },
  },
  mfaWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    'gap': '1rem',
    'maxWidth': 520,
    'padding': '0 40px',
    'boxSizing': 'border-box',
    'width': '100%',
    'overflow': 'hidden',
    'backgroundColor': '#fff',
    '@media (max-width: 1023px)': {
      width: 503,
    },
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 8,
  },
  body: {
    fontSize: '14px',
    marginBottom: 8,
  },
  mfAOption: {
    'display': 'flex',
    'gap': 8,
    'alignItems': 'center',
    'padding': '4px 4px 4px 16px',
    'borderRadius': '8px',
    'width': '100%',
    'boxSizing': 'border-box',
    'boxShadow': '0 2px 9px 0 rgba(0,0,0,.23)',
    'backgroundColor': '#fff',
    '& span:nth-child(2)': {
      fontWeight: 600,
    },
  },
  termsLink: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  confirmButton: {
    marginTop: '24px',
  },
  dialogContainer: {
    height: '85vh',
    margin: '32px 0px',
  },
}))
