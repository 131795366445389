import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { testTypes } from '../../constants'
import { Quantity } from '../../features/shopify/shopify-ui-items'
import { selectLanguageContent } from '../../features/translation'
import {
  HTMLTextComponent,
  XLResponsiveContentWrapper,
} from '../../libs/shared-components'
import { useMUITheme } from '../../libs/useTheme'
import { currency } from '../../libs/utils'
import { Button, Loading } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import {
  getTestKitDetails,
  selectIsLoading,
  selectTestKitDetails,
} from './model'

export const OrderDetailPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const isLoading = useSelector(selectIsLoading)
  const testData = useSelector(selectTestKitDetails)
  const networkSettings = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const showPrice = true
  const isMobile = useMUITheme().isMobile
  const isTablet = useMUITheme({ breakpoint: 960 }).isMobile
  useEffect(() => {
    dispatch(getTestKitDetails(id))
  }, [])

  const goBack = () => {
    history.push(paths.selectTestOrder(type))
  }

  const goNext = () => {
    if (type === testTypes.APPOINTMENT) {
      history.push(paths.facility(testTypes.APPOINTMENT, id))
    } else if (type === testTypes.ORDER) {
      // if allowed pay for test by insurance
      if (
        !networkSettings?.labOrderCoverPatientResponsibility &&
        networkSettings?.labOrderCollectInsuranceInformation &&
        testData?.price
      ) {
        history.push(paths.app.insurance(testTypes.ORDER, id))
      } else {
        // history.push(paths.shopifyCart(testTypes.ORDER, id))
      }
    }
  }

  const handleChangeQuantity = (type: 'decrease' | 'increase') => {
    if (type === 'decrease') {
      if (quantity === 1) return
      setQuantity((prev) => prev - 1)
    } else setQuantity((prev) => prev + 1)
  }

  if (isLoading) return <Loading />

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={goBack}
      rightContentTitle={i18n['ecommerce.cart_view.navigation_title']}
      // centerContentTitle={i18n.choose_a_test_title}
      rightActionIcon={<ShoppingCartOutlinedIcon />}
      // rightContentAction={handleExit}
      color="#505358"
      border={!isMobile}
    >
      <XLResponsiveContentWrapper>
        <Box className={classes.contentWrapper}>
          <div className={classes.imageLimit}>
            <Box className={classes.imageWrapper}>
              <img
                className={classes.img}
                src={testData?.productAssetDetail.imageURL || ''}
                alt={
                  testData?.customTestName
                    ? testData.customTestName
                    : 'Step One'
                }
              />
            </Box>
          </div>
          <Box className={classes.content}>
            {testData?.customTestName && (
              <div>
                <Typography className={classes.testName}>
                  {testData.customTestName}
                </Typography>
                <Typography className={classes.altName}>
                  {testData.orderableName}
                </Typography>
              </div>
            )}
            {showPrice &&
              !networkSettings?.labOrderCoverPatientResponsibility &&
              typeof testData?.price === 'number' && (
                <Typography className={classes.testPrice}>
                  {currency(testData.price, '$')}
                </Typography>
              )}
            {/* {isTablet && (
              <>
                <Quantity
                  showTitle={true}
                  i18n={i18n}
                  quantity={quantity}
                  size="lg"
                  handleChangeQuantity={handleChangeQuantity}
                />
                <Box className={classes.lgButtonBox}>
                  <Button onClick={goNext} className={classes.confirmButton}>
                    {i18n['ecommerce.add_to_cart_button']}
                  </Button>
                </Box>
              </>
            )} */}
            {testData?.longDescription && (
              <div>
                <Typography className={classes.descriptionTitle}>
                  {i18n['ecommerce.description_header_text']}
                </Typography>
                <Typography className={classes.description}>
                  {testData.longDescription}
                </Typography>
              </div>
            )}
            {/* {!isTablet && (
              <Quantity
                showTitle={true}
                i18n={i18n}
                quantity={quantity}
                size="lg"
                handleChangeQuantity={handleChangeQuantity}
              />
            )} */}
            {testData?.productAssetDetail.body && (
              <div>
                <Typography className={classes.includedLabel}>
                  {i18n.whats_included_label}
                </Typography>
                <Box className={classes.assetBox}>
                  <Box className={classes.htmlContainer}>
                    <HTMLTextComponent
                      HTMLString={testData.productAssetDetail.body}
                    />
                  </Box>
                </Box>
              </div>
            )}
            {!isTablet && (
              <Box className={classes.lgButtonBox}>
                <Button onClick={goNext} className={classes.confirmButton}>
                  {i18n['ecommerce.add_to_cart_button']}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </XLResponsiveContentWrapper>
    </ModuleHeader>
  )
}

const SPACE_BETWEEN = 'space-between'

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    height: 0,
    padding: '50% 0px',
    width: '100%',
    maxWidth: 540,
    maxHeight: 540,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5',
    [theme.breakpoints.down(960)]: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '90vw',
      height: '40vw',
      margin: '48px auto 0px',
      padding: 0,
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      margin: 0,
      width: '100vw',
      height: '100vw',
    },
  },
  imageLimit: {
    width: '100%',
    maxWidth: 540,
    // maxHeight: 540,
    [theme.breakpoints.down(1400)]: {
      maxWidth: '35vw',
    },
    [theme.breakpoints.down(960)]: {
      margin: '0px auto',
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
  },
  dialogWrapper: {
    padding: '30px 10px',
    height: 250,
    width: 350,
    [theme.breakpoints.down(960)]: {
      height: 'unset',
      width: 'unset',
      padding: '30px 0px',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  img: {
    height: '15vw',
    maxHeight: '250px',
    [theme.breakpoints.down(960)]: {
      width: '50vw',
      maxHeight: 'unset',
      height: 'unset',
    },
    [theme.breakpoints.down(600)]: {
      objectFit: 'cover',
      width: '80%',
    },
  },
  htmlContainer: {
    width: '100%',
    overflow: 'auto',
  },
  content: {
    maxWidth: 500,
    display: 'flex',
    gap: 32,
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    [theme.breakpoints.down(960)]: {
      maxWidth: 'unset',
      width: '90%',
      margin: '0px 64px',
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: 'unset',
      width: '90%',
      margin: '0px auto',
    },
  },
  minorHeader: {
    color: '#757575',
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  includedLabel: {
    color: '#505358',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down(960)]: {
      marginTop: 10,
    },
  },
  testName: {
    fontWeight: 600,
    color: '#282D37',
    fontSize: 32,
    textAlign: 'left',
    lineHeight: '110%',
    margin: '4px 0px',
    [theme.breakpoints.down(960)]: {
      fontSize: 24,
      fontWeight: 500,
      margin: '24px 0px 0px',
    },
  },
  altName: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'left',
  },
  descriptionTitle: {
    fontWeight: 600,
    color: '#505358',
    fontSize: 12,
    textAlign: 'left',
    lineHeight: '170%',
    letterSpacing: '1.25px',
  },
  description: {
    fontWeight: 500,
    color: '#757575',
    fontSize: 16,
    textAlign: 'left',
    lineHeight: '170%',
  },
  cart: {
    [theme.breakpoints.down(600)]: {
      margin: 0,
    },
  },
  quantityWrapper: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  quantityTextWrapper: {
    borderRadius: 8,
    backgroundColor: '#F4F4F4',
  },
  quantityText: {
    padding: '0px 9px',
    fontSize: 20,
    fontWeight: 500,
    color: '#505358',
  },
  assetBox: {
    width: '100%',
    minWidth: 320,
    [theme.breakpoints.down(320)]: {
      minWidth: 'unset',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: '78px 0px',
    [theme.breakpoints.down(960)]: {
      // width: '90%',
      margin: '0px auto',
      flexDirection: 'column',
      justifyContent: SPACE_BETWEEN,
      padding: 0,
    },
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
  },
  confirmButton: {
    padding: '0px 48px',
    maxWidth: 'fit-content',
    height: '46px',

    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: '100%',
      minHeight: '46px',
    },
  },
  testPrice: {
    fontSize: '36px',
    lineHeight: '150%',
    fontWeight: 600,
    width: '100%',
    color: '#282D37',
    // marginBottom: 32,
    [theme.breakpoints.down(960)]: {
      fontSize: '24px',
    },
  },
}))
