import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { Button } from '../../ui'
import { paths } from '../paths'

export const ResetPasswordSuccessfulPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)

  return (
    <div className={classes.pageWrapper}>
      <Box className={classes.contentWrapper}>
        <Typography align="center" variant="h1">
          {i18n.password_changed_successfully}
        </Typography>
        <Box className={classes.instructions} />
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.actionBtn}
            onClick={() => history.push(paths.login())}
          >
            {i18n.go_to_sign_in}
          </Button>
        </div>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    'justifyContent': 'center',
    'margin': '32px 0px',
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    '@media (max-width: 767px)': {
      backgroundColor: '#fff',
    },
  },
  contentWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    'gap': '1rem',
    'maxWidth': 520,
    'padding': '0 40px',
    'boxSizing': 'border-box',
    'width': '100%',
    'overflow': 'hidden',
    'backgroundColor': '#fff',
    '@media (max-width: 1023px)': {
      width: 503,
    },
  },
  buttonWrapper: {
    'marginTop': 'auto',
    'marginBottom': 0,
    '@media (max-width: 1023px)': {
      textAlign: 'center',
    },
  },
  actionBtn: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: 48,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 24,
  },
  title: {
    fontSize: 36,
    color: '#505358',
    textAlign: 'center',
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  instructions: {
    margin: '40px 15px',
    color: '#54565B',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Avenir Next Rounded regular',
    lineHeight: '22px',
  },
}))
