import { getCurrentUser } from '../../../../libs/utils'
import { lsClient } from '../../../../ls-client'
import { store } from '../../../../redux'

export class UserService {
  getSelectedTenant() {
    return lsClient.getUserLSByKey('tenantId')
  }

  getUserData() {
    return {
      email: getCurrentUser(),
    }
  }

  getSettings() {
    return store.getState().network.settings
  }
}
