import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { paths } from '../paths'
import { FaqList } from './faq-list'

export const FaqPage = () => {
  const i18n = useSelector(selectLanguageContent)
  const { faqId } = useParams<{ faqId: string }>()
  const history = useHistory()
  const onBack = () => {
    if (faqId) {
      history.push(paths.faqs())
    } else history.push(paths.app.dashboard())
  }

  return (
    <ModuleHeader
      leftContentAction={onBack}
      leftContentTitle={i18n.back_button}
      color="#505358"
    >
      <FaqList />
    </ModuleHeader>
  )
}
