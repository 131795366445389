import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { addHS } from '../../features/health-service-lite/model'
import { handleError } from '../../libs/helpers'
import { history } from '../../libs/history'
import { lsClient } from '../../ls-client'
import { TelehealthStatus } from '../../types/ls'
import { paths } from '../paths'

export enum NetworkInterruption {
  MEMBER = 'member',
  PROVIDER = 'provider',
}

type SessionStatus = 'W' | 'D' | 'C' | 'R' | 'V' | ''

export interface TelehealthType {
  ASYNC: 'ASYNC'
}

export interface TelehealthSession {
  channelId: string
  channelName: string
  isEnded: boolean
  isCancelled: boolean
  status: SessionStatus
  sessionAccessToken: string
  memberAccessId: number
  providerFirstName: string
  providerLastName: string
  providerEmail: string
  rtcAppId: string
}

export enum TelehealthPages {
  WAITING_ROOM = 'waiting-room',
  SESSION = 'session',
  RATE_YOUR_EXPERIENCE = 'rate-your-experience',
  COMPLETE = 'complete',
  BE_IN_TOUCH = 'we-will-be-in-touch',
}

export enum SAFETelehealthSessionOptions {
  W = 'safe_created',
  D = 'safe_dequeued',
  V = 'safe_cancelled',
  // E = 'safe_ended',
  C = 'completed',
  R = 'safe_in_progress',
  M = 'safe_missed',
}

interface TelehealthSlice {
  data: TelehealthSession
  sessionCanStart: boolean
  sessionAccessToken: string
  audioOn: boolean
  showControls: boolean
  telehealthStatus: TelehealthStatus
  memberInSession: boolean
  loading: boolean
  sessionStartTime: string
}
const initialStore: TelehealthSlice = {
  data: {
    channelId: '',
    channelName: '',
    isEnded: false,
    isCancelled: false,
    status: '',
    sessionAccessToken: '',
    memberAccessId: 0,
    providerFirstName: '',
    providerLastName: '',
    providerEmail: '',
    rtcAppId: '',
  },
  sessionCanStart: false,
  sessionStartTime: '',
  audioOn: true,
  showControls: false,
  sessionAccessToken: '',
  telehealthStatus: null,
  memberInSession: false,
  loading: false,
}

const getStatus = (session: TelehealthSession) => {
  if (session.status === 'V') {
    return session.isCancelled ? 'V' : 'C'
  }
  return session.status
}

export const telehealthSlice = createSlice({
  name: 'telehealth',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.loading = payload
    },
    setAudio(store, { payload }: { payload: boolean }) {
      store.audioOn = payload
    },
    //This allows forcing Agora controls visible if user is muted when trying to join session
    setForceShowControls(store, { payload }: { payload: boolean }) {
      store.showControls = payload
    },
    setSessionData(store, { payload }: { payload: any }) {
      store.data = {
        ...store.data,
        ...payload,
        sessionAccessToken: payload.sessionAccessToken,
        memberAccessId: Number.parseInt(payload.memberAccessId, 10),
        status: getStatus(payload),
      }
      store.sessionAccessToken = payload.sessionAccessToken
    },
    setTelehealthStatus(store, { payload }: { payload: TelehealthStatus }) {
      store.telehealthStatus = payload
    },
    setMemberInSession(store, { payload }: { payload: boolean }) {
      store.memberInSession = payload
    },
    setSessionCanStart(store, { payload }: { payload: boolean }) {
      store.sessionCanStart = payload
    },
    setSessionStatus(store, { payload }: { payload: any }) {
      store.data.status = getStatus(payload.session ? payload.session : payload)
    },
    setSessionId(store, { payload }: { payload: string }) {
      store.data.channelId = payload
    },
    setSessionReload(store, { payload }: { payload: any }) {
      store.data.sessionAccessToken = payload.sessionAccessToken
      store.data.memberAccessId = Number.parseInt(payload.sessionUserId, 10)
      store.data.channelName = payload.sessionId
      store.data.channelId = payload.sessionId
      store.sessionAccessToken = payload.sessionAccessToken
    },
    resetTelehealthStore: () => initialStore,
  },
})

const {
  setSessionData,
  setLoading,
  setSessionStatus,
  setSessionReload,
  setSessionCanStart,
} = telehealthSlice.actions
export const {
  setSessionId,
  setTelehealthStatus,
  resetTelehealthStore,
  setAudio,
  setForceShowControls,
  setMemberInSession,
} = telehealthSlice.actions

// export const createSAFETelehealthSession =
//   (data: any, onSuccess?: () => void) => async (dispatch: any) => {
//     dispatch(setLoading(true))
//     const res = await api.createProviderTelehealthSession(data)
//     if (res?.error) {
//       dispatch(setLoading(false))
//       dispatch(handleError(res, res.error))
//     }
//     if (res.data && res.isOK) {
//       dispatch(setLoading(false))
//       dispatch(setSessionData(res.data))
//       lsClient.setUserLS('telehealth', 'Waiting')
//       lsClient.setUserLS('telehealthSessionId', res.data._id)
//       dispatch(setTelehealthStatus('Waiting'))
//       dispatch(setSessionCanStart(true))
//       if (data.healthServiceId) {
//         const hsData = {
//           id: res.data._id,
//           type: 'Telehealth Session',
//         }
//         dispatch(addHS(data.healthServiceId, hsData, 'telehealthConsults'))
//       }
//       if (onSuccess) {
//         onSuccess()
//       }
//     }
//   }

export const createSession =
  (data: any, onSuccess?: () => void) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const res = await api.createProviderTelehealthSession(data)
    if (res?.error) {
      dispatch(setLoading(false))
      dispatch(handleError(res, res.error))
    }
    if (res.data && res.isOK) {
      dispatch(setLoading(false))
      if (data.healthServiceId) {
        const hsData = {
          id: res.data.consultId || res.data.channelId,
          type: 'Telehealth Session',
        }
        dispatch(addHS(data.healthServiceId, hsData, 'telehealthConsults'))
      }
      if (res.data.telehealthVisit) {
        dispatch(setSessionData(res.data))
        lsClient.setUserLS('telehealth', 'Waiting')
        lsClient.setUserLS('telehealthSessionId', res.data._id)
        dispatch(setTelehealthStatus('Waiting'))
        dispatch(setSessionCanStart(true))
        if (onSuccess) {
          onSuccess()
        }
        // history.push(paths.app.telehealth(TelehealthPages.WAITING_ROOM))
      } else {
        history.push(paths.app.telehealth(TelehealthPages.BE_IN_TOUCH))
      }
    }
  }
// export const createSTEADYMDTelehealthSession =
//   (healthServiceId: string, onSuccess?: () => void) =>
//   async (dispatch: any) => {
//     dispatch(setLoading(true))
//     const res = await api.createSteadyMDTelehealthSession(healthServiceId)
//     if (res?.error) {
//       dispatch(setLoading(false))
//       dispatch(handleError(res.error))
//       // if (onSuccess) {
//       //   onSuccess()
//       // }
//     }
//     if (res.data && res.isOK) {
//       dispatch(setLoading(false))
//       dispatch(setSessionData(res.data))
//       lsClient.setUserLS('telehealth', 'Waiting')
//       lsClient.setUserLS('telehealthSessionId', res.data._id)
//       dispatch(setTelehealthStatus('Waiting'))
//       const hsData = {
//         id: res.data._id,
//         type: 'Telehealth Session',
//       }
//       dispatch(addHS(healthServiceId, hsData, 'telehealthConsults'))

//       if (onSuccess) {
//         onSuccess()
//       }
//     }
//   }

function mapSessionStatus(status: SessionStatus): TelehealthStatus {
  switch (status) {
    case 'W':
      return 'Waiting'
    case 'D':
      return 'ProviderReviewing'
    case 'R':
      return 'ProviderJoined'
    case 'V':
      return 'Ended'
    case 'C':
      return 'Complete'
    default:
      return 'Waiting'
  }
}

export const getTelehealthSession =
  (id: string, isRejoin?: boolean) => async (dispatch: any) => {
    dispatch(setLoading(true))

    const res = await api.getTelehealthSession(id)
    if (res?.error) {
      dispatch(setLoading(false))
      dispatch(handleError(res.error))
    }
    if (res.data && res.isOK) {
      dispatch(setLoading(false))
      dispatch(setSessionData(res.data))
      if (isRejoin) {
        dispatch(getNewToken(id))
      } else dispatch(setSessionCanStart(true))
      if (
        res.data.session?.status === 'W' ||
        res.data.session?.status === 'D' ||
        res.data.session?.status === 'R'
      ) {
        const status = mapSessionStatus(res.data.status)
        lsClient.setUserLS('telehealth', status)
        dispatch(setTelehealthStatus(status))
      }
    }
  }

export const getSessionStatus =
  (id: string, callback?: () => void) =>
  async (dispatch: any, getStore: any) => {
    let sessionId = id
    if (!id) {
      sessionId = getStore().telehealth.data.channelId
    }
    const res = await api.getTelehealthSessionStatus(sessionId)

    if (res?.error) {
      dispatch(handleError(res.error))
    }

    if (res.data && res.isOK) {
      if (res.data.status === 'V' || res.data.status === 'C') {
        if (lsClient.getUserLSByKey('ratedProvider') === 'yes') {
          dispatch(setTelehealthStatus('Complete'))
        } else dispatch(setTelehealthStatus('Ended'))
      } else if (res.data.status === 'R' && callback) {
        callback()
      }
      const status = getStore().telehealth.data.status
      if (status !== res.data.status) {
        dispatch(setSessionStatus(res.data))
      }
    }
  }

export const getLastSession = () => async (dispatch: any) => {
  dispatch(setLoading(true))
  const res = await api.getLastTelehealthSession()

  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }
  if (res.data && res.isOK) {
    dispatch(setLoading(false))
    dispatch(setSessionStatus(res.data))
    if (
      res.data.status === 'W' ||
      res.data.status === 'D' ||
      res.data.status === 'R'
    ) {
      const status = mapSessionStatus(res.data.status)
      lsClient.setUserLS('telehealth', status)
      dispatch(setTelehealthStatus(status))
    }
    if (res.data.status !== 'C' && res.data.status !== 'V') {
      dispatch(getTelehealthSession(res.data.sessionId, true))
    } else {
      // eslint-disable-next-line no-lonely-if
      if (lsClient.getUserLSByKey('ratedProvider') === 'yes') {
        dispatch(setTelehealthStatus('Complete'))
      } else dispatch(setTelehealthStatus('Ended'))
    }
  }
}

export const getNewToken = (id: string) => async (dispatch: any) => {
  dispatch(setLoading(true))

  const res = await api.regenerateTelehealthToken(id)

  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }

  if (res.data && res.isOK) {
    dispatch(setLoading(false))
    dispatch(setSessionReload(res.data))
    dispatch(setSessionCanStart(true))
  }
}

export const tracktelehealthSession = (data: any) => async (dispatch: any) => {
  const res = await api.trackTelehealthSession(data)

  if (res?.error) {
    dispatch(handleError(res.error))
  }
}

export const cancelTelehealthSession =
  (
    id: string,
    healthServiceId: string,
    isReturning: boolean,
    callback?: () => void
  ) =>
  async (dispatch: any) => {
    dispatch(setLoading(true))
    const data = {
      _id: id,
      healthServiceId,
      isReturning,
    }

    const res = await api.cancelTelehealthSession(data)
    if (res?.error) {
      dispatch(setLoading(false))
      dispatch(handleError(res.error))
    }
    if (res.isOK) {
      dispatch(setLoading(false))
      if (!isReturning) {
        dispatch(resetTelehealthStore())
        if (callback) {
          callback()
        }
      } else if (res.data) dispatch(getTelehealthSession(res.data))
    }
  }

export const endTelehealthCall = (id: string) => async (dispatch: any) => {
  dispatch(setLoading(true))
  const data = {
    _id: id,
  }

  const res = await api.endTelehealthCall(data)
  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }
  if (res.data && res.isOK) {
    dispatch(setTelehealthStatus('Ended'))
    dispatch(setLoading(false))
  }
}

export const updateDirectCall = (data: any) => async (dispatch: any) => {
  const res = await api.updateDirectCall(data)
  if (res?.error) {
    dispatch(handleError(res.error))
  }
  if (res.data && res.isOK) {
    console.log(res)
  }
}

export const telehealthReducer = telehealthSlice.reducer
export const telehealthReducerName = telehealthSlice.name

interface RootStore {
  [telehealthReducerName]: typeof initialStore
}

export const selectTelehealthSessionLoading = ({ telehealth }: RootStore) =>
  telehealth.loading

export const selectAudioState = ({ telehealth }: RootStore) =>
  telehealth.audioOn

export const selectShowControls = ({ telehealth }: RootStore) =>
  telehealth.showControls

export const selectSessionStatus = ({ telehealth }: RootStore) =>
  telehealth.data.status

export const selectSessionCanStart = ({ telehealth }: RootStore) =>
  telehealth.sessionCanStart

export const selectSessionStartTime = ({ telehealth }: RootStore) =>
  telehealth.sessionStartTime

export const selectTelehealthSessionData = ({ telehealth }: RootStore) =>
  telehealth.data

export const selectTelehealthSessionToken = ({ telehealth }: RootStore) =>
  telehealth.sessionAccessToken

export const selectTelehealthStatus = ({ telehealth }: RootStore) =>
  telehealth.telehealthStatus

export const selectSessionId = ({ telehealth }: RootStore) =>
  telehealth.data.channelId

export const selectMemberInSession = ({ telehealth }: RootStore) =>
  telehealth.memberInSession
