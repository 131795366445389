import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { onboardingStyles } from '../../../pages/_styles/registrationStyles'
import { Address } from '../../../types/user'
import { Button } from '../../atoms/button/button'
import { AddressCard } from '../../molecules/address-card/address-card'

export interface VerifyAddressProps {
  pageTitle?: string
  pageInstructions?: string
  enteredAddress: Address
  enteredAddressLabel: string
  foundAddress: Address
  foundAddressLabel: string
  handleSubmit: (address: Address) => void
  buttonLabel: string
}

export const VerifyAddress = (props: VerifyAddressProps) => {
  const {
    pageTitle,
    pageInstructions,
    enteredAddress,
    enteredAddressLabel,
    foundAddress,
    foundAddressLabel,
    handleSubmit,
    buttonLabel,
  } = props
  const classes = onboardingStyles()
  const [selected, setSelected] = useState(enteredAddress)

  const handleConfirm = () => handleSubmit(selected)

  return (
    <>
      <div className={classes.content}>
        {pageTitle && (
          <Typography className={classes.title} color="primary" variant="h1">
            {pageTitle}
          </Typography>
        )}
        {pageInstructions && (
          <Typography className={classes.body} variant="body1" align="left">
            {pageInstructions}
          </Typography>
        )}
        <AddressCard
          label={enteredAddressLabel}
          selected={enteredAddress === selected}
          setSelected={() => setSelected(enteredAddress)}
          address={enteredAddress}
        />
        <AddressCard
          label={foundAddressLabel}
          selected={foundAddress === selected}
          setSelected={() => setSelected(foundAddress)}
          address={foundAddress}
        />
        <div className={classes.buttonWrapper}>
          <Button className={classes.actionBtn} onClick={handleConfirm}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </>
  )
}
