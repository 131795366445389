/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import styled from '@emotion/styled'
import { Box, TextField } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  allowedGenders,
  ethnicities,
  races,
  USAStateList,
} from '../../constants'
import { SubHeaderGetTested } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { translateOptions } from '../../libs/helpers'
import { Button, Select, Typography } from '../../ui'
import {
  Content,
  Controls,
  FormWrapper,
  useStyles,
  Wrapper,
} from '../../ui/templates/app-layout'
import { selectNetworkSettings } from '../network/model'
import { selectProfileData, updateUserData } from '../profile/model'
import { FormValues } from './types'
import validationSchema from './validationSchema'

const InputMask = require('react-input-mask')

export const MemberAccountEditProfilePage = () => {
  const childClasses = childStyles()
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const historyState = history.location.state as { prevSection?: string }
  const memberData = useSelector(selectProfileData)
  const i18n = useSelector(selectLanguageContent)
  const networkSettings = useSelector(selectNetworkSettings)
  const homeAddress = memberData?.primaryAddress

  const [formData, setFormData] = useState<FormValues>({
    firstName: '',
    lastName: '',
    gender: '',
    birthDate: new Date(),
    ethnicity: '',
    race: '',
    email: '',
    line1: '',
    line2: '',
    city: '',
    state: USAStateList[0].value,
    country: 'US',
    zip: '',
    mobileNumber: '',
    isGuardianMode: false,
  })

  const dispatch = useDispatch()
  useEffect(() => {
    if (memberData) {
      setFormData({
        firstName: memberData?.firstName || '',
        lastName: memberData?.lastName || '',
        gender: memberData?.gender || '',
        birthDate: memberData?.birthDate
          ? new Date(memberData.birthDate)
          : new Date(),
        ethnicity: memberData?.ethnicity || '',
        race: memberData?.race || '',
        email: memberData?.email || '',
        line1: homeAddress?.line1 || '',
        line2: homeAddress?.line2 || '',
        city: homeAddress?.city || '',
        state: homeAddress?.state || USAStateList[0].value,
        country: homeAddress?.country || 'US',
        zip: homeAddress?.zip || '',
        mobileNumber: memberData?.contact?.mobileNumber || '',
        isGuardianMode: memberData?.isGuardianMode || false,
      })
    }
  }, [memberData])

  const onSubmit = async (values: FormValues) => {
    const mobileNumber = values.mobileNumber.replace(/\+1\s/, '')
    const updatedUser = {
      ...memberData,
      contact: { ...memberData.contact, mobileNumber },
      primaryAddress: {
        line1: values?.line1,
        line2: values?.line2,
        city: values?.city,
        state: values?.state,
        country: values?.country,
        zip: values?.zip,
      },
      ...values,
    }

    await dispatch(updateUserData(updatedUser))
  }

  const formik = useFormik({
    initialValues: {
      collectDateOfBirthRequired: networkSettings?.collectDateOfBirthRequired,
      ehtnicityRequired: networkSettings?.ehtnicityRequired,
      collectSexRequired: networkSettings?.collectSexRequired,
      collectAddressRequired: networkSettings?.collectAddressRequired,
      raceRequired: networkSettings?.collectDateOfBirthRequired,
      isGuardianMode: formData.isGuardianMode,
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender: formData.gender,
      birthDate: formData.birthDate,
      ethnicity: formData.ethnicity,
      race: formData.race,
      email: formData.email,
      line1: formData.line1,
      line2: formData.line2,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      zip: formData.zip,
      mobileNumber: formData.mobileNumber,
    },
    enableReinitialize: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Wrapper>
      <SubHeaderGetTested
        title={i18n.edit_profile_screen_title}
        onHandleBack={handleBack}
        fullWidth={matches && historyState?.prevSection !== 'checkout'}
      />
      <Content
        onSubmit={formik.handleSubmit}
        noValidate
        className={childClasses.content}
      >
        <FormWrapper>
          <FormHeader title={i18n.personal_information_profile_section} />
          <Box className={childClasses.formWrapper}>
            <NameWrapper>
              <FirstName formik={formik} i18n={i18n} />
              <LastName formik={formik} i18n={i18n} />
            </NameWrapper>
            <Email formik={formik} i18n={i18n} />
            <BirthDate
              formik={formik}
              i18n={i18n}
              networkSettings={networkSettings}
            />
            <Gender
              formik={formik}
              i18n={i18n}
              networkSettings={networkSettings}
            />
            <Race
              formik={formik}
              i18n={i18n}
              networkSettings={networkSettings}
            />
            <Ethnicity
              formik={formik}
              i18n={i18n}
              networkSettings={networkSettings}
            />
            <PhoneNumber formik={formik} i18n={i18n} />
          </Box>
          {networkSettings?.collectAddress && (
            <>
              <FormHeader title={i18n.home_address_profile_section} />
              <Box className={childClasses.formWrapper}>
                <StreetAddress formik={formik} i18n={i18n} />
                <Apt formik={formik} i18n={i18n} />
                <City formik={formik} i18n={i18n} />
                <State formik={formik} i18n={i18n} />
                <Country formik={formik} i18n={i18n} />
                <Zip formik={formik} i18n={i18n} />
              </Box>
            </>
          )}
        </FormWrapper>
        <Controls>
          <Button
            className={classes.button}
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {i18n.save_button}
          </Button>
        </Controls>
      </Content>
    </Wrapper>
  )
}
const FormHeader = (props: { title: string }) => {
  const childClasses = childStyles()
  const { title } = props
  return (
    <Box className={childClasses.formHeader}>
      <div style={{ padding: '0 15px' }}>
        <Typography className={childClasses.formHeader}>{title}</Typography>
      </div>
    </Box>
  )
}

const FirstName = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.first_name}
      name="firstName"
      value={formik.values.firstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.firstName && formik.touched.firstName)}
      helperText={formik.touched.firstName ? formik.errors.firstName : ''}
      required
    />
  )
}

const LastName = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.last_name}
      name="lastName"
      value={formik.values.lastName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.lastName && formik.touched.lastName)}
      helperText={formik.touched.lastName ? formik.errors.lastName : ''}
      required
    />
  )
}

const Gender = (props: { formik: any; i18n: I18n; networkSettings: any }) => {
  const { formik, i18n, networkSettings } = props

  if (!networkSettings?.collectSex) return null

  return (
    <Select
      label={i18n.sex}
      name="gender"
      value={formik.values.gender}
      options={translateOptions(allowedGenders, i18n)}
      onChange={formik.handleChange}
      error={formik.errors.gender && formik.touched.gender}
      onBlur={formik.handleBlur}
      required
    />
  )
}
const BirthDate = (props: { formik: any; i18n: I18n; networkSettings: any }) => {
  const { formik, i18n, networkSettings } = props
  const childClasses = childStyles()
  const handleChangeDate = (e: MaterialUiPickersDate) => {
    formik.setFieldValue('birthDate', e)
  }

  if (!networkSettings?.collectDateOfBirth) return null

  return (
    <KeyboardDatePicker
      className={childClasses.field}
      label={i18n.date_of_birth}
      name="birthDate"
      onChange={handleChangeDate}
      format="MM/dd/yyyy"
      value={formik.values.birthDate}
      error={Boolean(formik.errors.birthDate && formik.touched.birthDate)}
      helperText={formik.touched.birthDate ? formik.errors.birthDate : ''}
      onBlur={formik.handleBlur}
      required
      color="primary"
    />
  )
}

const Race = (props: { formik: any; i18n: I18n; networkSettings: any }) => {
  const { formik, i18n, networkSettings } = props

  if (!networkSettings?.collectRace) return null

  return (
    <Select
      options={translateOptions(races, i18n)}
      label={i18n.race}
      value={formik.values.race}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      name="race"
      required
    />
  )
}

const Ethnicity = (props: { formik: any; i18n: I18n; networkSettings: any }) => {
  const { formik, i18n, networkSettings } = props

  if (!networkSettings?.collectEthicity) return null

  return (
    <Select
      options={translateOptions(ethnicities, i18n)}
      label={i18n.ethnicity}
      value={formik.values.ethnicity}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      name="ethnicity"
      required
    />
  )
}

const Email = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.email}
      name="email"
      type="email"
      value={formik.values.email}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.email && formik.touched.email)}
      helperText={formik.touched.email ? formik.errors.email : ''}
      required
    />
  )
}
const StreetAddress = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.street_address}
      id="line1"
      name="line1"
      value={formik.values.line1}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.line1 && formik.touched.line1)}
      helperText={formik.touched.line1 ? formik.errors.line1 : ''}
      required
    />
  )
}
const Apt = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.apt_and_ste_short}
      id="line2"
      name="line2"
      value={formik.values.line2}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.line2 && formik.touched.line2)}
      helperText={formik.touched.line2 ? formik.errors.line2 : ''}
    />
  )
}
const City = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.city}
      id="city"
      name="city"
      value={formik.values.city}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.city && formik.touched.city)}
      helperText={formik.touched.city ? formik.errors.city : ''}
      required
    />
  )
}
const State = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  return (
    <Select
      options={USAStateList}
      label={i18n.state}
      value={formik.values.state}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      name="state"
      required
    />
  )
}
const Country = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const options = [
    { label: 'United States', value: 'US' },
    { label: 'Spain', value: 'ES' },
  ]
  return (
    <Select
      options={options}
      label={i18n.country}
      onBlur={formik.handleBlur}
      value={formik.values.country}
      onChange={formik.handleChange}
      name="country"
      required
    />
  )
}
const Zip = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <TextField
      className={childClasses.field}
      label={i18n.zip_code}
      id="zip"
      name="zip"
      type="number"
      value={formik.values.zip}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.zip && formik.touched.zip)}
      helperText={formik.touched.zip ? formik.errors.zip : ''}
      required
    />
  )
}
const PhoneNumber = (props: { formik: any; i18n: I18n }) => {
  const { formik, i18n } = props
  const childClasses = childStyles()

  return (
    <InputMask
      mask="+1 (999) 999-9999"
      maskChar=""
      // alwaysShowMask={true}
      value={formik.values.mobileNumber}
      onChange={formik.handleChange}
      label={i18n.phone_number}
      name="mobileNumber"
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.mobileNumber && formik.touched.mobileNumber)}
      helperText={formik.touched.mobileNumber ? formik.errors.mobileNumber : ''}
      required
    >
      {(inputProps: any) => (
        <TextField className={childClasses.field} {...inputProps} />
      )}
    </InputMask>
  )
}

const childStyles = makeStyles((_theme) => ({
  content: {
    padding: '0px 0px !important',
  },
  field: {
    width: '100%',
    marginBottom: '25px',
    letterSpacing: '.1em',
    fontFamily: 'Avenir Next Rounded Medium',
    fontWeight: 600,
    color: 'red',
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 15px',
  },
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#6d6d6d',
    fontWeight: 600,
    backgroundColor: 'rgb(237, 237, 237)',
    width: '100%',
    height: '45px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTop: '1px solid rgba(0, 0, 0, 0.42)',
  },
}))

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`
