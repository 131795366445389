import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Dialog, Typography } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import RoomIcon from '@material-ui/icons/Room'
import { useSelector } from 'react-redux'
import { Button } from '../../ui'
import { Controls, useStyles } from '../../ui/templates/app-layout'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import { selectLanguageContent } from '../../features/translation'

export const ConfirmationDialog = (props: {
  isOpen: boolean
  info: {
    date: string
    time: string
    selectedLocation: any
  }
  title?: string
  isLoading?: boolean
  onConfirm?: () => void
  onCancel: () => void
  confirmationDisabled?: boolean
}) => {
  const {
    isOpen,
    info,
    isLoading,
    onConfirm,
    onCancel,
    title = '',
    confirmationDisabled = false,
  } = props
  const i18n = useSelector(selectLanguageContent)

  const { date, time, selectedLocation } = info
  const classes = useStyles()
  const childClasses = childStyles()

  if (!selectedLocation) return null

  return (
    <Dialog open={isOpen}>
      <Box className={childClasses.schedulePageHolder}>
        {isLoading ? <ContentLoaderBox /> : null}
        <Box className={classes.scheduleWrapper}>
          <Box className={childClasses.title}>{title}</Box>
          <Box className={classes.appointmentOptionsWrapper}>
            <Box className={childClasses.optionLeftText}>
              <InsertInvitationIcon />
              <Box className={childClasses.boxSubtitle}>{date}</Box>
            </Box>
          </Box>
          <Box className={classes.appointmentOptionsWrapper}>
            <Box className={childClasses.optionLeftText}>
              <AccessTimeIcon />
              <Box className={childClasses.boxSubtitle}>{time}</Box>
            </Box>
          </Box>
          <Box className={classes.appointmentOptionsWrapper}>
            <Box className={childClasses.optionLeftText}>
              <RoomIcon />
              <Box className={childClasses.boxSubtitle}>
                <Typography>{selectedLocation.name}</Typography>
                <Typography>{selectedLocation.address1}</Typography>
                <Typography>
                  {`${selectedLocation.city}, ${
                    selectedLocation.state
                  } ${String(selectedLocation.zip).slice(0, 5)}`}
                </Typography>
                {selectedLocation?.comment && (
                  <>
                    <br />
                    <Typography>
                      {selectedLocation?.comment}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Controls>
            <Button
              onClick={onConfirm}
              className={classes.button}
              disabled={!time || confirmationDisabled}
            >
              {i18n.continue_button}
            </Button>
            <Button
              onClick={onCancel}
              className={classes.button}
              disabled={!time}
            >
              {i18n.cancel_button}
            </Button>
          </Controls>
        </Box>
      </Box>
    </Dialog>
  )
}

const childStyles = makeStyles((theme) => ({
  schedulePageHolder: {
    backgroundColor: '#F7F7F7',
    width: '100%',
  },
  title: {
    width: 327,
    fontSize: 24,
    color: '#505358',
    margin: '30px 0',
    [theme.breakpoints.down(400)]: {
      width: 'auto',
    },
  },
  boxSubtitle: {
    marginLeft: '10px',
    letterSpacing: 0.5,
    fontSize: 14,
    color: '#7B7C7D',
    alignSelf: 'center',
  },
  optionLeftText: {
    display: 'flex',
    textAlign: 'left',
    color: '#7B7C7D',
  },
}))
