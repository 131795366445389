import { I18n } from '../../../features/translation/types'
import { paths } from '../../paths'
import * as Cells from './testCells'

export interface TestDetailFields {
  id: string
  type: string
  translationName: keyof I18n
  plainTextName: string
  startIcon: () => void
  endIcon: () => void
  linkPath: any
  linkIsDetailView: boolean
  valueIsKey: boolean
  border: boolean
  themeColor: string
  fields: TestDetailFields[]
  divider: boolean
  enable: string
}

export const transformTestData = (data: any) => ({
  ...data,
  testName:
    data.details?.orderableTest?.customName ||
    data.details?.orderableTest?.orderableName ||
    data.details?.orderablePanel.shortName,
  testImage:
    data.details?.orderableTest?.productAssetDetail?.imageURL ||
    data.details?.orderablePanel.productAssetDetail.imageURL,
  labVendor: data.details?.labVendor?.name,
  appointmentTime: data.details?.appointment?.appointmentStart,
  location: data.details?.appointment?.locationName,
  facilityAddress: {
    homeAddressAddressOne: data.details?.facility?.homeAddressAddressOne,
    homeAddressAddressTwo: data.details?.facility?.homeAddressAddressTwo,
    homeAddressAddressCity: data.details?.facility?.homeAddressAddressCity,
    homeAddressState: data.details?.facility?.homeAddressState,
    homeAddressZip: data.details?.facility?.homeAddressZip,
  },
  shippingCarrier: data.details?.shippingCarrier,
  isCheckPrescriptionEligibilityAllowed:
    data.testResultSummary?.isCheckPrescriptionEligibilityAllowed,
  isFindTestRetailerAllowed: data.testResultSummary?.isFindTestRetailerAllowed,
  isIHaveTestAllowed: data.testResultSummary?.isIHaveTestAllowed,
  isOrderTestAllowed: data.testResultSummary?.isOrderTestAllowed,
  isReturnToDashboardAllowed:
    data.testResultSummary?.isReturnToDashboardAllowed,
  isStartTelehealthConsultAllowed:
    data.testResultSummary?.isStartTelehealthConsultAllowed || true,
  resultLongDescription: data.testResultSummary?.resultLongDescription,
  treatmentPlanDescription: data.testResultSummary?.treatmentPlanDescription,
  resultTitle: data.testResultSummary?.resultTitle,
  patientTestResult: data.testResultSummary?.patientTestResult,
  invalidResultSummary: data.details?.orderablePanel?.invalidResultSummary,
})

export const patientTestResultButtonOptions = {
  type: 'groupNoPaper',
  fields: [
    {
      id: 'dashboardButton',
      type: 'button',
      plainTextName: 'Return To Dashboard',
      enable: 'isReturnToDashboardAllowed',
      linkPath: paths.app.dashboard(),
    },
    {
      id: 'prescriptionButton',
      type: 'button',
      plainTextName: 'Check Prescription Eligibility',
      enable: 'isCheckPrescriptionEligibilityAllowed',
      linkPath: paths.symptomCheckerIntroById(
        '7da905eb-d237-40c8-95e2-bd6579470780'
      ),
    },
    {
      id: 'telehealthButton',
      type: 'button',
      plainTextName: 'Start Telehealth Consult',
      enable: 'isStartTelehealthConsultAllowed',
      linkPath: paths.telehealthIntro(),
    },
    {
      id: 'haveTestButton',
      type: 'button',
      plainTextName: 'I Have A Test',
      enable: 'isIHaveTestAllowed',
      linkPath: paths.scanIntroduction(),
    },
    {
      id: 'orderTestButton',
      type: 'button',
      plainTextName: 'Order A Test',
      enable: 'isOrderTestAllowed',
      linkPath: '/app/dashboard',
    },
    {
      id: 'findRetailerButton',
      type: 'button',
      plainTextName: 'Find A Test Retailer',
      enable: 'isFindTestRetailerAllowed',
      linkPath: '/app/dashboard',
    },
  ],
} as any

export const multiPathogenResultFields = {
  POSITIVE: {
    fields: [
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'resultLongDescription',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'treatmentPlanDescription',
            type: 'summary',
            plainTextName: 'Treatment Plan',
          },
        ],
      },
    ],
  },
  NEGATIVE: {
    fields: [
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'resultLongDescription',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'treatmentPlanDescription',
            type: 'summary',
            plainTextName: 'Treatment Plan',
          },
        ],
      },
    ],
  },
  INVALID: {
    fields: [
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'invalidResultSummary',
            type: 'titleAndDescription',
            translationName: 'result_summary',
          },
        ],
      },
    ],
  },
  INSTRUCTIONSDESKTOP: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testImage',
            type: 'testImage',
            translationName: 'test_cassette',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'disposalInstruction',
            type: 'titleAndDescription',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'splitGroup',
        fields: [
          {
            id: 'link',
            type: 'link',
            translationName: 'resources',
            linkPath: '/resources',
            startIcon: Cells.InfoCell,
            endIcon: Cells.ChevronRight,
          },
          {
            id: 'link',
            type: 'pdf',
            linkPath: '/app/download-lab-report',
            linkIsDetailView: true,
            translationName: 'download_lab_order',
            startIcon: Cells.PDFCell,
            endIcon: Cells.ChevronRight,
          },
        ],
      },
    ],
  },
  INSTRUCTIONSMOBILE: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testImage',
            type: 'testImage',
            translationName: 'test_cassette',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'disposalInstruction',
            type: 'titleAndDescription',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'link',
            type: 'link',
            translationName: 'resources',
            linkPath: '/resources',
            startIcon: Cells.InfoCell,
            endIcon: Cells.ChevronRight,
          },
          {
            id: 'link',
            type: 'pdf',
            linkPath: '/app/download-lab-report',
            linkIsDetailView: true,
            translationName: 'download_lab_order',
            startIcon: Cells.PDFCell,
            endIcon: Cells.ChevronRight,
          },
        ],
      },
    ],
  },
} as any

export const collectionResultFields = {
  POSITIVE: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'patientTestResult',
            type: 'result',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'testdetails',
            type: 'test',
            translationName: 'test_name',
            divider: false,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'resultLongDescription',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'treatmentPlanDescription',
            type: 'summary',
            plainTextName: 'Treatment Plan',
          },
        ],
      },
    ],
  },
  NEGATIVE: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'patientTestResult',
            type: 'result',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'testdetails',
            type: 'test',
            translationName: 'test_name',
            divider: false,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'resultLongDescription',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'treatmentPlanDescription',
            type: 'summary',
            plainTextName: 'Treatment Plan',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'dashboardButton',
            type: 'button',
            plainTextName: 'Return To Dashboard',
            enable: 'isReturnToDashboardAllowed',
            linkPath: paths.app.dashboard(),
          },
          {
            id: 'prescriptionButton',
            type: 'button',
            plainTextName: 'Check Prescription Eligibility',
            enable: 'isCheckPrescriptionEligibilityAllowed',
            linkPath: paths.symptomCheckerIntroById(
              '7da905eb-d237-40c8-95e2-bd6579470780'
            ),
          },
          {
            id: 'telehealthButton',
            type: 'button',
            plainTextName: 'Start Telehealth Consult',
            enable: 'isStartTelehealthConsultAllowed',
            linkPath: paths.telehealthIntro(),
          },
          {
            id: 'haveTestButton',
            type: 'button',
            plainTextName: 'I Have A Test',
            enable: 'isIHaveTestAllowed',
            linkPath: paths.scanIntroduction(),
          },
          {
            id: 'orderTestButton',
            type: 'button',
            plainTextName: 'Order A Test',
            enable: 'isOrderTestAllowed',
            linkPath: '/app/dashboard',
          },
          {
            id: 'findRetailerButton',
            type: 'button',
            plainTextName: 'Find A Test Retailer',
            enable: 'isFindTestRetailerAllowed',
            linkPath: '/app/dashboard',
          },
        ],
      },
    ],
  },
  INVALID: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'result',
            type: 'result',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'testdetails',
            type: 'test',
            translationName: 'test_name',
            divider: false,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'resultLongDescription',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'dashboardButton',
            type: 'button',
            plainTextName: 'Return To Dashboard',
            enable: 'isReturnToDashboardAllowed',
            linkPath: paths.app.dashboard(),
          },
          {
            id: 'prescriptionButton',
            type: 'button',
            plainTextName: 'Check Prescription Eligibility',
            enable: 'isCheckPrescriptionEligibilityAllowed',
            linkPath: paths.symptomCheckerIntroById(
              '7da905eb-d237-40c8-95e2-bd6579470780'
            ),
          },
          {
            id: 'telehealthButton',
            type: 'button',
            plainTextName: 'Start Telehealth Consult',
            enable: 'isStartTelehealthConsultAllowed',
            linkPath: paths.telehealthIntro(),
          },
          {
            id: 'haveTestButton',
            type: 'button',
            plainTextName: 'I Have A Test',
            enable: 'isIHaveTestAllowed',
            linkPath: paths.scanIntroduction(),
          },
          {
            id: 'orderTestButton',
            type: 'button',
            plainTextName: 'Order A Test',
            enable: 'isOrderTestAllowed',
            linkPath: '/app/dashboard',
          },
          {
            id: 'findRetailerButton',
            type: 'button',
            plainTextName: 'Find A Test Retailer',
            enable: 'isFindTestRetailerAllowed',
            linkPath: '/app/dashboard',
          },
        ],
      },
    ],
  },
  ERROR: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'result',
            type: 'result',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'testdetails',
            type: 'test',
            translationName: 'test_name',
            divider: false,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'resultLongDescription',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'dashboardButton',
            type: 'button',
            plainTextName: 'Return To Dashboard',
            enable: 'isReturnToDashboardAllowed',
            linkPath: paths.app.dashboard(),
          },
          {
            id: 'prescriptionButton',
            type: 'button',
            plainTextName: 'Check Prescription Eligibility',
            enable: 'isCheckPrescriptionEligibilityAllowed',
            linkPath: paths.symptomCheckerIntroById(
              '7da905eb-d237-40c8-95e2-bd6579470780'
            ),
          },
          {
            id: 'telehealthButton',
            type: 'button',
            plainTextName: 'Start Telehealth Consult',
            enable: 'isStartTelehealthConsultAllowed',
            linkPath: paths.telehealthIntro(),
          },
          {
            id: 'haveTestButton',
            type: 'button',
            plainTextName: 'I Have A Test',
            enable: 'isIHaveTestAllowed',
            linkPath: paths.scanIntroduction(),
          },
          {
            id: 'orderTestButton',
            type: 'button',
            plainTextName: 'Order A Test',
            enable: 'isOrderTestAllowed',
            linkPath: '/app/dashboard',
          },
          {
            id: 'findRetailerButton',
            type: 'button',
            plainTextName: 'Find A Test Retailer',
            enable: 'isFindTestRetailerAllowed',
            linkPath: '/app/dashboard',
          },
        ],
      },
    ],
  },
} as any

export const testDetailFields = {
  COMPLETED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'credential',
            type: 'credential',
            translationName: 'credential',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'result',
            type: 'result',
            translationName: 'result',
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'testdetails',
            type: 'test',
            translationName: 'test_name',
            divider: false,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'collectionDate',
            type: 'dateAndTime',
            translationName: 'collection_time',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
          },
        ],
      },
      {
        type: 'group',
        fields: [
          {
            id: 'summary',
            type: 'summary',
            translationName: 'result_summary',
          },
        ],
      },
      {
        type: 'groupNoPaper',
        fields: [
          {
            id: 'link',
            type: 'link',
            translationName: 'resources',
            linkPath: '/resources',
            startIcon: Cells.InfoCell,
            endIcon: Cells.ChevronRight,
          },
          {
            id: 'link',
            type: 'link',
            linkPath: '/app/download-lab-report',
            linkIsDetailView: true,
            translationName: 'download_lab_order',
            startIcon: Cells.PDFCell,
            endIcon: Cells.ChevronRight,
          },
        ],
      },
    ],
  },
  SCAN_COLLECTED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'order_manufacturer',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'collectionDate',
            type: 'dateAndTime',
            translationName: 'collection_time',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
          },
        ],
      },
    ],
  },
  APPOINTMENT_SCHEDULED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'order_manufacturer',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
            divider: true,
          },
          {
            id: 'appointmentTime',
            type: 'dateAndTime',
            translationName: 'appointment_time',
            divider: true,
          },
          {
            id: 'location',
            type: 'text',
            translationName: 'basic_info_location',
            divider: true,
          },
          {
            id: 'facilityAddress',
            type: 'address',
            translationName: 'address',
            divider: true,
          },
          {
            id: 'link',
            type: 'link',
            translationName: 'reschedule_appointment',
            startIcon: Cells.CalendarCell,
            endIcon: Cells.ChevronRight,
          },
        ],
      },
    ],
  },
  // TESTKIT_PICKUP_SCHEDULED:  {
  //     fields: [
  //         {
  //             type: 'group',
  //             fields: [
  //                 {
  //                     id: 'testName',
  //                     type: 'text',
  //                     translationName: 'test_name',
  //                     divider: true
  //                 },
  //                 {
  //                     id: 'labVendor',
  //                     type: 'text',
  //                     translationName: 'reference_lab',
  //                     divider: true
  //                 },
  //                 {
  //                     id: 'created',
  //                     type: 'dateAndTime',
  //                     translationName: 'order_date',
  //                     divider: true
  //                 },
  //                 {
  //                     id: 'shippingCarrier',
  //                     type: 'text',
  //                     translationName: 'shipping_carrier',
  //                     divider: true
  //                 },
  //                 {
  //                     id: 'status',
  //                     type: 'text',
  //                     translationName: 'status',
  //                     valueIsKey: true,
  //                     divider: true
  //                 },
  //                 {
  //                     id: 'facilityAddress',
  //                     type: 'address',
  //                     translationName: 'drop_off_address',
  //                     divider: true
  //                 },
  //             ]
  //         },
  //     ]
  // },
  TESTKIT_DROPOFF_CONFIRMED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'reference_lab',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'shippingCarrier',
            type: 'text',
            translationName: 'shipping_carrier',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
            divider: true,
          },
          {
            id: 'facilityAddress',
            type: 'address',
            translationName: 'address',
            divider: true,
          },
        ],
      },
    ],
  },
  CANCELLED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'order_manufacturer',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
            border: true,
            themeColor: 'warning',
            divider: true,
          },
          {
            id: 'appointmentTime',
            type: 'dateAndTime',
            translationName: 'appointment_time',
            divider: true,
          },
          {
            id: 'location',
            type: 'text',
            translationName: 'basic_info_location',
            divider: true,
          },
          {
            id: 'facilityAddress',
            type: 'address',
            translationName: 'address',
            divider: true,
          },
          // {
          //     id: 'link',
          //     type: 'link',
          //     translationName: 'reschedule_appointment',
          //     startIcon: Cells.CalendarCell,
          //     endIcon: Cells.ChevronRight,
          // },
        ],
      },
    ],
  },
  TESTKIT_ORDER_PLACED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'reference_lab',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'shippingCarrier',
            type: 'text',
            translationName: 'shipping_carrier',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
            divider: true,
          },
          {
            id: 'link',
            type: 'link',
            translationName: 'register_kit',
            linkPath: paths.scanIntroduction(),
            startIcon: Cells.CameraCell,
            endIcon: Cells.ChevronRight,
          },
        ],
      },
    ],
  },
  TESTKIT_PAIRED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'order_manufacturer',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            valueIsKey: true,
          },
        ],
      },
    ],
  },
  INITIALIZED: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'order_manufacturer',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            // valueIsKey: true
          },
        ],
      },
    ],
  },
  todo: {
    fields: [
      {
        type: 'group',
        fields: [
          {
            id: 'testName',
            type: 'text',
            translationName: 'test_name',
            divider: true,
          },
          {
            id: 'labVendor',
            type: 'text',
            translationName: 'order_manufacturer',
            divider: true,
          },
          {
            id: 'created',
            type: 'dateAndTime',
            translationName: 'order_date',
            divider: true,
          },
          {
            id: 'status',
            type: 'text',
            translationName: 'status',
            divider: true,
          },
          {
            id: 'link',
            type: 'link',
            translationName: 'download_lab_order',
            startIcon: Cells.PDFCell,
            endIcon: Cells.ChevronRight,
          },
        ],
      },
    ],
  },
} as any
