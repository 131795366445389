import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { capitalizeString } from '../../libs/utils'
import { lsClient } from '../../ls-client'
import { PatientTest } from '../../types/patientTest'
import { Button, Loading, Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import {
  collectionResultFields,
  multiPathogenResultFields,
  patientTestResultButtonOptions,
  TestDetailFields,
  transformTestData,
} from './helpers/testDetailsMapper'
import {
  AddressField,
  ButtonField,
  CredentialHeaderField,
  DateField,
  Divider,
  LinkField,
  PDFField,
  ResultField,
  ResultIcon,
  SummaryField,
  TestField,
  TestImageField,
  TextField,
  TitleAndDescField,
} from './helpers/testFields'
import {
  getOrderDetails,
  getOrderStatus,
  selectOrderDetails,
  selectTestDetailsLoading,
} from './model'

export const TestDetailsPage = () => {
  const { resultId } = useParams<{ resultId: string }>()
  const dispatch = useDispatch()
  const childClasses = childStyles()
  const history = useHistory()
  const latestDetails = useSelector(selectOrderDetails)
  const i18n = useSelector(selectLanguageContent)
  const isLoading = useSelector(selectTestDetailsLoading)
  const patientTestId = lsClient.getUserLSByKey('patientTestId')
  const [testDetails, setTestDetails] = useState<any>({})
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  const isCollectionOutcome = resultId === patientTestId

  useEffect(() => {
    if (latestDetails) {
      setTestDetails(transformTestData(latestDetails))
    }
  }, [latestDetails])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (resultId === 'latest') {
      dispatch(getOrderStatus())
    } else dispatch(getOrderDetails(resultId))
  }, [])

  if (isLoading) return <Loading size={100} />

  const handleBack = () => history.goBack()

  const displayFields = (fields: TestDetailFields[], test: PatientTest) => {
    const steps = [] as any
    fields.forEach((field: TestDetailFields, i: number) => {
      if (field.type === 'text') {
        steps.push(
          <TextField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'test') {
        steps.push(<TestField key={`${i}-${field.type}`} test={testDetails} />)
      } else if (field.type === 'dateAndTime') {
        steps.push(
          <DateField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'result') {
        steps.push(
          <ResultField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'address') {
        steps.push(
          <AddressField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'link') {
        steps.push(
          <LinkField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'button') {
        steps.push(
          <ButtonField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'credential') {
        steps.push(
          <CredentialHeaderField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'titleAndDescription') {
        steps.push(
          <TitleAndDescField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'pdf') {
        steps.push(
          <PDFField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'testImage') {
        steps.push(
          <TestImageField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
            isMobile={isMobile}
          />
        )
      } else if (field.type === 'summary') {
        steps.push(
          <SummaryField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'group') {
        steps.push(
          <Box
            className={childClasses.optionWrapper}
            key={`${i}-${field.type}`}
          >
            <Box className={childClasses.testDescription}>
              {displayFields(field.fields, test)}
            </Box>
          </Box>
        )
      } else if (field.type === 'splitGroup') {
        steps.push(
          <Box
            className={`${childClasses.paperless} halfWidth`}
            key={`${i}-${field.type}`}
          >
            {displayFields(field.fields, test)}
          </Box>
        )
      } else if (field.type === 'groupNoPaper') {
        steps.push(
          <Box className={childClasses.paperless} key={`${i}-${field.type}`}>
            {displayFields(field.fields, test)}
          </Box>
        )
      }
    })
    return steps
  }

  const multiPathogenValidResult = () => {
    return (
      <>
        {testDetails?.testResultsSummary?.map((testResult: any) => (
          <Accordion
            key={testResult.id}
            TransitionProps={{ timeout: 250 }}
            elevation={0}
            variant="outlined"
            className={childClasses.accordion}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon className={childClasses.expandIcon} />
              }
            >
              <div className={childClasses.multiPathResultWrapper}>
                <ResultIcon result={testResult.patientTestResult} />
                <div className={childClasses.outcomeWrapper}>
                  <Typography className={childClasses.multiPathResult}>
                    {capitalizeString(testResult.patientTestResult)}
                  </Typography>
                  {!isMobile && (
                    <div
                      style={{
                        height: 32,
                        borderRight: 'solid 1px #E0E0E0',
                      }}
                    />
                  )}
                  <Typography className={childClasses.multiPathTitle}>
                    {testResult.orderableTestName}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={childClasses.details}>
              {multiPathogenResultFields[testResult.patientTestResult] &&
                displayFields(
                  multiPathogenResultFields[testResult.patientTestResult]
                    .fields,
                  testResult
                )}
            </AccordionDetails>
          </Accordion>
        ))}
        {displayFields(
          isMobile
            ? multiPathogenResultFields.INSTRUCTIONSMOBILE.fields
            : multiPathogenResultFields.INSTRUCTIONSDESKTOP.fields,
          testDetails
        )}
      </>
    )
  }

  const multiPathogenInvalidResult = () => {
    return (
      <>
        <Box className={childClasses.optionWrapper}>
          <Box className={childClasses.testDescription}>
            <div className={childClasses.multiPathResultWrapper}>
              <ResultIcon result={testDetails.result} />
              <div className={childClasses.outcomeWrapper}>
                <Typography className={childClasses.multiPathResult}>
                  {capitalizeString(testDetails.result)}
                </Typography>
                {!isMobile && (
                  <div
                    style={{
                      height: 32,
                      borderRight: 'solid 1px #E0E0E0',
                    }}
                  />
                )}
                <Typography className={childClasses.multiPathTitle}>
                  {testDetails.testName}
                </Typography>
              </div>
            </div>
            <Divider />
            {displayFields(
              multiPathogenResultFields.INVALID.fields,
              testDetails
            )}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <ModuleHeader
      leftContentTitle={isCollectionOutcome ? undefined : i18n.back_button}
      leftContentAction={isCollectionOutcome ? undefined : handleBack}
      rightContentTitle={i18n.exit_button}
      color="#505358"
    >
      <Box className={childClasses.innerWrapper}>
        <div className={childClasses.titleWrapper}>
          <Typography className={childClasses.pageTitle}>
            {i18n.test_results_screen_title}
          </Typography>
          <Typography className={childClasses.multiPathTitle}>
            {testDetails.testName} | {testDetails.labVendor}
          </Typography>
        </div>
        {testDetails?.testResultsSummary?.length > 1 ? (
          //Multi-Pathogen
          <>
            {testDetails.result !== 'INVALID'
              ? multiPathogenValidResult()
              : multiPathogenInvalidResult()}
          </>
        ) : (
          //Rapid test
          <>
            {collectionResultFields[
              testDetails?.testResultsSummary?.[0].patientTestResult
            ] &&
              displayFields(
                collectionResultFields[
                  testDetails.testResultsSummary[0].patientTestResult
                ].fields,
                testDetails.testResultsSummary[0]
              )}
          </>
        )}
        <Box className={childClasses.buttonWrapper}>
          {isCollectionOutcome && testDetails.result !== 'INVALID' && (
            <Box className={childClasses.paperless}>
              {displayFields(
                patientTestResultButtonOptions.fields,
                testDetails
              )}
            </Box>
          )}
          <Button
            color="primary"
            onClick={() => history.push(paths.app.dashboard())}
          >
            {i18n.return_home}
          </Button>
        </Box>
      </Box>
    </ModuleHeader>
  )
}

const SPACE_BETWEEN = 'space-between'

const childStyles = makeStyles((theme) => ({
  paperless: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    'width': '100%',
    'cursor': 'pointer',
    '&.halfWidth': {
      margin: '16px 0px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 24,
    },
  },
  titleWrapper: {
    margin: '78px 0px 32px',
    [theme.breakpoints.down(600)]: {
      margin: '24px 0px',
    },
  },
  pageTitle: {
    fontSize: 36,
    fontWeight: 600,
    color: '#282D37',
  },
  testDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  outcomeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
    },
  },
  optionWrapper: {
    minHeight: '44px',
    padding: '10px 20px',
    borderRadius: 8,
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    border: '1px solid #E6E7EA',
    margin: '16px auto',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'white',
    [theme.breakpoints.down(600)]: {
      margin: '0px auto 16px',
    },
  },
  innerWrapper: {
    alignItems: 'center',
    maxWidth: '800px',
    width: '100%',
    margin: '0px auto',
    [theme.breakpoints.down(960)]: {
      width: '90%',
    },
  },
  accordion: {
    'borderRadius': '8px',
    'marginBottom': 24,
    '&::before': {
      opacity: 0,
    },
    '&.MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: 16,
    },
  },
  details: {
    borderTop: '1px solid #E0E0E0',
    margin: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    alignItems: 'flex-start',
    padding: '8px 0px 16px',
    [theme.breakpoints.down(320)]: {
      '& p': {
        maxWidth: '85vw',
      },
    },
  },
  expandIcon: {
    [theme.breakpoints.up(600)]: {
      marginRight: 32,
    },
  },
  multiPathResultWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: '32px',
    [theme.breakpoints.down(600)]: {
      padding: '8px 0px',
    },
  },
  multiPathTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#757575',
  },
  multiPathResult: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#505358',
    [theme.breakpoints.up(600)]: {
      minWidth: '115px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
    width: '100%',
    maxHeight: '250px',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      width: '100%',
      margin: '0 auto',
      padding: '20px 0px',
    },
    [theme.breakpoints.down(320)]: {
      padding: '0px 0px 16px',
    },
  },
}))
