import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'
import WarningRoundedIcon from '../../assets/icons/warning-icon.svg'
import { Button, Loading } from '../../ui'
import { paths } from '../paths'
import { selectSelectedProvider } from '../insurance/model'
import {
  Controls,
  Content,
  Wrapper,
  useStyles,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'

export const InsuranceProblemView = () => {
  const childClasses = childStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const { id, type } = useParams<{ id: string; type: string }>()

  const selectedProvider = useSelector(selectSelectedProvider)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!selectedProvider) {
      return history.push(paths.addInsuranceProvider(type, id))
    }
    setLoading(false)
  }, [selectedProvider])

  if (loading) return <Loading />

  return (
    <Wrapper>
      <Content>
        <img
          src={WarningRoundedIcon}
          alt="warning"
          className={childClasses.mainImage}
        />
        <Message i18n={i18n} />
      </Content>
      <Actions i18n={i18n} />
    </Wrapper>
  )
}

const Message = (props: { i18n: any }) => {
  const { i18n } = props
  const childClasses = childStyles()
  return (
    <Box>
      <Typography className={childClasses.infoTitle}>{i18n.insurance_failed_message_title}</Typography>
      <Typography className={childClasses.infoText}>
        {i18n.insurance_failed_message_details}
      </Typography>
    </Box>
  )
}

const Actions = (props: { i18n: any }) => {
  const { i18n } = props
  const classes = useStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()

  const handleUpdateInsuranceInfo = () => {
    history.push(paths.confirmInsurance(type, id, 'edit'))
  }

  const handlePayWithCreditCard = () => {
    history.push(paths.checkout(type, id))
  }

  return (
    <Controls style={{ marginBottom: 16 }}>
      <Button className={classes.button} onClick={handleUpdateInsuranceInfo}>
        {i18n.insurance_update_policy_button}
      </Button>
      <Button className={classes.button} onClick={handlePayWithCreditCard}>
        {i18n.pay_with_credit_card_label}
      </Button>
    </Controls>
  )
}

const primaryColor = '#003C72'
const childStyles = makeStyles({
  infoTitle: {
    color: '#54565B',
    textAlign: 'center',
    fontSize: 37,
    paddingBottom: 10,
    fontWeight: 700,
  },
  infoText: {
    color: '#54565B',
    textAlign: 'center',
    fontSize: 21,
  },
  mainImage: {
    color: primaryColor,
    height: 300,
    width: 200,
  },
})
