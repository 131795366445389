/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stripHtml } from 'string-strip-html'
import { selectLanguageContent } from '../../features/translation'
import { Button, Col, Loading, Typography } from '../../ui'
import {
  nextQuestionV2,
  selectAutoSubmit,
  selectCanContinue,
  selectCurrentDescription,
  selectCurrentQuestion,
  selectExistingAnswer,
  selectQuestionLoading,
  selectQuestionnaireTitle,
} from './questionnaireV2Slice'
import {
  DateQuestion,
  HeightQuestion,
  LargeTextQuestion,
  MultipleQuestion,
  NumericQuestion,
  ScaleQuestion,
  SliderQuestion,
  SmallTextQuestion,
  YesNoQuestion,
} from './questions'
import { QuestionType } from './types'
import { HTMLTextComponent } from '../../libs/shared-components'
import { Observer } from '../../libs/observer'
import { useMUITheme } from '../../libs/useTheme'

const questionTypes: Record<QuestionType, React.ReactNode> = {
  [QuestionType.Multiple]: <MultipleQuestion />,
  [QuestionType.Numeric]: <NumericQuestion />,
  [QuestionType.Date]: <DateQuestion />,
  [QuestionType.Scale]: <ScaleQuestion />,
  [QuestionType.Height]: <HeightQuestion />,
  [QuestionType.LargeText]: <LargeTextQuestion />,
  [QuestionType.SmallText]: <SmallTextQuestion />,
  [QuestionType.YesNo]: <YesNoQuestion />,
  [QuestionType.Slider]: <SliderQuestion />,
}

export const QuestionnaireExecution = () => {
  const dispatch = useDispatch()
  const questionDescription = useSelector(selectCurrentDescription)
  const questionnaireTitle = useSelector(selectQuestionnaireTitle)
  const autoSubmit = useSelector(selectAutoSubmit)
  const question = useSelector(selectCurrentQuestion)
  const isLoading = useSelector(selectQuestionLoading)
  const canContinue = useSelector(selectCanContinue)
  const existingAnswer = useSelector(selectExistingAnswer)
  const classes = useStyles()
  const [overFlowIndicator, setOverFlowIndicator] = useState(true)
  const [contentInView, setContentInView] = useState(false)
  const i18n = useSelector(selectLanguageContent)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const isAutoSubmitQuestion =
    (question.type === QuestionType.YesNo && !existingAnswer) ||
    (question.type === QuestionType.Multiple &&
      !existingAnswer &&
      question.multiSelect === false)
  const { isMobile } = useMUITheme()
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(nextQuestionV2())
  }

  useEffect(() => {
    if (!existingAnswer && question) {
      setOverFlowIndicator(true)
    } 
    // else {
    //   setOverFlowIndicator(false)
    // }
  }, [question, existingAnswer])

  useEffect(() => {
    setContentInView(false)
  }, [question])

  const observe = () => {
    setContentInView(true)
    setOverFlowIndicator(false)
    console.log('In View')
  }

  useEffect(() => {
    if (autoSubmit)
      setTimeout(() => {
        dispatch(nextQuestionV2())
      }, 100)
  }, [autoSubmit])

  if (isLoading)
    return (
      <div className={classes.loadingContainer}>
        <Loading size={100} color="secondary" />
      </div>
    )

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Box className={classes.questionWrapper}>
        <Box className={classes.questionContent}>
          <Col gap={'.5rem'}>
            <Typography className={classes.questionnaireName}>
              {questionnaireTitle}
            </Typography>
            <Typography className={classes.question}>
              {question?.name?.translations.eng}
            </Typography>
            {question.help?.translations?.eng && (
              <div
                className={classes.infoWrapper}
                onClick={() => setHelpDialogOpen(true)}
              >
                <InfoOutlinedIcon color="secondary" />
                <Typography color="secondary" className={classes.questionInfo}>
                  {question.help.translations.eng}
                </Typography>
              </div>
            )}
            {question?.description?.translations.eng && (
              <Box className={classes.questionDescription}>
                {stripHtml(
                  question.description.translations.eng
                ).result.replace(/([\r|\n]+)/g, ' ')}
              </Box>
            )}
            <Typography>{questionDescription}</Typography>
            {question?.type && questionTypes[question.type]}
          </Col>
          {isMobile && !isLoading && <Observer callback={observe} />}
        </Box>
        {!isLoading && overFlowIndicator && (
          <div className={classes.overflowIndicator}>
            <div className={`${classes.iconWrapper} top`}>
              <ExpandMoreRoundedIcon />
            </div>
            <div className={`${classes.iconWrapper} bottom`}>
              <ExpandMoreRoundedIcon />
            </div>
          </div>
        )}
        <Box className={classes.btnWrapper}>
          {question?.type === 'slider' && (
            <Typography color="secondary">
              {i18n.virtual_consult_slide_to_pick}
            </Typography>
          )}
          {!isAutoSubmitQuestion && (
            <Button
              color="secondary"
              type="submit"
              disabled={!canContinue}
              className={classes.btnStyle}
            >
              {i18n.continue_button}
            </Button>
          )}
          {isMobile === false && <Observer callback={observe} />}
        </Box>
      </Box>
      <Dialog open={helpDialogOpen}>
        <HelpDialog
          handleClose={() => setHelpDialogOpen(false)}
          body={question.helpDescription?.translations?.eng || ''}
        />
      </Dialog>
    </form>
  )
}

const HelpDialog = (props: { handleClose: () => void; body: string }) => {
  const { handleClose, body } = props
  const classes = useStyles()

  return (
    <div className={classes.dialogWrapper}>
      <div className={classes.dialogContent}>
        <HTMLTextComponent HTMLString={body} />
      </div>
      <div>
        <div onClick={() => handleClose()}>
          <CloseRoundedIcon style={{ color: '#757575' }} />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  'btnWrapper': {
    display: 'flex',
    backgroundColor: '#FFF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '32px 0px',
    position: 'relative',
    width: '100%',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      left: 0,
      bottom: '16px',
      padding: '0px',
      width: '100%',
    },
  },
  'btnStyle': {
    margin: '10px auto',
    padding: '10px',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  'loadingContainer': {
    marginTop: '128px',
  },
  'form': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  'errorWrapper': {
    boxSizing: 'border-box',
    maxWidth: 625,
    width: '100%',
    margin: '0 auto',
    padding: 16,
  },
  'questionWrapper': {
    flexGrow: 1,
    alignContent: 'space-between',
    maxWidth: 600,
    width: '100vw',
    padding: '0px 16px',
    [theme.breakpoints.down(600)]: {
      padding: '0px',
      minWidth: '90vw',
      width: '100%',
    },
  },
  'questionContent': {
    padding: 10,
    overflowX: 'auto',
    [theme.breakpoints.down(600)]: {
      height: 'calc(100vh - 200px)',
    },
  },
  'questionnaireName': {
    fontSize: 14,
    fontWeight: 500,
  },
  'question': {
    color: '#54565B',
    fontSize: 24,
    textAlign: 'left',
    margin: '8px 0px',
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      textAlign: 'left',
    },
  },
  'questionDescription': {
    color: '#898989',
    fontStyle: 'italic',
    fontSize: 18,
    textAlign: 'left',
    maxWidth: 500,
    margin: '0px auto 16px',
    [theme.breakpoints.down(600)]: {
      textAlign: 'left',
    },
  },
  'infoWrapper': {
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
  },
  'questionInfo': {
    fontSize: 16,
    fontWeight: 500,
  },
  'dialogWrapper': {
    padding: 16,
    display: 'flex',
  },
  'dialogContent': {
    padding: '16px 8px 16px 32px',
    [theme.breakpoints.down(380)]: {
      padding: '4px 2px 4px 8px',
    },
  },
  'iconWrapper': {
    'position': 'absolute',
    'top': 0,
    'color': theme.palette.secondary.main,
    '&.top': {
      'transform': 'translate(0,22px)',
      'animation': '$topMotion 1.56s ease-in-out',
      'animationDelay': '1s',
      'animation-iteration-count': 'infinite',
    },
    '&.bottom': {
      'transform': 'translate(0,30px)',
      'animation': '$bottomMotion 1.56s ease-in-out',
      'animationDelay': '1s',
      'animation-iteration-count': 'infinite',
    },
  },
  '@keyframes topMotion': {
    '0%': {
      transform: 'translate(0,22px)',
    },
    '15%': {
      transform: 'translate(0,0px)',
    },
    '50%': {
      transform: 'translate(0,22px)',
    },
    '100%': {
      transform: 'translate(0,22px)',
    },
  },
  '@keyframes bottomMotion': {
    '0%': {
      transform: 'translate(0,30px)',
    },
    '15%': {
      transform: 'translate(0,0px)',
    },
    '50%': {
      transform: 'translate(0,30px)',
    },
    '100%': {
      transform: 'translate(0,30px)',
    },
  },
  'overflowIndicator': {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 75,
    right: 'calc(50% - 300px)',
    [theme.breakpoints.down(600)]: {
      bottom: 215,
      right: 30,
    },
  },
}))
