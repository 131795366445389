import {
  APIConfiguration,
  EcommerceStringsHardcodedDefault,
  EcommerceUIConfiguration,
  IDTokenProcessorJSExposed,
  ReachabilityCheckerJSExposed,
  SafeEcommerceConfiguration,
  SafeEcommerceSDKJS,
  ShippingDescription,
  ShopifyCollectionType,
} from './EcommerceSDK'
export default class SafeEcommerceSDKService {
  constructor(userService, authService) {
    if (userService == null) {
      throw new Error(
        'UserService is required; Please refer to the UserServiceAbstract class for more information.'
      )
    }
    if (authService == null) {
      throw new Error(
        'AuthService is required; Please refer to the AuthServiceAbstract class for more information.'
      )
    }
    this.baseUrl = 'https://' + process.env.REACT_APP_VITE_BASE_URL
    this.shopDomain = process.env.REACT_APP_VITE_SHOP_DOMAIN
    this.key = process.env.REACT_APP_VITE_SHOP_KEY
    this.isTestPayment = process.env.REACT_APP_VITE_TEST_PAYMENT === 'true'
    this.accountId = process.env.REACT_APP_VITE_ACCOUNT_ID
    this.userService = userService
    this.authService = authService
    // isSetup is if shopDomain && key is not empty
    this.isSetup = this.shopDomain.length > 0 && this.key.length > 0
    this.isLoggingEnabled = process.env.REACT_APP_VITE_LOGGING_ENABLED === 'true'
    
  }

  setupSDK() {
    if (!this.isSetup) {
      throw new Error(
        'E-commerce Service is not setup. Please check your configuration file.'
      )
    }

    let accountId = this.accountId
    let tenantId = this.userService.getSelectedTenant()
    let email = this.userService.getUserData().email
    let settings = this.userService.getSettings()
    let ecommerceSettings = settings?.ecommerceSettings

    if (
      accountId == null ||
      tenantId == null ||
      email == null ||
      settings == null ||
      ecommerceSettings == null
    ) {
      throw new Error(
        'Account ID, Tenant ID and Email, Tenant Settings and Shopify Collections should not be empty at this point.'
      )
    }

    let needsSetup = true
    if (this.sdk != null) {
      if (this.accountId === accountId && this.tenantId === tenantId) {
        needsSetup = false
      }

      if (needsSetup && this.accountId != null && this.tenantId != null) {
        this.sdk.disposeInstance(true)
      }
    }

    if (!needsSetup) {
      console.log(
        'Did not initialize new SafeEcommerce SDK Service, already exists with same Health Id and Id Token'
      )
      return
    }

    this.accountId = accountId
    this.tenantId = tenantId

    console.log('Setting up SafeEcommerce SDK Service')
    let benefitSettings = settings.benefitSettings
    let benefitCardSettings = benefitSettings.employerBenefitCardSettings

    let shippingDescription =
      settings.ecommerceSettings.shippingDescriptions.map((desc) => {
        return new ShippingDescription(
          desc.title,
          desc.desc,
          desc.sortOrder,
          desc.shippingCode,
          null,
          null,
          null
        )
      })
    let shopifyCollections = []
    if (ecommerceSettings.dynamicShopifyCollections && !ecommerceSettings.dynamicShopifyCollections.isEmpty) {
      shopifyCollections = ecommerceSettings.dynamicShopifyCollections.map(
        (dynamicShopifyCollection) => {
          return new ShopifyCollectionType(
            dynamicShopifyCollection.key,
            dynamicShopifyCollection.collectionId + '',
            dynamicShopifyCollection.readOnly,
            dynamicShopifyCollection.telehealth
          )
        }
      )
    } else if (ecommerceSettings.shopifyCollections != null) {
      shopifyCollections = [
        new ShopifyCollectionType(
          'test_kits',
          ecommerceSettings.shopifyCollections.testKits,
          false,
          false
        ),
        new ShopifyCollectionType(
          'welcome_kits',
          ecommerceSettings.shopifyCollections.welcomeKits,
          true,
          false
        ),
        new ShopifyCollectionType(
          'telehealth_sync',
          ecommerceSettings.shopifyCollections.telehealthSync,
          true,
          true
        ),
        new ShopifyCollectionType(
          'telehealth_async',
          ecommerceSettings.shopifyCollections.telehealthAsync,
          true,
          true
        ),
      ]
    }

    let apiConfiguration = new APIConfiguration(
      this.baseUrl,
      this.accountId,
      this.tenantId
    )
    let uiConfiguration = new EcommerceUIConfiguration(
      benefitCardSettings.backgroundColor, // benefitsCardBackgroundColorHex
      benefitCardSettings.title, // benefitsTitleFormat
      benefitCardSettings.description, // benefitsDescriptionFormat
      'Avenir' //fontFamily
    )

    let configuration = new SafeEcommerceConfiguration(
      apiConfiguration,
      email, // email
      true, // tenantInsuranceEnabled
      benefitCardSettings.employerBenefit, // tenantEmployeeBenefitsEnabled
      benefitCardSettings.exceedBenefit, // tenantExceedBenefitsEnabled
      uiConfiguration, // uiConfiguration
      shopifyCollections, // shopifyCollections
      new EcommerceStringsHardcodedDefault(),
      shippingDescription, // shippingDescriptions
      settings.labOrderCollectInsuranceInformation, // isInsuranceCollected
      settings.collectPhoneNumber, // isMobileNumberCollected
      settings.collectPhoneNumberRequired, // isMobileNumberRequired
      false, // isTermsAndConditionsEnabled
      this.isLoggingEnabled, // isLoggingEnabled
      this.isTestPayment // isTestPayment
    )
    let authService = this.authService
    let idTokenProcessor = new IDTokenProcessorJSExposed(function () {
      return new Promise(function (resolve, reject) {
        authService
          .getIDToken()
          .then((idToken) => {
            resolve(idToken)
          })
          .catch((error) => {
            reject(error)
          })
      })
    })
    let reachabilityChecker = new ReachabilityCheckerJSExposed(function () {
      return new Promise(function (resolve, reject) {
        resolve(true)
      })
    })
    this.sdk = new SafeEcommerceSDKJS(
      configuration, // configuration
      this.shopDomain, // shopDomain
      this.key, // apiKey
      idTokenProcessor, // idTokenProcessor
      reachabilityChecker // reachabilityChecker
    )
    this.sdk.setupDependencies()
  }
  getSDKService() {
    try {
      this.setupSDK()
    } catch (error) {
      // print stack trace
      console.log(error.stack)
    }
    return this.sdk
  }
}
