import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Button, Checkbox, Select } from '../../ui'
import { USAStateList, ADDRESS_TYPES, testTypes } from '../../constants'
import { SubHeaderGetTested } from '../../features/application'
import { selectProfileData, updateUserData } from '../profile/model'
import { paths } from '../paths'
import { selectLanguageContent } from '../../features/translation'
import {
  Wrapper,
  FormWrapper,
  Content,
} from '../../ui/templates/app-layout'
import { InvalidAddressDialog } from '../schedule-pickup/invalidAddressDialog'
import {
  checkPickUpAvailability,
  setDropOffPickupLocation,
  validatePickUpAddress,
} from '../shipping/shippingTestSlice'
import { ShortAddress } from '../shipping/types'
import validationSchema from './validationSchema'
import { FormValues } from './types'

const InputMask = require('react-input-mask')

export const AddressInfoPage = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory<any>()
  const { type, id, orderId } = useParams<{
    type: string
    id: string
    orderId: string
  }>()
  const { addressType } = history.location.state
  const currentUser = useSelector(selectProfileData)
  const [formValues, setFormValues] = useState<FormValues>({
    city: '',
    country: '',
    line1: '',
    line2: '',
    state: '',
    zip: '',
    mobileNumber: '',
    isSameAsShipping: false,
  })
  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser) {
      const address =
        addressType === ADDRESS_TYPES.SHIPPING_ADDRESS
          ? currentUser.shippingAddress
          : currentUser.billingAddress

      setFormValues({
        city: address?.city || '',
        country: address?.country || '',
        line1: address?.line1 || '',
        line2: address?.line2 || '',
        state: address?.state || '',
        zip: address?.zip || '',
        mobileNumber: currentUser.contact.mobileNumber || '',
        isSameAsShipping: false,
      })
    }
  }, [currentUser])

  const onSubmit = async (values: FormValues) => {
    const { city, country, line1, line2, state, zip } = values
    const address = {
      city,
      country,
      line1,
      line2,
      state,
      zip,
    }

    if (addressType === ADDRESS_TYPES.PICKUP_ADDRESS) {
      const payload: ShortAddress = {
        city,
        postalCode: String(zip),
        countryCode: country,
        stateOrProvinceCode: state,
        addressLine: line1,
        // logisticsPartner: 'FedEx',
        logisticsPartner: 0,
      }
      if (await dispatch(validatePickUpAddress(payload))) {

      dispatch(setDropOffPickupLocation({
        address1: `${line1}, ${line2}`,
            ...values,
          })
        )
        if (
          await dispatch(
            checkPickUpAvailability({
              address: payload,
              pickupDate: new Date().toISOString(),
              logisticsPartner: 'FedEx',
            })
          )
        ) {
          history.push(paths.schedulePickup(testTypes.PICKUP, orderId), {
            addressType: ADDRESS_TYPES.PICKUP_ADDRESS,
          })
        }
      } else {
        setIsOpen(true)
      }
    } else {
      const addressTypeKey =
        addressType === ADDRESS_TYPES.SHIPPING_ADDRESS
          ? 'shippingAddress'
          : 'billingAddress'

      const mobileNumber = values.mobileNumber.replace(/\+1\s/, '')
      const updatedUser = {
        ...currentUser,
        contact: { ...currentUser.contact, mobileNumber },
        [addressTypeKey]: address,
      }
      await dispatch(updateUserData(updatedUser))
      history.push(paths.checkout(type, id))
    }
  }

  const formik = useFormik({
    initialValues: {
      city: formValues.city,
      country: formValues.country,
      line1: formValues.line1,
      line2: formValues.line2,
      state: formValues.state,
      zip: formValues.zip,
      mobileNumber: formValues.mobileNumber,
      isSameAsShipping: formValues.isSameAsShipping,
    },
    validationSchema: validationSchema(i18n),
    enableReinitialize: true,
    onSubmit,
  })

  useEffect(() => {
    if (formik.values.isSameAsShipping) {
      const userShippingAddress = currentUser.shippingAddress

      formik.setValues({
        city: userShippingAddress?.city || '',
        country: userShippingAddress?.country || '',
        line1: userShippingAddress?.line1 || '',
        line2: userShippingAddress?.line2 || '',
        state: userShippingAddress?.state || '',
        zip: userShippingAddress?.zip || '',
        mobileNumber: formValues.mobileNumber || '',
        isSameAsShipping: formik.values.isSameAsShipping,
      })
    }
  }, [formik.values.isSameAsShipping])

  const getHeaderText = () => {
    let headerText = ''
    switch (addressType) {
      case ADDRESS_TYPES.SHIPPING_ADDRESS:
        headerText = i18n.shipping_address_title
        break

      case ADDRESS_TYPES.BILLING_ADDRESS:
        headerText = i18n.checkout_billing_address
        break

      case ADDRESS_TYPES.PICKUP_ADDRESS:
        headerText = i18n.confirm_address_screen_title
        break

      default:
        headerText = i18n.shipping_address_title
    }
    return headerText
  }

  const goBack = () => {
    history.goBack()
  }

  const handleCancelDialog = () => {
    history.goBack()
  }

  return (
    <Wrapper>
      <SubHeaderGetTested title={getHeaderText()} onHandleBack={goBack} />
      <Wrapper>
        <InvalidAddressDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          onRetry={formik.handleSubmit}
        />
        <Content onSubmit={formik.handleSubmit} noValidate>
          <FormWrapper>
            <AddressIsSameCheckBox
              formik={formik}
              addressType={addressType}
              i18n={i18n}
            />
            <StreetAddress formik={formik} i18n={i18n} />
            <Apt formik={formik} i18n={i18n} />
            <City formik={formik} i18n={i18n} />
            <State formik={formik} i18n={i18n} />
            <Country formik={formik} i18n={i18n} />
            <Zip formik={formik} i18n={i18n} />
            <PhoneNumber
              formik={formik}
              addressType={addressType}
              i18n={i18n}
            />
          </FormWrapper>
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.btn}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              isLoading={formik.isSubmitting}
            >
              {addressType === ADDRESS_TYPES.PICKUP_ADDRESS
                ? i18n.continue_button
                : i18n.save_button}
            </Button>
          </Box>
        </Content>
      </Wrapper>
    </Wrapper>
  )
}

const useStyles = makeStyles({
  btn: {
    margin: '8px',
    maxHeight: 48,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
  },
})

const AddressIsSameCheckBox = (props: {
  formik: any
  addressType: string
  i18n: any
}) => {
  const { formik, addressType, i18n } = props
  if (addressType !== ADDRESS_TYPES.BILLING_ADDRESS) return null
  return (
    <Checkbox
      label={i18n.same_as_shipping_address}
      name="isSameAsShipping"
      onChange={formik.handleChange}
      checked={formik.values.isSameAsShipping}
    />
  )
}

const StreetAddress = (props: { formik: any; i18n: any }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.street_address}
      id="line1"
      name="line1"
      value={formik.values.line1}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.line1 && formik.touched.line1)}
      helperText={formik.touched.line1 ? formik.errors.line1 : ''}
      disabled={formik.values.isSameAsShipping}
      required
    />
  )
}
const Apt = (props: { formik: any; i18n: any }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.apt_and_ste_short}
      id="line2"
      name="line2"
      value={formik.values.line2}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.line2 && formik.touched.line2)}
      helperText={formik.touched.line2 ? formik.errors.line2 : ''}
      disabled={formik.values.isSameAsShipping}
    />
  )
}
const City = (props: { formik: any; i18n: any }) => {
  const { formik, i18n } = props
  return (
    <TextField
      label={i18n.city}
      id="city"
      name="city"
      value={formik.values.city}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.city && formik.touched.city)}
      helperText={formik.touched.city ? formik.errors.city : ''}
      disabled={formik.values.isSameAsShipping}
      required
    />
  )
}
const State = (props: { formik: any; i18n: any }) => {
  const { formik, i18n } = props
  return (
    <Select
      options={USAStateList}
      label={i18n.state}
      value={formik.values.state}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      disabled={formik.values.isSameAsShipping}
      name="state"
      required
    />
  )
}
const Country = (props: { formik: any; i18n: any }) => {
  const { formik, i18n } = props
  const options = [
    { label: 'United States', value: 'US' },
    { label: 'Spain', value: 'ES' },
  ]
  return (
    <Select
      options={options}
      label={i18n.country}
      onBlur={formik.handleBlur}
      value={formik.values.country}
      onChange={formik.handleChange}
      disabled={formik.values.isSameAsShipping}
      name="country"
      required
    />
  )
}
const Zip = (props: { formik: any; i18n: any }) => {
  const { formik, i18n } = props
  return (
    <InputMask
          mask="99999"
          maskChar=""
          label={i18n.zip_code}
          value={formik.values.zip}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.zip && formik.touched.zip)}
          helperText={formik.touched.zip ? formik.errors.zip : ''}
          name="zip"
          disabled={formik.values.isSameAsShipping}
          required
        >
          {(inputProps: any) => <TextField {...inputProps} />}
        </InputMask>
    // <TextField
    //   label={i18n.zip_code}
    //   id="zip"
    //   name="zip"
    //   type="number"
    //   value={formik.values.zip}
    //   onChange={formik.handleChange}
    //   onBlur={formik.handleBlur}
    //   error={Boolean(formik.errors.zip && formik.touched.zip)}
    //   helperText={formik.touched.zip ? formik.errors.zip : ''}
    //   disabled={formik.values.isSameAsShipping}
    //   required
    // />
  )
}
const PhoneNumber = (props: {
  formik: any
  addressType: string
  i18n: any
}) => {
  const { formik, addressType, i18n } = props
  if (addressType === ADDRESS_TYPES.BILLING_ADDRESS) return null
  return (
    <InputMask
      mask="+1 (999) 999-9999"
      maskChar=""
      // alwaysShowMask={true}
      value={formik.values.mobileNumber}
      onChange={formik.handleChange}
      label={i18n.phone_number}
      name="mobileNumber"
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors.mobileNumber && formik.touched.mobileNumber)}
      helperText={formik.touched.mobileNumber ? formik.errors.mobileNumber : ''}
      required
    >
      {(inputProps: any) => <TextField {...inputProps} />}
    </InputMask>
  )
}
