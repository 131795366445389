import Box from '@material-ui/core/Box'
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined'
import React from 'react'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { Typography } from '../../../ui'
import { I18n } from '../../translation/types'

export const CheckoutItem = (props: {
  product: any
  color: string
  i18n: I18n
}) => {
  const { product, color, i18n } = props
  const classes = shopifyStoreStyles()

  return (
    <Box className={classes.testWrapper}>
      <div className={classes.cartItemContentWrapper}>
        <ShoppingBasketOutlinedIcon
          style={{ fontSize: 20, color: '#757575' }}
        />
        <div style={{ flex: 1 }}>
          <Box className={`${classes.testDetails} noMargin`}>
            <Typography className={classes.orderDetailItemDesc}>
              {product.productTitle}
            </Typography>
            <Typography className={classes.orderDetailQuantity}>
              {`${i18n['ecommerce.quantity_text']}: ${product.quantity}`}
            </Typography>
          </Box>
        </div>
        <div
          className={classes.checkoutImageWrapper}
          style={{ backgroundColor: color }}
        >
          {product.productImageUrl && (
            <img
              src={product.productImageUrl}
              className={classes.checkoutImage}
            />
          )}
        </div>
      </div>
    </Box>
  )
}
