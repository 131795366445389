/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { createHealthService } from '../../features/health-service-lite/model'
import { HTMLTextComponent } from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { Loading } from '../../ui'
import {
  ModuleActionButton,
  ModuleIntroWrapper,
} from '../../ui/templates/modules/moduleIntroWrapper'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import { resetQuestionnaireV2Store } from '../questionnaireV2/questionnaireV2Slice'
import {
  getIntroScreen,
  resetIntroStore,
  selectIntroLoading,
  selectVcIntroScreen,
} from './symptomCheckerIntroSlice'

export const SymptomCheckerIntroductionPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const introScreen = useSelector(selectVcIntroScreen)
  const { id } = useParams<{ id: string }>()
  const networkSettings = useSelector(selectNetworkSettings)
  const tenantId = localStorage.getItem('tenantId')
  const isLoading = useSelector(selectIntroLoading)

  useEffect(() => {
    if (lsClient.getUserLSByKey('VCquestionnaireId')) {
      history.push(paths.symptomCheckerV2())
    }
    if (!networkSettings && tenantId) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [])

  useEffect(() => {
    dispatch(resetQuestionnaireV2Store())
    dispatch(getIntroScreen(id ? id : ''))
  }, [id])

  const handleBack = () => {
    dispatch(resetIntroStore())
    history.goBack()
  }

  const handleCancel = () => {
    history.push(paths.app.dashboard())
  }

  const goNext = () => {
    const isHealthService = lsClient.getUserLSByKey('isHealthService')
    const healthService = lsClient.getUserLSByKey('healthServiceId')
    const tenantId = lsClient.getUserLSByKey('tenantId')
    lsClient.removeUserKeyLS('VCquestionnaireId')
    if (isHealthService && !healthService) {
      const params = {
        tenantId,
        accountId: user.accountId,
        UserId: user._id,
        Description: 'Starting Health Service',
      }
      const onCompletion = (healthServiceId: string) => {
        lsClient.setUserLS('healthServiceId', healthServiceId)
        if (id) history.push(paths.symptomCheckerById(id))
        else history.push(paths.symptomCheckerV2())
      }
      dispatch(createHealthService(params, onCompletion))
    } else if (id) history.push(paths.symptomCheckerById(id))
    else history.push(paths.symptomCheckerV2())
  }

  const actions = [
    {
      label: introScreen?.buttonText?.translations?.eng,
      action: goNext,
      variant: 'contained',
    },
  ] as ModuleActionButton[]

  if (isLoading) return <Loading size={100} />

  return (
    <ModuleIntroWrapper
      title={introScreen?.title?.translations?.eng}
      body={
        introScreen?.description?.translations?.eng ? (
          <HTMLTextComponent
            HTMLString={introScreen.description.translations.eng}
          />
        ) : (
          ''
        )
      }
      contentImage={introScreen?.image || ''}
      actions={actions}
      onBack={handleBack}
      onClose={handleCancel}
    />
  )
}
