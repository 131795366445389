import * as Yup from 'yup'
import { I18n } from '../../features/translation/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (i18n: I18n) {
  return Yup.object().shape({
    firstName: Yup.string().required(i18n.required),
    lastName: Yup.string().required(i18n.required),
    mobileNumber: Yup.string().required(i18n.required),
    dateOfBirth: Yup.date().required(i18n.required),
    relationship: Yup.string(),
    line1: Yup.string(),
    line2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string().min(5, i18n.zip_code_min).max(5, i18n.zip_code_max),
  })
}
