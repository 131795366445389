/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Link as MUILink, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import createDOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/hc-logo-no-text.png'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { lsClient } from '../../ls-client'
import { Loading } from '../../ui'
import { DashboardActionItem } from '../dashboard/components'
import {
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../network/model'
import { paths } from '../paths'
import { getProfileData, selectProfileData } from '../profile/model'
import { getVerbiage, selectWelcomeVerbiage } from './model'

const DOMPurify = createDOMPurify(window)

export const WelcomePage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectNetworkSettingsLoading)
  const i18n = useSelector(selectLanguageContent)
  const { firstName: userName } = useSelector(selectProfileData)
  const [profileRetry, setProfileRetry] = useState(false)
  const tenant = lsClient.getUserLSByKey('tenantId') || ''
  const networkSettings = useSelector(selectNetworkSettings)
  const welcomeMessage = useSelector(selectWelcomeVerbiage)
  const skipTitle = networkSettings?.welcomeScreenSettings
    ?.welcomeScreenSkipButton?.title as keyof I18n

  const replace = [userName, networkSettings?.appName || 'HealthCheck']
  let index = 0
  let message = i18n.hi_welcome_to_message.replace(
    /%@/g,
    () => replace[index++]
  )
  message = message.replace('\\n', '\n')

  useEffect(() => {
    if (!userName && !profileRetry) {
      setProfileRetry(true)
      dispatch(getProfileData())
    }
  }, [userName, profileRetry])

  useEffect(() => {
    if (networkSettings && !networkSettings.showWelcomeScreen) {
      history.push(paths.app.dashboard())
    }
    if (tenant) {
      dispatch(getVerbiage())
    }
  }, [networkSettings])

  const skipButtonAction = (action: any) => {
    if (
      !networkSettings?.welcomeScreenSettings?.welcomeScreenSkipButton?.enabled
    )
      return
    switch (action.value) {
      case 'skip':
      case 'GoToHomeScreen':
      case 'Iwilldoitlater': {
        return history.push(paths.app.dashboard())
      }
      case 'TakeaTest': {
        return history.push(paths.app.getTested())
      }
      case 'HealthService': {
        lsClient.setUserLS('isHealthService', 'Y')
        history.push(paths.symptomCheckerIntro())
        break
      }
      default:
        return history.push(paths.app.dashboard())
    }
  }

  if (isLoading) return <Loading />

  return (
    <Box className={classes.welcomeWrapper}>
      <Box className={classes.welcomeContentWrapper} style={{ flex: 1 }}>
        <Box className={classes.welcomeContent}>
          <img
            src={
              networkSettings?.welcomeScreenImage
                ? networkSettings?.welcomeScreenImage
                : undefined
            }
            alt="company logo"
            style={{ width: '150px', margin: '24px 0 10px' }}
          />
          <Box className={classes.contentContainer}>
            <Box className={classes.infoText}>{message}!</Box>
            <Box className={classes.optionsWrapper}>
              <Box className={classes.optionWrapper}>
                <Typography>
                  <span
                    className={classes.welcome}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(welcomeMessage),
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {networkSettings?.welcomeScreenSettings?.welcomeScreenButton?.visible && (
        <DashboardActionItem
          activeHealthService={false}
          button={networkSettings.welcomeScreenSettings.welcomeScreenButton}
          i18n={i18n}
          key={
            networkSettings.welcomeScreenSettings.welcomeScreenButton.cardTitle
              ? networkSettings.welcomeScreenSettings.welcomeScreenButton
                  .cardTitle
              : networkSettings.welcomeScreenSettings.welcomeScreenButton.title
          }
        />
      )}
      {networkSettings?.welcomeScreenSettings?.welcomeScreenSkipButton
        ?.visible && (
        <MUILink
          role="button"
          color={
            networkSettings?.welcomeScreenSettings?.welcomeScreenSkipButton
              ?.enabled
              ? 'primary'
              : 'secondary'
          }
          className={classes.pageLink}
          onClick={() =>
            skipButtonAction(
              networkSettings?.welcomeScreenSettings?.welcomeScreenSkipButton
                ?.action
            )
          }
        >
          {i18n[skipTitle]}
        </MUILink>
      )}
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((_theme) => ({
  welcomeWrapper: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '90%',
    margin: '0 auto',
    textAlign: 'center',
  },
  welcomeContentWrapper: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  welcome: {
    'fontFamily': 'Avenir Next Rounded Regular',
    'fontSize': 20,
    '& p': {
      textAlign: 'center',
    },
  },
  infoText: {
    fontWeight: 700,
    width: '100%',
    color: '#58585A',
    margin: '24px 0',
    fontSize: 24,
    alignSelf: 'center',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto',
    minHeight: 200,
  },
  welcomeContent: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 50px)',
  },
  optionsWrapper: {
    width: '100%',
  },
  optionWrapper: {
    'padding': '0px 20px',
    'borderRadius': 10,
    'display': 'flex',
    'justifyContent': SPACE_BETWEEN,
    'margin': '0 auto',
    'maxWidth': 400,
    'color': '#7B7C7D',
    '&:first-child': {
      cursor: 'pointer',
    },
  },
  pageLink: {
    marginTop: '15px',
    marginBottom: '30px',
    cursor: 'pointer',
  },
}))
