import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../ui'
import { paths } from '../paths'
import { placeOrder, selectLegalNotes } from '../checkout/model'
import { SubHeaderGetTested } from '../../features/application'
import { ContentLoaderBox } from '../../features/application/templates/content-loader-box'
import {
  Wrapper,
  Content,
  useStyles,
  Controls,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'

export const CheckoutConsent = () => {
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const { type, id } = useParams<{ type: string; id: string }>()
  const consents = useSelector(selectLegalNotes)
  const [consentData, setConsentData] = useState({ title: '', value: '' })
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!consents) {
      history.goBack()
    } else {
      const consentTitle =
        history.location.pathname === paths.informedConsent(type, id)
          ? `${i18n.informed_consent}`
          : `${i18n.hipaa_consent}`
      setConsentData(consents.filter((i: any) => i.title === consentTitle)[0])
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [consents])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }

  const handleBack = () => {
    history.goBack()
  }

  const handlePlaceOrder = async () => {
    setLoading(true)
    if (await dispatch(placeOrder(type))) history.push(paths.orderPlaced(type))
    setLoading(false)

    // history.push(paths.orderPlaced())
  }

  const goNext = () => {
    if (history.location.pathname === paths.informedConsent(type, id)) {
      history.push(paths.hippaConsnet(type, id))
    } else {
      handlePlaceOrder()
    }
  }

  return (
    <Wrapper>
      {loading ? <ContentLoaderBox /> : null}
      <SubHeaderGetTested title={consentData.title} onHandleBack={handleBack} />
      <Wrapper>
        <Content
          style={{ margin: '0px auto' }}
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid
            style={{
              height: 'calc(100vh - 185px)',
              overflow: 'hidden',
              overflowY: 'scroll',
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: consentData.value,
              }}
            />
          </Grid>
        </Content>
        <Controls>
          <Button className={classes.button} type="submit" onClick={goNext}>
            {i18n.user_agreement_agree}
          </Button>
        </Controls>
      </Wrapper>
    </Wrapper>
  )
}
