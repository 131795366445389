import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { I18n } from '../../../features/translation/types'
import { lsClient } from '../../../ls-client'
import { Button } from '../../../ui'
import { paths } from '../../paths'
import { resetQuestionnaireV2Store } from '../questionnaireV2Slice'
import { Outcome } from '../types'

export const Instructions = (props: { outcome: Outcome; i18n: I18n }) => {
  const { outcome, i18n } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const finishQuestionnaire = () => {
    dispatch(resetQuestionnaireV2Store())
    lsClient.removeUserKeyLS('VCquestionnaireId')
    history.push(paths.app.dashboard())
  }

  return (
    <div className={classes.root}>
      <Box className={classes.messageContainer}>
        <Box>
          {outcome.image && (
            <img
              className={classes.outcomeImage}
              src={outcome.image}
              alt="result-questionnaire-image"
            />
          )}
          {outcome.description ? (
            <Typography
              variant="h5"
              className={classes.outcomeResultText}
              align="center"
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                __html: outcome.description?.translations?.eng,
              }}
            ></Typography>
          ) : (
            <Typography>{i18n.error_outcome_info}</Typography>
          )}
        </Box>
        <Button className={classes.button} onClick={finishQuestionnaire}>
          {i18n.ok_button}
        </Button>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  messageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 45,
  },
  questionTitle: {
    color: '#54565b',
    fontSize: 23,
    textAlign: 'center',
  },
  outcomeImage: {
    width: '70%',
    maxWidth: '200px',
    display: 'block',
    margin: '0 auto',
  },
  outcomeResultText: {
    'marginTop': 40,
    'color': '#54565b',
    'fontFamily': 'Avenir Next Rounded Medium',
    'fontSize': 24,
    '& p': {
      textAlign: 'center',
    },
    [theme.breakpoints.up(600)]: {
      maxWidth: 500,
    },
  },
  button: {
    maxHeight: 45,
    margin: '0 auto',
  },
}))
