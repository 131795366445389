import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ScanError from '../../../assets/img/dialogs/qr-scan-error.png'
import { lsClient } from '../../../ls-client'
import { paths } from '../../../pages/paths'
import {
  resetScanPage,
  setActive,
} from '../../../pages/register-a-test-module/model'
import { RegisterTestStepType } from '../../../pages/register-a-test-module/types'
import { AppDialogWrapper } from '../dialog-wrapper'
import { SimpleDialogContent } from '../helpers/simple-dialog-content'
import { resetDialogStore, selectDialogOptions } from '../model'
import { DialogProps } from '../types'

export const TestScanErrorDialog = (props: DialogProps) => {
  const options = useSelector(selectDialogOptions)
  const history = useHistory()
  const dispatch = useDispatch()
  const onCancel = () => {
    lsClient.removeUserKeyLS('testId')
    dispatch(setActive(0))
    history.push(paths.registerTest(RegisterTestStepType.INTRO))
    dispatch(resetDialogStore())
  }

  const tryAgain = () => {
    dispatch(resetScanPage(true))
    dispatch(resetDialogStore())
  }

  return (
    <AppDialogWrapper disableClickAway>
      <SimpleDialogContent
        {...props}
        {...options}
        image={ScanError}
        onCancel={onCancel}
        onConfirm={tryAgain}
      />
    </AppDialogWrapper>
  )
}
