import MUICheckbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { getColor } from '../../../libs/utils'
import { Color } from '../../../types/general'

interface RoundedCheckboxProps {
  checked: boolean
  color: Color
  onChange?: (e: React.ChangeEvent) => void
}
export const RoundedCheckbox = ({
  checked = false,
  onChange,
  color,
}: RoundedCheckboxProps) => {
  const classes = useStyles({ color })

  return (
    <MUICheckbox
      classes={{
        root: classes.checkmarkRound,
        checked: classes.checkboxActiveRound,
      }}
      disableRipple
      checked={checked}
      onChange={onChange}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  checkmarkRound: {
    'margin': '2px 17px 0px -8px',
    '&:before': {
      content: '""',
      display: 'inline-flex',
      width: 16,
      height: 16,
      borderRadius: '50%',
      border: '2px solid',
      borderColor: '#505358',
      flexShrink: 0,
      backgroundColor: '#FFF',
    },
    '&.MuiCheckbox-colorSecondary:hover': {
      backgroundColor: '#fff',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  checkboxActiveRound: {
    '&:before': {
      borderColor: ({ color }: { color: Color }) => getColor(color, theme),
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      border: '5px solid',
      borderColor: ({ color }: { color: Color }) => getColor(color, theme),
    },
  },
}))
