import moment from 'moment'
import * as Yup from 'yup'
import { StepType } from '../../../constants'
import { I18n } from '../../translation/types'
import { UserDataMethods } from './types'

const NOT_ALLOWED = 'Value not allowed'

const shippingAddressValidation = (i18n: I18n) => {
  const line1 = Yup.string().required(
    i18n['ecommerce.errors.address_validation.line1']
  )

  const city = Yup.string().required(
    i18n['ecommerce.errors.address_validation.city']
  )

  const state = Yup.string().required(
    i18n['ecommerce.errors.address_validation.state']
  )

  const country = Yup.string().required(
    i18n['ecommerce.errors.address_validation.country']
  )

  const zip = Yup.string()
    .min(5, i18n.invalid_zip_code_error_message)
    .max(5, i18n.invalid_zip_code_error_message)
    .matches(/^(?!0{5})/, NOT_ALLOWED)
    .required(i18n['ecommerce.errors.address_validation.zip'])

  return Yup.object().shape({
    [UserDataMethods.SHIPPING_ADDRESS]: Yup.object().shape({
      address: Yup.object().shape({
        line1,
        city,
        state,
        country,
        zip,
      }),
    }),
  })
}

const creditCardValidation = (i18n: I18n) => {
  const cardNumber = Yup.string().required(i18n.required)

  const cvc = Yup.string()
    .min(3, i18n.cvc_invalid)
    .max(4, i18n.cvc_invalid)
    .required(i18n.required)

  const expiration = Yup.string()
    .min(5, NOT_ALLOWED)
    .max(5, NOT_ALLOWED)
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, NOT_ALLOWED)
    .test({
      name: 'date-in-future',
      message: 'Card is expired',
      test: (value) => {
        if (!value) return true
        const [mm, yy] = value.split('/')
        const lastDayOfMonth = new Date(
          Number(yy) + 2000,
          Number(mm),
          0
        ).getDate()
        const dateObj = new Date(
          Number(yy) + 2000,
          Number(mm) - 1,
          lastDayOfMonth
        )
        return dateObj.getTime() > new Date().getTime()
      },
    })
    .required(i18n.required)

  const cardName = Yup.string()
    .matches(/^(?! )[A-Za-z\s]+$/, NOT_ALLOWED)
    .required(i18n.required)

  const zip = Yup.string()
    .min(5, i18n.invalid_zip_code_error_message)
    .max(5, i18n.invalid_zip_code_error_message)
    .matches(/^(?!0{5})/, NOT_ALLOWED)
    .required(i18n.required)

  return Yup.object().shape({
    cardNumber,
    cvc,
    expiration,
    cardName,
    zip,
  })
}

const BillingAddressValidation = (i18n: I18n) => {
  const line1 = Yup.string().required(i18n.required)

  const city = Yup.string().required(i18n.required)

  const state = Yup.string().required(i18n.required)

  const zip = Yup.string()
    .min(5, i18n.invalid_zip_code_error_message)
    .max(5, i18n.invalid_zip_code_error_message)
    .matches(/^(?!0{5})/, NOT_ALLOWED)
    .required(i18n.required)

  return Yup.object().shape({
    [UserDataMethods.BILLING_ADDRESS]: Yup.object().shape({
      address: Yup.object().shape({
        line1,
        city,
        state,
        zip,
      }),
    }),
  })
}

const insuranceValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    [StepType.INSURANCE]: Yup.object().shape({
      insuranceProviderCompany: Yup.object().nullable().required(i18n.required),
      insuranceId: Yup.string().required(i18n.required),
      groupNumber: Yup.string().required(i18n.required),
      planName: Yup.string().required(i18n.required),
      coverageDate: Yup.date()
        .nullable()
        .typeError(i18n.invalid_date)
        .required(i18n.required),
    }),
  })

const policyInfoValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    [StepType.POLICY_INFO]: Yup.object().shape({
      firstName: Yup.string().required(i18n.required),
      lastName: Yup.string().required(i18n.required),
      phone: Yup.string()
        .min(17, i18n.invalid_phone_error_message)
        .max(17, i18n.invalid_phone_error_message)
        .matches(/^(?!0{10})/, NOT_ALLOWED)
        .matches(/^(?!0+[1-9])/, NOT_ALLOWED)
        .required(i18n.required),
      dob: Yup.date()
        .nullable()
        .typeError(i18n.invalid_date)
        .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
        .test({
          name: 'furture-date',
          message: 'Date of birth cannot be a date in the future.',
          test: (value) => {
            if (!value) return true
            return new Date() > new Date(value)
          },
        })
        .max(
          moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
          'User must be at least 18 years old to create an account.'
        )
        .required(i18n.required),
      relationship: Yup.string().required(i18n.required),
      address: Yup.object().shape({
        line1: Yup.string().required(i18n.required),
        line2: Yup.string(),
        city: Yup.string().required(i18n.required),
        state: Yup.string().required(i18n.required),
        zipCode: Yup.string()
          .min(5, i18n.invalid_zip_code_error_message)
          .max(5, i18n.invalid_zip_code_error_message)
          .matches(/^(?!0{5})/, NOT_ALLOWED)
          .required(i18n.required),
      }),
    }),
  })

export const getValidationSchema = (i18n: I18n, type: string) => {
  switch (type) {
    case UserDataMethods.PAYMENT_METHOD:
      return creditCardValidation(i18n)
    case UserDataMethods.BILLING_ADDRESS:
      return BillingAddressValidation(i18n)
    case UserDataMethods.SHIPPING_ADDRESS:
      return shippingAddressValidation(i18n)
    // case StepType.POLICY_INFO:
    //   return policyInfoValidationSchema(i18n)
    default:
      return null
  }
}
