import Box from '@material-ui/core/Box'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {
  Content,
  ContentInner,
  useStyles,
  Wrapper,
  Controls,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'
import VerifiedIcon from '../../assets/icons/Group@3x.png'
import { Button, Typography } from '../../ui'
import { paths } from '../paths'
import { selectDropOffPickupLocation } from './shippingTestSlice'

export const DropOffVerifiedView = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()

  return (
    <Wrapper>
      <Box className={classes.contentStyles}>
        <Content style={{ justifyContent: 'center', margin: 0, padding: '32px 20px 20px 20px' }}>
          <ContentInner>
            <img
              src={VerifiedIcon}
              style={{ color: 'white', width: 100, height: 100 }}
            />
            <Box className={classes.verifyingProcessText} fontSize={20} maxWidth="90%">
              {i18n.drop_off_within_24_message}
            </Box>
          </ContentInner>
          <Snackbar />
        </Content>
        <Controls style={{ paddingBottom: 35 }}>
          <Button
            className={childClasses.button}
            onClick={() => history.push(paths.app.empty())}
          >{i18n.ok_button}</Button>
        </Controls>
      </Box>
    </Wrapper>
  )
}

const Snackbar = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const selectedLocation = useSelector(selectDropOffPickupLocation)
  const { type, id } = useParams<{ type: string; id: string }>()
  const history = useHistory()

  if (!selectedLocation) history.push(paths.dropOff(type, id))

  return (
    <Box className={childClasses.optionWrapper}>
      <Box className={classes.option}>
        <Typography className={classes.testName}>
          {selectedLocation?.name}
        </Typography>
        <Typography variant="subtitle2" className={childClasses.description}>
          {`${selectedLocation?.address1},`}
        </Typography>
        <Typography variant="subtitle2" className={childClasses.description}>
          {`${selectedLocation?.city}, ${
            selectedLocation?.state
          } ${selectedLocation?.zip.slice(0, 5)}`}
        </Typography>
        {selectedLocation?.comment && (
          <>
            <br />
            <Typography className={childClasses.description}>
              {selectedLocation?.comment}
            </Typography>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box className={childClasses.rightChevron}>
          <ChevronRightIcon />
        </Box>
      </Box>
    </Box>
  )
}

const childStyles = makeStyles({
  optionWrapper: {
    padding: '15px 15px',
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E7EA',
    maxWidth: 475,
    cursor: 'pointer',
    backgroundColor: '#f3f3f3',
    width: '90%',
    marginTop: '40px',
  },
  description: {
    color: '#7B7C7D',
  },
  rightChevron: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row-reverse',
    alignSelf: 'center',
    color: '#003C72',
    fontSize: 16,
    fontFamily: 'Avenir Next Rounded Regular',
    lineHeight: '22px',
    fontWeight: 900,
  },
  button: {
    'backgroundColor': 'white',
    'color': '#003C72',
    '& :hover': {
      backgroundColor: 'white',
      color: '#003C72',
    },
  },
})
