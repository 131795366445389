import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { completeHealthService } from '../../../../features/health-service-lite/model'
import { selectLanguageContent } from '../../../../features/translation'
import { I18n } from '../../../../features/translation/types'
import { clearTestKitFlow } from '../../../../libs/helpers'
import { lsClient } from '../../../../ls-client'
import { Button } from '../../../../ui'
import { paths } from '../../../paths'
import { resetQuestionnaireV2Store } from '../../questionnaireV2Slice'
import { DispositionScreen } from '../../types'

export const ResultEarlyExitDisposition = (props: {
  dispositionType: DispositionScreen
}) => {
  const { dispositionType } = props
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const provider_phone = '646-555-5864'
  const [message, setMessage] = useState(
    dispositionType.body.replace('$provider_phone', provider_phone)
  )

  const endTest = () => {
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    const paramsForHS = {
      status: 'Completed',
    }

    const callabck = () => {
      lsClient.removeUserKeyLS('VCquestionnaireId')
      lsClient.removeUserKeyLS('healthServiceId')
      lsClient.removeUserKeyLS('patientTestId')
      clearTestKitFlow()
      dispatch(resetQuestionnaireV2Store())
      history.push(paths.app.dashboard())
    }
    dispatch(completeHealthService(healthServiceId, paramsForHS, callabck))
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.placedText}>
          <Box className={classes.loadingContent}>
            <Box>{dispositionType.image}</Box>
          </Box>
          <Typography className={classes.title}>
            {dispositionType.title}
          </Typography>
          <Typography align="center" className={classes.processText}>
            {message}
          </Typography>
        </Box>
        <Box>
          <Button onClick={endTest} className={classes.loadingButton}>
            Return Home
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    // maxWidth: '400px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: '600px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '80px',
  },
  title: {
    fontWeight: 500,
    fontSize: '2rem',
    color: '#FFF',
  },
  processText: {
    fontSize: 16,
    color: '#FFF',
  },
  loadingButton: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 48,
    fontWeight: 600,
    marginBottom: 24,
    marginLeft: 15,
    marginRight: 15,
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
}))
