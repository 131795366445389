import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { TermsAndCondsPage } from './appTerms'

export const TermsAndCondsPageWapper = () => {
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)

  const onBack = () => {
    history.goBack()
  }

  return (
    <ModuleHeader
      leftContentAction={onBack}
      leftContentTitle={i18n.back_button}
      color="#505358"
    >
      <TermsAndCondsPage />
    </ModuleHeader>
  )
}

