import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Button, Loading } from '../../ui'
import DateInput from '../../libs/datepicker'
import { SubHeaderGetTested } from '../../features/application'
import {
  useStyles,
  Wrapper,
  Controls,
  Content,
} from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'
import { selectSelectedFacility } from '../checkout/model'
import { paths } from '../paths'
import { ConfirmationDialog } from './confirmationDialog'
import {
  getAvailableQuestSlots,
  selectAvailableSlots,
  selectScheduleAppointmentError,
  selectIsLoading,
  selectIsLoadingBook,
  bookAppointment,
} from './model'

export const ScheduleAppointmentTimePage = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const { type, id, siteCode } = useParams<{
    type: string
    id: string
    siteCode: string
  }>()
  const i18n = useSelector(selectLanguageContent)
  const selectedFacility = useSelector(selectSelectedFacility)
  const isLoadingBook = useSelector(selectIsLoadingBook)

  // eslint-disable-next-line sonarjs/no-duplicate-string
  const [date, setDate] = useState(moment().format('D MMM YYYY'))
  const [time, setTime] = useState('')
  const [open, setOpen] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAvailableQuestSlots(siteCode, date))
  }, [])

  const goBack = () => {
    history.goBack()
  }

  const goNext = () => {
    setOpen(true)
  }

  const handleChangeDate = (date: string) => {
    setTime('')
    setDate(moment(date, 'MMM DD, YYYY').format('D MMM YYYY'))
    dispatch(
      getAvailableQuestSlots(
        siteCode,
        moment(date, 'MMM DD, YYYY').format('D MMM YYYY')
      )
    )
  }

  const handleConfirm = async () => {
    // history.push(paths.appointmentInsurance())

    const payload = {
      date,
      time: moment(time, 'h:mm A').format('H:m'),
      siteCode,
      isCovidAntibodyTest: selectedFacility?.isCovidAntibody,
    }

    if (await dispatch(bookAppointment(payload))) {
      history.push(paths.checkout(type, id))
    }
  }

  if (!selectedFacility) {
    history.push(paths.facility(type, id))
  }
  return (
    <>
      <Wrapper>
        <SubHeaderGetTested
          title={i18n.schedule_appointment_screen_title}
          onHandleBack={goBack}
        />
        <Content style={{ padding: 0 }}>
          <Box className={childClasses.datePickerStyle}>
            <DateInput handleChange={handleChangeDate} value={date} />
            <TimeSelect time={time} setTime={setTime} />
          </Box>
        </Content>
        <Controls>
          <Button onClick={goNext} className={classes.button} disabled={!time}>
            {i18n.continue_button}
          </Button>
        </Controls>
      </Wrapper>
      <ConfirmationDialog
        isOpen={open}
        info={{
          time,
          date,
          selectedLocation: selectedFacility,
        }}
        isLoading={isLoadingBook}
        onConfirm={handleConfirm}
        title={i18n.appointment_confirmation_title}
        onCancel={() => setOpen(false)}
      />
    </>
  )
}

const TimeSelect = (props: { time: string; setTime: any }) => {
  const { time, setTime } = props
  const availableSlots = useSelector(selectAvailableSlots)
  const isLoading = useSelector(selectIsLoading)
  const error = useSelector(selectScheduleAppointmentError)
  const classes = childStyles()

  return !isLoading && !error ? (
    <Box className={classes.timeSelectWrapper}>
      {availableSlots.map((slot: { hour: number; min: number }) => {
        const displayTime = moment(`${slot.hour}:${slot.min}`, 'H:m').format(
          'h:mm A'
        )
        return (
          <Button
            onClick={() => setTime(displayTime)}
            className={
              displayTime === time
                ? classes.selectedButton
                : classes.calendarButton
            }
          >
            {displayTime}
          </Button>
        )
      })}
    </Box>
  ) : error ? (
    <Box style={{ textAlign: 'center' }}>{error}</Box>
  ) : (
    <Loading />
  )
}

const childStyles = makeStyles((theme) => ({
  datePickerStyle: {
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      '& .MuiPickersBasePicker-container': {
        'width': 370,
        '& .MuiPickersToolbar-toolbar': {
          backgroundColor: theme.palette.primary.main,
        },
        '& .MuiPickersBasePicker-pickerView': {
          alignSelf: 'center',
        },
      },
      '& .MuiPickersSlideTransition-transitionContainer': {
        '& div': {
          '& .MuiPickersCalendar-week': {
            '& div': {
              '& .MuiPickersDay-daySelected': {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  },
  calendarButton: {
    minWidth: 0,
    maxWidth: 180,
    height: 48,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#54565B',
    fontSize: 14,
    fontWeight: 900,
    border: '1px solid #C1C1C1',
    alignSelf: 'center',
    width: '100%',
    margin: 5,

    // '&:hover': {
    //   backgroundColor: '#003C71',
    //   color: '#FFF',
    //   border: 'none',
    // },
  },
  selectedButton: {
    'minWidth': 110,
    'maxWidth': 180,
    'height': 48,
    'borderRadius': 8,
    'fontSize': 14,
    'fontWeight': 900,
    'alignSelf': 'center',
    'width': '100%',
    'marginBottom': 10,
    'backgroundColor': theme.palette.primary.main,
    // 'color': '#FFF',
    'border': 'none',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      border: 'none',
    },
  },
  timeSelectWrapper: {
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 110px)',
    gridGap: 10,
    justifyItems: 'center',
    justifyContent: 'center',
  },
}))