import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import WhiteCheckMark from '../../../assets/animations/WhiteCheckMark.json'
import { paths } from '../../../pages/paths'
import { Button } from '../../../ui'
import { selectLanguageContent } from '../../translation'

export const HSBeInTouch = () => {
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const provider_phone = '646-555-5864'

  const endTest = () => {
    history.push(paths.app.dashboard())
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.placedText}>
          <Box className={classes.loadingContent}>
            <Lottie animationData={WhiteCheckMark} loop={true} />
          </Box>
          <Typography className={classes.title}>We’ll be in touch!</Typography>
          <Typography align="center" className={classes.processText}>
            {`A licensed care provider will call you shortly to discuss your treatment plan. Please be ready to answer a call from ${provider_phone}.`}
          </Typography>
        </Box>
        <div className={classes.buttonContainer}>
          <Button onClick={endTest} className={classes.loadingButton}>
            Return Home
          </Button>
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    // maxWidth: '400px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: '600px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    height: '200px',
    width: '200px',
    textAlign: 'center',
    marginTop: '80px',
  },
  title: {
    fontWeight: 500,
    fontSize: '2rem',
    color: '#FFF',
  },
  processText: {
    fontSize: 16,
    color: '#FFF',
  },
  buttonContainer: {
    width: '100%',
  },
  loadingButton: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 48,
    fontWeight: 600,
    marginBottom: 24,
    marginLeft: 15,
    marginRight: 15,
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
}))
