import React, { useEffect, useState } from 'react'
import DoorDash from '../../../../assets/img/DoorDash-logo.png'
import { shopifyStoreStyles } from '../../../../pages/_styles/shopifyStoreStyles'
import { Button, RoundedCheckbox, Typography } from '../../../../ui'
import { I18n } from '../../../translation/types'

export const ShippingMethod = (props: {
  onChange: (method: any) => void
  i18n: I18n
  sdk: any
  currentSelectedMethod: any
}) => {
  const { i18n, onChange, sdk, currentSelectedMethod } = props
  const classes = shopifyStoreStyles()
  const [isLoading, setIsloading] = useState(false)
  const [shippingMethods, setShippingMethods] = useState<any>()
  const [selectedMethod, setSelectedMethod] = useState<any>(
    currentSelectedMethod
  )

  useEffect(() => {
    setIsloading(true)
    loadShippingMethods()
  }, [])

  const onSelectMethod = (methodData: any) => {
    setSelectedMethod(methodData)
  }

  const handleContinue = () => {
    onChange(selectedMethod)
  }

  function loadShippingMethods() {
    if (isLoading) {
      return
    }
    setIsloading(true)
    sdk.checkoutManager
      .getShippingMethods()
      .then((response: any) => {
        setShippingMethods(response)
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setIsloading(false)
      })
  }

  return (
    <div className={classes.checkoutMethodContent}>
      <div className={classes.titleWrapper}>
        <Typography variant="h2" className={`${classes.pageTitle} noPadding`}>
          {i18n['ecommerce.shipping_method_header']}
        </Typography>
      </div>
      <div className={classes.entries}>
        {shippingMethods &&
          shippingMethods.length > 0 &&
          shippingMethods.map((m: any) => (
            <Methods
              key={m.identifier}
              shippingMethod={m}
              selected={selectedMethod?.identifier === m.identifier}
              onSelectMethod={onSelectMethod}
              i18n={i18n}
            />
          ))}
      </div>
      <div className={classes.lgButtonBox}>
        <Button
          onClick={() => handleContinue()}
          className={classes.dialogButton}
          disabled={!selectedMethod}
        >
          {i18n.continue_button}
        </Button>
      </div>
    </div>
  )
}

const Methods = (props: {
  shippingMethod: any
  selected: boolean
  i18n: I18n
  onSelectMethod: (id: string) => void
}) => {
  const { shippingMethod, selected, onSelectMethod, i18n } = props
  const classes = shopifyStoreStyles()

  const getDeliveryText = (methodTitle: string) => {
    switch (methodTitle) {
      case 'Standard Delivery':
      case 'Standard Shipping':
      case 'Standard': {
        return (
          <Typography className={classes.summaryLineText}>
            <span style={{ fontWeight: 600 }}>
              {i18n['ecommerce.shipping_method.standard.default_title']}
            </span>{' '}
            {`(${i18n['ecommerce.shipping_method.standard.default_description']})`}
          </Typography>
        )
      }
      case 'Express Delivery':
      case 'Express Shipping':
      case 'Express': {
        return (
          <Typography className={classes.summaryLineText}>
            <span style={{ fontWeight: 600 }}>
              {i18n['ecommerce.shipping_method.express.default_title']}
            </span>{' '}
            {`(${i18n['ecommerce.shipping_method.express.default_description']})`}
          </Typography>
        )
      }
      case 'Priority Delivery':
      case 'Priority Shipping':
      case 'Priority': {
        return (
          <Typography className={classes.summaryLineText}>
            <span style={{ fontWeight: 600 }}>
              {i18n['ecommerce.shipping_method.priority.default_title']}
            </span>{' '}
            {`(${i18n['ecommerce.shipping_method.priority.default_description']})`}
          </Typography>
        )
      }
      case 'Free Delivery':
      case 'Free Shipping':
      case 'Free': {
        return (
          <Typography className={classes.summaryLineText}>
            <span style={{ fontWeight: 600 }}>
              {i18n['ecommerce.shipping_method.free.default_title']}
            </span>{' '}
            {`(${i18n['ecommerce.shipping_method.free.default_description']})`}
          </Typography>
        )
      }
      default:
        return (
          <Typography className={classes.summaryLineText}>
            <span style={{ fontWeight: 600 }}>{methodTitle}</span>
          </Typography>
        )
    }
  }

  return (
    <div
      className={classes.methodSelectionWrapper}
      onClick={() => onSelectMethod(shippingMethod)}
    >
      <div className={classes.methodSelectionContent}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {shippingMethod.isDoorDash ? (
            <>
              <Typography className={classes.summaryLineText}>
                <span style={{ fontWeight: 600 }}>Delivery via</span>
              </Typography>
              <img
                src={DoorDash}
                alt="Door Dash"
                style={{ height: 16, marginLeft: 8 }}
              />
            </>
          ) : (
            <>{getDeliveryText(shippingMethod.methodTitle)}</>
          )}
        </div>
        <RoundedCheckbox checked={selected} color="#757575" />
      </div>
    </div>
  )
}
