export const statusColorHelper = {
  COMPLETED: {
    color: 'SUCCESS',
  },
  CANCELLED: {
    color: 'ERROR',
  },
  APPOINTMENT_SCHEDULED: {
    color: 'WARNING',
  },
  SCAN_COLLECTED: {
    color: 'WARNING',
  },
  TESTKIT_PAIRED: {
    color: 'WARNING',
  },
  TESTKIT_DISPATCHED: {
    color: 'WARNING',
  },
  TESTKIT_PICKUP_SCHEDULED: {
    color: 'WARNING',
  },
  TESTKIT_PICKUP_CANCELLED: {
    color: 'WARNING',
  },
  TESTKIT_DROPOFF_CONFIRMED: {
    color: 'WARNING',
  },
  TESTKIT_ORDER_PLACED: {
    color: 'WARNING',
  },
  SHIPPED: {
    color: 'WARNING',
  },
  PENDING_RESULTS: {
    color: 'WARNING',
  },
  INITIALIZED: {
    color: 'WARNING',
  },
}
