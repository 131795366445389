import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined'
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined'
import { FormikProps } from 'formik'
import React from 'react'
import { I18n } from '../../../../features/translation/types'
import { formatPhoneForDisplayUS } from '../../../../libs/utils'
import { Typography } from '../../../../ui'
import { ProfileData } from '../../../profile/type'
import { CurrentOptions } from '../../../questionnaireV2/types'
import { telehealthStyles } from '../../components/styles'
import { FormValues } from './formik/types'

export const CurrentPhoneNumber = (props: {
  i18n: I18n
  profileData: ProfileData
  formik: FormikProps<FormValues>
  type: CurrentOptions
  setEditType: (type: CurrentOptions) => void
}) => {
  const { i18n, profileData, setEditType, type, formik } = props
  const classes = telehealthStyles()

  const phoneNumber = formik.values.phone
    ? formatPhoneForDisplayUS(formik.values.phone)
    : ''

  return (
    <div className={classes.container}>
      <Typography className={classes.locationTitle}>
        {i18n.telehealth_intake_current_phone_num}
      </Typography>
      <div className={classes.phoneContainer}>
        <LocalPhoneOutlinedIcon
          color="primary"
          style={{ alignSelf: 'flex-start', marginRight: '8px' }}
        />
        <Typography>{phoneNumber}</Typography>
        <span onClick={() => setEditType(type)} className={classes.editLink}>
          {i18n.telehealth_intake_edit_action}
        </span>
      </div>
      <Typography className={classes.locationBody}>
        {i18n.telehealth_safe_intake_phone_num_msg}
      </Typography>
      {profileData.email && !profileData.contact?.mobileNumber && (
        <Typography variant="body1" className={classes.errorMessage}>
          {i18n.telehealth_intake_missing_data}
        </Typography>
      )}
    </div>
  )
}

export const CurrentLocation = (props: {
  i18n: I18n
  profileData: ProfileData
  type: CurrentOptions
  formik: FormikProps<FormValues>
  setEditType: (type: CurrentOptions) => void
  hasMissingData: boolean
}) => {
  const { i18n, setEditType, type, hasMissingData, formik } = props
  const classes = telehealthStyles()

  const { values } = formik

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <Typography className={classes.locationTitle}>
          {i18n.current_location}
        </Typography>
        <div>
          <div className={classes.locationContainer}>
            <NearMeOutlinedIcon
              color="primary"
              style={{ alignSelf: 'flex-start', marginRight: '8px' }}
            />
            <div>
              {values.line1 && <Typography>{values.line1}</Typography>}
              {values.line2 && <Typography>{values.line2}</Typography>}
              <Typography>
                {[
                  values.city ? `${values.city},` : '',
                  values.state,
                  values.zip,
                ].join(' ')}
              </Typography>
            </div>
            <span
              onClick={() => setEditType(type)}
              className={classes.editLink}
            >
              {i18n.telehealth_intake_edit_action}
            </span>
          </div>
        </div>
      </div>
      {hasMissingData && (
        <Typography variant="body1" className={classes.errorMessage}>
          {i18n.telehealth_intake_missing_data}
        </Typography>
      )}
    </div>
  )
}
