import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuestionnaireCheckbox } from '../../../features/questionnaire/atoms'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  setAutoSubmit,
  setCanContiune,
  updateAnswer,
} from '../questionnaireV2Slice'
import { MultipleChoiceAnswer, Option, QuestionType } from '../types'

const NONE = 'None of the above'

export const MultipleQuestion = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const existingAnswer = useSelector(selectExistingAnswer)

  const question = useSelector(selectCurrentQuestion)
  const [answer, setAnswer] = useState<string[]>([])

  useEffect(() => {
    const answers = existingAnswer?.value.split(', ')
    if (answers) {
      setAnswer(answers)
      dispatch(updateAnswer(createAnswer(answers)))
    }
  }, [existingAnswer])

  const createAnswer = (answerUpdate: string[]) => {
    const updatedAnswer: MultipleChoiceAnswer[] = []
    answerUpdate.forEach((answerValue) => {
      const answerOption = {
        answer: answerValue,
        nodeId: null,
        comment: null,
        type: QuestionType.SmallText,
      }
      updatedAnswer.push(answerOption)
    })
    return updatedAnswer
  }

  const multipleAnswerChanged = (value: string) => {
    if (question.multiSelect) {
      const answerUpdate = [...answer]
      if (answerUpdate.includes(value)) {
        answerUpdate.splice(answerUpdate.indexOf(value), 1)
      } else {
        if (answerUpdate.includes(NONE)) {
          answerUpdate.splice(answerUpdate.indexOf(NONE, 1))
        }
        answerUpdate.push(value)
      }

      if (answerUpdate.includes(NONE)) {
        setAnswer([NONE])
        createAnswer([NONE])
        dispatch(updateAnswer(createAnswer([NONE])))
      } else {
        setAnswer(answerUpdate)
        createAnswer(answerUpdate)
        dispatch(updateAnswer(createAnswer(answerUpdate)))
        if (answerUpdate.length === 0) {
          dispatch(setCanContiune(false))
        }
      }
    } else {
      setAnswer([value])
      dispatch(updateAnswer(createAnswer([value])))
      dispatch(setAutoSubmit(true))
    }
  }

  const optionList = () => {
    return question.options?.map((option: Option, i: number) => (
      <>
        <QuestionnaireCheckbox
          key={option.value.translations.eng}
          checked={answer.includes(option.value.translations.eng)}
          onChange={() => multipleAnswerChanged(option.value.translations.eng)}
          label={option.value.translations.eng}
          type={question.multiSelect ? 'checkbox' : 'radio'}
        />
        {question.options?.length && i !== question.options.length - 1 && (
          <Divider />
        )}
      </>
    ))
  }

  return (
    <div className={classes.root} id="content">
      {optionList()}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.down(600)]: {
      paddingBottom: '50px',
    },
  },
}))

const Divider = styled.div`
  width: 100%;
  border-bottom: solid 1px #eaeaea;
`
