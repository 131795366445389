import { Typography } from '@material-ui/core'
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp'
import React from 'react'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { UserDataMethods } from '../model/types'

export const CheckoutUserData = (props: {
  id: UserDataMethods
  title: string
  actionText: string | undefined
  hide: boolean
  icon: React.ReactNode | (() => React.ReactNode)
  handleOpen: (prop1: UserDataMethods) => void
}) => {
  const { id, title, actionText, hide, icon, handleOpen } = props
  if (hide) return null
  const classes = shopifyStoreStyles()

  return (
    <div className={classes.dataCardWrapper} onClick={() => handleOpen(id)}>
      <div className={classes.dataContentWrapper}>
        <Typography className={classes.checkoutMethodTitle}>{title}</Typography>
        <div className={classes.infoWrapper}>
          <div className={classes.iconWrapper}>{icon}</div>
          <Typography className={classes.summaryLineText}>
            {actionText}
          </Typography>
        </div>
      </div>
      <ChevronRightSharpIcon style={{ color: '#757575' }} />
    </div>
  )
}
