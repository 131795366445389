import styled from '@emotion/styled'
import { Box, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { HTMLTextComponent } from '../../libs/shared-components'
import { Loading, Typography } from '../../ui'
import { BackHeader } from '../../ui/templates/back-header'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { paths } from '../paths'
import {
  ListArticleResource,
  ListVideoResource,
} from './components/resourceCards'
import {
  getArticleResources,
  getVideoResources,
  selectArticlesList,
  selectArticlesLoading,
  selectResourcesLoaded,
  selectResourcesSearch,
  selectVideosList,
  selectVideosLoading,
} from './resourcesSlice'
import { ArticleResource, ResourceType, VideoResource } from './types'

export const ResourceDetailsPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { resourceId, type } = useParams<{ resourceId: string; type: string }>()
  const videoResources = useSelector(selectVideosList)
  const articleResources = useSelector(selectArticlesList)
  const [video, setVideo] = useState<VideoResource>()
  const [article, setArticle] = useState<ArticleResource>()
  const [relatedArticles, setRelatedArticles] = useState<ArticleResource[]>()
  const [relatedVideos, setRelatedVideos] = useState<VideoResource[]>()
  const relatedContainer = useRef<HTMLDivElement>(null)
  const resourcesLoaded = useSelector(selectResourcesLoaded)
  const videosLoading = useSelector(selectVideosLoading)
  const articlesLoading = useSelector(selectArticlesLoading)
  const [resourceIndex, setResourceIndex] = useState(0)
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))
  const searchValueState = useSelector(selectResourcesSearch)

  const i18n = useSelector(selectLanguageContent)

  useEffect(() => {
    if (!resourcesLoaded) {
      dispatch(getVideoResources(searchValueState))
      dispatch(getArticleResources(searchValueState))
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (relatedContainer.current) {
      relatedContainer.current.scroll({ left: 0, behavior: 'smooth' })
    }
    if (resourceId && type === 'video' && videoResources.length > 0) {
      setResourceIndex(
        videoResources.findIndex((res: VideoResource) => res._id === resourceId)
      )
      const foundResource = videoResources.find(
        (res: VideoResource) => res._id === resourceId
      )
      if (foundResource) {
        setVideo(foundResource)
        getRelated(foundResource.keywords)
      }
    } else if (
      resourceId &&
      type === 'article' &&
      articleResources.length > 0
    ) {
      setResourceIndex(
        articleResources.findIndex(
          (res: ArticleResource) => res._id === resourceId
        )
      )
      const foundResource = articleResources.find(
        (res) => res._id === resourceId
      )
      if (foundResource) {
        setArticle(foundResource)
        getRelated(foundResource.keywords)
      }
    }
  }, [resourceId, videoResources, articleResources])

  const getRelated = (keywords: string[]) => {
    const relatedVideo: VideoResource[] = []
    const relatedArticle: ArticleResource[] = []
    if (keywords && keywords.length > 0) {
      keywords.forEach((tag) => {
        const videos = videoResources.filter((r) => r.keywords.includes(tag))
        if (videos.length > 0) {
          videos.forEach((v) => {
            if (v._id === resourceId) return
            const found = relatedVideo.find((vr) => vr._id === v._id)
            if (!found) relatedVideo.push(v)
          })
        }
        const articles = articleResources.filter((r) =>
          r.keywords.includes(tag)
        )
        if (articles.length > 0) {
          articles.forEach((a) => {
            if (a._id === resourceId) return
            const found = relatedArticle.find((ar) => ar._id === a._id)
            if (!found) relatedArticle.push(a)
          })
        }
      })
    }
    setRelatedVideos(relatedVideo)
    setRelatedArticles(relatedArticle)
  }

  const next = () => {
    if (type === 'video') {
      if (resourceIndex !== videoResources.length - 1)
        history.push(
          paths.resourceDetails(type, videoResources[resourceIndex + 1]._id)
        )
      else if (articleResources.length > 0)
        history.push(paths.resourceDetails('article', articleResources[0]._id))
      else history.push(paths.resources())
    } else {
      if (resourceIndex !== articleResources.length - 1)
        history.push(
          paths.resourceDetails(type, articleResources[resourceIndex + 1]._id)
        )
      else history.push(paths.resources())
    }
  }

  const previous = () => {
    if (resourceIndex !== 0)
      history.push(
        paths.resourceDetails(type, videoResources[resourceIndex - 1]._id)
      )
    else if (type === 'article')
      history.push(
        paths.resourceDetails(
          'video',
          videoResources[videoResources.length - 1]._id
        )
      )
    else history.push(paths.resources())
  }

  if (videosLoading || articlesLoading) {
    return (
      <PageWrapper showBack={false}>
        <Box className={classes.loading}>
          <Loading />
        </Box>
      </PageWrapper>
    )
  }

  const nextRes = (type: 'next' | 'prev') => {
    if (!relatedContainer.current) return
    let scroll = relatedContainer.current.scrollLeft
    const cardWidth = matches ? 266 : 408
    type === 'next' ? (scroll += cardWidth) : (scroll -= cardWidth)
    const offset = scroll % cardWidth
    scroll -= offset
    relatedContainer.current.scroll({ left: scroll, behavior: 'smooth' })
  }

  const buttonLabel = (d: 'Next' | 'Prev') => {
    const RETURN_TO_LIST = 'Return to list'
    if (d === 'Next') {
      if (type === 'video') {
        if (
          resourceIndex !== videoResources.length - 1 ||
          articleResources.length > 0
        )
          return 'Next'
        return RETURN_TO_LIST
      }
      if (resourceIndex !== articleResources.length - 1) return 'Next'
      return RETURN_TO_LIST
    }
    if (type === 'video') {
      if (resourceIndex !== 0) return 'Previous'
      return RETURN_TO_LIST
    }
    return 'Previous'
  }
  const onClick = (type: ResourceType, id: string) => {
    history.push(paths.resourceDetails(type, id))
  }

  const Buttons = () => (
    <Box className={classes.lgButtonBox}>
      <div className={classes.backIconWrapper} onClick={() => previous()}>
        <ArrowBackIcon className={classes.buttonText} />
        <Typography className={classes.buttonText} style={{ marginLeft: 8 }}>
          {buttonLabel('Prev')}
        </Typography>
      </div>
      <div className={classes.backIconWrapper} onClick={() => next()}>
        <Typography className={classes.buttonText} style={{ marginRight: 8 }}>
          {buttonLabel('Next')}
        </Typography>
        <ArrowBackIcon className={`${classes.buttonText} next`} />
      </div>
    </Box>
  )

  return (
    <div className={classes.pageWrapper}>
      <BackHeader
        title={matches ? i18n.resources_screen_title : ''}
        backPath={paths.resources()}
      />
      <Box className={classes.resourceContainer}>
        <Content>
          {type === 'video' ? (
            <div className={classes.articleContainer}>
              {video?.thumbnailURL && !video?.mediaURL && (
                <ThumbnailURL
                  src={video?.thumbnailURL}
                  alt="thumbnail"
                  className={classes.img}
                />
              )}
              {video?.mediaURL && (
                <Video
                  src={video?.mediaURL}
                  controls
                  autoPlay
                  className={classes.img}
                />
              )}
              <Box className={classes.htmlContainer}>
                <Typography align="left" className={classes.articleTitle}>
                  {video?.title}
                </Typography>
                {video?.description && (
                  <Typography align="left" className={classes.articleContent}>
                    <HTMLTextComponent HTMLString={video.description} />
                  </Typography>
                )}
              </Box>
              <Buttons />
            </div>
          ) : (
            <Box className={classes.articleContainer}>
              {article?.imageURL && (
                <Box className={classes.imageContainer}>
                  <img
                    src={article?.imageURL}
                    alt="thumbnail"
                    className={classes.img}
                  />
                </Box>
              )}
              <Box
                className={
                  article?.imageURL
                    ? classes.htmlContainer
                    : classes.htmlContainerNoImage
                }
              >
                <Typography className={classes.articleTitle}>
                  {article?.title}
                </Typography>
                {article?.html && (
                  <Typography align="left" className={classes.articleContent}>
                    <HTMLTextComponent HTMLString={article.html} />
                  </Typography>
                )}
              </Box>
              <Buttons />
            </Box>
          )}
          {((relatedVideos && relatedVideos.length > 0) ||
            (relatedArticles && relatedArticles.length > 0)) && (
            <>
              <div className={classes.divider} />
              <div className={classes.articleContainer}>
                <Typography className={classes.articleTitle}>
                  Related Resources
                </Typography>
                <div
                  className={classes.relatedListContainer}
                  id="relatedListContainer"
                >
                  <ChevronRightSharpIcon
                    className={`${classes.iconFilled} left`}
                    onClick={() => nextRes('prev')}
                  />
                  <div className={classes.relatedList} ref={relatedContainer}>
                    {relatedVideos &&
                      relatedVideos.length > 0 &&
                      relatedVideos.map((res) => (
                        <ListVideoResource
                          key={res._id}
                          r={res}
                          onResourceClick={onClick}
                          grid={false}
                        />
                      ))}
                    {relatedArticles &&
                      relatedArticles.length > 0 &&
                      relatedArticles.map((res) => (
                        <ListArticleResource
                          key={res._id}
                          r={res}
                          onResourceClick={onClick}
                          grid={false}
                        />
                      ))}
                  </div>
                  <ChevronRightSharpIcon
                    className={classes.iconFilled}
                    onClick={() => nextRes('next')}
                  />
                </div>
              </div>
            </>
          )}
        </Content>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: '125px',
  },
  pageWrapper: {
    width: '100%',
    margin: '0px auto',
  },
  resourceContainer: {
    width: '100%',
    backgroundColor: '#FFF',
    margin: '78px auto',
    position: 'relative',
  },
  backIconWrapper: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: '0px',
  },
  buttonText: {
    'fontFamily': theme.typography.fontFamily,
    'marginLeft': 4,
    'cursor': 'pointer',
    'alignSelf': 'center',
    'marginTop': 1,
    'color': '#505358',
    'fontWeight': 600,
    '&.next': {
      transform: 'rotate(-180deg)',
    },
  },
  divider: {
    borderBottom: '1px solid #E0E0E0',
    margin: '32px 0px',
    width: '80vw',
    maxWidth: '1000px',
  },
  htmlContainerNoImage: {
    width: '80%',
    [theme.breakpoints.down(600)]: {
      height: 'calc(90vh)',
      overflow: 'scroll',
    },
  },
  htmlContainer: {
    'width': '100%',
    'overflow': 'auto',
    'marginTop': 32,
    '& span': {
      color: '#757575',
      fontFamily: theme.typography.fontFamily,
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 0,
      width: '90%',
    },
  },
  articleTitle: {
    fontSize: '36px',
    fontWeight: 600,
    color: '#282D37',
    lineHeight: '130%',
    marginBottom: 16,
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
    },
  },
  articleContent: {
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
    color: '#757575',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '48px auto',
  },
  img: {
    borderRadius: '8px',
    width: '85vw',
    maxWidth: '1000px',
    [theme.breakpoints.down(600)]: {
      margin: '16px 0px',
      maxWidth: 350,
    },
  },
  title: {
    color: '#505358',
    fontSize: '36px',
    lineHeight: '47px',
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
      lineHeight: '31px',
    },
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '85vw',
    maxWidth: 1000,
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    [theme.breakpoints.down(600)]: {
      padding: '24px 0px 32px',
      backgroundColor: '#FFF',
      position: 'fixed',
      bottom: 0,
      width: '85vw',
      alignItems: 'center',
    },
  },
  iconFilled: {
    'borderRadius': '100%',
    'fontSize': '28px',
    'padding': 6,
    'backgroundColor': 'rgba(0, 0, 0, 0.34)',
    'color': 'white',
    'cursor': 'pointer',
    '&.left': {
      marginLeft: '-48px',
      transform: 'rotate(-180deg)',
      [theme.breakpoints.down(600)]: {
        marginLeft: '-12px',
      },
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      padding: 6,
    },
  },
  relatedListContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(85vw)',
    maxWidth: '1062px',
  },
  relatedList: {
    'padding': '32px 30px',
    'backgroundColor': '#FFF',
    'display': 'flex',
    'gap': 16,
    'overflowY': 'auto',
    'width': 'calc(95vw + 162px)',
    'maxWidth': '1000px',
    '-webkit-mask-image':
      'linear-gradient(90deg, transparent 1%, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 97%, transparent 99%)',
    'maskImage':
      'linear-gradient(90deg, transparent 1%, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 97%, transparent 99%)',
  },
}))

const Video = styled.video`
  border-radius: 8px;
  max-height: 500px;
  min-height: 200px;
  width: 100%;
`
const ThumbnailURL = styled.img`
  width: 100%;
  border-radius: 8px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
