/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp'
import { format } from 'date-fns'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { I18n } from '../../../features/translation/types'
import { hexToRGBA } from '../../../libs/helpers'
import { Observer } from '../../../libs/observer'
import { capitalizeString } from '../../../libs/utils'
import { Loading, Typography } from '../../../ui'
import { paths } from '../../paths'
import { statusColorHelper } from '../helpers/resultMapper'
import { getTestResultsMore } from '../model/model'
import {
  selectIsDone,
  selectIsLoading,
  selectTestResults,
} from '../model/selectors'
import { AllowedResults, Status } from '../model/types'

export const TestResultsList = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const testResults = useSelector(selectTestResults)
  const isDone = useSelector(selectIsDone)
  const isLoading = useSelector(selectIsLoading)
  const i18n = useSelector(selectLanguageContent)

  // These cannot be in the return due to their conditional usage
  const loadingText = i18n.loading
  const testResultNameUnknown = i18n.test_result_name_unknown

  const getMoreItems = () => {
    dispatch(getTestResultsMore())
  }
  if (isLoading && testResults?.length === 0)
    return (
      <div className={classes.loadingWrapper}>
        <Loading />
      </div>
    )
  return (
    <div className={classes.listWrapper}>
      <Typography className={classes.pageTitle}>
        {i18n.test_history_screen_title}
      </Typography>
      <NoTestResults i18n={i18n} classes={classes} />
      {testResults?.map((testResult, index) => (
        <React.Fragment key={testResult._id}>
          {testResults.length >= 20 &&
            !isDone &&
            testResults.length - 5 === index && (
              <Observer callback={getMoreItems} />
            )}
          <ResultItem
            testId={testResult._id}
            classes={classes}
            status={testResult.status}
            statusText={testResult.statusDisplayText}
            result={
              testResult.metadata?.outcome &&
              AllowedResults[testResult.metadata?.outcome]
            }
            name={
              testResult.details.orderableTest?.orderableName ||
              testResultNameUnknown
            }
            i18n={i18n}
            created={testResult.created}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

const NoTestResults = (props: { classes: ClassNameMap; i18n: I18n }) => {
  const { i18n, classes } = props
  const testResults = useSelector(selectTestResults)
  if (testResults?.length !== 0) return null
  return (
    <Typography className={classes.noItemsTitle}>
      {i18n.no_test_results}
    </Typography>
  )
}

const ResultItem = ({
  status,
  statusText,
  result,
  name,
  created,
  testId,
  classes,
  i18n,
}: {
  status: Status | undefined
  statusText: string
  result: AllowedResults | undefined
  name: string
  created: string
  testId: string
  classes: ClassNameMap
  i18n: I18n
}) => {
  const history = useHistory()
  return (
    <Box
      className={classes.listItemBox}
      onClick={() => history.push(paths.testOrderDetails(testId))}
    >
      <div>
        <Typography variant="body1" className={classes.name}>
          {name}
        </Typography>
        <Typography variant="body2" className={classes.result}>
          Results: {result ? capitalizeString(result) : 'N/A'}
        </Typography>
        <StatusPill
          classes={classes}
          status={status}
          i18n={i18n}
          statusText={statusText}
        />
        <Typography>{`${created && format(new Date(created), 'MMM d, y')} | @ ${
          created && format(new Date(created), 'h:mm a')
        }`}</Typography>
      </div>
      <ChevronRightSharpIcon />
    </Box>
  )
}

const StatusPill = ({
  status,
  statusText,
  classes,
  i18n,
}: {
  status: Status | undefined
  statusText: string
  classes: ClassNameMap
  i18n: I18n
}) => {
  const theme = useTheme()
  const getColor = (color: string) => {
    switch (color) {
      case 'SUCCESS': {
        return theme.palette.success.main
      }
      case 'ERROR': {
        return theme.palette.error.main
      }
      case 'WARNING': {
        return theme.palette.warning.main
      }
      default:
        return '#505358'
    }
  }

  return (
    <>
      {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        status && statusColorHelper[status] ? (
          <>
            <div
              className={classes.pill}
              style={{
                backgroundColor: getColor(statusColorHelper[status].color),
              }}
            >
              <Typography className={classes.pillText}>{statusText}</Typography>
            </div>
          </>
        ) : (
          <div className={classes.pill} style={{ backgroundColor: '#505358' }}>
            <Typography className={classes.pillText}>{i18n.UNKNOWN}</Typography>
          </div>
        )
      }
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  noItemsTitle: {
    padding: '1rem',
  },
  loadingWrapper: {
    width: '100%',
    margin: '250px auto',
  },
  pageTitle: {
    fontSize: 32,
    fontWeight: 500,
  },
  listWrapper: {
    paddingTop: '72px',
    width: '100%',
    maxWidth: '800px',
    margin: '0px auto',
  },
  listItemBox: {
    'padding': 12,
    'display': 'flex',
    'width': '100%',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'borderBottom': '1px solid #E0E0E0',
    'transition': 'all .03s ease-in-out',
    '&:hover': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
    },
    [theme.breakpoints.down(600)]: {
      padding: '12px 0px',
    },
  },
  name: {
    'margin': '4px 0px',
    'lineHeight': '1.1rem',
    'fontWeight': 600,
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
  },
  result: {
    fontWeight: 600,
    fontSize: '.9rem',
  },
  pill: {
    margin: '4px 0px',
    padding: '0px 8px',
    maxWidth: 'fit-content',
    borderRadius: '16px',
  },
  pillText: {
    color: '#FFF',
    fontWeight: 500,
    fontSize: '14px',
  },
}))
