import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import CircleCheck from '../../assets/icons/Group@3x.png'
import { testTypes } from '../../constants'
import { selectLanguageContent } from '../../features/translation'
import { Button } from '../../ui'
import { paths } from '../paths'

export const OrderPlacedPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { type } = useParams<{ type: string }>()
  const i18n = useSelector(selectLanguageContent)

  const goNext = () => {
    history.push(paths.app.dashboard())
  }

  const renderTitle = () => {
    if (type === testTypes.ORDER) {
      return (
        <>
          <Box>{i18n.checkout_order_success}</Box>
        </>
      )
    }
    if (type === testTypes.APPOINTMENT) {
      return (
        <>
          <Box>{i18n.appointment_confirmed_label}</Box>
        </>
      )
    }
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.placedText}>
          <Box className={classes.loadingContent}>
            <Box>
              {/* <CheckCircleOutlineIcon className={classes.loadingIcon} /> */}
              <img
                src={CircleCheck}
                alt="checkmark"
                className={classes.circleCheck}
              />
            </Box>
          </Box>
          <Box className={classes.processText}>{renderTitle()}</Box>
        </Box>
        <Button onClick={goNext} className={classes.loadingButton}>
          {i18n.ok_button}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  circleCheck: {
    height: '110px',
  },
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
  },
  loadingContainer: {
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: '500px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '200px',
  },
  processText: {
    fontSize: 26,
    color: '#FFF',
    width: '80%',
    marginTop: 20,
  },
  loadingIcon: {
    display: 'block',
    fontSize: 250,
    color: '#fff',
  },
  loadingText: {
    color: '#fff',
    fontSize: 26,
    padding: '0 30px',
  },
  loadingButton: {
    'width': '100%',
    'maxWidth': 300,
    'maxHeight': 48,
    'fontWeight': 600,
    'backgroundColor': '#FFF',
    'color': '#003C71',
    'marginBottom': 48,
    'marginLeft': 15,
    'marginRight': 15,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFF',
      border: '1px solid #fff',
    },
    '&.Mui-disabled': {
      backgroundColor: '#FFF',
      color: '#FFF',
      border: '1px solid #003C71',
      opacity: 0.6,
    },
  },
}))
