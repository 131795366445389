import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { TestResultsList } from './components/test-results-list'
import { getTestResults } from './model/model'

export const TestResultsPage = () => {
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const history = useHistory()
  const handleBack = () => history.push(paths.app.dashboard())

  React.useEffect(() => {
    dispatch(getTestResults())
  }, [dispatch])

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      // rightContentTitle={i18n.exit_button}
      color="#505358"
    >
      <TestResultsList />
    </ModuleHeader>
  )
}
