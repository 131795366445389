import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import { Button, Checkbox, Typography } from '../../ui'
import { paths } from '../paths'
import {
  selectInsurance,
  selectNotPolicyholder,
  setPolicyHolderFlag,
  selectSelectedProvider,
  updateInsuranceData,
  selectPolicyHolderInfo,
  selectPayorId,
} from '../insurance/model'
import { SubHeaderGetTested } from '../../features/application'
import { selectNationalHealthId } from '../profile/model'
import { setInsuranceIsSelected } from '../checkout/model'
import {
  useStyles,
  Wrapper,
  Content,
  Controls,
  FormWrapper,
} from '../../ui/templates/app-layout'
import validationSchema from './validationSchema'
import { FormValues } from './types'
import { selectLanguageContent } from '../../features/translation'

export const ConfirmInsurancePage = () => {
  const classes = useStyles()
  const history = useHistory<any>()
  const i18n = useSelector(selectLanguageContent)
  const { type, id, page } = useParams<{
    type: string
    id: string
    page: string
  }>()

  const isEditing = page === 'edit'

  const insuranceData = useSelector(selectInsurance)
  const selectedProvider = useSelector(selectSelectedProvider)
  const payorId = useSelector(selectPayorId)
  const notPolicyHolder = useSelector(selectNotPolicyholder)
  const policyHolderInfo = useSelector(selectPolicyHolderInfo)
  const nationalHealthId = useSelector(selectNationalHealthId)

  const [formValues, setFormValues] = useState<FormValues>({
    insuranceId: insuranceData?.insuranceId || '',
    groupNumber: insuranceData?.group || '',
    planName: insuranceData?.planType || '',
    coverageDate: Date.parse(insuranceData?.coverageDate) || new Date(),
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (isEditing && !selectedProvider) {
      history.push(paths.addInsuranceProvider(type, id))
    }
  }, [selectedProvider])

  useEffect(() => {
    if (insuranceData) {
      setFormValues({
        insuranceId: insuranceData?.insuranceId || '',
        groupNumber: insuranceData?.group || '',
        planName: insuranceData?.planType || '',
        coverageDate: Date.parse(insuranceData?.coverageDate) || new Date(),
      })
    }
  }, [insuranceData])

  const onSubmit = async (values: FormValues) => {
    const insuranceData = {
      planType: values.planName,
      payorId,
      notPolicyHolder,
      nationalHealthId,
      insuranceId: values.insuranceId,
      group: values.groupNumber,
    }
    const requestData = notPolicyHolder
      ? {
          ...insuranceData,
          dependantPolicyHolderDetail: policyHolderInfo,
        }
      : insuranceData

    dispatch(updateInsuranceData(requestData))

    history.push(paths.insuranceVerifyingProcess(type, id))
  }

  const formik = useFormik({
    initialValues: {
      insuranceId: formValues.insuranceId,
      groupNumber: formValues.groupNumber,
      planName: formValues.planName,
      coverageDate: formValues.coverageDate,
    },
    validationSchema: validationSchema(i18n),
    enableReinitialize: true,
    onSubmit,
  })

  const goBack = () => {
    if (isEditing) {
      history.push(paths.addInsuranceProvider(type, id))
    } else {
      history.push(paths.checkout(type, id))
    }
  }

  return (
    <Wrapper>
      <SubHeaderGetTested
        title={isEditing ? `${i18n.checkout_add_insuranse_provider}` : `${i18n.confirm_insurance_screen_title}`}
        onHandleBack={goBack}
      />
      <Wrapper>
        <Content onSubmit={formik.handleSubmit} noValidate>
          <FormWrapper>
            <Typography variant="h5" component="h2">
              {isEditing ? selectedProvider?.name : insuranceData?.name}
            </Typography>
            <InsuranceID formik={formik} isDisabled={!isEditing} i18n={i18n} />
            <GroupNumber formik={formik} isDisabled={!isEditing} i18n={i18n} />
            <PlanName formik={formik} isDisabled={!isEditing} i18n={i18n} />
            <CoverageDate formik={formik} isDisabled={!isEditing} i18n={i18n} />
          </FormWrapper>
          <ConfirmationBox formik={formik} notPolicyHolder={notPolicyHolder} i18n={i18n} />
        </Content>
      </Wrapper>
    </Wrapper>
  )
}
const InsuranceID = (props: { formik: any; isDisabled: boolean, i18n: any }) => {
  const { formik, isDisabled, i18n } = props
  return (
    <TextField
      label={i18n.insurance_id}
      name="insuranceId"
      value={formik.values.insuranceId}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.insuranceId && formik.touched.insuranceId)}
      helperText={formik.touched.insuranceId ? formik.errors.insuranceId : ''}
      disabled={isDisabled}
      required
    />
  )
}
const GroupNumber = (props: { formik: any; isDisabled: boolean, i18n: any }) => {
  const { formik, isDisabled, i18n } = props
  return (
    <TextField
      label={i18n.insurance_group_number_field_name}
      name="groupNumber"
      value={formik.values.groupNumber}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.groupNumber && formik.touched.groupNumber)}
      helperText={formik.touched.groupNumber ? formik.errors.groupNumber : ''}
      disabled={isDisabled}
      required
    />
  )
}
const PlanName = (props: { formik: any; isDisabled: boolean, i18n: any }) => {
  const { formik, isDisabled, i18n } = props
  return (
    <TextField
      label={i18n.insurance_plan_name_field_name}
      name="planName"
      value={formik.values.planName}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={Boolean(formik.errors.planName && formik.touched.planName)}
      helperText={formik.touched.planName ? formik.errors.planName : ''}
      disabled={isDisabled}
      required
    />
  )
}
const CoverageDate = (props: { formik: any; isDisabled: boolean, i18n: any }) => {
  const { formik, isDisabled, i18n } = props
  const handleChangeDate = (e: any) => {
    formik.setFieldValue('coverageDate', e)
  }
  return (
    <KeyboardDatePicker
      label={i18n.insurance_coverage_date_field_name}
      name="coverageDate"
      onChange={handleChangeDate}
      value={formik.values.coverageDate}
      error={Boolean(formik.errors.coverageDate && formik.touched.coverageDate)}
      helperText={formik.touched.coverageDate ? formik.errors.coverageDate : ''}
      required
      color="primary"
      disabled={isDisabled}
    />
  )
}

const ConfirmationBox = (props: { formik: any; notPolicyHolder: boolean, i18n: any }) => {
  const classes = useStyles()
  const childClasses = childStyles()
  const { type, id, page } = useParams<{
    type: string
    id: string
    page: string
  }>()
  const { formik, notPolicyHolder, i18n } = props
  const isEditing = page === 'edit'
  const isLooksGoodDisabled =
    !formik.values.insuranceId ||
    !formik.values.groupNumber ||
    !formik.values.planName
  const history = useHistory()
  const dispatch = useDispatch()

  const handleUpdateInfo = () => {
    history.push(paths.addInsurance(type, id))
  }
  const handleLooksGood = async () => {
    dispatch(setInsuranceIsSelected())
    history.push(paths.checkout(type, id))
  }

  const handleCheckboxChange = () => {
    if (!notPolicyHolder) {
      history.push(paths.policyHolderInfo(type, id))
    } else {
      dispatch(setPolicyHolderFlag(false))
    }
  }

  const handleVerifyInsurance = async () => {
    history.push(paths.insuranceProviderAdded(type, id))
  }

  const isDisabledLooksGood = !formik.values.insuranceId ||
    !formik.values.groupNumber ||
    !formik.values.planName ||
    !formik.values.coverageDate

  return (
    <Controls>
      {isEditing ? (
        <>
          <Checkbox
            label={i18n.policy_holder_not_a_policy_holder}
            checked={notPolicyHolder}
            onChange={handleCheckboxChange}
          />
          <Button
            className={`${classes.button} ${childClasses.btn}`}
            disabled={formik.isSubmitting || !formik.isValid}
            // onClick={handleVerifyInsurance}
            type="submit"
          >
            {i18n.continue_button}
          </Button>
        </>
      ) : (
        <>
          <Button
            className={`${classes.button} ${childClasses.btn}`}
            onClick={handleUpdateInfo}
          >
            {i18n.insurance_update_button}
          </Button>
          <Button
            className={`${classes.button} ${childClasses.btn}`}
            type="submit"
            disabled={isLooksGoodDisabled}
            onClick={handleLooksGood}
          >
            {i18n.insurance_looks_good_button_title}
          </Button>
        </>
      )}
    </Controls>
  )

}

const childStyles = makeStyles({
  btn: {
    margin: '8px',
    maxHeight: 48,
    width: '100%',
  },
})
