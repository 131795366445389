import styled from '@emotion/styled'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  selectIsLoading,
  SystemMessageItem,
} from '../../features/system-messages'
import {
  fetchMoreSystemMessages,
  selectIsDone,
  selectIsLoadingPage,
  selectList,
} from '../../features/system-messages/model'
import { selectLanguageContent } from '../../features/translation'
import { Loading } from '../../ui'
import { paths } from '../paths'

export const SystemMessagesItem = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const isDone = useSelector(selectIsDone)
  const isLoadingPage = useSelector(selectIsLoadingPage)
  const { id: messageId } = useParams<{ id: string }>()
  const messages = useSelector(selectList)
  const [messageIndex, setMessageIndex] = useState(
    messages.findIndex((m) => m.conversationId === messageId)
  )
  const [messageData, setMessageData] = useState(messages[messageIndex])

  useEffect(() => {
    setMessageData(messages[messageIndex])
    history.push(paths.systemMessageItem(messages[messageIndex].conversationId))
  }, [messageIndex])

  const getMoreMessages = async () => {
    await dispatch(fetchMoreSystemMessages())
    setMessageIndex(messageIndex + 1)
  }

  const getNewMessage = (direction: string) => {
    if (direction === 'previous') {
      setMessageIndex(messageIndex - 1)
    } else if (messageIndex === messages.length - 1) {
      getMoreMessages()
    } else {
      setMessageIndex(messageIndex + 1)
    }
  }

  const isLoading = useSelector(selectIsLoading)

  if (isLoading) return <Loading />

  return (
    <Box className={classes.messageWrapper}>
      <Box className={classes.pageHeader}>
        <IconButton
          size="small"
          onClick={() => history.push(paths.app.systemMessages())}
          style={{ marginLeft: 10 }}
        >
          <KeyboardBackspaceIcon color="primary" />
        </IconButton>
        <Box className={classes.buttonContainer}>
          <IconButton
            size="small"
            onClick={() => getNewMessage('previous')}
            disabled={messageIndex === 0}
          >
            <ChevronRightIcon className={classes.previous} />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => getNewMessage('next')}
            disabled={isDone && messageIndex === messages.length - 1}
          >
            <ChevronRightIcon className={classes.next} />
          </IconButton>
        </Box>
      </Box>
      {isLoadingPage ? (
        <div className={classes.observerLoading}>
          <Loading />
        </div>
      ) : (
        <SystemMessagesWrapper>
          <SystemMessageItem messageData={messageData} />
        </SystemMessagesWrapper>
      )}
    </Box>
  )
}

const SystemMessagesWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
`
const useStyles = makeStyles((theme) => ({
  observerLoading: {
    minHeight: 'calc(90vh)',
    display: 'flex',
    justifyContent: 'center',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #efefef',
    width: '100%',
  },
  buttonContainer: {
    maxWidth: 80,
    margin: 5,
  },
  previous: {
    fontSize: 32,
    transform: 'rotate(-90deg)',
  },
  next: {
    fontSize: 32,
    transform: 'rotate(90deg)',
  },
  messageWrapper: {
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down(500)]: {
      padding: '0px',
    },
  },
}))
