import moment from 'moment-timezone'
import * as Yup from 'yup'
import { I18n } from '../../features/translation/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (i18n: I18n) {
  return Yup.object().shape({
    firstName: Yup.string().required(i18n.required),
    lastName: Yup.string().required(i18n.required),
    mobileNumber: Yup.string().required(i18n.required),
    email: Yup.string().email(),
    birthDate: Yup.string()
      .when('collectDateOfBirthRequired', {
        is: true,
        then: Yup.string().required(i18n.required),
      })
      .when('isGuardianMode', {
        is: false,
        then: Yup.string().test(
          'birthDate',
          i18n.to_young_error_message,
          (value) => {
            return moment().diff(moment(value), 'years') >= 18
          }
        ),
      }),
    gender: Yup.string().when('collectSexRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    ethnicity: Yup.string().when('ehtnicityRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    race: Yup.string().when('raceRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    line1: Yup.string().when('collectAddressRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    line2: Yup.string(),
    city: Yup.string().when('collectAddressRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    state: Yup.string().when('collectAddressRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    country: Yup.string().when('collectAddressRequired', {
      is: true,
      then: Yup.string().required(i18n.required),
    }),
    zip: Yup.string()
      .min(5, i18n.zip_code_min)
      .max(10, i18n.zip_code_max)
      .when('collectAddressRequired', {
        is: true,
        then: Yup.string().required(i18n.required),
      }),
  })
}
