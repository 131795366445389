/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import styled from '@emotion/styled'
import { Box, IconButton } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SubHeaderGetTested } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Button, TextField } from '../../ui'
import {
  Content,
  Controls,
  FormWrapper,
  useStyles,
  Wrapper,
} from '../../ui/templates/app-layout'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'

export const MemberAccountPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const i18n = useSelector(selectLanguageContent)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
  }

  const handleBack = () => {
    history.goBack()
  }
  const handleUpdateInfo = () => {
    history.push(paths.checkout(type, id))
  }
  const goToEdit = () => {
    history.push(paths.editAccount(type, id), { prevSection: 'checkout' })
  }

  return (
    <Wrapper>
      <SubHeaderWrapper>
        <SubHeaderGetTested
          title={i18n.member_account_screen_title}
          onHandleBack={handleBack}
        />
        <Box style={{ borderBottom: '1px solid #efefef' }}>
          <IconButton onClick={goToEdit}>
            <EditOutlinedIcon />
          </IconButton>
        </Box>
      </SubHeaderWrapper>
      <Content onSubmit={handleSubmit} noValidate>
        <FormWrapper>
          <FirstName i18n={i18n} />
          <LastName i18n={i18n} />
        </FormWrapper>
        <Controls>
          <Button className={classes.button} onClick={handleUpdateInfo}>
            {i18n.save_button}
          </Button>
        </Controls>
      </Content>
    </Wrapper>
  )
}

const FirstName = (props: { i18n: I18n }) => {
  const { i18n } = props
  const memberData = useSelector(selectProfileData)
  return (
    <TextField
      label={i18n.first_name}
      type="text"
      value={memberData?.firstName || ''}
      required
      disabled
    />
  )
}
const LastName = (props: { i18n: I18n }) => {
  const { i18n } = props
  const memberData = useSelector(selectProfileData)
  return (
    <TextField
      label={i18n.last_name}
      type="text"
      value={memberData?.lastName || ''}
      required
      disabled
    />
  )
}

const SubHeaderWrapper = styled.div`
  display: grid;
  //grid-template-columns: 48px 1fr 48px;
  grid-template-columns: 1fr 48px;
  align-items: center;
  max-width: 540px;
  width: 100%;
`
