import { useStyles } from '../../../ui/templates/app-layout'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isFacilityOpenNow, isFedExOpenNow } from '../../../libs/helpers'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { useParams } from 'react-router-dom'
import { testTypes } from '../../../constants'

export const FacilityOption = (props: {
  item: any
  setShowDetails: any
  setSelectedItem: any
}) => {
  const classes = useStyles()
  const childClasses = childStyles()
  const { item, setShowDetails, setSelectedItem } = props
  const { type } = useParams<{ type: string; id: string }>()

  const i18n = useSelector(selectLanguageContent)

  const facilityIsWorking = type === testTypes.DROPOFF ? isFedExOpenNow(item?.normalHours): isFacilityOpenNow(item?.workingHour)

  const handleShowDetails = () => {
    setSelectedItem(item)
    setShowDetails(true)
  }

  return (
    <Box className={childClasses.optionWrapper} onClick={handleShowDetails}>
      <Box className={classes.option}>
        <Typography className={classes.testName}>{item.name}</Typography>
        <Typography variant="subtitle2" className={childClasses.description}>
          {`${item.address1},`}
        </Typography>
        <Typography variant="subtitle2" className={childClasses.description}>
          {`${item.city}, ${item.state} ${item.zip.slice(0, 5)}`}
        </Typography>
      </Box>
      <Box className={childClasses.rightChevron}>
        <Box>
          <Box className={`${childClasses.locationText} ${facilityIsWorking ? childClasses.open : childClasses.closed}`}>
            {facilityIsWorking ? `${i18n.open_label}` : `${i18n.closed_label}`}
          </Box>
          <Typography variant="subtitle2" className={childClasses.description}>
            {`${Math.round(item.latitudeLongitudeDistance) / 10}mi`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const childStyles = makeStyles({
  optionWrapper: {
    padding: '15px 15px',
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E7EA',
    margin: '0 20px',
    maxWidth: 475,
    cursor: 'pointer',
  },
  description: {
    color: '#7B7C7D',
    textAlign: 'end',
  },
  rightChevron: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row-reverse',
    alignSelf: 'center',
    color: '#003C72',
    fontSize: 16,
    fontFamily: 'Avenir Next Rounded Regular',
    lineHeight: '22px',
    fontWeight: 900,
  },
  locationText: {
    color: '#505358',
    letterSpacing: 0.25,
    fontSize: 15,
    fontWeight: 100,
  },
  open: {
    color: '#5cb960',
  },
  closed: {
    color: '#f91819',
  }
})
