import { styled } from '@material-ui/core'
import React from 'react'
import { useMUITheme } from '../../../libs/useTheme'
import { Loading } from '../../../ui'

export const ShopLoading = () => {
  const isMobile = useMUITheme().isMobile

  return (
    <LoadingWrapper>
      <Loading size={isMobile ? 50 : 100} />
    </LoadingWrapper>
  )
}

const LoadingWrapper = styled('div')({
  height: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
