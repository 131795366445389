import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import createDOMPurify from 'dompurify'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SubBackHeader } from '../../features/application'
import { createHealthService } from '../../features/health-service-lite/model'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { Button, Loading } from '../../ui'
import { Content, Controls, Wrapper } from '../../ui/templates/app-layout'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import {
  getVerbiage,
  selectIntroLoading,
  selectMoreInfoVerbiage,
} from './symptomCheckerIntroSlice'

const DOMPurify = createDOMPurify(window)

export const SymptomCheckerMoreInfoPage = () => {
  const childClasses = childStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const i18n = useSelector(selectLanguageContent)
  const info = useSelector(selectMoreInfoVerbiage)
  const isLoading = useSelector(selectIntroLoading)

  const goNext = () => {
    const isHealthService = lsClient.getUserLSByKey('isHealthService')
    const healthService = lsClient.getUserLSByKey('healthServiceId')
    const tenantId = lsClient.getUserLSByKey('tenantId')

    if (isHealthService && !healthService) {
      const params = {
        tenantId,
        accountId: user.accountId,
        UserId: user._id,
        Description: 'Starting Health Service',
      }
      const onCompletion = (healthServiceId: string) => {
        lsClient.setUserLS('healthServiceId', healthServiceId)
        history.push(paths.symptomCheckerV2())
      }
      dispatch(createHealthService(params, onCompletion))
    } else {
      history.push(paths.symptomCheckerV2())
    }
  }

  useEffect(() => {
    dispatch(getVerbiage())
  }, [])

  if (isLoading) return <Loading />

  return (
    <Wrapper>
      <SubBackHeader
        title="More About Virtual Consult"
        backPath={paths.symptomCheckerIntro()}
        className={childClasses.header}
      />
      <Content style={{ padding: '0 20px' }}>
        <Typography className={childClasses.assetBox}>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(info),
            }}
          />
        </Typography>
      </Content>
      <Controls>
        <Button onClick={goNext} className={childClasses.confirmButton}>
          {i18n.start_virtual_consult_button}
        </Button>
      </Controls>
    </Wrapper>
  )
}

const childStyles = makeStyles((theme) => ({
  confirmButton: {
    marginTop: 15,
    marginBottom: 20,
  },
  header: {
    padding: 0,
    borderBottom: '1px solid #D7D7D7',
  },
  assetBox: {
    margin: '0 15px',
    color: '#54565b',
    fontFamily: 'Avenir Next Rounded Medium',
    fontSize: 18,
    textAlign: 'center',
    [theme.breakpoints.down(500)]: {
      height: 'calc(90vh - 65px)',
      overflow: 'scroll',
    },
  },
  img: {
    margin: 'auto',
    maxWidth: '100%',
  },
  pageLink: {
    marginLeft: 4,
    cursor: 'pointer',
  },
}))
