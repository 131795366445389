import { createSlice } from '@reduxjs/toolkit'
import { Dispatch, Store } from 'redux'
import api from '../../api'
import { setDialog } from '../../features/dialog/model'
import { DialogType } from '../../features/dialog/types'
import { addHS } from '../../features/health-service-lite/model'
import { HealthServiceType } from '../../features/health-service-lite/types'
import { I18n } from '../../features/translation/types'
import { objectToArray, objectToSortedArray } from '../../libs/utils'
import { lsClient } from '../../ls-client'
import {
  InstructionV2,
  ProductAssetDetail,
  RegisterTestDetails,
  SelfAssessmentInstruction,
} from '../../types/tests'

interface TestSlice {
  isLoading: boolean
  fullTest: any
  testIds: string[]
  registerTestDetails: RegisterTestDetails | undefined
  productAssetDetail: ProductAssetDetail | undefined
  collectionInstructionsV2: InstructionV2[]
  isSelfAssessment: boolean
  selfAssessmentInstructions: SelfAssessmentInstruction[]
  surveySettings: any
  scanImageDetails: any
  disclaimerDetail: any
  cassetteNumber: string
  elapsedTimeOnSubmission: number
  QR2DKitId: string
  error: string
  allowSkipCollectionInstructionsV2: boolean
  active: number
  resetScanPage: boolean
}
const initialStore: TestSlice = {
  testIds: [''],
  isLoading: false,
  fullTest: {},
  registerTestDetails: undefined,
  productAssetDetail: undefined,
  collectionInstructionsV2: [],
  isSelfAssessment: false,
  selfAssessmentInstructions: [],
  surveySettings: {},
  scanImageDetails: {},
  disclaimerDetail: {},
  cassetteNumber: '',
  elapsedTimeOnSubmission: 0,
  QR2DKitId: '',
  error: '',
  allowSkipCollectionInstructionsV2: false,
  active: 0,
  resetScanPage: false,
}

export const TestSlice = createSlice({
  name: 'test',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setActive(store, { payload }: { payload: number }) {
      store.active = payload
    },
    resetScanPage(store, { payload }: { payload: boolean }) {
      store.resetScanPage = payload
    },
    setCassetteNumber(store, { payload }: { payload: any }) {
      store.cassetteNumber = payload
    },
    setElapsedTimeOnSubmission(store, { payload }: { payload: any }) {
      store.elapsedTimeOnSubmission = payload
    },
    setLabtest(store, { payload }: { payload: any }) {
      store.testIds = payload.testData.labTestOrderableIds
      store.fullTest = payload.testData
      store.productAssetDetail = payload.testData.productAssetDetail || {}
      store.collectionInstructionsV2 =
        payload.testData.collectionInstructionV2 || []
      store.isSelfAssessment = payload.testData.isSelfAssessmentMode
      store.selfAssessmentInstructions = payload.testData
        ?.selfAssessmentInstructions
        ? objectToSortedArray(payload.testData?.selfAssessmentInstructions, [
            'negativeInstructions',
            'positiveInstructions',
            'invalidInstructions',
          ])
        : []
      store.registerTestDetails = payload.testData.registerTestDetails
      store.surveySettings = payload.testData.surveySettings || {}
      store.scanImageDetails = payload.testData.scanImageDetails || {}
      store.disclaimerDetail = payload.testData.disclaimerDetail || {}
      store.QR2DKitId = payload.id
      store.allowSkipCollectionInstructionsV2 =
        payload.testData.allowSkipCollectionInstructionsV2
    },
    setCollectionsForContinue(store, { payload }: { payload: any }) {
      store.fullTest = payload
      store.testIds = payload.labTestOrderableIds
      store.collectionInstructionsV2 = payload.collectionInstructionV2
      store.selfAssessmentInstructions = payload.selfAssessmentInstructions
        ? objectToSortedArray(payload.selfAssessmentInstructions, [
            'negativeInstructions',
            'positiveInstructions',
            'invalidInstructions',
          ])
        : []
      store.scanImageDetails = payload.scanImageDetails || {}
      store.disclaimerDetail = payload.disclaimerDetail || {}
      store.isSelfAssessment = payload.isSelfAssessmentMode
      store.productAssetDetail = payload.productAssetDetail || {}
      store.allowSkipCollectionInstructionsV2 =
        payload.allowSkipCollectionInstructionsV2
    },
    setRegisterTestDetails(store, { payload }: { payload: any }) {
      store.registerTestDetails = payload.registerTestDetails
    },
    setError(store, { payload }: { payload: any }) {
      store.error = payload
    },
    resetTestStore: () => initialStore,
  },
})
export const {
  resetScanPage,
  setActive,
  setLoading,
  setLabtest,
  setCollectionsForContinue,
  setCassetteNumber,
  setElapsedTimeOnSubmission,
  setRegisterTestDetails,
  resetTestStore,
  setError,
} = TestSlice.actions

export const getScanCode =
  (
    language: string,
    code: string,
    format: string,
    i18n: I18n,
    healthServiceId?: string,
    onSuccess?: () => void,
  ) =>
  async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))
    const params = {
      data: code,
      dataFormat: format,
      markTestAsUsed: true,
    }
    const { data, error } = await api.getScanCode(language, params)

    if (data) {
      dispatch(setLoading(false))
      if (data.orderableTest) {
        lsClient.setUserLS('testId', data.orderableTest._id)
        lsClient.setUserLS('testType', 'orderableTest')
        lsClient.setUserLS('kitId', data.id)
        lsClient.setUserLS('skuId', data.orderableTest.skuId)
      } else if (data.orderablePanel) {
        lsClient.setUserLS('testId', data.orderablePanel._id)
        lsClient.setUserLS('testType', 'orderablePanel')
        lsClient.setUserLS('kitId', data.id)
        lsClient.setUserLS('skuId', data.orderablePanel.skuId)
      }
      const testData = {
        orderId: data.orderId,
        id: data.id,
        testData: data.orderableTest ? data.orderableTest : data.orderablePanel,
      }
      dispatch(setLabtest(testData))
      if (healthServiceId) {
        const params = {
          id: data.orderableTest
            ? data.orderableTest._id
            : data.orderablePanel._id,
          type: 'Screening',
        }
        dispatch(addHS(healthServiceId, params, 'connectedTests'))
      }
      if (onSuccess) onSuccess()
    } else if (error) {
      if (error.message) {
        lsClient.removeUserKeyLS('code')
        dispatch(
          setDialog({
            type: DialogType.TEST_SCAN_ERROR,
            options: {
              title: i18n[error.messageTitle],
              body: i18n[error.message],
              confirmationLabel: i18n.try_again,
            },
          })
        )
      } else {
        dispatch(setError(error))
      }
      dispatch(setLoading(false))
    }
  }

export const getLabTestDetails =
  (testId: string, callback?: () => void) =>
  async (dispatch: Dispatch, getStore: any) => {
    dispatch(setLoading(true))

    const language = getStore().translation.selectedLanguage

    const testDetails = await api.getLabTestOrderable(testId, language)

    if (testDetails) {
      dispatch(setRegisterTestDetails(testDetails))
      if (callback) {
        callback()
      }
    }
    // else if (error) {
    //   dispatch(setError(error))
    //   dispatch(setLoading(false))
    // }
    dispatch(setLoading(false))
  }

export const getTestPanelDetails =
  (testId: string, callback?: () => void) =>
  async (dispatch: Dispatch, getStore: any) => {
    dispatch(setLoading(true))

    const language = getStore().translation.selectedLanguage

    const testDetails = await api.getLabTestPanel(testId, language)

    if (testDetails) {
      dispatch(setRegisterTestDetails(testDetails.result))
      if (callback) {
        callback()
      }
    }
    // else if (error) {
    //   dispatch(setError(error))
    //   dispatch(setLoading(false))
    // }
    dispatch(setLoading(false))
  }

export const testSliceReducer = TestSlice.reducer
export const testSliceReducerName = TestSlice.name
interface RootStore {
  [testSliceReducerName]: typeof initialStore
}

export const selectTestLoading = ({ test }: RootStore) => test.isLoading

export const selectResetScanPage = ({ test }: RootStore) => test.resetScanPage

export const selectFullOrderableTest = ({ test }: RootStore) => test.fullTest

export const selectIsRapidTest = ({ test }: RootStore) =>
  test.fullTest?.testFormat?.isRapidTest

export const selectProductAssetDetail = ({ test }: RootStore) =>
  test.productAssetDetail

export const selectRegisterDetails = ({ test }: RootStore) =>
  test.registerTestDetails

export const selectProductAssetDetailLoading = ({ test }: RootStore) =>
  test.isLoading

export const selectTestCanSkipInstructions = ({ test }: RootStore) =>
  test.allowSkipCollectionInstructionsV2

export const selectCassetteNumber = ({ test }: RootStore) => test.cassetteNumber

export const selectQR2DKitId = ({ test }: RootStore) => test.QR2DKitId

export const selectElapsedTimeOnSubmission = ({ test }: RootStore) =>
  test.elapsedTimeOnSubmission

export const selectTestIds = ({ test }: RootStore) => test.testIds

export const selectIsSelfAssessmentMode = ({ test }: RootStore) =>
  test.isSelfAssessment

export const selectCollectionInstructionsV2 = ({ test }: RootStore) =>
  test.collectionInstructionsV2

export const selectSelfAssessmentInstructions = ({ test }: RootStore) =>
  test.selfAssessmentInstructions

export const selectSurveySettings = ({ test }: RootStore) => test.surveySettings

export const selectScanImageDetails = ({ test }: RootStore) =>
  test.scanImageDetails

export const selectDisclaimerDetail = ({ test }: RootStore) =>
  test.disclaimerDetail

export const selectError = ({ test }: RootStore) => test.error

export const selectCurrentStep = ({ test }: RootStore) => test.active
