import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '../../ui'
import { paths } from '../paths'
import scancode from '../../assets/img/credit.svg'
import { selectLanguageContent } from '../../features/translation'
import { Controls, useStyles } from '../../ui/templates/app-layout'

export const AddPaymentCardScreen = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const { type, id } = useParams<{ type: string; id: string }>()
  const i18n = useSelector(selectLanguageContent)

  const goNext = () => {
    history.push(paths.addCard(type, id))
  }

  return (
    <Box className={childClasses.loadingContainer}>
      <Box className={childClasses.loadingWrapper}>
        <Box className={childClasses.placedText}>
          <Box className={childClasses.loadingContent}>
            <Box>
              {/*<CreditCardIcon className={classes.loadingIcon} />*/}
              <img
                style={{ maxWidth: '250px', marginBottom: 20 }}
                src={scancode}
                alt="test-history"
              />
            </Box>
          </Box>
          <Box className={childClasses.processText}>
            {i18n.pm_tap_to_add_payment_method}
          </Box>
        </Box>
        <Controls>
          <Button onClick={goNext} className={classes.button}>
            {i18n.add_credit_card_screen_title}
          </Button>
        </Controls>
      </Box>
    </Box>
  )
}

const childStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
  },
  loadingContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '500px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '150px',
  },
  processText: {
    fontSize: 26,
    color: '#003C71',
    marginTop: 10,
  },
  loadingIcon: {
    display: 'block',
    fontSize: 250,
    color: '#003C71',
  },
  loadingText: {
    color: '#fff',
    fontSize: 26,
    padding: '0 30px',
  },
}))
