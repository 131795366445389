import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { Dispatch } from 'redux'
import api from '../../api'
import { getEncounterRecord } from '../../features/encounter/model'
import { addHS } from '../../features/health-service-lite/model'
import { handleError } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { setRegisterTestDetails } from '../scan-qr/model'
import {
  Outcome,
  OutcomeTestDetails,
  Question,
  QuestionnaireProgress,
  QuestionType,
} from './types'

interface QuestionnaireV2Slice {
  canContinue: boolean
  isLoading: boolean
  questionnaireTitle: string
  progressBar: QuestionnaireProgress
  autoSubmit: boolean
  questionnaire: any
  executionId: string
  questionnaireComplete: boolean
  currentQuestion: Question
  questionName: string
  description: string
  minimumNumber: number
  maximumNumber: number
  questionAnswerKey: string
  answer: {
    nodeId: string
    answer: any[] | number
    type: QuestionType
  }
  existingAnswer: QuestionAnswerData | undefined
  isFirstQuestion: boolean
  outcome: Outcome
  outcomeOrderableTestDetails: OutcomeTestDetails[]
}
const initialStore: QuestionnaireV2Slice = {
  canContinue: false,
  isLoading: false,
  questionnaireTitle: '',
  questionnaire: {},
  executionId: '',
  progressBar: {
    currentQuestion: 0,
    totalQuestions: 0,
  },
  autoSubmit: false,
  questionnaireComplete: false,
  currentQuestion: {
    id: '',
    name: null,
    shortName: '',
    description: null,
    help: null,
    helpDescription: null,
    introduction: null,
    readOnly: false,
    required: false,
    coding: {
      system: null,
      code: null,
      description: null,
    },
    tenantId: '',
    created: null,
    updated: null,
    createdBy: null,
    tags: null,
    status: 'ACTIVE',
    autoAnswerOption: null,
    min: 0,
    max: 1,
    label: '',
    maxFeet: null,
    maxInches: null,
    defaultValue: null,
    step: 1,
    type: QuestionType.Multiple,
    options: [],
    unit: null,
    multiSelect: false,
    containsNone: false,
    precision: null,
  },
  answer: {
    nodeId: '',
    answer: [],
    type: QuestionType.Multiple,
  },
  existingAnswer: undefined,
  isFirstQuestion: true,
  questionName: '',
  description: '',
  minimumNumber: 0,
  maximumNumber: 0,
  questionAnswerKey: '',
  outcome: {
    code: null,
    coding: {
      system: null,
      code: null,
      description: null,
    },
    description: null,
    id: '',
    image: '',
    links: [],
    name: null,
    noDisplay: false,
    report: null,
    shortName: null,
    tenantId: '',
    title: null,
    type: '',
    orderableTestIdList: [],
  },
  outcomeOrderableTestDetails: [],
}

export interface QuestionAnswerData {
  name: string
  value: string
}

const getQuestionAnswers = (
  answerData: QuestionAnswerData[],
  questionShortName: string
) => {
  const answer = answerData.find((answer) =>
    answer.name.includes(questionShortName)
  )
  if (answer) {
    return answer
  }
  return undefined
}

export const questionnaireV2Slice = createSlice({
  name: 'questionnaireV2',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setAutoSubmit(store, { payload }: { payload: boolean }) {
      store.autoSubmit = payload
    },
    setQuestionnaireV2(store, { payload }: { payload: any }) {
      store.questionnaireTitle = payload.questionnaireTitle.translations.eng
      store.currentQuestion = payload.question.question
      store.answer.nodeId = payload.question.question.id
      store.answer.type = payload.question.question.type
      store.existingAnswer = getQuestionAnswers(
        payload.variables,
        payload.question.question.shortName
      )
      store.executionId = payload.executionId
      store.isFirstQuestion = payload.first
      store.progressBar = payload.progressBar
      store.autoSubmit = false
    },
    setCurrentQuestion(store, { payload }: { payload: any }) {
      store.currentQuestion = payload
      store.questionName = payload.introduction
      store.description = payload.description
      store.questionAnswerKey = payload.name
      store.minimumNumber = payload.minimumNumber
      store.maximumNumber = payload.maximumNumber
    },
    updateAnswer(store, { payload }: { payload: any }) {
      store.canContinue = true
      store.answer.answer = payload
    },
    setTestDetailList(store, { payload }: { payload: OutcomeTestDetails[] }) {
      store.outcomeOrderableTestDetails = payload
    },
    setQuestionnaireComplete(store, { payload }: { payload: any }) {
      store.questionnaireTitle = payload.questionnaireTitle.translations.eng
      store.questionnaireComplete = true
      store.outcome = payload.question.outcome
    },
    setCanContiune(store, { payload }: { payload: boolean }) {
      store.canContinue = payload
    },
    resetQuestionnaireV2Store: () => initialStore,
  },
})

export const {
  setQuestionnaireV2,
  setCurrentQuestion,
  updateAnswer,
  setAutoSubmit,
  setLoading,
  setCanContiune,
  setQuestionnaireComplete,
  resetQuestionnaireV2Store,
} = questionnaireV2Slice.actions

const { setTestDetailList } = questionnaireV2Slice.actions

export const startQuestionnareV2 =
  (healthServiceId?: string, questionnaireId?: string) =>
  async (dispatch: any, getStore: any) => {
    const { enableVirtualConsult = false } = getStore().network.settings || {}
    dispatch(setLoading(true))
    const result = await api.startQuestionnaireV2(
      enableVirtualConsult,
      healthServiceId,
      questionnaireId
    )

    if (result.error) {
      dispatch(setLoading(false))
      dispatch(handleError({ message: result.error }))
    } else if (result) {
      dispatch(setLoading(false))
      if (result.executionId) {
        lsClient.VCB1.setQuestionnaireId(result.executionId)
        dispatch(setQuestionnaireV2(result))
        if (healthServiceId) {
          const data = {
            id: result.executionId,
            type: 'Screening',
          }
          dispatch(addHS(healthServiceId, data, 'virtualConsults'))
        }
      }
    }
    dispatch(setLoading(false))
  }

export const resumeQuestionnaireV2 =
  (questionnaireId: string) => async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))

    const result = await api.resumeQuestionnaireV2(questionnaireId)

    if (result.error) {
      dispatch(setLoading(false))
      dispatch(handleError({ message: result.error }))
    } else if (result) {
      dispatch(setLoading(false))
      if (!result.complete) {
        dispatch(setQuestionnaireV2(result))
      } else if (result.complete) {
        dispatch(setQuestionnaireComplete(result))
      }
    }
    dispatch(setLoading(false))
  }

export const nextQuestionV2 = () => async (dispatch: any, getStore: any) => {
  dispatch(setLoading(true))
  const answers = getStore().questionnaireV2.answer
  const executionId = getStore().questionnaireV2.executionId
  const result = await api.nextQuestionV2(answers, executionId)

  if (result.error) {
    dispatch(setLoading(false))
    dispatch(handleError({ message: result.error }))
  } else if (result) {
    dispatch(setCanContiune(false))
    dispatch(setLoading(false))
    if (!result.complete) {
      dispatch(setQuestionnaireV2(result))
    } else if (result.complete) {
      dispatch(setQuestionnaireComplete(result))
    }
  }
}

export const previousQuestionV1 =
  (questionnaireId: string) => async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))
    const result = await api.previousQuestionV2(questionnaireId)

    if (result.error) {
      dispatch(setLoading(false))
      dispatch(handleError({ message: result.error }))
    } else if (result) {
      dispatch(setLoading(false))
      dispatch(setQuestionnaireV2(result))
    }
  }

export const getOutcomeTestDetails =
  (testId: string, i?: number) => async (dispatch: Dispatch, getStore: any) => {
    dispatch(setLoading(true))
    const language = getStore().translation.selectedLanguage

    const testDetails = await api.getLabTestOrderable(testId, language)

    if (testDetails?.error) {
      dispatch(setLoading(false))
    }

    if (testDetails) {
      dispatch(setLoading(false))
      const { outcomeOrderableTestDetails } = getStore().questionnaireV2
      const testListDetails = _.cloneDeep(outcomeOrderableTestDetails)

      if (i === 0) {
        dispatch(setRegisterTestDetails(testDetails))
      }

      if (
        testListDetails.find(
          (test: OutcomeTestDetails) => test._id === testDetails._id
        )
      )
        return

      const test = {
        _id: testDetails._id,
        price: testDetails.price,
        imageURL: testDetails.productAssetDetail?.imageURL,
        customTestName: testDetails.customTestName,
        shortDescription: testDetails.shortDescription,
        scanImageUrl: testDetails.scanImageDetails?.imageURL,
      } as OutcomeTestDetails

      testListDetails.push(test)
      dispatch(setTestDetailList(testListDetails))
    }
  }

export const questionnaireV2Reducer = questionnaireV2Slice.reducer
export const questionnaireV2ReducerName = questionnaireV2Slice.name
interface RootStore {
  [questionnaireV2ReducerName]: typeof initialStore
}

export const selectCanContinue = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.canContinue

export const selectQuestionnaire = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.questionnaire

export const selectQuestionLoading = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.isLoading

export const selectQuestionnaireComplete = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.questionnaireComplete

export const selectQuestionnaireOutcome = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.outcome

export const selectCurrentQuestion = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.currentQuestion

export const selectCurrentDescription = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.description

export const selectV2Answers = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.answer

export const selectCurrentQuestionAnswerKey = ({
  questionnaireV2,
}: RootStore) => questionnaireV2.questionAnswerKey

export const selectMinValue = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.minimumNumber

export const selectMaxValue = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.maximumNumber

export const selectIsFirstQuestion = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.isFirstQuestion

export const selectExistingAnswer = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.existingAnswer

export const selectQuestionnaireTitle = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.questionnaireTitle

export const selectQuestionnaireProgress = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.progressBar

export const selectAutoSubmit = ({ questionnaireV2 }: RootStore) =>
  questionnaireV2.autoSubmit

export const selectOutcomeOrderableTestDetails = ({
  questionnaireV2,
}: RootStore) => questionnaireV2.outcomeOrderableTestDetails
