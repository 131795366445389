import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useHistory, useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import _ from 'lodash'
import { Loading } from '../../ui'
import { paths } from '../paths'
import { getLastDropOffTime, isFacilityOpenNow } from '../../libs/helpers'
import FacilitiesMap from '../map/FacilitiesMap'
import { storeSelectedFacility } from '../checkout/model'
import { SubHeaderGetTested } from '../../features/application'
import { Wrapper } from '../../ui/templates/app-layout'
import { selectLanguageContent } from '../../features/translation'
import { Observer } from '../../libs/observer'
import { ConfirmationDialog } from '../schedule-appointment/confirmationDialog'
import { testTypes } from '../../constants'
import { FacilityOptionDetails } from './components/FacilityOptionDetails'
import { FacilityOption } from './components/FacilityOption'
import { ProcessingLoader } from '../../ui/templates/processingLoader'
import {
  getFacilities, getFedExOffices,
  selectFacilitiesList,
  selectLoading,
  selectMoreLoading,
} from './model'
import { confirmDropOff, selectIsLoadingConfirmation, setDropOffPickupLocation } from '../shipping/shippingTestSlice'
import { selectProfileData } from '../profile/model'

export const FacilityPage = () => {
  const childClasses = childStyles()
  const { type, id } = useParams<{ type: string; id: string }>()
  const history = useHistory()
  const facilities = useSelector(selectFacilitiesList)
  const isLoading = useSelector(selectLoading)
  const i18n = useSelector(selectLanguageContent)
  const currentUser = useSelector(selectProfileData)
  const loadMore = useSelector(selectMoreLoading)
  const isLoadingConfirmation = useSelector(selectIsLoadingConfirmation)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [facilitiesQuery, setFacilitiesQuery] = useState({
    lat: 0,
    lng: 0,
    term: '',
    pageNumber: 0,
    pageSize: 10,
  })
  const [ready, setReady] = useState(false)
  const delayedSearch = useRef(
    _.debounce((value) => {
      setFacilitiesQuery((prevState) => ({
        ...prevState,
        term: value,
      }))
    }, 500),
  ).current

  const dispatch = useDispatch()

  const getLocations = ()=>{
    if(type === testTypes.DROPOFF){
      dispatch(getFedExOffices(facilitiesQuery))
    }else{
      dispatch(getFacilities(facilitiesQuery))
    }
  }

  useEffect(() => {
    if(type === testTypes.DROPOFF && !currentUser){
      return history.push(paths.shippingOptions())
    }


    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFacilitiesQuery((prevState) => ({
            ...prevState,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }))
          setReady(true)
        },
        (error) => {
          console.error('Error Code = ' + error.code + ' - ' + error.message)
          getLocations()
          setReady(true)
        },
      )
    } else {
      getLocations()
      setReady(true)
    }
  }, [])

  useEffect(() => {
    if (!ready) return
    getLocations()
  }, [facilitiesQuery.term, ready])

  const goBack = () => {
    history.goBack()
  }

  const handleSearch = (e: any) => {
    setSearchInputValue(e.target.value)
    delayedSearch(e.target.value)
  }

  const cleanSearch = () => {
    setSearchInputValue('')
    delayedSearch('')
  }

  const setNextPage = () => {
    setFacilitiesQuery((prevState) => ({
      ...prevState,
      pageNumber: prevState.pageNumber + 1,
    }))
    dispatch(getFacilities(facilitiesQuery, true))
  }

  const onMarkerClick = (props: any) => {
    const { lat, lng } = props.position
    if (!facilities.length) return null

    const facility = facilities.find((item: any) => {
      return item.latitude === lat && item.longitude === lng
    })
    if (facility) {
      setSelectedItem(facility)
      setShowDetails(true)
    }
  }

  const goNextAppointment = () => {
    history.push(paths.scheduleAppointment(type, id, selectedItem?.key))
    dispatch(storeSelectedFacility(selectedItem))
  }

  const goNextDropOff = () => {
    setConfirmationOpen(true)
  }

  const config: any = {
    [testTypes.APPOINTMENT]: {
      title: i18n.select_facility_screen_title,
      selectButtonText: i18n.select_facility_button,
      onClickSelect: goNextAppointment,
      confirmationTitle: i18n.appointment_confirmation_title,
    },
    [testTypes.DROPOFF]: {
      title: i18n.find_drop_off_location_screen_title,
      selectButtonText: i18n.select_this_location_button,
      onClickSelect: goNextDropOff,
      confirmationTitle: i18n.drop_off_confirm_message,
    },
  }

  const handleConfirmDialog = async () => {

    setProcessing(true)
    dispatch(setDropOffPickupLocation(selectedItem))

    if (await dispatch(confirmDropOff({orderId: '3BN8sQDx7UqqfL'}))) {
      history.push(paths.dropOffVerified(type, id))
      setProcessing(false)
    }

    setConfirmationOpen(false)
    setProcessing(false)
  }

  if (isLoading) return <Loading />
  if (processing) return <ProcessingLoader i18n={i18n} />

  return (
    <>
      <Wrapper>
        <SubHeaderGetTested title={config[type].title} onHandleBack={goBack} />
        <Box className={childClasses.mapImage}>
          <FacilitiesMap
            selectedItem={selectedItem}
            facilities={facilities}
            onMarkerClick={onMarkerClick}
          />
        </Box>
        <Box className={childClasses.facilityInfoBox}>
          <Box className={childClasses.dividerWrapper}>
            <Box className={childClasses.mobileDivider} />
          </Box>
          {!showDetails ? (
            <>
              <Box className={childClasses.closeWrapper}>
                <input
                  type='text'
                  className={childClasses.composeInput}
                  value={searchInputValue}
                  style={{ outline: 'none', paddingLeft: '30px' }}
                  onChange={handleSearch}
                />
                <SearchIcon
                  style={{ color: 'gray', position: 'absolute', left: 25 }}
                />
                <Typography
                  onClick={cleanSearch}
                  className={childClasses.closeIcon}
                >
                  {i18n.cancel_button}
                </Typography>
              </Box>
              {facilities.map((item: any, index: number) => (
                <>
                  {/*Disable autoupload scroll for dropoff locations */}
                  {facilities.length - 5 === index && type !== testTypes.DROPOFF && (
                    <Observer callback={setNextPage} />
                  )}
                  <FacilityOption
                    item={item}
                    setShowDetails={setShowDetails}
                    setSelectedItem={setSelectedItem}
                  />
                </>
              ))}
              <Box className={childClasses.moreLoadingBox}>
                {loadMore ? i18n.loading : null}
              </Box>
            </>
          ) : (
            <FacilityOptionDetails
              facility={selectedItem}
              i18n={i18n}
              setShowDetails={setShowDetails}
              config={config}
            />
          )}
        </Box>
      </Wrapper>
      <ConfirmationDialog
        title={config[testTypes.DROPOFF].confirmationTitle}
        isOpen={confirmationOpen}
        info={{
          selectedLocation: selectedItem,
          date: 'Today',
          time: `Drop off by ${
            getLastDropOffTime(selectedItem?.normalHours) || '-'}`,
        }}
        onConfirm={handleConfirmDialog}
        onCancel={() => setConfirmationOpen(false)}
      />
    </>
  )
}

const childStyles = makeStyles((theme) => ({
  mapImage: {
    display: 'block',
    width: '100%',
    maxWidth: 540,
    height: 540,
    position: 'relative',
  },
  facilityInfoBox: {
    width: '100%',
    borderRadius: '8px 8px 0 0',
    backgroundColor: '#fff',
    borderTop: '1px solid #CCCCCE',
    marginTop: -20,
    maxWidth: 540,
  },
  closeWrapper: {
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    position: 'relative',
    borderBottom: '1px solid #E6E7EA',
  },
  closeIcon: {
    color: '#003C71',
    marginRight: 20,
    marginLeft: 20,
    fontWeight: 600,
    cursor: 'pointer',
  },
  dividerWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  mobileDivider: {
    width: 36,
    height: 3,
    backgroundColor: '#CCCCCE',
    marginTop: 7,
    marginBottom: 10,
  },
  composeInput: {
    'flex': 1,
    'fontSize': '14px',
    'height': '30px',
    'width': '100%',
    'background': 'none',
    'backgroundColor': '#EDEDED',
    'borderRadius': '10px',
    'border': 'none',
    'marginLeft': '20px',
    'position': 'relative',
    '&::placeholder': {
      opacity: 0.3,
    },
  },
  moreLoadingBox: {
    margin: 20,
    paddingLeft: 15,
  },
}))
