/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import styled from '@emotion/styled'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setDialog } from '../../../features/dialog/model'
import { DialogType } from '../../../features/dialog/types'
import { updateEncounterRecord } from '../../../features/encounter/model'
import { useFlowControl } from '../../../features/flowcontrol/use-flow-control'
import { addHS } from '../../../features/health-service-lite/model'
import { selectLanguageContent } from '../../../features/translation'
import { clearTestKitFlow } from '../../../libs/helpers'
import { lsClient } from '../../../ls-client'
import { Button, Loading } from '../../../ui'
import {
  getDiagnosticResult,
  selectDiagnosticOutcomeResponseCode,
  selectDiagnosticResult,
  selectDiagnosticTestStatus,
} from '../../outcomes/model'
import { paths } from '../../paths'
import { selectElapsedTimeOnSubmission } from '../../scan-qr/model'
import {
  resetUploadStore,
  selectPatientTestId,
  selectSubmitPatientTestLoading,
} from '../model'

const SpinnerLi = styled.li`
  display: grid;
  justify-content: center;
`
const PE_QUESTIONNAIRE = process.env.REACT_APP_ELIGIBILITY_QUESTIONNAIRE

export const UploadProcessingPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [tries, setTries] = useState(0)
  const isLoading = useSelector(selectSubmitPatientTestLoading)
  const testStatus = useSelector(selectDiagnosticTestStatus)
  const response = useSelector(selectDiagnosticOutcomeResponseCode)
  const testQRId = useSelector(selectPatientTestId)
  const result = useSelector(selectDiagnosticResult)
  const elapsedTime = useSelector(selectElapsedTimeOnSubmission)
  const i18n = useSelector(selectLanguageContent)
  const [message, setMessage] = useState(i18n.scan_cassete_processing)
  const { state, actions } = useFlowControl()

  const messageSteps = [
    i18n.scan_cassete_processing,
    i18n.scan_cassete_quality_confirm,
    i18n.scan_cassete_processing_result,
  ]

  const errorMessageCodes = [
    'Err1',
    'Err2',
    'Err3',
    'Err4',
    'Err5',
    'Err6',
    'Err7',
    'Err8',
    'Err9',
  ]

  type ErrorResponse =
    | 'Err1'
    | 'Err2'
    | 'Err3'
    | 'Err4'
    | 'Err5'
    | 'Err6'
    | 'Err7'
    | 'Err8'
    | 'Err9'

  const mlErrorMessages = {
    Err1: {
      title: i18n.ml_result_code_error_01_title,
      body: i18n.ml_result_code_error_01_msg,
    },
    Err2: {
      title: i18n.ml_result_code_error_02_title,
      body: i18n.ml_result_code_error_02_msg,
    },
    Err3: {
      title: i18n.ml_result_code_error_03_title,
      body: i18n.ml_result_code_error_03_msg,
    },
    Err4: {
      title: i18n.ml_result_code_error_04_title,
      body: i18n.ml_result_code_error_04_msg,
    },
    Err5: {
      title: i18n.ml_result_code_error_05_title,
      body: i18n.ml_result_code_error_05_msg,
    },
    Err6: {
      title: i18n.ml_result_code_error_06_title,
      body: i18n.ml_result_code_error_06_msg,
    },
    Err7: {
      title: i18n.ml_result_code_error_07_title,
      body: i18n.ml_result_code_error_07_msg,
    },
    Err8: {
      title: i18n.ml_result_code_error_08_title,
      body: i18n.ml_result_code_error_08_msg,
    },
    Err9: {
      title: i18n.ml_result_code_error_09_title,
      body: i18n.ml_result_code_error_08_msg,
    },
  } as any

  useEffect(() => {
    if (isLoading) return
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    if (testStatus === 'COMPLETED') {
      if (errorMessageCodes.includes(response)) {
        const errorMessage = mlErrorMessages[response]

        dispatch(
          setDialog({
            type: DialogType.TEST_PROCESSING_ERROR,
            options: {
              ...mlErrorMessages[response],
              confirmationLabel: i18n.try_again,
            },
          })
        )
        // }
        // history.push(paths.uploadFailed())
      } else {
        clearTestKitFlow()
        if (healthServiceId) {
          const encounterParams = {
            _id: healthServiceId,
            status: 0,
            patientTestIds: [result?._id],
          }
          dispatch(updateEncounterRecord(encounterParams))
          if (
            result
            // result?.metadata?.selfAssessmentOutcome?.toLowerCase() ===
            //   'positive' ||
            // result?.metadata?.selfAssessmentOutcome?.toLowerCase() ===
            //   'negative' ||
            // result?.metadata?.outcome?.toLowerCase() === 'negative' ||
            // result?.metadata?.outcome?.toLowerCase() === 'positive'
          ) {
            const data = {
              id: result._id,
              type: 'Screening',
            }
            //next - Flow Control
            dispatch(addHS(healthServiceId, data, 'connectedTests'))
            if (lsClient.getUserLSByKey('flowId')) {
              actions.next({ out: result._id })
              // dispatch(resetUploadStore())
            } else {
              // dispatch(resetUploadStore())
              history.push(paths.uploadSuccessful())
              // history.push(paths.symptomCheckerIntroById(PE_QUESTIONNAIRE))
            }
          }
          // dispatch(resetUploadStore())
          // todo: add path for Invalid result "More Testing Needed"
        } else history.push(paths.uploadSuccessful())
      }
    } else if (errorMessageCodes.includes(response)) {
      dispatch(
        setDialog({
          type: DialogType.TEST_PROCESSING_ERROR,
          options: {
            ...mlErrorMessages[response],
            confirmationLabel: i18n.try_again,
          },
        })
      )
    } else if (testStatus !== 'COMPLETED' && tries <= 5) {
      const timeout = setTimeout(() => {
        if (testQRId) {
          dispatch(getDiagnosticResult(testQRId, elapsedTime))
        }
        if (tries < 2) {
          setMessage(messageSteps[tries + 1])
        }
        setTries(tries + 1)
      }, 5000)
      return () => {
        clearTimeout(timeout)
      }
    }
    // else {
    //   if (healthServiceId) {
    //     const data = {
    //       id: testQRId,
    //       type: 'Screening',
    //     }
    //     dispatch(addHS(healthServiceId, data, 'connectedTests'))
    //   }
    //   dispatch(resetUploadStore())
    //   history.push(paths.uploadFailed())
    // }
  }, [tries, testQRId, isLoading])

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.spinner}>
          <Loading size={100} />
        </Box>
        <Typography variant="h6" className={classes.processText}>
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '500px',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '150px',
  },
  processText: {
    fontSize: 32,
    color: '#282D37',
    margin: 32,
  },
  loadingIcon: {
    display: 'block',
    fontSize: 250,
    color: '#fff',
  },
  loadingButton: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 48,
    fontWeight: 600,
    marginBottom: 48,
    marginLeft: 15,
    marginRight: 15,
  },
}))
