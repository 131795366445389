import Box from '@material-ui/core/Box'
import React from 'react'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { Typography } from '../../../ui'

export const ProductListItem = (props: {
  product: any
  color: string
  handleNext: (testId: string) => void
}) => {
  const { product, color, handleNext } = props
  const classes = shopifyStoreStyles()

  return (
    <Box
      className={classes.productItemWrapper}
      onClick={() => {
        handleNext(product.identifier)
      }}
    >
      <div
        className={classes.productItemImageWrapper}
        style={{ backgroundColor: color }}
      >
        {product?.featuredImageUrlString && (
          <img
            src={product?.featuredImageUrlString}
            className={classes.productItemDetailImg}
          />
        )}
      </div>
      <Box className={classes.productItemDetailWrapper}>
        <Typography className={classes.productItemName}>
          {product?.productName}
        </Typography>
        <Typography className={classes.productItemDesc}>
          {product?.productVendor}
        </Typography>
        <Typography className={classes.productItemPrice}>
          {product.price.printed()}
        </Typography>
      </Box>
    </Box>
  )
}
