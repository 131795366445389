import { makeStyles } from '@material-ui/core/styles'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTestImage,
  selectImageLoading,
  selectTestImage,
} from '../../../pages/test-details/model'
import { Loading } from '../../../ui'

export const TestImageViewer = (props: { onClose: () => void; id: string }) => {
  const { onClose, id } = props
  const loadingImage = useSelector(selectImageLoading)
  const dispatch = useDispatch()
  const testImage = useSelector(selectTestImage)
  const classes = useStyles()

  const imageRef = useRef<HTMLImageElement>(null)
  const imageIsLandscape =
    imageRef.current && imageRef.current.width > imageRef.current.height
  const [landscape, setLandscape] = useState<boolean | null>(null)

  useEffect(() => {
    dispatch(getTestImage(id))
  }, [id])

  useEffect(() => {
    if (imageRef.current) {
      setLandscape(imageRef.current.width > imageRef.current.height)
    }
  }, [imageIsLandscape, imageRef])

  return (
    <div className={classes.wrapper}>
      <CallReceivedIcon
        className={classes.leftIcon}
        onClick={() => onClose()}
      />
      {loadingImage ? (
        <div className={classes.spinner}>
          <Loading size={100} />
        </div>
      ) : (
        <div style={{ padding: 24 }}>
          <img
            src={`data:image/*;base64,${testImage}`}
            className={landscape ? `${classes.image} landscape` : classes.image}
            ref={imageRef}
          />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  image: {
    height: 500,
    borderRadius: 12,
    [theme.breakpoints.down(600)]: {
      'width': '70vw',
      'height': 'unset',
      '&.landscape': {
        margin: '70px 0px 100px',
        transform: 'rotate(90deg)',
      },
    },
  },
  leftIcon: {
    margin: 24,
    transform: 'rotate(45deg)',
    [theme.breakpoints.down(600)]: {
      marginLeft: '5vw',
    },
  },
  spinner: {
    height: 552,
    width: 600,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      height: 350,
      width: '70vw',
    },
  },
}))
