import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { QuestionnaireCheckbox } from '../../../features/questionnaire/atoms'
import { hexToRGBA } from '../../../libs/helpers'
import { Typography } from '../../../ui'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  setAutoSubmit,
  updateAnswer,
} from '../questionnaireV2Slice'
import { Option } from '../types'

export const YesNoQuestion = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const [answer, setAnswer] = useState('')
  const existingAnswer = useSelector(selectExistingAnswer)

  useEffect(() => {
    if (existingAnswer) {
      dispatch(updateAnswer(existingAnswer.value))
      setAnswer(existingAnswer.value)
    }
  }, [question, existingAnswer])

  const answerChanged = (value: string) => {
    setAnswer(value)
    dispatch(updateAnswer(value))
    dispatch(setAutoSubmit(true))
  }

  const optionList = () => {
    return question.options?.map((option: Option) => (
      <div
        key={option.value.translations.eng}
        onClick={() => answerChanged(option.value.translations.eng)}
        className={
          answer.includes(option.value.translations.eng)
            ? `${classes.checkbox} checked`
            : classes.checkbox
        }
      >
        <Typography className={classes.text}>
          {option.value.translations.eng}
        </Typography>
      </div>
      // <QuestionnaireCheckbox
      //   key={option.value.translations.eng}
      //   checked={answer.includes(option.value.translations.eng)}
      //   onChange={() => answerChanged(option.value.translations.eng)}
      //   label={option.value.translations.eng}
      // />
    ))
  }
  return <div className={classes.root}>{optionList()}</div>
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'space-between',
    },
  },
  checkbox: {
    'cursor': 'pointer',
    'height': 171,
    'width': 171,
    'border': '2px solid #E0E0E0',
    'borderRadius': 8,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '&:hover': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.05),
    },
    '&.checked': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.1),
    },
    [theme.breakpoints.down(600)]: {
      height: '40vw',
      width: '40vw',
    },
  },
  text: {
    fontSize: 36,
    fontWeight: 500,
  },
}))
