import makeStyles from '@material-ui/core/styles/makeStyles'
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined'
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined'
import React from 'react'
import { I18n } from '../../../../features/translation/types'
import { formatPhoneForDisplayUS } from '../../../../libs/utils'
import { Typography } from '../../../../ui'
import { ProfileData } from '../../../profile/type'
import { CurrentOptions } from '../../types'

export const CurrentPhoneNumber = (props: {
  i18n: I18n
  profileData: ProfileData
  type: CurrentOptions
  setEditType: (type: CurrentOptions) => void
}) => {
  const { i18n, profileData, setEditType, type } = props
  const classes = useStyles()

  const phoneNumber = profileData.contact?.mobileNumber
    ? formatPhoneForDisplayUS(profileData.contact.mobileNumber)
    : ''

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Current Phone Number</Typography>
      <div className={classes.infoContainer}>
        <LocalPhoneOutlinedIcon color="primary" />
        <Typography>{phoneNumber}</Typography>
        <span
          onClick={() => setEditType(type)}
          className={classes.editLink}
        >{`(${i18n.edit_button})`}</span>
      </div>
      <Typography className={classes.body}>
        This will be the phone number your licensed provider will use to call
        you.
      </Typography>
      {!profileData.contact?.mobileNumber && (
        <Typography variant="body1" className={classes.errorMessage}>
          You are missing required data. Please update your location info.
        </Typography>
      )}
    </div>
  )
}

export const CurrentLocation = (props: {
  i18n: I18n
  profileData: ProfileData
  type: CurrentOptions
  setEditType: (type: CurrentOptions) => void
  hasMissingData: boolean
}) => {
  const { i18n, profileData, setEditType, type, hasMissingData } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Current Location</Typography>
      <div className={classes.infoContainer}>
        <StoreOutlinedIcon
          color="primary"
          style={{ alignSelf: 'flex-start' }}
        />
        <div>
          <Typography>{profileData.currentLocation?.line1}</Typography>
          {profileData.currentLocation?.line2 && (
            <Typography>{profileData.currentLocation.line2}</Typography>
          )}
          <Typography>{`${profileData.currentLocation?.city}, ${profileData.currentLocation?.state} ${profileData.currentLocation?.zip}`}</Typography>
        </div>
        <span
          style={{ alignSelf: 'flex-end', marginBottom: '3px' }}
          onClick={() => setEditType(type)}
          className={classes.editLink}
        >{`(${i18n.edit_button})`}</span>
      </div>
      <Typography className={classes.body}>
        Used to locate pharmacies near you and pairs you with a licensed
        provider in your state (U.S. only).
      </Typography>
      {hasMissingData && (
        <Typography variant="body1" className={classes.errorMessage}>
          You are missing required data. Please update your location info.
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '342px',
    padding: '24px',
    margin: 8,
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '8px',
    [theme.breakpoints.down(600)]: {
      width: 'calc(100% - 48px)',
    },
    [theme.breakpoints.down(389)]: {
      padding: '12px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    fontFamily: 'inherit',

    textAlign: 'inherit',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  body: {
    width: '100%',
    fontWeight: 400,
    lineHeight: '16px',
    fontSize: 12,
    textAlign: 'inherit',
    fontFamily: 'inherit',
    // marginBottom: '32px',
    [theme.breakpoints.down(600)]: {
      // marginBottom: '16px',
      // width: '90%',
    },
    [theme.breakpoints.down(389)]: {
      // fontSize: 14,
      // marginBottom: '10px',
      // lineHeight: '20px',
    },
  },
  editLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.main,
  },
  button: {
    maxHeight: 45,
    margin: '0 auto',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}))
