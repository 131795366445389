import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import testResultsImg from '../../assets/img/test-results-main.svg'
import { SubHeaderGetTested } from '../../features/application'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { Content, useStyles, Wrapper } from '../../ui/templates/app-layout'
import { paths } from '../paths'

export const AddInsuranceInfoPage = () => {
  const classes = useStyles()
  const childClasses = childStyles()
  const history = useHistory()
  const { id, type } = useParams<{ id: string; type: string }>()
  const i18n = useSelector(selectLanguageContent)

  const goBack = () => {
    history.goBack()
  }

  const addInsuranceProvider = () => {
    history.push(paths.addInsuranceProvider(type, id))
  }

  return (
    <Wrapper>
      <SubHeaderGetTested title="" onHandleBack={goBack} />
      <Content noValidate>
        <img src={testResultsImg} alt="" />
        <Typography className={childClasses.infoText}>
          {i18n.select_add_insurance_option_message}
        </Typography>
        <Box className={classes.optionsWrapper} onClick={() => console.log()}>
          <ScanInsuranceIDCard i18n={i18n} />
        </Box>
        <Box className={classes.optionsWrapper} onClick={addInsuranceProvider}>
          <FindInsuranceProvider i18n={i18n} />
        </Box>
      </Content>
    </Wrapper>
  )
}

const ScanInsuranceIDCard = (props: { i18n: I18n }) => {
  const { i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  return (
    <Box
      className={childClasses.optionWrapper}
      style={{ cursor: 'default', backgroundColor: 'rgb(230 230 230 / 70%)' }}
    >
      <Box className={classes.option}>
        <Typography className={classes.testName}>
          {i18n.scan_insurance_button_title}
        </Typography>
        <Typography variant="subtitle2" className={childClasses.description}>
          {i18n.scan_insurance_button_description}
        </Typography>
      </Box>
      <Box className={childClasses.rightChevron}>
        <ChevronRightIcon />
      </Box>
    </Box>
  )
}

const FindInsuranceProvider = (props: { i18n: I18n }) => {
  const { i18n } = props
  const classes = useStyles()
  const childClasses = childStyles()
  return (
    <Box className={childClasses.optionWrapper}>
      <Box className={classes.option}>
        <Typography className={classes.testName}>
          {i18n.find_your_insurance_button_title}
        </Typography>
        <Typography variant="subtitle2" className={childClasses.description}>
          {i18n.find_your_insurance_button_description}
        </Typography>
      </Box>
      <Box className={childClasses.rightChevron}>
        <ChevronRightIcon />
      </Box>
    </Box>
  )
}

const childStyles = makeStyles({
  description: {
    color: '#7B7C7D',
  },
  rightChevron: {
    display: 'flex',
    alignSelf: 'center',
    color: '#003C72',
    fontSize: 16,
    fontFamily: 'Avenir Next Rounded Regular',
    lineHeight: '22px',
    fontWeight: 900,
  },
  optionWrapper: {
    'padding': '20px 15px',
    'borderRadius': 10,
    'display': 'flex',
    'justifyContent': 'space-between',
    'border': '1px solid #E6E7EA',
    'margin': '0 auto 20px',
    'maxWidth': 400,

    '&:first-child': {
      cursor: 'pointer',
    },
  },
  infoText: {
    textAlign: 'center',
    fontSize: 37,
    padding: '30px 0px',
  },
})
